import React, {useRef, useState} from "react";
import {Box, Button, Link, Paper, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";
import PropTypes from "prop-types";

import {CodeInput, EmailInput, ErrorNote, FormFooter} from "../common";

export default function ConfirmSignUpForm({authState, onSubmit, onBackToSignIn, onCodeResend}) {
  const formRef = useRef(null);
  const {
    context: {alert, userEmail},
  } = authState;
  const [verificationCode, setVerificationCode] = useState("");
  const onChange = ({currentTarget: {value}}) => setVerificationCode(value);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const {currentTarget: form} = event;
    form.classList.add("was-validated");

    if (form.checkValidity()) {
      onSubmit(verificationCode);
      form.classList.remove("was-validated");
    }
  };

  return (
    <Paper elevation={16} sx={{p: 2}}>
      <Typography variant="h6" align="center" mb={3}>
        Confirm Sign Up
      </Typography>
      <Box component="form" ref={formRef} noValidate onSubmit={handleSubmit}>
        <EmailInput form={formRef.current} name={"email"} value={userEmail} readOnly={true} onChange={onChange} />
        <CodeInput name={"verificationCode"} value={verificationCode} onChange={onChange} />

        <Typography color="text.disabled" variant="body2">
          Lost your Code?
          <Button
            component={Link}
            onClick={onCodeResend}
            size="small"
            sx={{fontSize: "inherit", color: blue.A400, ml: 1, "&:hover": {textDecoration: "underline"}}}>
            Resend Code
          </Button>
        </Typography>

        <ErrorNote {...{alert}} />
        <FormFooter backBtnTitle={"Back to Sign In"} onBackClicked={onBackToSignIn} sumbitBtnTitle={"Confirm"} />
      </Box>
    </Paper>
  );
}

ConfirmSignUpForm.propTypes = {
  authState: PropTypes.object,
  onSubmit: PropTypes.func,
  onBackToSignIn: PropTypes.func,
  onCodeResend: PropTypes.func,
};
