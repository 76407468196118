import React, {useContext, useEffect, useState} from "react";
import {Email, FolderZip, Link, PictureAsPdf, Share} from "@mui/icons-material";
import {Avatar, Box, IconButton, ListItemIcon, Menu, MenuItem, Tooltip} from "@mui/material";
import {useActor} from "@xstate/react";
import {Popover} from "bootstrap";
import {saveAs} from "file-saver";
import {MachineContext} from "lib";
import PropTypes from "prop-types";

import createVcfFile from "./createVcfFile";

export default function UserToolbar({isCvAvailable, handleCVMenuClick}) {
  const [state, send] = useActor(useContext(MachineContext));
  const {profileMachineRef, user} = state.context;
  const {state: profileState} = profileMachineRef || {};
  const {crewProfile, profileImgKey} = profileState?.context || {};
  const {firstName, midName, lastName, country, crewRank} = crewProfile || {};
  const {email, phone_number: phoneNumber} = user?.attributes || {};
  const [anchorEl, setAnchorEl] = useState(null);
  const [creatingVCard, setCreatingVCard] = useState(false);
  const open = Boolean(anchorEl);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const onMenuClick = (eventType) => () => handleCVMenuClick(eventType);

  const onVCardClick = async () => {
    setCreatingVCard(true);
    const fileInput = {
      firstName,
      midName,
      lastName,
      title: crewRank?.title || "",
      phoneNumber,
      email,
      country,
      profileImgKey,
    };
    const vcfFileString = await createVcfFile(fileInput);
    const vcfFile = new File([vcfFileString], "vCard.vcf");
    const message = `Share "vCard-${lastName}.vcf" file from your Downloads folder with your friend via Email or any Messenger.`;

    saveAs(vcfFile, `vCard-${lastName}.vcf`);
    send({type: "alert", data: {type: "success", message}});
    setCreatingVCard(false);
  };

  useEffect(() => {
    const popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
    popoverTriggerList.forEach((element) => new Popover(element));
  }, []);

  return (
    <Box
      sx={{
        width: 1,
        maxWidth: "20rem",
        display: "flex",
        justifyContent: "space-between",
        my: 1,
        "& .MuiIconButton-root": {"&:hover": {color: "primary.main"}},
        "& .MuiAvatar-root": {"&:hover": {bgcolor: "primary.main"}},
      }}>
      {isCvAvailable && (
        <Tooltip title="Generate CV">
          <IconButton onClick={handleClick}>
            <Avatar>CV</Avatar>
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Get vCard to Share">
        <IconButton onClick={onVCardClick} disabled={creatingVCard}>
          <Share sx={{width: 38, height: 38}} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Invite a Friend via email">
        <IconButton
          componenent="a"
          href={`mailto:?subject=I am using CrewBook! Try it too!
            &body=Join CrewBook today!%0d%0dIt's a simple and secure web app to store and manage crew documents at https://www.crewbook.app.`}>
          <Email sx={{width: 38, height: 38}} />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{horizontal: "left", vertical: "bottom"}}
        anchorOrigin={{horizontal: "left", vertical: "bottom"}}>
        <MenuItem onClick={onMenuClick("generateCV")}>
          <ListItemIcon>
            <PictureAsPdf fontSize="small" />
          </ListItemIcon>
          Download CV.pdf
        </MenuItem>
        <MenuItem onClick={onMenuClick("generateCVzip")}>
          <ListItemIcon>
            <FolderZip fontSize="small" />
          </ListItemIcon>
          Download CV-package.zip
        </MenuItem>
        <MenuItem onClick={onMenuClick("generateCVlink")}>
          <ListItemIcon>
            <Link fontSize="small" />
          </ListItemIcon>
          Get the link to share CV-package.zip
        </MenuItem>
      </Menu>
    </Box>
  );
}

UserToolbar.propTypes = {
  isCvAvailable: PropTypes.bool,
  handleCVMenuClick: PropTypes.func,
};
