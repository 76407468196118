import React from "react";
import {Grid, MenuItem} from "@mui/material";
import {FormInputSelect} from "lib/components";
import {LANG_LEVELS, LANGUAGES} from "lib/data";
import PropTypes from "prop-types";

export default function LanguagesForm({control, disabled}) {
  const rules = {
    englishLangLevel: {required: "English Language Proficiency is required"},
  };

  return (
    <Grid container spacing={{xs: 2, sm: 3}} mb={3}>
      <Grid item xs={12} sm={4}>
        <FormInputSelect
          name={"englishLangLevel"}
          control={control}
          label={"English Language Proficiency"}
          fullWidth
          rules={rules.englishLangLevel}
          disabled={disabled}
          options={LANG_LEVELS.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormInputSelect
          name={"otherLanguage1"}
          control={control}
          label={"Additional Language"}
          fullWidth
          disabled={disabled}
          options={LANGUAGES.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormInputSelect
          name={"otherLanguage2"}
          control={control}
          label={"Additional Language"}
          fullWidth
          disabled={disabled}
          options={LANGUAGES.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        />
      </Grid>
    </Grid>
  );
}

LanguagesForm.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
};
