import React from "react";
import {Typography} from "@mui/material";
import {ValidationFormField} from "lib/components";
import PropTypes from "prop-types";

export default function PasswordInput(props) {
  const {form, name, value, title = "Password", onChange, showRequirements = false} = props;

  return (
    <>
      {showRequirements && (
        <Typography variant="body2" color="text.disabled" sx={{pb: 3}}>
          <strong>Password requirements: </strong>
          minimum 8 characters, must contain at least one number, uppercase and lowercase letter.
        </Typography>
      )}
      <ValidationFormField
        form={form}
        required
        fullWidth
        type="password"
        id={name}
        label={title}
        size="small"
        helperText="Invalid password"
        name={name}
        value={value}
        onChange={onChange}
      />
    </>
  );
}

PasswordInput.propTypes = {
  form: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
  showRequirements: PropTypes.bool,
};
