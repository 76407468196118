import React, {useContext, useEffect, useRef} from "react";
import {useActor} from "@xstate/react";
import {Tooltip} from "bootstrap";
import {ICalendar} from "datebook";
import dayjs from "dayjs";
import {appSettings, MachineContext} from "lib";
import {calendarIcon} from "lib/icons";

export default function CalendarLink() {
  const [state, send] = useActor(useContext(MachineContext));
  const {crewDocsMachineRef} = state.context;
  const {crewDocs} = crewDocsMachineRef?.state.context || [];
  const calendarBtnRef = useRef(null);

  const getCalendarEvents = (docs) => {
    return docs.reduce((result, doc) => {
      const {
        expiryDate,
        doc: {title},
        docDescription: {content},
      } = doc;

      if (!expiryDate) return result;

      const today = dayjs();
      const expDate = dayjs(expiryDate);
      const isExpired = expDate.isBefore(today);
      return isExpired ? result : [...result, {date: expiryDate, docTitle: `${title} - ${content}`}];
    }, []);
  };

  const onClick = () => {
    const {
      requiredDocs: {items: docs},
    } = crewDocs;
    const expEvents = getCalendarEvents(docs);

    if (expEvents.length === 0) {
      const message = "No Events (expiry dates) to add to Calendar.";
      send({type: "alert", data: {type: "error", message}});
      return;
    }

    let icalendar = null;

    expEvents.forEach(({docTitle, date}) => {
      const eventOptions = {
        title: "Certification Expires",
        description: `Expiring Document: "${docTitle}"`,
        start: new Date(`${date}T00:00:00`),
      };
      const calEvent = new ICalendar(eventOptions);

      icalendar = !icalendar ? calEvent : (icalendar = icalendar.addEvent(calEvent));
    });

    if (icalendar) {
      icalendar.download();
      const message = `Open "Certification Expires.ics" file from your Downloads folder.
      Add events to Google Calendar / Outlook Calendar / iOS or macOS Calendar.`;
      !appSettings.isIOS && send({type: "alert", data: {type: "success", message}});
    }
  };

  useEffect(() => {
    const tooltipObj = new Tooltip(calendarBtnRef.current, {container: "body"});
    return () => {
      tooltipObj.dispose();
    };
  }, []);

  return (
    <div className="col-auto ms-auto">
      <button
        ref={calendarBtnRef}
        type="button"
        data-bs-toggle="tooltip"
        title="Add Expiry Dates to your Calendar"
        className="btn btn-outline-primary border-0"
        onClick={onClick}
        disabled={!(crewDocs?.requiredDocs?.items.length > 0)}>
        {calendarIcon}
      </button>
    </div>
  );
}
