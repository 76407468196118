import React, {useEffect, useRef} from "react";
import PropTypes from "prop-types";

import UnitSwitch from "./UnitSwitch";

export default function NonSatDiveForm({formState, onChange}) {
  const diveFormRef = useRef(null);
  const chamberTimeHH = formState.chamberTime.split(":")[0].padStart(2, "0");
  const chamberTimeMM = formState.chamberTime.split(":")[1].padStart(2, "0");

  const onChamberTimeChange = ({target}) => {
    const chamberTime = formState.chamberTime.split(":");
    const targetIntValue = parseInt(target.value, 10);
    let targetValue = Math.max(0, isNaN(targetIntValue) ? 0 : targetIntValue);
    targetValue =
      target.name === "chamberTimeHH" && targetValue > 99
        ? 99
        : target.name === "chamberTimeMM" && targetValue > 59
        ? 59
        : targetValue;
    const value =
      target.name === "chamberTimeHH" ? `${targetValue}:${chamberTime[1]}` : `${chamberTime[0]}:${targetValue}`;
    onChange({target: {name: "chamberTime", value}});
  };

  useEffect(() => {
    const allInputs = diveFormRef.current.querySelectorAll("input");
    allInputs.forEach((input) => {
      input.readOnly = !formState.diveType;
    });
  }, [formState.diveType]);

  return (
    <div ref={diveFormRef} className="col-12">
      <h6>Bell Bounce or Surface Dives</h6>
      <div className="row border rounded my-2 py-3 mx-0">
        <div className="col-12 col-sm-6 col-lg-4 col-xl-4">
          <div className="row mb-2 g-1">
            <label className="form-label col" htmlFor="maxDepth">
              Maximum depth of dive (<strong>{formState.measureUnit}</strong>)
            </label>
            <div className="col-auto">
              <input
                type="number"
                min="0"
                max="60"
                className="form-control form-control-sm"
                id="maxDepth"
                value={formState.maxDepth}
                onChange={onChange}
                name="maxDepth"
                required
              />
              <div className="invalid-feedback">Please provide a valid number Maximum depth of dive (example: 35).</div>
            </div>
            <div className="col-auto">
              <UnitSwitch {...{formState, onChange}} />
            </div>
          </div>
          <div className="row mb-2 g-1">
            <label className="form-label col" htmlFor="leftSurfaceTime">
              Time left surface or started pressurisation
            </label>
            <div className="col-auto">
              <input
                type="time"
                className="form-control form-control-sm"
                id="leftSurfaceTime"
                value={formState.leftSurfaceTime}
                name="leftSurfaceTime"
                pattern="[0-9]{2}:[0-9]{2}"
                onChange={onChange}
                required
              />
              <div className="invalid-feedback">Please provide a valid time when left surface.</div>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-lg-4 col-xl-4">
          <div className="row mb-2 g-1">
            <label className="form-label col" htmlFor="bottomTime">
              Bottom time (min)
            </label>
            <div className="col-auto">
              <input
                type="number"
                min="0"
                max="200"
                className="form-control form-control-sm"
                id="bottomTime"
                value={formState.bottomTime}
                onChange={onChange}
                name="bottomTime"
                required
              />
              <div className="invalid-feedback">Please provide a valid bottom time.</div>
            </div>
          </div>
          <div className="row mb-2 g-1">
            <label className="form-label col" htmlFor="decoCompletedAt">
              Decompression completed at
            </label>
            <div className="col-auto">
              <input
                type="time"
                className="form-control form-control-sm"
                id="decoCompletedAt"
                value={formState.decoCompletedAt}
                name="decoCompletedAt"
                pattern="[0-9]{2}:[0-9]{2}"
                onChange={onChange}
                required
              />
              <div className="invalid-feedback">Please provide a valid time of competed decompression.</div>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-lg-4 col-xl-4">
          <div className="row mb-2 g-1">
            <strong>For surface decompression only</strong>
          </div>
          <div className="row mb-2 g-1">
            <label className="form-label col" htmlFor="surfaceDecoInterval">
              Surface interval (min)
            </label>
            <div className="col-auto">
              <input
                type="number"
                min="0"
                max="99"
                className="form-control form-control-sm"
                id="surfaceDecoInterval"
                value={formState.surfaceDecoInterval}
                name="surfaceDecoInterval"
                onChange={onChange}
              />
            </div>
          </div>
          <div className="row g-1">
            <label className="form-label col" htmlFor="chamberTimeHH">
              Time spent in chamber (hh:mm)
            </label>
            <div className="col-auto">
              <input
                type="number"
                min="0"
                max="99"
                className="form-control form-control-sm"
                id="chamberTimeHH"
                value={chamberTimeHH}
                name="chamberTimeHH"
                onChange={onChamberTimeChange}
              />
            </div>
            <div className="col-auto">:</div>
            <div className="col-auto">
              <input
                type="number"
                min="0"
                max="59"
                className="form-control form-control-sm"
                id="chamberTimeMM"
                value={chamberTimeMM}
                name="chamberTimeMM"
                onChange={onChamberTimeChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

NonSatDiveForm.propTypes = {
  formState: PropTypes.object,
  onChange: PropTypes.func,
};
