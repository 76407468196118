export default function validatePasswords(password, confirmPassword) {
  if (password !== confirmPassword) {
    return {isValid: false, message: "Passwords do not match."};
  }

  const regex = /^\S*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])\S*$/g;
  const isValid = regex.test(password);
  return {
    isValid,
    message: isValid
      ? ""
      : "Password does not match requirements: minimum length 8 characters, contain at least one of each: number, uppercase and lowercase letter.",
  };
}
