import {assign, sendParent} from "xstate";

const actions = {
  updateUserEmail: assign({
    userEmail: (_, {data: {email}}) => email,
    alert: null,
  }),
  updateUser: assign({
    user: (_, {data}) => data,
  }),
  updateSuccess: assign({
    alert: (_, {data}) => ({type: "success", message: data}),
  }),
  updateError: assign({
    alert: (_, {data, type}) => {
      const message =
        data?.errors?.map((error) => error.message).join(". ") || data?.message || data?.stack || `${data}.${type}`;
      return {type: "error", message};
    },
  }),
  machineStartsWorking: assign({isMachineWorking: () => true}),
  machineStopsWorking: assign({isMachineWorking: () => false}),
  sendParentMachineStartsWorking: sendParent(() => ({type: "authMachineIsWorking", data: true})),
  sendParentMachineStopsWorking: sendParent(() => ({type: "authMachineIsWorking", data: false})),
  resetAlert: assign({alert: null}),
  logger: (context, event) => console.log("AUTH MACHINE EVENT", event, context),
};

export default actions;
