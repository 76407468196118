import React, {useMemo} from "react";
import {Box, Grid} from "@mui/material";
import {useActor, useSelector} from "@xstate/react";
import PropTypes from "prop-types";

import ResetPasswordForm from "./passwordReset/ResetPasswordForm";
import VerifyPasswordResetForm from "./passwordReset/VerifyPasswordResetForm";
import SignInForm from "./signIn/SignInForm";
import ConfirmSignUpForm from "./signUp/ConfirmSignUpForm";
import SignUpForm from "./signUp/SignUpForm";

const selectState = (state) => state.toStrings().pop();

export default function AuthForm({authMachine}) {
  const [authState, authSend] = useActor(authMachine);
  const {alert} = authState.context;
  const stateValue = useSelector(authMachine, selectState);

  const authComponents = useMemo(() => {
    const handleSignIn = (props) => authSend({type: "signIn", data: props});
    const handleResetPassword = () => authSend("resetPassword");
    const handleSendCode = (email) => authSend({type: "sendCode", data: {email}});
    const handleConfirmReset = ({verificationCode, newPassword}) =>
      authSend({type: "confirm", data: {verificationCode, newPassword}});
    const handleSignUp = (props) => authSend({type: "signUp", data: props});
    const handleConfirmSignUp = (props) => authSend({type: "confirm", data: props});
    const handleBackToSignIn = () => authSend("backToSignIn");
    const handleCodeResend = () => authSend("resendCode");

    return {
      "signIn.loginInput": (
        <SignInForm
          alert={alert}
          onSubmit={handleSignIn}
          onResetPassword={handleResetPassword}
          onSignUp={handleSignUp}
        />
      ),
      "passwordReset.emailInput": (
        <ResetPasswordForm alert={alert} onSubmit={handleSendCode} onBackToSignIn={handleBackToSignIn} />
      ),
      "passwordReset.changePassword": (
        <VerifyPasswordResetForm alert={alert} onSubmit={handleConfirmReset} onBackToSignIn={handleBackToSignIn} />
      ),
      "passwordReset.verifyPasswordReset": (
        <VerifyPasswordResetForm
          alert={alert}
          onSubmit={handleConfirmReset}
          onBackToSignIn={handleBackToSignIn}
          isCodeRequired={true}
        />
      ),
      "signUp.registration": <SignUpForm alert={alert} onSubmit={handleSignUp} onSignIn={handleSignIn} />,
      "signUp.confirmation": (
        <ConfirmSignUpForm
          authState={authState}
          onSubmit={handleConfirmSignUp}
          onBackToSignIn={handleBackToSignIn}
          onCodeResend={handleCodeResend}
        />
      ),
    };
  }, [alert, authState, authSend]);

  return (
    <Grid
      container
      spacing={1}
      sx={{
        flexGrow: 1,
        alignItems: "center",
        zIndex: 1000,
        justifyContent: {xs: "center", sm: "flex-end"},
      }}>
      <Grid item xs={12} sm={10} md={8} lg={6} xl={5} sx={{display: "flex", justifyContent: "center"}}>
        <Box sx={{flexGrow: 1, maxWidth: "32rem"}}>
          {authComponents[stateValue] ? authComponents[stateValue] : <></>}
        </Box>
      </Grid>
    </Grid>
  );
}

AuthForm.propTypes = {
  authMachine: PropTypes.object,
};
