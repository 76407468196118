import React, {useState} from "react";
import PropTypes from "prop-types";

export default function EmploymentForm({isSearchDisabled, handleSearch}) {
  const [userEmail, setUserEmail] = useState("");

  const onChange = ({target: {value}}) => setUserEmail(value);

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;

    form.classList.add("was-validated");

    if (form.checkValidity()) {
      handleSearch(userEmail.toLocaleLowerCase());
      form.classList.remove("was-validated");
      setUserEmail("");
    }
  };

  return (
    <form onSubmit={onSubmit} className="row g-3 needs-validation" noValidate>
      <label htmlFor="employerEmail" className="form-label mb-0">
        Crew Manager email address
      </label>
      <div className="input-group mb-3 mt-0">
        <input
          type="email"
          className="form-control"
          id="employerEmail"
          onChange={onChange}
          value={userEmail}
          name="employerEmail"
          required
          disabled={isSearchDisabled}
          aria-label="crew manager's email"
          aria-describedby="search"
        />
        <button
          className="btn btn-outline-primary rounded-end px-4 px-sm-5"
          type="submit"
          disabled={isSearchDisabled}
          id="search">
          Search
        </button>
        <div className="invalid-feedback">Please provide a valid email.</div>
      </div>
    </form>
  );
}

EmploymentForm.propTypes = {
  isSearchDisabled: PropTypes.bool,
  handleSearch: PropTypes.func,
};
