async function confirmDialog({send, service, setup}) {
  send({type: "setupConfirmModal", data: setup});

  return new Promise((resolve) => {
    const subscription = service.subscribe((state) => {
      const {decision} = state.context.confirmModalSetup || {};

      if (decision !== undefined) {
        subscription.unsubscribe();
        send({type: "setupConfirmModal", data: null});
        resolve(decision);
      }
    });
  });
}

export default confirmDialog;
