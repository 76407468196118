import React from "react";
import {Typography} from "@mui/material";
import {ValidationFormField} from "lib/components";
import PropTypes from "prop-types";

export default function CodeInput(props) {
  const {form, name, value, onChange, showSpamHint = false} = props;

  return (
    <>
      {showSpamHint && (
        <Typography variant="body2">
          Check your Spam or Junk folder, if the Confirmation Email does not arrive in your Inbox.
        </Typography>
      )}

      <ValidationFormField
        fullWidth
        required
        autoFocus
        form={form}
        autoComplete="off"
        type="text"
        id={name}
        label="Verification Code"
        size="small"
        name={name}
        value={value}
        onChange={onChange}
        helperText="Incorrect Confirmation code (check your email)"
      />
    </>
  );
}

CodeInput.propTypes = {
  form: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  showSpamHint: PropTypes.bool,
};
