import dayjs from "dayjs";

const sortData = ({rows, column}) => {
  const sortedData = [...rows];
  const {field, type, valueGetter} = column;

  sortedData.sort((rowA, rowB) => {
    const valueA = valueGetter ? valueGetter({row: rowA}) : rowA[field];
    const valueB = valueGetter ? valueGetter({row: rowB}) : rowB[field];

    let sortingResult =
      !valueA && !!valueB ? (column.sorted < 0 ? -1 : 1) : !!valueA && !valueB ? (column.sorted < 0 ? 1 : -1) : 0;

    if (sortingResult !== 0 || (!valueA && !valueB)) {
      return column.sorted * sortingResult;
    }

    switch (type) {
      case "string": {
        const a = valueA.toLowerCase();
        const b = valueB.toLowerCase();
        sortingResult = a < b ? -1 : a > b ? 1 : 0;
        break;
      }
      case "number":
        sortingResult = valueA - valueB;
        break;
      case "date": {
        const dateA = dayjs(valueA);
        const dateB = dayjs(valueB);
        sortingResult = dateA.isBefore(dateB) ? -1 : dateB.isBefore(dateA) ? 1 : 0;
        break;
      }
      case "boolean": {
        const a = valueA.props.checked ? 1 : 0;
        const b = valueB.props.checked ? 1 : 0;
        sortingResult = a < b ? -1 : a > b ? 1 : 0;
        break;
      }
      default:
        break;
    }
    return column.sorted * sortingResult;
  });
  return sortedData;
};

export default sortData;
