export const getCrewProfile = /* GraphQL */ `
  query GetCrewProfile($id: ID!) {
    getCrewProfile(id: $id) {
      id
      owner
      employerId
      firstName
      midName
      lastName
      birthDate
      birthPlace
      address
      city
      state
      country
      emerContactName
      emerContactRelation
      emerContactPhone
      emerContactAddress
      emerContactEmail
      nextOfKin
      nationality
      fatherName
      motherName
      nearestAirport
      maritalStatus
      religion
      numberOfChildren
      weight
      bloodGroup
      eyesColor
      hairColor
      gender
      coverallSize
      shoeSize
      englishLangLevel
      otherLanguage1
      otherLanguage2
      crewRankId
      signatureHash
      crewRank {
        id
        title
        status
        groupId
        group {
          id
          title
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          crewRanks {
            items {
              id
              title
              status
              hierarchy
              groupId
            }
            nextToken
          }
        }
        docs {
          items {
            document {
              id
              title
              docType
              descriptions {
                items {
                  id
                  content
                }
                nextToken
              }
            }
          }
        }
      }
      docs {
        items {
          id
          owner
          issueDate
          expiryDate
          issuePlace
          includedInCV
          file {
            bucket
            key
            region
          }
          docSize
          docNum
          title
          docId
          doc {
            title
            docType
          }
          docDescriptionId
          docDescription {
            content
          }
        }
      }
      personalDocs {
        items {
          id
          issueDate
          includedInCV
          expiryDate
          file {
            bucket
            key
            region
          }
          docSize
          docNum
          issuePlace
          title
          owner
        }
      }
      seaLog {
        items {
          id
          vesselName
          vesselType
          tonnage
          operationArea
          engagementPlace
          dischargePlace
          rankTitle
          dateFrom
          dateTo
          company
          createdAt
          updatedAt
          owner
        }
      }
      diveLog {
        items {
          id
          owner
          diveDate
          contractorName
          contractorAddress
          diveLocation
          worksite
          diveType
          measureUnit
          helmetType
          gasMixture
          workDescription
          decoSchedules
          incidents
          remarks
          divingSupName
          storageDepth
          maxDepth
          bellLockOff
          leftBell
          returnedBell
          lockOutTime
          bellLockOn
          leftSurfaceTime
          bottomTime
          decoCompletedAt
          surfaceDecoInterval
          chamberTime
        }
      }
      diveSupLog {
        items {
          id
          owner
          diveDate
          contractorName
          contractorAddress
          diveLocation
          worksite
          supervisionType
          diveType
          unitRatio
          incidents
          remarks
          contractorRepTitle
          contractorRepName
        }
      }
      subscriptions {
        items {
          id
          isFree
          expiryDate
        }
      }
      notifications {
        items {
          id
          notificationId
          notification {
            id
            description
            expiryInterval
          }
          owner
        }
      }
      employmentUpdates {
        items {
          employmentId
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const rankGroupsByCharge = /* GraphQL */ `
  query RankGroupsByCharge(
    $status: String!
    $subscriptionCharge: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRankGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rankGroupsByCharge(
      status: $status
      subscriptionCharge: $subscriptionCharge
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        subscriptionCharge
        subscriptionDetails
        subscriptionPriceId
        bucketSize
      }
    }
  }
`;
export const getShortCrewProfileByCrewId = /* GraphQL */ `
  query GetCrewProfile($id: ID!) {
    getCrewProfile(id: $id) {
      id
      firstName
      midName
      lastName
      subscriptions {
        items {
          id
          isFree
          expiryDate
        }
      }
      crewRankId
      crewRank {
        id
        title
        groupId
        group {
          id
          title
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
        }
      }
    }
  }
`;
export const listShipCrewEmployments = /* GraphQL */ `
  query ListShipCrewEmployments {
    listShipCrewEmployments {
      updates
      items {
        id
        tenantId
        employerName
        employmentStatus {
          status
          description
          crewActions
        }
        salaryPaymentMethod
        designatedRankTitle
        vesselName
        crewTrainingMatrix
        contract {
          signedDate
          expiryDate
          rotationOn
          rotationOff
          contractFile {
            bucket
            key
            region
          }
          offerFile {
            bucket
            key
            region
          }
        }
        dayRates {
          currency
          dayRates {
            status
            rate
          }
          ON_TRAVEL
          ON_QUARANTINE
          ON_BOARD
          ON_SICK_LEAVE
          ON_TRAINING
          ON_STANDBY
        }
        employmentEvents {
          items {
            id
            tenantId
            senderId
            senderName
            memo
            type
            file {
              bucket
              key
              region
            }
            shipCrewId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEmploymentDetails = /* GraphQL */ `
  query GetEmploymentDetails($id: ID!) {
    getEmploymentDetails(id: $id) {
      id
      tenantId
      employerName
      employmentStatus {
        status
        description
        crewActions
      }
      salaryPaymentMethod
      designatedRankTitle
      vesselName
      crewTrainingMatrix
      contract {
        signedDate
        expiryDate
        rotationOn
        rotationOff
        contractFile {
          bucket
          key
          region
        }
        offerFile {
          bucket
          key
          region
        }
      }
      dayRates {
        currency
        dayRates {
          status
          rate
        }
        ON_TRAVEL
        ON_QUARANTINE
        ON_BOARD
        ON_SICK_LEAVE
        ON_TRAINING
        ON_STANDBY
      }
      employmentEvents {
        items {
          id
          tenantId
          shipCrewId
          senderId
          senderName
          memo
          type
          file {
            bucket
            key
            region
          }
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
