import React, {useCallback, useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Box} from "@mui/material";
import {useActor} from "@xstate/react";
import MachineContext from "lib/MachineContext";

import FormFooter from "../submit/FormFooter";

import EmergencyContact from "./EmergencyContact";
import FamilyDetailsForm from "./FamilyDetailsForm";
import LanguagesForm from "./LanguagesForm";

const initialFormState = {
  emerContactName: "",
  emerContactRelation: "",
  emerContactPhone: "",
  emerContactAddress: "",
  emerContactEmail: "",
  fatherName: "",
  motherName: "",
  englishLangLevel: "",
  otherLanguage1: "",
  otherLanguage2: "",
  maritalStatus: "",
  disabled: true,
};

export default function AdditionalForm() {
  const [state, send] = useActor(useContext(MachineContext));
  const {profileMachineRef} = state.context;
  const {state: profileState} = profileMachineRef; // TODO: refactor with useMemo
  const {crewProfile} = profileState.context;
  const [disabled, setDisabled] = useState(true);

  const methods = useForm({defaultValues: initialFormState, mode: "all"});
  const {handleSubmit, control, reset, formState} = methods;
  const {isValid, isDirty, dirtyFields} = formState;

  const initFormState = useCallback(
    ({
      emerContactName,
      emerContactRelation,
      emerContactPhone,
      emerContactAddress,
      emerContactEmail,
      fatherName,
      motherName,
      englishLangLevel,
      otherLanguage1,
      otherLanguage2,
      maritalStatus,
    }) => ({
      emerContactName: emerContactName || "",
      emerContactRelation: emerContactRelation || "",
      emerContactAddress: emerContactAddress || "",
      emerContactPhone: emerContactPhone || "",
      emerContactEmail: emerContactEmail || "",
      fatherName: fatherName || "",
      motherName: motherName || "",
      englishLangLevel: englishLangLevel || "",
      otherLanguage2: otherLanguage2 || "",
      otherLanguage1: otherLanguage1 || "",
      maritalStatus: maritalStatus || "",
    }),
    []
  );

  const onDiscard = () => {
    reset();
    setDisabled(true);
  };

  const onEdit = () => setDisabled(false);

  const onSubmit = (data) => {
    if (isValid && isDirty) {
      const dirtyFieldsKeys = Object.keys(dirtyFields);
      const updatedProfile = dirtyFieldsKeys.reduce((acc, key) => {
        acc[key] = data[key];
        return acc;
      }, {});
      const {emerContactEmail, ...props} = updatedProfile;
      const emerContactEmailEmpty = emerContactEmail === "";
      const deleteEmerContactEmail = emerContactEmailEmpty && !!crewProfile.emerContactEmail;
      const updateData = {updatedProfile: emerContactEmailEmpty ? props : updatedProfile, deleteEmerContactEmail};
      send({type: "updateProfile", data: updateData});
    }
  };

  useEffect(() => {
    !!crewProfile && reset(initFormState(crewProfile));
    !crewProfile || !crewProfile?.emerContactName ? setDisabled(false) : setDisabled(true);
  }, [crewProfile, initFormState, reset]);

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <LanguagesForm control={control} disabled={disabled} />
      <FamilyDetailsForm control={control} disabled={disabled} />
      <EmergencyContact control={control} disabled={disabled} />
      <FormFooter isDisabled={disabled} onDiscard={onDiscard} onEdit={onEdit} isValid={isValid && isDirty} />
    </Box>
  );
}
