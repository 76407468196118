import React from "react";
import {Controller} from "react-hook-form";
import {DatePicker} from "@mui/x-date-pickers";
import {isAfter, isBefore, isValid, set} from "date-fns";
import PropTypes from "prop-types";

const resetValue = {hours: 0, minutes: 0, seconds: 0, milliseconds: 0};

export default function FormInputDate(props) {
  const {control, label, name, size = "small", required = true, disabled = false, rangeLimits} = props;

  const rules = {
    validate: (value) => {
      const isValidDate = isValid(value);
      const resetedValue = isValidDate ? set(value, resetValue) : null;
      return !required && !resetedValue
        ? true
        : (isValidDate &&
            (rangeLimits?.max ? !isAfter(resetedValue, rangeLimits.max) : true) &&
            (rangeLimits?.min ? !isBefore(value, rangeLimits.min) : true)) ||
            `Choose valid Date`;
    },
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field: {onChange, value, onBlur, ref}, fieldState: {invalid, error}}) => (
        <DatePicker
          views={["year", "month", "day"]}
          label={label}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          inputRef={ref}
          minDate={rangeLimits?.min}
          maxDate={rangeLimits?.max}
          defaultValue={rangeLimits?.max}
          format="dd/MM/yyyy"
          disabled={disabled}
          slotProps={{
            textField: {
              variant: "outlined",
              fullWidth: true,
              size,
              error: invalid,
              helperText: error?.message,
              FormHelperTextProps: {error: invalid},
            },
          }}
        />
      )}
    />
  );
}

FormInputDate.propTypes = {
  control: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  rangeLimits: PropTypes.object,
};
