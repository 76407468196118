import React from "react";
import {sortAlphaDownIcon, sortAlphaUpIcon} from "lib/icons";
import PropTypes from "prop-types";

export default function Header({column, onClick}) {
  const {title, sorted} = column;
  const onHeaderClick = () => onClick(column);

  return (
    <th className="table-header" scope="col" onClick={onHeaderClick}>
      <div className="d-flex justify-content-between">
        <span>{title}</span>
        <span className="text-primary">{sorted === 1 ? sortAlphaUpIcon : sorted === -1 ? sortAlphaDownIcon : ""}</span>
      </div>
    </th>
  );
}

Header.propTypes = {
  column: PropTypes.object,
  onClick: PropTypes.func,
};
