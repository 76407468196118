import React from "react";
import {Controller} from "react-hook-form";
import {FormControlLabel, FormHelperText} from "@mui/material";
import {CustomSwitch} from "lib/components";
import PropTypes from "prop-types";

export default function FormInputSwitch(props) {
  const {name, control, label, rules, disabled = false} = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field: {onChange, onBlur, value, name, ref}, fieldState: {error}}) => (
        <FormControlLabel
          label={label}
          sx={{m: 0}}
          control={
            <CustomSwitch
              id={name}
              checked={value}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              name={name}
              disabled={disabled}
            />
          }>
          {error && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControlLabel>
      )}
    />
  );
}

FormInputSwitch.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  rules: PropTypes.object,
};
