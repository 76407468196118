import React from "react";
import {Close} from "@mui/icons-material";
import {Dialog, DialogContent, DialogTitle, Divider, IconButton} from "@mui/material";
import PropTypes from "prop-types";

export default function AlertToast({content, show, onCloseClick}) {
  return (
    <Dialog open={show} onClose={onCloseClick} aria-labelledby="alert-dialog-title" fullWidth maxWidth="xs">
      <DialogTitle id="alert-dialog-title" sx={{p: 1.5}}>
        <img className="bg-primary text-white rounded me-3 p-1" src="/images/logo.svg" width="30" alt="CrewB logo" />
        CrewBook
        <IconButton
          aria-label="close"
          data-confirm={-1}
          onClick={onCloseClick}
          sx={{position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
          <Close />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent sx={{p: 1.5}}>{content}</DialogContent>
    </Dialog>
  );
}

AlertToast.propTypes = {
  content: PropTypes.node,
  show: PropTypes.bool,
  onCloseClick: PropTypes.func,
};
