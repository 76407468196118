import React from "react";
import {Controller} from "react-hook-form";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";
import PropTypes from "prop-types";

export default function FormInputSelect(props) {
  const {
    name,
    control,
    label,
    fullWidth,
    size = "small",
    rules,
    options = [],
    disabled = false,
    addNoneOption = false,
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field: {onChange, onBlur, value, name, ref}, fieldState: {invalid, error}}) => (
        <FormControl error={invalid} fullWidth={fullWidth} size={size}>
          <InputLabel htmlFor={name}>{label}</InputLabel>
          <Select
            id={name}
            label={label}
            value={value}
            onBlur={onBlur}
            inputRef={ref}
            onChange={onChange}
            name={name}
            disabled={disabled}>
            {addNoneOption && <MenuItem value="">--None--</MenuItem>}
            {options}
          </Select>
          {error && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}

FormInputSelect.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  addNoneOption: PropTypes.bool,
  size: PropTypes.string,
  rules: PropTypes.object,
  options: PropTypes.array,
};
