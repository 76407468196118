const unbreakableStack = ({tableTitle, widths, body}) =>
  body.length === 1
    ? ""
    : {
        stack: [
          {text: tableTitle, style: "h1", headlineLevel: 1},
          {
            table: {
              headerRows: 1,
              widths,
              dontBreakRows: true,
              body,
            },
          },
        ],
      };

export default unbreakableStack;
