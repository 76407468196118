import React, {useEffect, useReducer, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {Box, Paper, Typography} from "@mui/material";
import intlTelInput from "intl-tel-input";
import {formReducer, validateEmail, validatePasswords} from "lib/helpers";
import PropTypes from "prop-types";

import {ConsentCheckbox, EmailInput, ErrorNote, FormFooter, PasswordInput, PhoneFieldInput} from "../common";

import "intl-tel-input/build/css/intlTelInput.css";
import "scss/phoneInput.scss";

export default function SignUpForm({alert, onSubmit, onSignIn}) {
  const formRef = useRef(null);
  const [error, setError] = useState("");
  const [phoneInput, setPhoneInput] = useState(null);
  const initialState = {
    email: localStorage.getItem("loginEmail") || "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  };
  const [formState, dispatch] = useReducer(formReducer, initialState);

  const onChange = ({currentTarget: {name, value}}) => dispatch({type: name, data: value});

  const handlePhoneChange = (event) => {
    const isValid = phoneInput.isValidNumber();
    event.currentTarget.setCustomValidity(isValid ? "" : "Phone Number is Invalid.");
    onChange(event);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    formRef.current.classList.add("was-validated");

    if (formRef.current.checkValidity()) {
      localStorage.setItem("loginEmail", formState.email);
      const {email, password, confirmPassword} = formState;
      const {isValid: isPasswordValid, message: passwordError} = validatePasswords(password, confirmPassword);
      const {isValid: isEmailValid, message: emailError} = validateEmail(email);
      const isValid = isPasswordValid && isEmailValid;

      isValid
        ? onSubmit({email, password, phoneNumber: phoneInput.getNumber()})
        : setError(`${passwordError} ${!isEmailValid ? " " : ""} ${emailError}`);
      formRef.current.classList.remove("was-validated");
      dispatch({type: "reset", data: isValid ? initialState : {password: "", confirmPassword: ""}});
    }
  };

  useEffect(() => {
    const input = intlTelInput(document.getElementById("phoneNumber"), {
      preferredCountries: [],
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.min.js",
    });
    setPhoneInput(input);
    return () => {
      input.destroy();
    };
  }, []);

  return (
    <Paper elevation={16} sx={{p: 2}}>
      <Typography variant="h6" align="center" mb={3}>
        Create a new Crew Account
      </Typography>
      <Box component="form" ref={formRef} noValidate onSubmit={handleSubmit}>
        <EmailInput form={formRef.current} name={"email"} value={formState.email} onChange={onChange} />
        <PasswordInput
          form={formRef.current}
          name={"password"}
          value={formState.password}
          onChange={onChange}
          showRequirements={true}
        />
        <PasswordInput
          form={formRef.current}
          name={"confirmPassword"}
          value={formState.confirmPassword}
          onChange={onChange}
          title={"Confirm Password"}
        />

        <PhoneFieldInput
          form={formRef.current}
          name={"phoneNumber"}
          value={formState.phoneNumber}
          onChange={handlePhoneChange}
        />

        <ErrorNote {...{alert, error}} />
        <Typography variant="subtitle2" mb={1}>
          To Create an account you must agree with our&nbsp;
          <Link to="/terms">Terms and Conditions</Link>
          {`, `}
          <Link to="/privacy">Privacy Policy</Link>
          &nbsp;and&nbsp;<Link to="/cookies">Cookie Policy</Link>.
        </Typography>
        <ConsentCheckbox form={formRef.current} name={"consent"} fieldType="CHECKBOX" />
        <FormFooter
          backActionLabel={"Have an account?"}
          backBtnTitle={"Sign In"}
          onBackClicked={onSignIn}
          sumbitBtnTitle={"Create Account"}
        />
      </Box>
    </Paper>
  );
}

SignUpForm.propTypes = {
  alert: PropTypes.object,
  onSubmit: PropTypes.func,
  onSignIn: PropTypes.func,
};
