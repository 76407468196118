import React, {useEffect} from "react";
import {Grid, MenuItem} from "@mui/material";
import {FormInputSelect, FormInputText} from "lib/components";
import COUNTRIES from "lib/data/coutriesList";
import PropTypes from "prop-types";

const fields = ["engagementPlace", "dischargePlace", "operationCountry", "operationArea"];

export default function OperationForm({getValues, trigger, watch, dirtyFields, control}) {
  const [engagementPlace, dischargePlace, operationCountry, operationArea] = watch(fields);

  const rules = {
    engagementPlace: {
      validate: (value) => {
        if (value !== "") return true;
        const values = getValues(["dischargePlace", "operationCountry", "operationArea"]);
        const [dischargePlace, operationCountry, operationArea] = values;
        return (
          (dischargePlace === "" && (operationCountry !== "" || operationArea !== "")) || "Choose Engagement Country"
        );
      },
    },
    dischargePlace: {
      validate: (value) => {
        if (value !== "") return true;
        const values = getValues(["engagementPlace", "operationCountry", "operationArea"]);
        const [engagementPlace, operationCountry, operationArea] = values;
        return (
          (engagementPlace === "" && (operationCountry !== "" || operationArea !== "")) || "Choose Discharge Country"
        );
      },
    },
    operationCountry: {
      validate: (value) => {
        if (value !== "") return true;
        const values = getValues(["engagementPlace", "dischargePlace", "operationArea"]);
        const [engagementPlace, dischargePlace, operationArea] = values;
        return (
          (operationArea === "" && (engagementPlace !== "" || dischargePlace !== "")) || "Choose Country of Operation"
        );
      },
    },
    operationArea: {
      pattern: {value: /^[\w .,-/]{1,64}$/, message: "Only alphanumeric characters and .,-/ are allowed"},
    },
  };

  useEffect(() => {
    const isDirty = Object.keys(dirtyFields).some((field) => fields.includes(field));
    isDirty && trigger(fields);
  }, [engagementPlace, dischargePlace, operationCountry, operationArea, dirtyFields, trigger]);

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} sm={6} md={3}>
        <FormInputSelect
          name={"engagementPlace"}
          control={control}
          label={"Engagement Country"}
          rules={rules.engagementPlace}
          fullWidth
          addNoneOption
          options={
            COUNTRIES.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            )) || []
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormInputSelect
          name={"dischargePlace"}
          control={control}
          label={"Discharge Country"}
          rules={rules.dischargePlace}
          fullWidth
          addNoneOption
          options={
            COUNTRIES.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            )) || []
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormInputSelect
          name={"operationCountry"}
          control={control}
          label={"Country of Operation"}
          rules={rules.operationCountry}
          fullWidth
          addNoneOption
          options={
            COUNTRIES.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            )) || []
          }
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <FormInputText
          name={"operationArea"}
          control={control}
          label={"Area of Operation"}
          fullWidth
          rules={rules.operationArea}
        />
      </Grid>
    </Grid>
  );
}

OperationForm.propTypes = {
  watch: PropTypes.func,
  getValues: PropTypes.func,
  trigger: PropTypes.func,
  control: PropTypes.object,
  dirtyFields: PropTypes.object,
};
