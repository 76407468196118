import React, {useContext} from "react";
import {useActor} from "@xstate/react";
import dayjs from "dayjs";
import {Table} from "lib/components";
import {confirmDialog} from "lib/helpers";
import MachineContext from "lib/MachineContext";
import PropTypes from "prop-types";

const DocsTable = ({columns, rows}) => {
  const service = useContext(MachineContext);
  const [state, send] = useActor(service);
  const {crewDocsMachineRef} = state.context;

  async function onRowDelete(row) {
    const docTitle = row.doc?.title || row.title;
    const content = (
      <div>
        <p>
          You are about to delete your Document: &#34;<strong>{docTitle}</strong>&#34;.
        </p>
        <p>This process cannot be undone.</p>
      </div>
    );
    const result = await confirmDialog({send, service, setup: {content, consent: true}});

    result === 1 &&
      send({
        type: "deleteDoc",
        data: {docData: {fileKey: row.file.key, id: row.id}},
      });
  }

  const onDocDownload = (row) => {
    const {doc, title, file} = row;
    const data = {fileName: doc?.title || title, fileKey: file.key};
    crewDocsMachineRef.send({type: "downloadDoc", data});
  };

  const rowStyle = (row) => {
    const style = {};

    if (row.expiryDate) {
      const today = dayjs();
      const expDate = dayjs(row.expiryDate);
      const isExpired = expDate.isBefore(today);
      const isExpiringSoon = expDate.subtract(3, "month").isBefore(today);
      style.class = isExpired ? "doc-expired" : isExpiringSoon ? "doc-expiring-soon" : "";
      style.tooltip = isExpired
        ? "Document expired"
        : isExpiringSoon
        ? "Document is expiring in less than 3 month"
        : null;
    }
    return style;
  };

  return (
    <div className="col-12">
      <Table
        rows={rows}
        columns={columns}
        rowStyle={rowStyle}
        onRowDelete={onRowDelete}
        onDocDownload={onDocDownload}
      />
    </div>
  );
};

export default DocsTable;

DocsTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
};
