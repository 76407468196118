import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Close} from "@mui/icons-material";
import {
  Alert,
  Card,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {blue} from "@mui/material/colors";
import {useActor, useSelector} from "@xstate/react";
import {BankAccountPreference, EMPLOYED_STATUS, SALARY_PAYMENT_METHOD} from "lib/data";
import {confirmDialog} from "lib/helpers";
import {Is} from "lib/helpers/is";
import MachineContext from "lib/MachineContext";

import BankAccounts from "components/finance/bankAccounts/BankAcounts";
import EWallets from "components/finance/wallets/EWallets";

const paymentMethodLabel = "Salary Payment Option";
const paymentMethodPlaceholder = "Choose your Salary Payment Option";
const paymentMethodId = "paymentMethod";

const PAYMENT_METHOD_DESCRIPTION = {
  [SALARY_PAYMENT_METHOD.DEPOSIT_TO_BANK_ACCOUNT]: "Deposit to Bank Account",
  [SALARY_PAYMENT_METHOD.TRANSFER_TO_E_WALLET]: "Transfer to e-wallet",
  [SALARY_PAYMENT_METHOD.RETAIN_WITH_EMPLOYER]: "Retain with Employer",
};
const BG_COLOR = blue[50];

const _getBankAccountMethodConfirmDetails = (bankAccount) => {
  if (!bankAccount) {
    return {account: "", provider: "", nickName: ""};
  }
  const bank = JSON.parse(bankAccount.bank);
  const beneficiary = JSON.parse(bankAccount.beneficiary);
  const account = (bankAccount.iban || bankAccount.accountNumber)?.toUpperCase() ?? "";

  return {account, provider: bank.name, nickName: beneficiary.bank_details_label};
};

const _getWalletMethodConfirmDetails = (eWallet) => {
  if (!eWallet) {
    return {account: "", provider: "", nickName: ""};
  }
  const account = eWallet.walletId?.toUpperCase() ?? "";
  return {account, provider: eWallet.provider.name, nickName: ""};
};

export default function PaymentDetails() {
  const theme = useTheme();
  const service = useContext(MachineContext);
  const [state, send] = useActor(service);

  const {employmentMachineRef, marfinMachineRef, remitterAuthMachineRef} = state.context;
  const remitterAuthMachineStateValue = useSelector(remitterAuthMachineRef, (state) => state?.toStrings().pop());
  const marfinMachineStateValue = useSelector(marfinMachineRef, (state) => state?.toStrings().pop());
  const employments = useMemo(() => {
    return (employmentMachineRef?.state?.context.employments ?? []).filter((employment) =>
      EMPLOYED_STATUS.includes(employment.employmentStatus.status)
    );
  }, [employmentMachineRef?.state?.context.employments]);
  const bankAccounts = useMemo(() => marfinMachineRef?.state?.context.bankAccounts, [marfinMachineRef?.state?.context]);
  const eWallets = useMemo(() => marfinMachineRef?.state?.context.eWallets, [marfinMachineRef?.state?.context]);
  const [isInitialised, setIsInitialised] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethodsWarningOpen, setPaymentMethodsWarningOpen] = useState(false);
  const [paymentMethodsWarningDescription, setPaymentMethodsWarningDescription] = useState("");
  const [isWarningRequired, setIsWarningRequired] = useState(false);

  const primaryBankAccount = useMemo(
    () => bankAccounts?.find((item) => item.preference === BankAccountPreference.Primary),
    [bankAccounts]
  );
  const primaryEWallet = useMemo(
    () => eWallets?.find((item) => item.preference === BankAccountPreference.Primary),
    [eWallets]
  );
  const isPaymentMethodMissing = useMemo(
    () => paymentMethods.some((paymentMethod) => !paymentMethod.name),
    [paymentMethods]
  );

  const paymentMethodDescription = useCallback(
    (paymentMethod) => {
      let description = "Select salary payment option.";
      let color = "error";

      switch (paymentMethod) {
        case SALARY_PAYMENT_METHOD.DEPOSIT_TO_BANK_ACCOUNT: {
          const {account} = _getBankAccountMethodConfirmDetails(primaryBankAccount);

          setIsWarningRequired(!account);
          color = account ? "primary" : "error";
          description = account
            ? `Salary will be deposited into bank account: ${account}.`
            : "Warning! No Account found. Please add a bank account to receive your salary.";
          break;
        }
        case SALARY_PAYMENT_METHOD.TRANSFER_TO_E_WALLET: {
          const {account} = _getWalletMethodConfirmDetails(primaryEWallet);

          setIsWarningRequired(!account);
          color = account ? "primary" : "error";
          description = account
            ? `Salary will be transferred to eWallet ID: ${account}.`
            : "Warning! No E-Wallet found. Please add a e-wallet to receive your salary.";
          break;
        }
        case SALARY_PAYMENT_METHOD.RETAIN_WITH_EMPLOYER:
          setIsWarningRequired(false);
          color = "primary";
          description = "Salary will be held with employer. Contact Employer HR department for more details.";
          break;
        default:
          break;
      }

      return (
        <TextField
          InputProps={{readOnly: true, style: {color: theme.palette[color].main}}}
          value={description}
          size="small"
          color={color}
          multiline
          focused
          fullWidth
        />
      );
    },
    [primaryBankAccount, primaryEWallet, theme.palette]
  );

  const getPaymentMethod = useCallback(
    (employmentId) => paymentMethods.find((paymentMethod) => paymentMethod.id === employmentId)?.name ?? "",
    [paymentMethods]
  );

  const handleMethodChange = useCallback(
    (employmentId) => async (event) => {
      const paymentMethod = event.target.value;
      const employerName = employments.find((employment) => employment.id === employmentId)?.employerName ?? "";
      const isMethodBankAccount = paymentMethod === SALARY_PAYMENT_METHOD.DEPOSIT_TO_BANK_ACCOUNT;
      const isMethodEWallet = paymentMethod === SALARY_PAYMENT_METHOD.TRANSFER_TO_E_WALLET;
      const isPrimaryMethodMissing = isMethodBankAccount
        ? !primaryBankAccount
        : isMethodEWallet
        ? !primaryEWallet
        : false;
      const {account, provider, nickName} = isMethodBankAccount
        ? _getBankAccountMethodConfirmDetails(primaryBankAccount)
        : isMethodEWallet
        ? _getWalletMethodConfirmDetails(primaryEWallet)
        : {account: "", provider: "", nickName: ""};

      const content = isPrimaryMethodMissing ? (
        <>
          <Typography color={"error"} gutterBottom>
            {"Warning! Unable to Set Salary Payment Method to "}
            <strong>{PAYMENT_METHOD_DESCRIPTION[paymentMethod]}</strong>
            {", as no primary option found for this method."}
          </Typography>
          <Typography gutterBottom>
            {"Please add new "}
            <strong>
              {paymentMethod === SALARY_PAYMENT_METHOD.DEPOSIT_TO_BANK_ACCOUNT ? "Bank Account" : "E-Wallet"}
            </strong>
            {" to select this payment method."}
          </Typography>
        </>
      ) : (
        <>
          <Typography gutterBottom>
            {"You are about to set new "}
            <strong>Salary Payment Method</strong>:
          </Typography>
          <Typography>
            {"Employer: "} <strong>{employerName}</strong>
          </Typography>
          <Typography gutterBottom>
            {"Payment Method: "} <strong>{PAYMENT_METHOD_DESCRIPTION[paymentMethod]}</strong>
          </Typography>
          {(isMethodBankAccount || isMethodEWallet) && (
            <>
              <Typography>
                {isMethodBankAccount ? "Bank Account: " : "eWallet ID: "}
                <strong>{account}</strong>
              </Typography>
              <Typography>
                {isMethodBankAccount ? "Bank: " : "Provider: "}
                <strong>{provider}</strong>
              </Typography>
              {nickName && (
                <Typography>
                  {"Nickname: "} <strong>{nickName}</strong>
                </Typography>
              )}
              <Typography mt={2}>{"Ensure you payment details are up-to-date."}</Typography>
            </>
          )}
        </>
      );
      const setup = {content, confirmTitle: isPrimaryMethodMissing ? "Ok" : "Confirm", consent: true};
      const result = await confirmDialog({send, service, setup});

      if (!isPrimaryMethodMissing && result === 1) {
        send({type: "UPDATE_EMPLOYMENT", data: {employmentIds: [employmentId], salaryPaymentMethod: paymentMethod}});
      }
    },
    [primaryBankAccount, primaryEWallet, employments, send, service]
  );

  const emoloymentsPaymentMethods = useMemo(() => {
    return employments.map((employment) => {
      const paymentMethod = getPaymentMethod(employment.id);
      return (
        <Grid item key={employment.id} xs={12} p={1}>
          <Card sx={{width: 1, height: 1, bgcolor: BG_COLOR, p: 1, pt: 2}}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={3} p={1}>
                <TextField
                  label="Employer"
                  InputProps={{readOnly: true}}
                  defaultValue={employment.employerName}
                  size="small"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} p={1}>
                <FormControl fullWidth>
                  <InputLabel htmlFor={paymentMethodId + employment.id} size="small">
                    {paymentMethod ? paymentMethodLabel : paymentMethodPlaceholder}
                  </InputLabel>
                  <Select
                    size="small"
                    id={paymentMethodId + employment.id}
                    value={paymentMethod}
                    label={paymentMethod ? paymentMethodLabel : paymentMethodPlaceholder}
                    error={!paymentMethod}
                    onChange={handleMethodChange(employment.id)}>
                    {Object.keys(PAYMENT_METHOD_DESCRIPTION).map((key) => (
                      <MenuItem value={key} key={key}>
                        {PAYMENT_METHOD_DESCRIPTION[key]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} p={1}>
                {paymentMethodDescription(paymentMethod)}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      );
    });
  }, [employments, getPaymentMethod, handleMethodChange, paymentMethodDescription]);

  useEffect(() => {
    if (isPaymentMethodMissing && (Is.defined(primaryBankAccount) || Is.defined(primaryEWallet))) {
      const salaryPaymentMethod = Is.defined(primaryBankAccount)
        ? SALARY_PAYMENT_METHOD.DEPOSIT_TO_BANK_ACCOUNT
        : SALARY_PAYMENT_METHOD.TRANSFER_TO_E_WALLET;
      const missingPaymentMethods = paymentMethods
        .filter((paymentMethod) => !paymentMethod.name)
        .map((item) => item.id);

      send({type: "UPDATE_EMPLOYMENT", data: {employmentIds: missingPaymentMethods, salaryPaymentMethod}});
      setPaymentMethodsWarningDescription("Your Salary Payment Method(s) have been updated.");
      setPaymentMethodsWarningOpen(true);
    }
  }, [isPaymentMethodMissing, primaryBankAccount, primaryEWallet, paymentMethods, send]);

  useEffect(() => {
    const paymentMethods = employments?.map((employment) => ({
      id: employment.id,
      name: employment.salaryPaymentMethod ?? "",
    }));

    setPaymentMethods(paymentMethods);
  }, [employments]);

  useEffect(() => {
    if (remitterAuthMachineStateValue === "authorised" && marfinMachineStateValue === "stanby" && !isInitialised) {
      send("INIT");
      setIsInitialised(true);
    }
  }, [isInitialised, send, remitterAuthMachineStateValue, marfinMachineStateValue]);

  useEffect(() => {
    send({type: "loadEmployments"});
  }, [send]);

  useEffect(() => {
    setPaymentMethodsWarningDescription(
      isWarningRequired ? "Update is required for your Salary Payment Method(s)." : ""
    );
    setPaymentMethodsWarningOpen(isWarningRequired);
  }, [isWarningRequired]);

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      useFlexGap
      spacing={1}
      sx={{height: 1}}>
      <Grid container spacing={1}>
        <Grid item xs={12} p={1}>
          <Collapse in={paymentMethodsWarningOpen}>
            <Alert
              severity="error"
              action={
                <IconButton color="inherit" size="small" onClick={() => setPaymentMethodsWarningOpen(false)}>
                  <Close fontSize="inherit" />
                </IconButton>
              }>
              {paymentMethodsWarningDescription}
              {" Please review all Salary Payment Method(s)."}
            </Alert>
          </Collapse>
        </Grid>
        {emoloymentsPaymentMethods}
      </Grid>
      <Grid container spacing={1} sx={{flexGrow: 1}}>
        <Grid item xs={12} md={6} sx={{minHeight: "20rem", height: {xs: "25rem", md: 1}, p: 1}}>
          <Card sx={{width: 1, height: 1, bgcolor: BG_COLOR, p: 1, pt: 2}}>
            <BankAccounts />
          </Card>
        </Grid>
        <Grid item xs={12} md={6} sx={{minHeight: "20rem", height: {xs: "25rem", md: 1}, p: 1}}>
          <Card sx={{width: 1, height: 1, bgcolor: BG_COLOR, p: 1, pt: 2}}>
            <EWallets />
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
}
