import React, {useRef} from "react";
import {Controller} from "react-hook-form";
import {Button, FormHelperText, Grid, TextField} from "@mui/material";
import {getFileExtension} from "lib/helpers";
import PropTypes from "prop-types";

export default function FormInputUpload(props) {
  const {
    name,
    control,
    disabled = false,
    accept,
    acceptedTypes,
    label,
    acceptedExt,
    maxFileSize,
    required = true,
  } = props;

  const inputRef = useRef(null);

  const rules = {
    validate: {
      notEmpty: (value) => (!required && !value ? true : !!value?.file || "Upload File is required"),
      fileSize: (value) => {
        if (!required && !value) return true;
        const {file} = value;
        const fileSize = Math.ceil(file.size / 1024);
        return (
          fileSize < maxFileSize ||
          `File size should be less than ${
            maxFileSize < 1024 ? `${maxFileSize}Kb` : `${Math.floor(maxFileSize / 1024)}Mb`
          }`
        );
      },
      fileType: (value) => {
        if (!required && !value) return true;
        const {file, fileExt} = value;
        const isTypeValid = file.type !== "" ? acceptedTypes.includes(file.type) : true;
        const isExtValid = acceptedExt.includes(fileExt.toLowerCase());
        return (!!fileExt && isExtValid && isTypeValid) || `File type is invalid`;
      },
    },
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field: {onChange, value, name, onBlur, ref}, fieldState: {invalid, error}}) => {
        const onFileChange = ({target}) => {
          const file = target?.files[0];
          if (!file) {
            onChange({file: null, fileExt: null});
          } else {
            const fileName = file.name;
            const fileExt = getFileExtension(fileName);
            onChange({file, fileExt});
          }
        };
        const onClick = () => {
          inputRef.current.value = null;
        };

        return (
          <Grid container spacing={1}>
            <Grid item xs="auto">
              <Button variant="contained" component="label" disabled={disabled}>
                Upload
                <input
                  type="file"
                  hidden
                  onChange={onFileChange}
                  ref={inputRef}
                  onClick={onClick}
                  accept={accept}
                  onBlur={onBlur}
                />
              </Button>
            </Grid>
            <Grid item xs>
              <TextField
                id={name}
                error={invalid}
                disabled
                fullWidth
                inputRef={ref}
                label={value?.file?.name || ""}
                size="small"
                variant="standard"
              />
              <FormHelperText error={invalid}>{!error ? label : error?.message}</FormHelperText>
            </Grid>
          </Grid>
        );
      }}
    />
  );
}

FormInputUpload.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  control: PropTypes.object,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  accept: PropTypes.string,
  acceptedTypes: PropTypes.array,
  acceptedExt: PropTypes.array,
  maxFileSize: PropTypes.number,
};
