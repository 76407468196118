import React from "react";
import {Grid} from "@mui/material";
import {appSettings} from "lib";
import {FormInputUpload} from "lib/components";
import PropTypes from "prop-types";

const maxFileSize = 5120;

export default function FileUploadForm({control, watch}) {
  const {passportPhotoDocId} = appSettings;
  const watchDocId = watch("docId");
  const acceptInput = passportPhotoDocId === watchDocId ? `image/png, image/jpeg,` : `image/png, image/jpeg, .pdf`;
  const acceptedTypes =
    passportPhotoDocId === watchDocId ? ["image/png", "image/jpeg"] : ["image/png", "image/jpeg", "application/pdf"];
  const acceptedExt = passportPhotoDocId === watchDocId ? ["jpeg", "jpg", "png"] : ["jpeg", "jpg", "png", "pdf"];
  const fileExts = `*.${acceptedExt.join(", *.")}`;
  const label = `Choose file less than ${Math.floor(maxFileSize / 1024)}Mb ${fileExts}`;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormInputUpload
          name={"docFile"}
          control={control}
          label={label}
          disabled={watchDocId === ""}
          accept={acceptInput}
          acceptedTypes={acceptedTypes}
          acceptedExt={acceptedExt}
          maxFileSize={maxFileSize}
        />
      </Grid>
    </Grid>
  );
}

FileUploadForm.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
};
