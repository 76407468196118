import React from "react";
import {Controller} from "react-hook-form";
import {FormControl, FormHelperText, Input, InputLabel, OutlinedInput} from "@mui/material";
import PropTypes from "prop-types";

export default function FormInputText(props) {
  const {
    name,
    control,
    label,
    fullWidth,
    size = "small",
    type = "text",
    multiline = false,
    rows,
    shrink,
    disabled = false,
    readOnly = false,
    startAdornment,
    endAdornment,
    rules,
    autoComplete = "on",
    autocapitalize = false,
    variant = "outlined",
    required,
    ...inputProps
  } = props;
  const Component = variant === "standard" ? Input : OutlinedInput;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field: {onChange, onBlur, value, name, ref}, fieldState: {invalid, error}}) => {
        const onValueChange = (event) => {
          onChange(autocapitalize ? event.target.value?.toUpperCase() : event.target.value);
        };

        return (
          <FormControl error={invalid} fullWidth={fullWidth} size={size}>
            <InputLabel htmlFor={name} shrink={shrink} required={!!required}>
              {label}
            </InputLabel>
            <Component
              id={name}
              label={label}
              value={value}
              onBlur={onBlur}
              inputRef={ref}
              type={type}
              multiline={multiline}
              rows={rows}
              notched={shrink}
              readOnly={readOnly}
              onChange={onValueChange}
              inputProps={inputProps}
              disabled={disabled}
              startAdornment={startAdornment}
              endAdornment={endAdornment}
              autoComplete={autoComplete}
            />
            {error && <FormHelperText>{error?.message}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
}

FormInputText.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  shrink: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  autocapitalize: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
  autoComplete: PropTypes.string,
  rules: PropTypes.object,
  rows: PropTypes.number,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  variant: PropTypes.string,
};
