const ACCOUNT_DETAILS_VALIDATION_ERROR = {
  IBAN: {
    0: {value: "NoIBANProvided", error: "Field is required"},
    1: {value: "NoIBANCountry", error: "No IBAN Country"},
    2: {value: "WrongBBANLength", error: "Wrong BBAN Length"},
    3: {value: "WrongBBANFormat", error: "Wrong BBAN Format"},
    4: {value: "ChecksumNotNumber", error: "Wrong IBAN Checksum"},
    5: {value: "WrongIBANChecksum", error: "Wrong IBAN Checksum"},
    6: {value: "WrongAccountBankBranchChecksum", error: "Wrong Account Bank Branch Checksum"},
    7: {value: "QRIBANNotAllowed", error: "QR-IBAN Not Allowed"},
  },
  BIC: {
    0: {value: "NoBICProvided", error: "Field is required"},
    1: {value: "NoBICCountry", error: "No Code Country"},
    2: {value: "WrongBICFormat", error: "Wrong Code Format"},
  },
};

const VALIDATION_ERROR = {
  required: "Field is required",
  swiftCodeIsNotValid: "Swift code is not valid",
  ibanIsNotValid: "Iban is not valid",
  invalidRoutingCodeFormat: "Invalid routing code format, 2-12 letters or digits expected",
  invalidAccountNumberFormat: "Invalid account number format",
  bankCountryMismatchShouldBeProvided: (deafultCountry, currentCountry) =>
    `Bank country mismatch. Should be ${deafultCountry}, provided: ${currentCountry}`,
};

export {ACCOUNT_DETAILS_VALIDATION_ERROR, VALIDATION_ERROR};
