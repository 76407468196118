import React, {useCallback, useContext, useMemo} from "react";
import {StorageImage} from "@aws-amplify/ui-react-storage";
import {Box, Card, CardContent, CardMedia, Typography} from "@mui/material";
import {lightBlue} from "@mui/material/colors";
import {useActor} from "@xstate/react";
import {MachineContext} from "lib";

import Signature from "./Signature";
import UserToolbar from "./UserToolbar";

const PROFILE_IMG_HEIGHT = 10;
const photoStyles = {
  height: `${PROFILE_IMG_HEIGHT}rem`,
  width: `${PROFILE_IMG_HEIGHT}rem`,
  marginTop: `-${PROFILE_IMG_HEIGHT / 2}rem`,
  borderRadius: "50%",
};

export default function UserCard() {
  const [state, send] = useActor(useContext(MachineContext));
  const user = useMemo(() => state.context.user, [state.context.user]);
  const crewProfile = useMemo(
    () => state.context.profileMachineRef?.state.context.crewProfile,
    [state.context.profileMachineRef?.state.context.crewProfile]
  );
  const profileImgKey = useMemo(
    () => state.context.profileMachineRef?.state.context.profileImgKey,
    [state.context.profileMachineRef?.state.context.profileImgKey]
  );
  const crewDocs = useMemo(
    () => state.context.crewDocsMachineRef?.state.context.crewDocs,
    [state.context.crewDocsMachineRef?.state.context.crewDocs]
  );
  const isCvAvailable =
    !!crewProfile &&
    !!crewProfile.bloodGroup &&
    !!crewProfile.englishLangLevel &&
    crewDocs?.requiredDocs?.items?.length > 0;
  const detailsProps = {variant: "body2", align: "center", color: "text.disabled"};

  const handleCVMenuClick = useCallback((eventType) => send(eventType), [send]);

  return (
    <Card sx={{flexGrow: 1, display: " flex", flexDirection: "column"}}>
      <CardMedia component="img" alt="ocean" src={"/images/ocean.svg"} sx={{height: "9rem"}} />

      <CardContent
        sx={{
          position: "relative",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 0,
        }}>
        {!profileImgKey ? (
          <CardMedia
            component="img"
            alt="seaman"
            src="/images/logo.svg"
            sx={{
              ...photoStyles,
              backgroundColor: lightBlue[50],
              border: "1px solid white",
              objectPosition: "center",
              objectFit: "cover",
            }}
          />
        ) : (
          <Box sx={{...photoStyles, overflow: "hidden"}}>
            <StorageImage imgKey={profileImgKey} accessLevel={"protected"} width={"100%"} height={"auto"} />
          </Box>
        )}

        {!profileImgKey && (
          <Box
            sx={{
              width: `${PROFILE_IMG_HEIGHT}rem`,
              height: `${PROFILE_IMG_HEIGHT}rem`,
              marginTop: `-${PROFILE_IMG_HEIGHT}rem`,
              display: "flex",
              alignItems: "center",
            }}>
            <Typography align="center" color="error" variant="button" component="p">
              upload your passport photo in CrewDocs
            </Typography>
          </Box>
        )}

        <Typography variant="subtitle1" align="center">
          {crewProfile?.firstName} {crewProfile?.lastName}
        </Typography>
        <Typography {...detailsProps}>{user?.attributes?.email}</Typography>
        <Typography {...detailsProps}>{user?.attributes?.phone_number}</Typography>
        <Typography {...detailsProps}>{crewProfile?.crewRank?.title}</Typography>
        <Typography {...detailsProps}>{crewProfile?.country}</Typography>

        {!!isCvAvailable && <Signature />}
        {!!crewProfile && <UserToolbar isCvAvailable={isCvAvailable} handleCVMenuClick={handleCVMenuClick} />}
      </CardContent>
    </Card>
  );
}
