import React from "react";
import PropTypes from "prop-types";

export default function RemarksForm({formState, onChange}) {
  return (
    <>
      <div className="col-12 col-lg-8 col-xl-6 mb-3">
        <label className="form-label" htmlFor="incidents">
          Any decompression sickness, other illness or injury or incident of special note
        </label>
        <input
          type="text"
          className="form-control"
          id="incidents"
          value={formState.incidents}
          name="incidents"
          maxLength="64"
          onChange={onChange}
        />
      </div>

      <div className="col-12 col-md-6 col-xl-3 mb-3">
        <label className="form-label" htmlFor="remarks">
          Any other remarks
        </label>
        <input
          type="text"
          className="form-control"
          id="remarks"
          value={formState.remarks}
          name="remarks"
          maxLength="64"
          onChange={onChange}
        />
      </div>

      <div className="col-xs-12 col-md-6 col-xl-3 mb-3">
        <label className="form-label" htmlFor="divingSupName">
          Name of Diving Supervisor
        </label>
        <input
          type="text"
          className="form-control"
          id="divingSupName"
          value={formState.divingSupName}
          name="divingSupName"
          maxLength="64"
          onChange={onChange}
          required
        />
        <div className="invalid-feedback">Please provide a valid Name of Diving Supervisor.</div>
      </div>
    </>
  );
}

RemarksForm.propTypes = {
  formState: PropTypes.object,
  onChange: PropTypes.func,
};
