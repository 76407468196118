import React, {useContext, useEffect, useState} from "react";
import {Alert, Slide, Snackbar, Typography} from "@mui/material";
import {useActor} from "@xstate/react";

import MachineContext from "../MachineContext";

export default function InfoToast() {
  const [state, send] = useActor(useContext(MachineContext));
  const [alert, setAlert] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (state.context.alert) {
      setAlert({...state.context.alert});
      setOpen(true);
      send({type: "resetAlert"});
    }
  }, [state.context.alert, send]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={10000}
      onClose={handleClose}
      anchorOrigin={{vertical: "top", horizontal: "right"}}
      TransitionComponent={Slide}
      sx={{maxWidth: {xs: "100%", sm: "60%", md: "40%"}}}>
      <Alert onClose={handleClose} variant="filled" severity={alert?.type || "error"} sx={{width: "100%"}}>
        {typeof alert?.message === "string"
          ? alert?.message?.split("\n").map((line, index) => (
              <Typography component="p" key={index} p={0} m={0}>
                {line}
              </Typography>
            ))
          : alert?.message}
      </Alert>
    </Snackbar>
  );
}
