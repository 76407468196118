export const createSubscriptionStatus = /* GraphQL */ `
  mutation CreateSubscriptionStatus(
    $input: CreateSubscriptionStatusInput!
    $condition: ModelSubscriptionStatusConditionInput
  ) {
    createSubscriptionStatus(input: $input, condition: $condition) {
      id
      isFree
      expiryDate
      owner
      createdAt
      updatedAt
    }
  }
`;
export const updateCrewProfile = /* GraphQL */ `
  mutation UpdateCrewProfile($input: UpdateCrewProfileInput!, $condition: ModelCrewProfileConditionInput) {
    updateCrewProfile(input: $input, condition: $condition) {
      id
      employerId
      firstName
      midName
      lastName
      birthDate
      birthPlace
      address
      city
      state
      country
      emerContactName
      emerContactRelation
      emerContactPhone
      emerContactAddress
      emerContactEmail
      nextOfKin
      nationality
      fatherName
      motherName
      nearestAirport
      maritalStatus
      religion
      numberOfChildren
      weight
      bloodGroup
      eyesColor
      hairColor
      gender
      coverallSize
      shoeSize
      englishLangLevel
      otherLanguage1
      otherLanguage2
      signatureHash
      crewRankId
      crewRank {
        id
        title
        status
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
        }
        docs {
          items {
            document {
              id
              title
              docType
              descriptions {
                items {
                  id
                  content
                }
                nextToken
              }
            }
          }
        }
      }
    }
  }
`;
export const createCrewProfile = /* GraphQL */ `
  mutation CreateCrewProfile($input: CreateCrewProfileInput!, $condition: ModelCrewProfileConditionInput) {
    createCrewProfile(input: $input, condition: $condition) {
      id
      owner
      employerId
      firstName
      midName
      lastName
      birthDate
      birthPlace
      address
      city
      state
      country
      emerContactName
      emerContactRelation
      emerContactPhone
      emerContactAddress
      emerContactEmail
      nextOfKin
      nationality
      fatherName
      motherName
      nearestAirport
      maritalStatus
      religion
      numberOfChildren
      weight
      bloodGroup
      eyesColor
      hairColor
      gender
      coverallSize
      shoeSize
      englishLangLevel
      otherLanguage1
      otherLanguage2
      crewRankId
      crewRank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          crewRanks {
            items {
              id
              title
              status
              hierarchy
              groupId
            }
            nextToken
          }
        }
        docs {
          items {
            document {
              id
              title
              docType
              descriptions {
                items {
                  id
                  content
                }
                nextToken
              }
            }
          }
        }
        designatedTitles {
          nextToken
        }
        createdAt
        updatedAt
      }
      docs {
        items {
          id
          owner
          issueDate
          expiryDate
          docSize
          docNum
          issuePlace
          includedInCV
          title
          docId
          docDescriptionId
          createdAt
          updatedAt
        }
        nextToken
      }
      personalDocs {
        items {
          id
          owner
          issueDate
          expiryDate
          docSize
          docNum
          issuePlace
          includedInCV
          title
          createdAt
          updatedAt
        }
        nextToken
      }
      seaLog {
        items {
          id
          owner
          vesselName
          vesselType
          tonnage
          operationArea
          engagementPlace
          dischargePlace
          rankTitle
          dateFrom
          dateTo
          company
          createdAt
          updatedAt
        }
        nextToken
      }
      diveLog {
        items {
          id
          owner
          diveDate
          contractorName
          contractorAddress
          diveLocation
          worksite
          diveType
          measureUnit
          helmetType
          gasMixture
          workDescription
          decoSchedules
          incidents
          remarks
          divingSupName
          storageDepth
          maxDepth
          bellLockOff
          leftBell
          returnedBell
          lockOutTime
          bellLockOn
          leftSurfaceTime
          bottomTime
          decoCompletedAt
          surfaceDecoInterval
          chamberTime
          createdAt
          updatedAt
        }
        nextToken
      }
      diveSupLog {
        items {
          id
          owner
          diveDate
          contractorName
          contractorAddress
          diveLocation
          worksite
          supervisionType
          diveType
          unitRatio
          incidents
          remarks
          contractorRepTitle
          contractorRepName
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          notificationId
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      subscriptions {
        items {
          id
          isFree
          expiryDate
          owner
          createdAt
          updatedAt
        }
        nextToken
      }
      signatureHash
      createdAt
      updatedAt
    }
  }
`;
export const createCrewDoc = /* GraphQL */ `
  mutation CreateCrewDoc($input: CreateCrewDocInput!, $condition: ModelCrewDocConditionInput) {
    createCrewDoc(input: $input, condition: $condition) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      docId
      doc {
        id
        title
        docType
        hierarchy
        createdAt
        updatedAt
      }
      docDescriptionId
      docDescription {
        id
        content
        docId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCrewDoc = /* GraphQL */ `
  mutation UpdateCrewDoc($input: UpdateCrewDocInput!, $condition: ModelCrewDocConditionInput) {
    updateCrewDoc(input: $input, condition: $condition) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      docId
      doc {
        id
        title
        docType
        hierarchy
        createdAt
        updatedAt
      }
      docDescriptionId
      docDescription {
        id
        content
        docId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCrewDoc = /* GraphQL */ `
  mutation DeleteCrewDoc($input: DeleteCrewDocInput!, $condition: ModelCrewDocConditionInput) {
    deleteCrewDoc(input: $input, condition: $condition) {
      id
      owner
      title
      docId
      doc {
        title
      }
      docDescriptionId
    }
  }
`;
export const createPersonalDoc = /* GraphQL */ `
  mutation CreatePersonalDoc($input: CreatePersonalDocInput!, $condition: ModelPersonalDocConditionInput) {
    createPersonalDoc(input: $input, condition: $condition) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      createdAt
      updatedAt
    }
  }
`;
export const updatePersonalDoc = /* GraphQL */ `
  mutation UpdatePersonalDoc($input: UpdatePersonalDocInput!, $condition: ModelPersonalDocConditionInput) {
    updatePersonalDoc(input: $input, condition: $condition) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      createdAt
      updatedAt
    }
  }
`;
export const deletePersonalDoc = /* GraphQL */ `
  mutation DeletePersonalDoc($input: DeletePersonalDocInput!, $condition: ModelPersonalDocConditionInput) {
    deletePersonalDoc(input: $input, condition: $condition) {
      id
      owner
      title
      createdAt
      updatedAt
    }
  }
`;
export const createDocCrewRankJoin = /* GraphQL */ `
  mutation CreateDocCrewRankJoin($input: CreateDocCrewRankJoinInput!, $condition: ModelDocCrewRankJoinConditionInput) {
    createDocCrewRankJoin(input: $input, condition: $condition) {
      id
      crewRankId
      crewRankTitle
      docId
      docTitle
    }
  }
`;
