import React, {useCallback, useContext, useEffect, useMemo, useRef} from "react";
import {ExpandMore} from "@mui/icons-material";
import {Accordion, AccordionDetails, AccordionSummary, Badge, Grid, List, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";
import {useActor} from "@xstate/react";
import MachineContext from "lib/MachineContext";
import PropTypes from "prop-types";

import {EmploymentActionCard, MessageListItem, MessageToolbar} from "./tools";

const msgBoxHeight = "17rem";

export default function EmploymentBlock(props) {
  const {employment, expanded, handleCollapse, onActionClick, onDownload} = props;
  const {id, employmentStatus, employerName, employmentEvents} = employment;
  const [state, send] = useActor(useContext(MachineContext));
  const {user, employmentMachineRef} = state.context;
  const {sub: userSub} = user?.attributes || {};
  const {employmentActions, employmentUpdates} = employmentMachineRef.state.context;
  const messageBoxRef = useRef(null);
  const hasUpdates = useMemo(() => employmentUpdates.has(id), [employmentUpdates, id]);
  const isBlockExpanded = expanded === id;

  const scrollChatBox = useCallback(() => {
    messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
  }, []);

  const handleChange = (employmentId) => (_, isExpanded) => handleCollapse({isExpanded, employmentId});

  const events = useMemo(
    () =>
      employmentEvents?.items.map((event) => (
        <MessageListItem
          key={event.id}
          event={event}
          userSub={userSub}
          employerName={employerName}
          employmentActions={employmentActions}
          onDownload={onDownload}
        />
      )) || [],
    [employmentEvents?.items, employmentActions, employerName, onDownload, userSub]
  );

  useEffect(() => {
    hasUpdates && isBlockExpanded && send({type: "getEmploymentUpdates", data: id});
  }, [hasUpdates, isBlockExpanded, id, send]);

  useEffect(() => {
    employmentEvents?.items.length > 0 && isBlockExpanded && scrollChatBox();
  }, [employmentEvents?.items, isBlockExpanded, scrollChatBox]);

  return (
    <Accordion expanded={isBlockExpanded} onChange={handleChange(id)}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{backgroundColor: isBlockExpanded ? blue[50] : ""}}>
        <Typography variant="button" color={isBlockExpanded ? "text.primary" : "text.secondary"}>
          {employerName || "New employment"}
        </Typography>
        {hasUpdates && <Badge badgeContent="" variant="dot" color="error" />}
      </AccordionSummary>
      <AccordionDetails sx={{p: 1}}>
        <Grid container sx={{height: 1}} spacing={1}>
          <Grid item xs={12} md={4}>
            <EmploymentActionCard employment={employment} onActionClick={onActionClick} />
          </Grid>
          <Grid item xs={12} md={8}>
            <Grid container direction="column" wrap="nowrap" spacing={0} sx={{height: msgBoxHeight, width: 1}}>
              <Grid item xs zeroMinWidth ref={messageBoxRef} sx={{overflow: "auto", display: "flex"}}>
                <List sx={{marginTop: "auto", width: 1}}>{events}</List>
              </Grid>
              <Grid item xs="auto" zeroMinWidth>
                <MessageToolbar employmentStatus={employmentStatus} employmentId={id} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

EmploymentBlock.propTypes = {
  employment: PropTypes.object,
  expanded: PropTypes.string,
  handleCollapse: PropTypes.func,
  onActionClick: PropTypes.func,
  onDownload: PropTypes.func,
};
