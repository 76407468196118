import React from "react";
import {Divider, Grid, MenuItem} from "@mui/material";
import {FormInputSelect, FormInputText} from "lib/components";
import {COUNTRIES} from "lib/data";
import PropTypes from "prop-types";

export default function DocDetailsForm({control}) {
  const onlineCertificateMenuItem = (
    <MenuItem key="online" value="Online Certificate">
      {"Online Certificate"}
    </MenuItem>
  );

  const rules = {
    docNum: {
      required: "Document Number or N/A is required",
      pattern: {value: /^[\w .,-/]{1,64}$/, message: "Only alphanumeric characters and .,-/ are allowed"},
    },
    issuePlace: {required: "Place of Issue is required"},
  };

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} sm={6} md={12} lg={6}>
        <FormInputText name={"docNum"} control={control} label={"Document Number"} fullWidth rules={rules.docNum} />
      </Grid>
      <Grid item xs={12} sm={6} md={12} lg={6}>
        <FormInputSelect
          name={"issuePlace"}
          control={control}
          label={"Place of Issue"}
          rules={rules.issuePlace}
          fullWidth
          options={COUNTRIES.reduce(
            (acc, name) => [
              ...acc,
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>,
            ],
            [onlineCertificateMenuItem, <Divider key="divider" light />]
          )}
        />
      </Grid>
    </Grid>
  );
}

DocDetailsForm.propTypes = {
  control: PropTypes.object,
  setValue: PropTypes.func,
};
