import React from "react";
import PropTypes from "prop-types";

import UnitSwitch from "./UnitSwitch";

export default function SatDiveForm({formState, onChange}) {
  return (
    <div className="col-12">
      <h6>Saturation Dives</h6>
      <div className="row border rounded my-2 py-3 mx-0">
        <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
          <div className="row mb-2 justify-content-end g-1">
            <div className="col-auto">
              <UnitSwitch {...{formState, onChange}} />
            </div>
          </div>
          <div className="row mb-2 g-1">
            <label className="form-label col" htmlFor="storageDepth">
              Storage depth (<strong>{formState.measureUnit}</strong>)
            </label>
            <div className="col-auto">
              <input
                type="number"
                min="0"
                max="500"
                className="form-control form-control-sm"
                id="storageDepth"
                value={formState.storageDepth}
                onChange={onChange}
                name="storageDepth"
                required
              />
              <div className="invalid-feedback">Please provide a valid number of storage depth.</div>
            </div>
          </div>
          <div className="row mb-2 g-1">
            <label className="form-label col" htmlFor="maxDepth">
              Maximum depth of dive (<strong>{formState.measureUnit}</strong>)
            </label>
            <div className="col-auto">
              <input
                type="number"
                min="0"
                max="500"
                className="form-control form-control-sm"
                id="maxDepth"
                value={formState.maxDepth}
                onChange={onChange}
                name="maxDepth"
                required
              />
              <div className="invalid-feedback">Please provide a valid maximum depth of dive.</div>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-lg-4 col-xl-4">
          <div className="row mb-2 g-1">
            <label className="form-label col" htmlFor="bellLockOff">
              Bell lock-off
            </label>
            <div className="col-auto">
              <input
                type="time"
                className="form-control form-control-sm"
                id="bellLockOff"
                value={formState.bellLockOff}
                name="bellLockOff"
                pattern="[0-9]{2}:[0-9]{2}"
                onChange={onChange}
                required
              />
              <div className="invalid-feedback">Please provide a valid time of bell lock-off.</div>
            </div>
          </div>
          <div className="row mb-2 g-1">
            <label className="form-label col" htmlFor="leftBell">
              Diver left bell
            </label>
            <div className="col-auto">
              <input
                type="time"
                className="form-control form-control-sm"
                id="leftBell"
                value={formState.leftBell}
                name="leftBell"
                pattern="[0-9]{2}:[0-9]{2}"
                onChange={onChange}
                required
              />
              <div className="invalid-feedback">Please provide a valid time when diver left bell.</div>
            </div>
          </div>
          <div className="row mb-2 g-1">
            <label className="form-label col" htmlFor="returnedBell">
              Diver returned to bell
            </label>
            <div className="col-auto">
              <input
                type="time"
                className="form-control form-control-sm"
                id="returnedBell"
                value={formState.returnedBell}
                name="returnedBell"
                pattern="[0-9]{2}:[0-9]{2}"
                onChange={onChange}
                required
              />
              <div className="invalid-feedback">Please provide a valid time when diver returned.</div>
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-6 col-lg-4 col-xl-4 offset-sm-6 offset-lg-0">
          <div className="row mb-2 g-1">
            <label className="form-label col" htmlFor="lockOutTime">
              Lock-out time
            </label>
            <div className="col-auto">
              <input
                type="time"
                className="form-control form-control-sm"
                id="lockOutTime"
                value={formState.lockOutTime}
                name="lockOutTime"
                pattern="[0-9]{2}:[0-9]{2}"
                onChange={onChange}
                required
              />
              <div className="invalid-feedback">Please provide a valid Lock-out time.</div>
            </div>
          </div>
          <div className="row g-1">
            <label className="form-label col" htmlFor="bellLockOn">
              Bell lock-on
            </label>
            <div className="col-auto">
              <input
                type="time"
                className="form-control form-control-sm"
                id="bellLockOn"
                value={formState.bellLockOn}
                name="bellLockOn"
                pattern="[0-9]{2}:[0-9]{2}"
                onChange={onChange}
                required
              />
              <div className="invalid-feedback">Please provide a valid Bell lock-on time.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

SatDiveForm.propTypes = {
  formState: PropTypes.object,
  onChange: PropTypes.func,
};
