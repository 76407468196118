import {createMachine} from "xstate";

import actions from "./actions";
import {checkoutSubscription, updateActiveNotifications, updateProfile} from "./services";

const profileMachine = ({owner, crewProfile, profileImgKey, crewRanks, crewSubscription}) =>
  createMachine(
    {
      id: "profileMachine",
      predictableActionArguments: true,
      initial: "displaying",
      context: {
        owner,
        crewProfile,
        crewRanks,
        crewSubscription,
        profileImgKey,
        isMachineWorking: false,
      },
      states: {
        displaying: {
          on: {
            updateProfile: "updatingProfile",
            updateProfileImg: {actions: "updateProfileImg"},
            deleteProfileImg: {actions: "deleteProfileImg"},
            updateActiveNotifications: "updatingActiveNotifications",
            checkout: "checkingoutSubscription",
          },
        },
        updatingProfile: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: ({owner, crewProfile}, {data}) => updateProfile({id: owner, crewProfile, ...data}),
            onDone: [
              {
                target: "displaying",
                actions: ["updateProfile", "sendAlert", "sendUpdateCrewRankDocs"],
                cond: (_, {data: {newCrewRanks}}) => !!newCrewRanks,
              },
              {target: "displaying", actions: ["updateProfile", "sendAlert"]},
            ],
            onError: {target: "displaying", actions: "sendError"},
          },
        },
        updatingActiveNotifications: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: ({crewProfile: {notifications, id}}, {data}) =>
              updateActiveNotifications({
                owner: id,
                currentSetup: notifications.items,
                newSetup: data,
              }),
            onDone: {
              target: "displaying",
              actions: ["updateActiveNotifications", "sendAlert"],
            },
            onError: {target: "displaying", actions: "sendError"},
          },
        },
        checkingoutSubscription: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: ({crewSubscription, crewProfile}) => {
              return checkoutSubscription(crewSubscription.id, crewProfile.crewRank.group.subscriptionPriceId);
            },
            onError: {target: "displaying", actions: "sendError"},
          },
        },
      },
    },
    {actions}
  );

export default profileMachine;
