import React from "react";
import {Grid, MenuItem} from "@mui/material";
import {FormInputSelect, FormInputText} from "lib/components";
import PropTypes from "prop-types";

export default function VesselForm({control, vesselTypes}) {
  const rules = {
    vesselName: {
      required: "Vessel name is required",
      pattern: {value: /^[\w .,-/]{1,64}$/, message: "Only alphanumeric characters and .,-/ are allowed"},
    },
    tonnage: {min: {value: 10, message: "Min value 10"}, max: {value: 500000, message: "Max value is 500000"}},
    company: {
      required: "Company name is required",
      pattern: {value: /^[\w .,-/]{1,64}$/, message: "Only alphanumeric characters and .,-/ are allowed"},
    },
  };

  return (
    <Grid container spacing={2} mb={1}>
      <Grid item xs={12} sm={6} md={3} lg={4}>
        <FormInputText name={"vesselName"} control={control} label={"Vessel Name"} fullWidth rules={rules.vesselName} />
      </Grid>
      <Grid item xs={12} sm={6} md={3} lg={3}>
        <FormInputSelect
          name={"vesselType"}
          control={control}
          label={"Vessel Type"}
          fullWidth
          options={
            vesselTypes?.map((typeName) => (
              <MenuItem key={typeName} value={typeName}>
                {typeName}
              </MenuItem>
            )) || []
          }
        />
      </Grid>
      <Grid item xs={12} sm={5} md={2} lg={1}>
        <FormInputText
          name={"tonnage"}
          control={control}
          label={"Tonnage"}
          fullWidth
          rules={rules.tonnage}
          type="number"
        />
      </Grid>
      <Grid item xs={12} sm={7} md={4} lg={4}>
        <FormInputText name={"company"} control={control} label={"Company Name"} fullWidth rules={rules.company} />
      </Grid>
    </Grid>
  );
}

VesselForm.propTypes = {
  control: PropTypes.object,
  vesselTypes: PropTypes.array,
};
