import {API, graphqlOperation} from "aws-amplify";
import {addDays, formatISO, parseISO} from "date-fns";
import {createSubscriptionStatus} from "graphql/customMutations";
import {getCrewProfile} from "graphql/customQueries";
import {ranksByTitle, subscriptionByOwner} from "graphql/queries";
import {appSettings} from "lib";

const {passportPhotoDocId} = appSettings;
const getExpiryDate = (dateCreated) => formatISO(addDays(parseISO(dateCreated), 31), {representation: "date"});

const _createNewSusbcription = async ({userSub, dateCreated}) => {
  const expiryDate = getExpiryDate(dateCreated);
  const input = {owner: userSub, isFree: true, expiryDate};
  const response = await API.graphql(graphqlOperation(createSubscriptionStatus, {input}));
  const {createSubscriptionStatus: newSubscription} = response.data;

  return newSubscription;
};

export default async function getCrewData(userSub, dateCreated) {
  const profileResponse = await API.graphql(graphqlOperation(getCrewProfile, {id: userSub}));
  const {getCrewProfile: profile} = profileResponse.data;
  let crewRankDocs = null;
  let crewRanks = null;
  let crewSubscription = null;
  let profileImgKey = null;

  if (profile) {
    const {docs, group, ...crewRankProps} = profile.crewRank;
    const {crewRanks: ranks, ...groupProps} = group;
    const passportPhotoDoc = profile.docs.items.find((doc) => doc.docId === passportPhotoDocId);

    profileImgKey = passportPhotoDoc?.file.key;
    crewRankDocs = docs.items;
    crewRanks = ranks.items;
    profile.crewRank = {...crewRankProps, group: groupProps};
  } else {
    const {data: ranksResponse} = await API.graphql(
      graphqlOperation(ranksByTitle, {status: "active", sortDirection: "ASC"})
    );
    const {items: allRanks} = ranksResponse.ranksByTitle;
    const response1 = await API.graphql(graphqlOperation(subscriptionByOwner, {owner: userSub}));
    const {data: subscriptionsResponse} = response1;
    const {items: subscriptions} = subscriptionsResponse.subscriptionByOwner;

    crewRanks = allRanks;
    crewSubscription =
      subscriptions.length > 0 ? subscriptions[0] : await _createNewSusbcription({userSub, dateCreated});
  }

  const {
    subscriptions,
    docs,
    personalDocs,
    seaLog: crewSeaLogs,
    diveLog: crewDiveLogs,
    diveSupLog: crewDiveSupLogs,
    employmentUpdates,
    ...crewProfile
  } = profile || {};

  if (subscriptions?.items.length === 0 && !crewSubscription) {
    crewSubscription = await _createNewSusbcription({userSub, dateCreated});
  }

  return {
    crewProfile: !profile ? null : crewProfile,
    profileImgKey,
    crewDocs: !profile ? null : {requiredDocs: docs, personalDocs},
    crewSeaLogs,
    crewDiveLogs,
    crewDiveSupLogs,
    crewRankDocs,
    crewRanks,
    crewSubscription: crewSubscription || subscriptions.items[0],
    employmentUpdates: new Set(employmentUpdates?.items.map(({employmentId}) => employmentId)),
  };
}
