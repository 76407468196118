import {loadStripe} from "@stripe/stripe-js/pure";
import {API, graphqlOperation} from "aws-amplify";
import {createCrewProfile, updateCrewProfile} from "graphql/customMutations";
import {createActiveNotification, deleteActiveNotification, deleteProfileField} from "graphql/mutations";
import {getSignatureText} from "lib/helpers";
import {v5 as uuidv5} from "uuid";

const STRIPE_API_KEY = `pk_live_51J533OKrdI7hB26coYTm2SDJ4QV3GUSZzWVoB9WzQyiorqUeYYqx2RV7zwerko3vGmW6ambebqGfj78btUMMZ00w00N6MFVO55`;
const stripePromise = loadStripe(STRIPE_API_KEY);

const signatureHashUpdates = ({updatedProfile, crewProfile, id}) => {
  const {firstName, lastName} = updatedProfile;
  if (!firstName && !lastName) return {};

  const {firstName: oldFirstName, lastName: oldLastName} = crewProfile || {};
  const signText = getSignatureText({firstName: firstName || oldFirstName, lastName: lastName || oldLastName});
  const signatureHash = uuidv5(signText, id).toUpperCase();
  return {signatureHash};
};

const updateProfile = async (props) => {
  const {id, isNew = false, crewProfile, updatedProfile, deleteEmerContactEmail} = props;
  const nothingToUpdate = !updatedProfile || Object.keys(updatedProfile).length === 0;
  const alertSuccess = {type: "success", message: `Your Profile was updated successfully!`};

  deleteEmerContactEmail &&
    (await API.graphql(graphqlOperation(deleteProfileField, {input: {fields: ["emerContactEmail"]}})));

  if (nothingToUpdate && deleteEmerContactEmail) {
    return {newProfile: {...crewProfile, emerContactEmail: null}, alert: alertSuccess};
  } else if (!updatedProfile) {
    const alertError = {type: "error", message: `Your Profile was not updated! Please, try again.`};
    return {alert: alertError};
  }

  const currentOperation = isNew ? createCrewProfile : updateCrewProfile;
  const input = {...updatedProfile, id, ...signatureHashUpdates({updatedProfile, crewProfile, id})};
  const {data} = await API.graphql(graphqlOperation(currentOperation, {input}));
  const newProfile = isNew ? data.createCrewProfile : data.updateCrewProfile;
  const shouldUpdateRanks = !!updatedProfile?.crewRankId;
  let newCrewRanks = null;
  let newCrewRanksDocs = null;

  if (shouldUpdateRanks) {
    // crew rank GROUP CAN BE changed with Subcription only!
    const {docs, group, ...crewRankProps} = newProfile.crewRank;
    const {crewRanks: ranks, ...groupProps} = group;
    newCrewRanks = isNew ? ranks.items : null; // UPDATE ranks only when new Crew Profile.
    newCrewRanksDocs = docs.items;
    newProfile.crewRank = {...crewRankProps, group: groupProps};
  }

  return {newProfile: newProfile, newCrewRanks, newCrewRanksDocs, alert: alertSuccess};
};

const updateActiveNotifications = async ({owner, currentSetup, newSetup}) => {
  const gqlOperations = {createActiveNotification, deleteActiveNotification};
  let updatedSetup = [...currentSetup];
  const message = "Notifications settings updated successfully!";
  const alert = {type: "success", message};

  for (const item of newSetup) {
    const {isActive, id} = item;
    const currentNotification = currentSetup.find(({notificationId}) => notificationId === id);
    const isCurrentlyActive = !!currentNotification;

    if (isCurrentlyActive !== isActive) {
      const input = isActive ? {notificationId: id, owner} : {id: currentNotification.id};
      const currentOperationTitle = isActive ? "createActiveNotification" : "deleteActiveNotification";
      const operation = gqlOperations[currentOperationTitle];
      const {data} = await API.graphql(graphqlOperation(operation, {input}));
      const requestResult = data[currentOperationTitle];

      updatedSetup = isActive
        ? [...updatedSetup, requestResult]
        : updatedSetup.filter((item) => item.id !== requestResult.id);
    }
  }
  return {updatedSetup, alert};
};

const checkoutSubscription = async (subscriptionId, priceId) => {
  const fetchSession = async () => {
    const apiName = "stripeAPI";
    const apiEndpoint = "/subscription/checkout";
    const body = {
      quantity: 1,
      client_reference_id: subscriptionId,
      priceId,
      success_url: `${window.location.href}?success=true`,
      cancel_url: `${window.location.href}?canceled=true`,
    };
    return API.post(apiName, apiEndpoint, {body});
  };

  const session = await fetchSession();
  const {id: sessionId, raw} = session;
  const stripe = await stripePromise;

  !!sessionId &&
    stripe.redirectToCheckout({sessionId}).then((result) => {
      // If `redirectToCheckout` fails due to a browser or network error,
      // display the localized error message: to your customer using `result.error.message`.
      return !result.error
        ? null
        : {message: `Checkout Failed due to Network Error or Browser restrictions. ${result?.error?.message}`};
    });
  return {message: raw?.message};
};

export {checkoutSubscription, updateActiveNotifications, updateProfile};
