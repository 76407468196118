import React, {useContext, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material";
import {useActor} from "@xstate/react";

import MachineContext from "../MachineContext";
const TRANSITION_DURATION = 400;

export default function ConfirmationModal() {
  const [state, send] = useActor(useContext(MachineContext));
  const {
    context: {confirmModalSetup},
  } = state;
  const [setup, setSetup] = useState(null);
  const [open, setOpen] = useState(false);

  const handleClose = ({target: {dataset}}) => {
    setOpen(false);
    send({type: "confirmModalDecision", data: parseInt(dataset.confirm)});
    setTimeout(() => setSetup(null), TRANSITION_DURATION);
  };

  useEffect(() => {
    !!confirmModalSetup && setSetup(confirmModalSetup);
    !!confirmModalSetup && !open && confirmModalSetup?.decision === undefined && setOpen(true);
  }, [confirmModalSetup, confirmModalSetup?.consent, open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      transitionDuration={TRANSITION_DURATION}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-content">
      <DialogContent>
        <DialogContentText component="div" sx={{color: "text.primary"}}>
          {setup?.content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{display: "flex", justifyContent: "space-between", px: 3, pb: 2}}>
        <Button
          onClick={handleClose}
          data-confirm={-1}
          variant="outlined"
          id="cancel-btn"
          color="error"
          sx={{color: "error"}}>
          Cancel
        </Button>

        {!!confirmModalSetup?.thirdOption && (
          <Button
            onClick={handleClose}
            data-confirm={0}
            variant="outlined"
            id="third-btn"
            color="error"
            sx={{color: "error"}}>
            {confirmModalSetup?.thirdOption}
          </Button>
        )}

        <Button
          onClick={handleClose}
          data-confirm={1}
          variant="outlined"
          id="confirm-btn"
          color="primary"
          sx={{color: "primary.dark"}}
          disabled={!setup?.consent}>
          {setup?.confirmTitle || "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
