import React from "react";

const basicColumns = [
  {
    field: "doc",
    type: "string",
    valueGetter: ({row: {doc}}) => doc?.title || "",
    title: "Title",
  },
  {
    field: "docDescription",
    type: "string",
    valueGetter: ({row: {docDescription, title}}) => `${docDescription?.content || ""}. ${title || ""}`,
    title: "Description",
  },
  {
    field: "docNum",
    type: "string",
    title: "Number",
  },
  {
    field: "issueDate",
    type: "date",
    title: "Issued",
  },
  {
    field: "expiryDate",
    type: "date",
    title: "Expire",
  },
  {
    field: "issuePlace",
    type: "string",
    title: "Place of Issue",
  },
];

const requiredDocsCols = (onIncludedInCVChanged) =>
  !onIncludedInCVChanged
    ? [...basicColumns]
    : [
        ...basicColumns,
        {
          field: "includedInCV",
          type: "boolean",
          title: "Include in CV",
          valueGetter: ({row: {includedInCV, id, docId}}) => {
            const onChange = ({target}) =>
              onIncludedInCVChanged({isRequired: !!docId, newDocData: {id, [target.name]: target.checked}});
            return (
              <input
                className="form-check-input"
                type="checkbox"
                id={`includedInCV_${id}`}
                name="includedInCV"
                checked={includedInCV}
                onChange={onChange}
              />
            );
          },
        },
      ];

export default requiredDocsCols;
