import React, {useContext, useEffect, useState} from "react";
import {useActor} from "@xstate/react";
import {Table} from "lib/components";
import {confirmDialog, dateToString} from "lib/helpers";
import MachineContext from "lib/MachineContext";
import PropTypes from "prop-types";

export default function DiverLogTable({columns}) {
  const service = useContext(MachineContext);
  const [state, send] = useActor(service);
  const {crewLogsMachineRef} = state.context;
  const {state: logState} = crewLogsMachineRef;
  const {crewDiveLogs} = logState.context;

  const [records, setRecords] = useState([]);

  async function onRowDelete(row) {
    const {diveDate, id} = row;
    const content = (
      <div>
        <p>You are about to delete Log Entry:</p>
        <p>
          <strong>Dive on:</strong> {dateToString(diveDate)}.
        </p>
        <p>This process cannot be undone.</p>
      </div>
    );
    const setup = {content, consent: true};
    const result = await confirmDialog({send, service, setup});
    result === 1 && send({type: "deleteLogEntry", data: {logType: "DiverLog", logId: id}});
  }

  // const editForm = (row) =>
  // (<form noValidate className="row needs-validation justify-content-start" >
  //   <p className='mb-2'><strong>Rank:</strong> {row.rankTitle}</p>
  //   <p className='mb-2'><strong>Vessel:</strong> {row.vesselName}</p>
  //   <div className='container'>
  //     <div className='row'>
  //       <div className='col'>
  //         <p className='mb-2'><strong>Date From:</strong></p>
  //         <input type='text' className='form-control' readOnly value={dayjs(row.dateFrom).format('DD-MMM-YYYY')} />
  //       </div>
  //       <div className='col-6'>
  //         <DateForm dateName='dateTo' dateTitle='Choose Date To' onChange={onDateToChange}
  //           cssClass='col-12' min={dayjs(row.dateFrom)} max={dayjs()} />
  //       </div>
  //     </div>
  //   </div>
  // </form >
  // )

  const onRowEdit = async (row) => {
    console.log("EDIT ROW:", row);
    // const result = await confirmDialog({ send, title: 'Log Entry', content: editForm(row), consent: true })
    // result === 1 && send({ type: 'editLogEntry', data: { id: row.id, dateTo: newDateTo } })
  };

  useEffect(() => {
    const logRecords = crewDiveLogs?.items || [];
    setRecords(logRecords);
  }, [crewDiveLogs]);

  return (
    <div className="col-12" style={{fontSize: "0.9rem"}}>
      <Table rows={records} columns={columns} onRowDelete={onRowDelete} onRowEdit={onRowEdit} />
    </div>
  );
}

DiverLogTable.propTypes = {
  columns: PropTypes.array,
};
