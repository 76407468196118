import React, {useContext} from "react";
import {Close} from "@mui/icons-material";
import {Button, Dialog, DialogContent, DialogTitle, Fade, IconButton, Link, Paper, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";
import {useActor} from "@xstate/react";
import MachineContext from "lib/MachineContext";
import PropTypes from "prop-types";

const RATING_SUBSCIPTION_ID = "ff335a4f-9681-49e9-9376-b6a261a8852c";

export default function TryFreeInfo({open, handleClose, handleSignUp}) {
  const [state] = useActor(useContext(MachineContext));
  const {subscriptions} = state.context;
  const minCharge = subscriptions?.find((item) => item.id === RATING_SUBSCIPTION_ID)?.subscriptionCharge || 2.5;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth={"md"}
      aria-labelledby="sign-up-dialog-title"
      TransitionComponent={Fade}
      transitionDuration={{enter: 500, exit: 300}}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          data-confirm={-1}
          onClick={handleClose}
          sx={{position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{display: "flex", flexDirection: "column", alignItems: "center", m: {xs: 0, sm: 2}}}>
        <Paper sx={{width: 70, height: 70, backgroundColor: blue.A700, p: 0.5, mb: 1}}>
          <img src="/images/logo.svg" width="100%" alt="CrewB logo" />
        </Paper>

        <Typography align="center" color="text.primary" sx={{fontSize: "1.6rem", fontWeight: 500, my: 2}}>
          Get full access to CrewBook account free for 1 month,
        </Typography>
        <Typography align="center" color="text.primary" variant="h6">
          then as low as ${minCharge}/month.
        </Typography>

        <Button
          onClick={handleSignUp}
          variant="contained"
          color="primary"
          sx={{
            mx: 4,
            my: 5,
            px: 5,
            py: 1,
            fontSize: "1.3rem",
            width: {xs: 1, sm: "auto"},
          }}>
          Create Account
        </Button>

        <Typography color="text.disabled" variant="body" align="center">
          Already have an account?
          <Button
            component={Link}
            onClick={handleClose}
            sx={{
              fontSize: "inherit",
              color: blue.A400,
              ml: {xs: 0, sm: 2},
              "&:hover": {textDecoration: "underline"},
            }}>
            Sign In
          </Button>
        </Typography>
      </DialogContent>
    </Dialog>
  );
}

TryFreeInfo.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSignUp: PropTypes.func,
};
