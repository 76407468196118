export default function totalSeadaysTableBody({totalSeaDays}) {
  const styles = {style: "th2", fillColor: "#eeeeff", alignment: "center"};
  let total = 0;
  const logEntries = Object.keys(totalSeaDays).map((key) => {
    total += totalSeaDays[key];
    return [
      {text: key, style: "tdLight"},
      {text: totalSeaDays[key], style: "tdLight", alignment: "right", margin: [0, 0, 10, 0]},
    ];
  });

  return [
    [
      {text: "Rank Title", ...styles},
      {text: "Total Sea Service Days", ...styles, margin: [10, 0, 10, 0]},
    ],
    ...logEntries,
    [
      {text: "Total", ...styles},
      {text: total, ...styles},
    ],
  ];
}
