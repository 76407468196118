import React, {useContext, useEffect, useMemo} from "react";
import {useForm} from "react-hook-form";
import {Box, Button, Collapse, Grid, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {formatISO} from "date-fns";
import {appSettings, MachineContext} from "lib";
import {FormInputSwitch} from "lib/components";
import PropTypes from "prop-types";

import {
  checkExistingDoc,
  checkPassportPhoto,
  DocDetailsForm,
  DocSelectForm,
  DocValidityForm,
  FileUploadForm,
} from "./uploadForm";

export default function DocUpload({docCategory, isCollapsed, onCancel}) {
  const service = useContext(MachineContext);
  const [state, send] = useActor(service);
  const {crewDocsMachineRef, profileMachineRef} = state.context;
  const {crewDocs} = crewDocsMachineRef?.state.context || {};
  const {
    crewProfile: {crewRank},
  } = profileMachineRef.state.context;
  const {passportPhotoDocId} = appSettings;
  const {bucketSize} = crewRank.group;
  const usedBucketSize = crewDocs?.totalDocsVolume / 1024 || 0; // in Mb
  const availableBucketSize = `${(bucketSize - usedBucketSize).toFixed(2)}Mb`;

  const initialFormState = useMemo(
    () => ({
      docId: "",
      docFile: null,
      docDescriptionId: "",
      title: "",
      issueDate: null,
      expiryDate: null,
      docNum: "",
      issuePlace: "",
      includedInCV: true,
    }),
    []
  );

  const methods = useForm({defaultValues: initialFormState, mode: "all"});
  const {handleSubmit, control, reset, formState, setValue, watch} = methods;
  const {isValid, isSubmitted, isSubmitSuccessful, isDirty} = formState;
  const watchDocId = watch("docId");
  const handleReset = () => onCancel();

  async function onSubmit(data) {
    if (isValid) {
      const isValidUpload =
        passportPhotoDocId === data.docId
          ? await checkPassportPhoto({photoFile: data.docFile.file, send, service})
          : isValid;
      if (isValidUpload) {
        const checkInput = {crewDocs, formState: data, send, service};
        const {result, existingDoc} = await checkExistingDoc(checkInput);

        result !== -1 && handleUpload({data, shouldArchive: result, existingDoc});
      }
    }
  }

  const handleUpload = ({data, shouldArchive, existingDoc}) => {
    let uploadData = {shouldArchive, existingDoc};
    const {expiryDate: expiry, issueDate: issue, ...restData} = data;
    const issueDate = issue ? formatISO(issue, {representation: "date"}) : null;
    const expiryDate = expiry ? formatISO(expiry, {representation: "date"}) : null;
    uploadData = {...uploadData, issueDate, expiryDate};
    if (restData.docId !== "Personal") {
      uploadData = {...uploadData, ...restData, includedInCV: true};
    } else {
      const {docDescriptionId, docId, ...docData} = restData;
      uploadData = {...uploadData, ...docData};
    }
    send({type: "uploadDoc", data: uploadData});
    onCancel();
  };

  useEffect(() => {
    isSubmitted && isSubmitSuccessful && reset(initialFormState);
  }, [isSubmitted, isSubmitSuccessful, reset, initialFormState]);

  useEffect(() => {
    return () => isCollapsed && isDirty && reset(initialFormState);
  }, [isCollapsed, isDirty, reset, initialFormState]);

  return (
    <Collapse in={!isCollapsed}>
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)} mb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <DocSelectForm
              control={control}
              docCategory={docCategory}
              setValue={setValue}
              watch={watch}
              isCollapsed={isCollapsed}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            {passportPhotoDocId !== watchDocId && (
              <>
                <DocDetailsForm control={control} />
                <DocValidityForm control={control} watch={watch} />
              </>
            )}
          </Grid>
          <Grid item xs={12} md={7}>
            <FileUploadForm control={control} watch={watch} />
          </Grid>

          {["ids", "other"].includes(docCategory) && watchDocId !== passportPhotoDocId && (
            <Grid item xs={12} md={5} sx={{display: "flex", alignItems: "center", justifyContent: "end"}}>
              <FormInputSwitch name={"includedInCV"} control={control} label={"Include in my CV"} />
            </Grid>
          )}
        </Grid>

        <Grid container spacing={3} mt={1}>
          <Grid item xs={12} sm="auto">
            <Button onClick={handleReset} fullWidth variant={"outlined"} color="error">
              Cancel Upload
            </Button>
          </Grid>
          <Grid item xs />
          <Grid item xs="auto" sx={{display: "flex", alignItems: "center"}}>
            <Typography align="right">Available Space: {availableBucketSize}</Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button type="submit" fullWidth variant={"contained"}>
              Upload Document
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  );
}

DocUpload.propTypes = {
  docCategory: PropTypes.string,
  isCollapsed: PropTypes.bool,
  onCancel: PropTypes.func,
};
