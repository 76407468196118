import React from "react";
import PropTypes from "prop-types";

export default function UnitSwitch({formState, onChange, fieldName = ""}) {
  const mId = `${fieldName}measureUnitMetric`;
  const ftId = `${fieldName}measureUnitImperial`;

  return (
    <div className="btn-group btn-group-sm" role="group" aria-label="units-switch">
      <input
        type="radio"
        className="btn-check"
        name="measureUnit"
        id={mId}
        onChange={onChange}
        checked={formState.measureUnit === "m"}
        value={"m"}
        autoComplete="off"
      />
      <label className="btn btn-outline-primary" htmlFor={mId}>
        m
      </label>
      <input
        type="radio"
        className="btn-check"
        name="measureUnit"
        id={ftId}
        onChange={onChange}
        checked={formState.measureUnit === "ft"}
        value={"ft"}
        autoComplete="off"
      />
      <label className="btn btn-outline-primary" htmlFor={ftId}>
        ft
      </label>
    </div>
  );
}

UnitSwitch.propTypes = {
  formState: PropTypes.object,
  onChange: PropTypes.func,
  fieldName: PropTypes.string,
};
