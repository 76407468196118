import React, {useContext, useEffect, useRef, useState} from "react";
import {useActor} from "@xstate/react";
import {Modal} from "bootstrap";
import {MachineContext} from "lib";

const signOutCoundown = 30000;

export default function TimeoutAlertModal() {
  const [state, send] = useActor(useContext(MachineContext));
  const isShown = state.matches("signedIn.alertingUser");
  const [countDown, setCountDown] = useState(signOutCoundown);
  const [modal, setModal] = useState(null);
  const countDownInterval = useRef(null);
  const signOutTimeout = useRef(null);

  const onSignOut = () => {
    clearInterval(countDownInterval.current);
    clearTimeout(signOutTimeout.current);
    send("signOut");
  };

  const onStaySignedIn = () => {
    clearInterval(countDownInterval.current);
    clearTimeout(signOutTimeout.current);
    send("staySignedIn");
  };

  useEffect(() => {
    const modalInstance = new Modal(document.getElementById("timeoutAlert"), {keyboard: false, backdrop: "static"});
    setModal(modalInstance);
    return () => {
      modalInstance.dispose();
    };
  }, []);

  useEffect(() => {
    if (isShown) {
      setCountDown(signOutCoundown);
      clearInterval(countDownInterval.current);
      countDownInterval.current = setInterval(() => setCountDown((counter) => counter - 1000), 1000);

      clearTimeout(signOutTimeout.current);
      signOutTimeout.current = setTimeout(() => {
        clearInterval(countDownInterval.current);
        modal.hide();
        send("signOut");
      }, signOutCoundown);

      modal.show();
    }
  }, [isShown, modal, send]);

  useEffect(() => {
    return () => {
      !!countDownInterval.current && clearInterval(countDownInterval.current);
      !!signOutTimeout.current && clearTimeout(signOutTimeout.current);
    };
  }, []);

  return (
    <div className="modal fade" id="timeoutAlert" tabIndex="-1" aria-labelledby="confirmModal" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-danger" id="confirmModal">
              Attention!
            </h5>
          </div>
          <div className="modal-body">
            <div>
              <p>
                Your Session will expire in <strong>{countDown / 1000}</strong> seconds.
              </p>
              <p>To stay signed in to your account, please, choose Stay Signed In.</p>
            </div>
          </div>
          <div className="modal-footer row justify-content-between mx-1">
            <button
              type="button"
              id="cancel-btn"
              onClick={onSignOut}
              className="btn btn-secondary col-auto"
              data-bs-dismiss="modal">
              Sign Out
            </button>
            <button
              type="button"
              id="confirm-btn"
              onClick={onStaySignedIn}
              className="btn btn-success col-auto"
              data-bs-dismiss="modal">
              Stay Signed In
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
