import {appSettings} from "lib";
import {createMachine} from "xstate";

import actions from "./actions";
import {deleteDoc, downloadDoc, getTotalDocsVolume, loadMoreDescriptions, updateDoc, uploadDoc} from "./services";
const {passportPhotoDocId} = appSettings;

const crewDocsMachine = ({crewDocs, crewRankDocs}) =>
  createMachine(
    {
      id: "crewDocsMachine",
      predictableActionArguments: true,
      initial: "displaying",
      context: {
        crewDocs: !crewDocs
          ? {totalDocsVolume: 0}
          : {
              ...crewDocs,
              totalDocsVolume: getTotalDocsVolume([
                ...(crewDocs?.requiredDocs?.items || []),
                ...(crewDocs?.personalDocs?.items || []),
              ]),
            },
        crewRankDocs,
        isMachineWorking: false,
      },
      states: {
        displaying: {
          id: "displaying",
          on: {
            deleteDoc: "deleting",
            downloadDoc: "downloading",
            uploadDoc: "uploading",
            updateCrewDoc: "updating",
            updateCrewRankDocs: {actions: "updateCrewRankDocs"},
            loadMoreDescriptions: "loadingMoreDescriptions",
          },
        },
        uploading: {
          id: "uploading",
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: (_, {data: docData}) => uploadDoc({docData}),
            onDone: [
              {
                target: "displaying",
                actions: ["updateDocs", "sendUploaded", "sendProfileImgUploaded"],
                cond: (_, {data: {profileImgKey}}) => !!profileImgKey,
              },
              {target: "displaying", actions: ["updateDocs", "sendUploaded"]},
            ],
            onError: {target: "displaying", actions: "sendError"},
          },
        },
        downloading: {
          id: "downloading",
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: (_, {data}) => downloadDoc(data),
            onDone: {target: "displaying", actions: "sendDownloaded"},
            onError: {target: "displaying", actions: "sendError"},
          },
        },
        deleting: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: (_, {data}) => deleteDoc(data),
            onDone: [
              {
                target: "displaying",
                actions: ["removeDoc", "sendDeleted", "sendProfileImgDeleted"],
                cond: (_, {data: {removedDoc}}) => removedDoc?.docId === passportPhotoDocId,
              },
              {target: "displaying", actions: ["removeDoc", "sendDeleted"]},
            ],
            onError: {target: "displaying", actions: "sendError"},
          },
        },
        updating: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: (_, {data}) => updateDoc(data),
            onDone: {target: "displaying", actions: "updateDoc"},
            onError: {target: "displaying", actions: "sendError"},
          },
        },
        loadingMoreDescriptions: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: (_, {data}) => loadMoreDescriptions(data),
            onDone: {target: "displaying", actions: "addDocDescriptions"},
            onError: {target: "displaying", actions: "sendError"},
          },
        },
      },
    },
    {actions}
  );

export default crewDocsMachine;
