import {API, graphqlOperation} from "aws-amplify";
import {formatISO} from "date-fns";
import {getShortCrewProfileByCrewId, rankGroupsByCharge} from "graphql/customQueries";
import {createBlockedAccount} from "graphql/mutations";
import {blockedAccountByOwner} from "graphql/queries";

async function loadSubscriptions(user) {
  const variables = {status: "active", sortDirection: "ASC"};

  try {
    // TODO: check access denied to this source
    const response = await API.graphql({query: rankGroupsByCharge, variables, authMode: "AWS_IAM"});
    const {rankGroupsByCharge: rankGroups} = response.data;

    return rankGroups.items;
  } catch (error) {
    if (user) {
      const rankGroupsResponse = await API.graphql(graphqlOperation(rankGroupsByCharge, variables));
      const {rankGroupsByCharge: rankGroups} = rankGroupsResponse.data;

      return rankGroups.items;
    }
    return [];
  }
}

const loadAccountData = async (owner) => {
  let crewProfile = null;
  const {data: blockedAccountByOwnerReponse} = await API.graphql(graphqlOperation(blockedAccountByOwner, {owner}));
  const {items: blockedAccounts} = blockedAccountByOwnerReponse.blockedAccountByOwner;
  const accountBlockInfo = blockedAccounts.length > 0 ? blockedAccounts[0] : null;

  if (blockedAccounts.length > 0) {
    const {
      data: {getCrewProfile},
    } = await API.graphql(graphqlOperation(getShortCrewProfileByCrewId, {id: owner}));
    crewProfile = getCrewProfile;
  }

  return {accountBlockInfo, crewProfile};
};

async function blockUserAccount({owner, reason}) {
  const blockedDate = formatISO(new Date(), {representation: "date"});
  const input = {owner, reason, isClosed: false, blockedDate};
  const {data: createBlockedAccountReponse} = await API.graphql(graphqlOperation(createBlockedAccount, {input}));
  const {createBlockedAccount: accountBlockInfo} = createBlockedAccountReponse;

  return {accountBlockInfo};
}

export {blockUserAccount, loadAccountData, loadSubscriptions};
