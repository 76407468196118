import React, {useReducer, useRef, useState} from "react";
import {Box, Paper, Typography} from "@mui/material";
import {formReducer, validatePasswords} from "lib/helpers";
import PropTypes from "prop-types";

import {CodeInput, ErrorNote, FormFooter, PasswordInput} from "../common";

export default function VerifyPasswordResetForm(props) {
  const {alert, onSubmit, onBackToSignIn, isCodeRequired = false} = props;
  const formRef = useRef(null);
  const [error, setError] = useState("");
  const title = isCodeRequired ? "Reset your password" : "Change password";
  const initialState = {verificationCode: "", newPassword: "", confirmNewPassword: ""};
  const [formState, dispatch] = useReducer(formReducer, initialState);

  const onChange = ({currentTarget: {name, value}}) => dispatch({type: name, data: value});

  const handleBack = () => onBackToSignIn();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    formRef.current.classList.add("was-validated");

    if (formRef.current.checkValidity()) {
      const {verificationCode, newPassword, confirmNewPassword} = formState;
      const {isValid, message} = validatePasswords(newPassword, confirmNewPassword);
      isValid ? onSubmit({verificationCode, newPassword}) : setError(message);
      formRef.current.classList.remove("was-validated");
    }
  };

  return (
    <Paper elevation={16} sx={{p: 2}}>
      <Typography variant="h6" align="center" mb={3}>
        {title}
      </Typography>
      <Box component="form" ref={formRef} noValidate onSubmit={handleSubmit}>
        {isCodeRequired && (
          <CodeInput
            name={"verificationCode"}
            form={formRef.current}
            value={formState.verificationCode}
            onChange={onChange}
          />
        )}
        <PasswordInput
          form={formRef.current}
          name={"newPassword"}
          value={formState.newPassword}
          title={"New Password"}
          onChange={onChange}
          showRequirements={true}
        />
        <PasswordInput
          form={formRef.current}
          name={"confirmNewPassword"}
          value={formState.confirmNewPassword}
          title={"Confirm New Password"}
          onChange={onChange}
        />
        <ErrorNote {...{alert, error}} />
        <FormFooter backBtnTitle={"Back to Sign In"} onBackClicked={handleBack} sumbitBtnTitle={"Submit"} />
      </Box>
    </Paper>
  );
}

VerifyPasswordResetForm.propTypes = {
  alert: PropTypes.object,
  onSubmit: PropTypes.func,
  onBackToSignIn: PropTypes.func,
  isCodeRequired: PropTypes.bool,
};
