import React, {useContext, useEffect, useState} from "react";
import {Box, Divider, List, ListItem, ListItemText, Typography} from "@mui/material";
import {orange} from "@mui/material/colors";
import {useActor} from "@xstate/react";
import {isBefore, parseISO, subMonths} from "date-fns";
import {AuthLink} from "lib/components";
import MachineContext from "lib/MachineContext";

import DashboardCard from "./DashboardCard";

export default function DocsNotes() {
  const [state] = useActor(useContext(MachineContext));
  const {crewDocsMachineRef} = state.context;
  const {crewDocs: allDocs} = crewDocsMachineRef?.state.context || [];
  const [docsNotes, setDocsNotes] = useState([]);

  useEffect(() => {
    const generalNote = (
      <Typography>
        Keep your&nbsp;<AuthLink to="/crewdocs">CrewDocs</AuthLink> up to date!
      </Typography>
    );

    if (!allDocs) {
      setDocsNotes([generalNote]);
      return;
    }

    const {items: crewDocs} = allDocs?.requiredDocs || {};

    if (!crewDocs || crewDocs.length === 0) {
      setDocsNotes([generalNote]);
      return;
    }

    const {expired, expireSoon} = crewDocs.reduce(
      (result, doc) => {
        if (doc.expiryDate) {
          const today = new Date();
          const expDate = parseISO(doc.expiryDate);
          const {expired, expireSoon} = result;
          isBefore(expDate, today) ? expired.push(doc) : isBefore(subMonths(expDate, 3), today) && expireSoon.push(doc);
        }
        return result;
      },
      {expired: [], expireSoon: []}
    );

    if (expired.length === 0 && expireSoon.length === 0) {
      setDocsNotes([generalNote]);
    } else {
      const docsMessages = [];

      expired.length > 0 &&
        docsMessages.push(
          <Typography>
            You have&nbsp;
            <Typography color="error.main" component="span" variant="button">
              {expired.length}&nbsp;expired Document(s)!
            </Typography>
          </Typography>
        );

      expireSoon.length > 0 &&
        docsMessages.push(
          <Typography>
            You have&nbsp;
            <Typography color={orange[500]} component="span" variant="button">
              {expireSoon.length}&nbsp;Document(s) expiring&nbsp;
            </Typography>
            in less than 3 month!
          </Typography>
        );

      docsMessages.push(generalNote);
      setDocsNotes(docsMessages);
    }
  }, [allDocs]);

  return (
    <DashboardCard title={"My Documents"} className="tour-step-docs-notifications">
      <List sx={{width: 1, pt: 0}}>
        {docsNotes.map((note, index) => (
          <ListItem key={index} disablePadding disableGutters sx={{m: 0}}>
            <ListItemText
              primary={
                <Box>
                  {note}
                  {index !== docsNotes.length - 1 && <Divider />}
                </Box>
              }
            />
          </ListItem>
        ))}
      </List>
    </DashboardCard>
  );
}
