import {WALLET_ID_FIELD_NAME} from "lib/data";
import {v5 as uuidv5} from "uuid";

export function getWalletId(wallet) {
  return wallet[WALLET_ID_FIELD_NAME] && wallet.provider?.id
    ? uuidv5(`${wallet[WALLET_ID_FIELD_NAME]}-${wallet.provider?.id}`, wallet.profileId)
    : undefined;
}
export function matchWallet(wallet1, wallet2) {
  return wallet1.provider.id === wallet2.provider.id && wallet1[WALLET_ID_FIELD_NAME] === wallet2[WALLET_ID_FIELD_NAME];
}
