import React, {useCallback, useContext, useEffect, useState} from "react";
import {Box, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {MachineContext} from "lib";
import {getSvgSignature} from "lib/helpers";

export default function Signature() {
  const [state] = useActor(useContext(MachineContext));
  const {profileMachineRef} = state.context;
  const {crewProfile} = profileMachineRef?.state?.context || {};
  const {signatureHash, lastName, firstName} = crewProfile || {};
  const [signatureSvg, setSignatureSvg] = useState(<></>);

  const updateSignature = useCallback(async () => {
    const newSignature = await getSvgSignature({firstName, lastName, hashNumber: signatureHash});
    setSignatureSvg(newSignature);
  }, [signatureHash, firstName, lastName]);

  useEffect(() => updateSignature(), [updateSignature]);

  return (
    <Box my={1} sx={{width: 1, maxWidth: 200}}>
      <Typography variant="subtitle2" align="center">
        electronic signature:
      </Typography>
      <Box>{signatureSvg}</Box>
    </Box>
  );
}
