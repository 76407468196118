import React, {useCallback, useContext, useEffect, useState} from "react";
import {NavLink} from "react-router-dom";
import {AppBar, Box, Button, Toolbar} from "@mui/material";
import {useActor} from "@xstate/react";
import {MachineContext} from "lib";

import UserMenu from "./navBar/UserMenu";

export default function NavBar() {
  const [, send] = useActor(useContext(MachineContext));
  const [showTour, setShowTour] = useState(false);
  const handleTourGuide = useCallback(() => setShowTour(true), []);

  useEffect(() => {
    if (showTour) {
      send({type: "setIsTourOpen", data: true});
      setShowTour(false);
    }
  }, [showTour, send]);

  return (
    <AppBar position="static">
      <Toolbar disableGutters variant="dense" sx={{paddingRight: "0.25rem"}}>
        <Button component={NavLink} to={"/"} sx={{"&:hover": {opacity: 0.7}}}>
          <img src="/images/logo.svg" alt="CrewB" width="auto" height="42px" />
        </Button>
        <Box sx={{flexGrow: 1, justifyContent: "center"}} />
        <UserMenu handleTourGuide={handleTourGuide} />
      </Toolbar>
    </AppBar>
  );
}
