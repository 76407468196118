import React, {useCallback} from "react";
import {Android, Apple, ArrowForwardIos} from "@mui/icons-material";
import {Box, Button, Grid, Typography} from "@mui/material";
import PropTypes from "prop-types";
import {QRCodeSVG} from "qrcode.react";

const ANDROID_LINKS = (
  <Typography>
    <Android />
    Android:{` `}
    <a href="https://play.google.com/store/apps/details?id=com.authy.authy">Authy</a>
    {`, `}
    <a href="https://play.google.com/store/apps/details?id=com.duosecurity.duomobile">Duo Mobile</a>
    {`, `}
    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">
      Google Authenticator
    </a>
    {`, `}
    <a href="https://play.google.com/store/apps/details?id=com.lastpass.authenticator">LastPass Authenticator</a>
    {`, `}
    <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator">Microsoft Authenticator</a>.
  </Typography>
);
const IOS_LINKS = (
  <Typography>
    <Apple />
    iOS:{` `}
    <a href="https://apps.apple.com/us/app/authy/id494168017">Authy</a>
    {`, `}
    <a href="https://apps.apple.com/us/app/duo-mobile/id422663827">Duo Mobile</a>
    {`, `}
    <a href="https://apps.apple.com/us/app/google-authenticator/id388497605">Google Authenticator</a>
    {`, `}
    <a href="https://apps.apple.com/us/app/lastpass-authenticator/id1079110004">LastPass Authenticator</a>
    {`, `}
    <a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458">Microsoft Authenticator</a>.
  </Typography>
);
const MIN_BTN_WIDTH = "10rem";

export default function SetupForm({totpSetup, send}) {
  const onContinueClick = useCallback(() => send("CONTINUE_TOTP_SETUP"), [send]);

  return (
    <>
      <Typography variant="h6" align="center" mb={3}>
        Setup your One-time Password
      </Typography>
      <Typography>
        To setup your One-time Password, you need to use an authenticator application installed on your mobile device or
        tablet.
      </Typography>
      <Typography>You may register any application from the list:</Typography>
      {IOS_LINKS}
      {ANDROID_LINKS}
      <Typography mt={2} gutterBottom>
        When you finish installation of an authenticator app, open it and complete registration process by choosing
        option to create a new account (the “+” icon).
      </Typography>
      <Typography gutterBottom>{"Then use the device's camera to scan the QR Code below."}</Typography>
      <Box sx={{display: "flex", justifyContent: "center"}}>
        {totpSetup?.qrCodeStr && <QRCodeSVG value={totpSetup?.qrCodeStr} size={200} includeMargin />}
      </Box>
      <Grid container spacing={1} justifyContent="flex-end">
        <Grid item xs={12} sm="auto">
          <Button
            onClick={onContinueClick}
            variant={"outlined"}
            color="primary"
            endIcon={<ArrowForwardIos />}
            fullWidth
            sx={{minWidth: MIN_BTN_WIDTH, visibility: !totpSetup?.qrCodeStr ? "hidden" : "visible"}}>
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

SetupForm.propTypes = {
  totpSetup: PropTypes.object,
  send: PropTypes.func,
};
