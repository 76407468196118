const ROUTING_CODE_PATTERN = /^[A-Z0-9]{2,12}$/;
const SWIFT_CODE_FIELD_NAME = "swiftCode";
const ACCOUNT_NUMBER_FIELD_NAME = "accountNumber";
const IBAN_FIELD_NAME = "iban";
const COUNTRY_CODE_FIELD_NAME = "country_code";
const COUNTRY_NAME_FIELD_NAME = "country_name";

// TODO: refactor to enum in TS
const AllowedBankCertificateFileExtension = Object.freeze({
  jpeg: "jpeg",
  jpg: "jpg",
  pdf: "pdf",
  png: "png",
});

const AccountDetailsDocumentKind = Object.freeze({
  BankAccountCertificate: "BankAccountCertificate",
  PassportCopy: "PassportCopy",
});

const BankAccountPreference = Object.freeze({
  Additional: "Additional",
  Primary: "Primary",
});

export {
  ACCOUNT_NUMBER_FIELD_NAME,
  AccountDetailsDocumentKind,
  AllowedBankCertificateFileExtension,
  BankAccountPreference,
  COUNTRY_CODE_FIELD_NAME,
  COUNTRY_NAME_FIELD_NAME,
  IBAN_FIELD_NAME,
  ROUTING_CODE_PATTERN,
  SWIFT_CODE_FIELD_NAME,
};
