import React from "react";
import {Grid, MenuItem} from "@mui/material";
import {FormInputSelect, FormInputText} from "lib/components";
import {COVERALL_SIZE, SHOW_SIZE} from "lib/data";
import PropTypes from "prop-types";

export default function BodySizeForm({control, disabled}) {
  const rules = {
    weight: {
      required: "Weight is required",
      min: {value: 40, message: "Min value 40"},
      max: {value: 200, message: "Max value is 200"},
    },
    coverallSize: {required: "Coverall Size is required"},
    shoeSize: {required: "Shoe Size  is required"},
  };

  return (
    <Grid container spacing={{xs: 2, sm: 3}} mb={3}>
      <Grid item xs={12} sm={4}>
        <FormInputText
          name={"weight"}
          control={control}
          label={"Weight, kg"}
          fullWidth
          rules={rules.weight}
          type="number"
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormInputSelect
          name={"coverallSize"}
          control={control}
          label={"Coverall Size (EU / USA)"}
          fullWidth
          rules={rules.coverallSize}
          disabled={disabled}
          options={COVERALL_SIZE.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormInputSelect
          name={"shoeSize"}
          control={control}
          label={"Shoe Size (EU / USA)"}
          fullWidth
          rules={rules.shoeSize}
          disabled={disabled}
          options={SHOW_SIZE.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        />
      </Grid>
    </Grid>
  );
}

BodySizeForm.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
};
