import React, {useContext} from "react";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {appSettings, MachineContext} from "lib";
import PropTypes from "prop-types";

export default function AuthLink(params) {
  const {children, to, className, isNavLink = false, ...props} = params;
  const [state, send] = useActor(useContext(MachineContext));
  const navigate = useNavigate();
  const {accountBlockInfo, profileMachineRef} = state.context;
  const crewProfile = profileMachineRef?.state.context?.crewProfile;
  const {authRoutes} = appSettings;

  const handleLinkClicked = (event) => {
    event.preventDefault();

    if (!authRoutes.includes(to)) {
      navigate(to);
      return;
    }

    if (!state.matches("signedIn") && !state.matches("signedInBlocked")) {
      const data = {type: "error", message: `Please, Log In to Manage your Crew Account.`};
      send({type: "alert", data});
      return;
    }

    const isSubscriptionPaid =
      !!profileMachineRef?.state.context?.crewSubscription && !profileMachineRef.state.context.crewSubscription.isFree;
    const isSubscriptionFulfilled = !appSettings.subscriptionRequiredRoutes.includes(to) || isSubscriptionPaid;
    const message =
      !!crewProfile && !isSubscriptionFulfilled ? (
        <Box>
          <Typography>This Service is available with paid Subscription Plan only.</Typography>
          <Typography>
            Activate your <Link to="/subscription">Subscription</Link> now to enjoy full range of service.
          </Typography>
        </Box>
      ) : (
        <Typography>Start with setting up your Profile.</Typography>
      );

    !!crewProfile && !!isSubscriptionFulfilled
      ? navigate(to)
      : !crewProfile && to === "/profile"
      ? navigate(to)
      : !accountBlockInfo && send({type: "alert", data: {type: "error", message}});
  };

  return isNavLink ? (
    <NavLink className={!className ? "" : className} to={to} {...props} onClick={handleLinkClicked}>
      {children}
    </NavLink>
  ) : (
    <Link className={!className ? "" : className} to={to} {...props} onClick={handleLinkClicked}>
      {children}
    </Link>
  );
}

AuthLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  className: PropTypes.string,
  isNavLink: PropTypes.bool,
};
