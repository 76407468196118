import React from "react";
import {DIVE_TYPE} from "lib/data";
import PropTypes from "prop-types";

export default function DiveSiteForm({formState, onChange}) {
  return (
    <>
      <div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-3">
        <label className="form-label" htmlFor="diveLocation">
          Dive/worksite Location
        </label>
        <input
          type="text"
          className="form-control"
          id="diveLocation"
          value={formState.diveLocation}
          name="diveLocation"
          maxLength="64"
          onChange={onChange}
          required
        />
        <div className="invalid-feedback">Please provide a valid Dive Location.</div>
      </div>

      <div className="col-12 col-sm-6 col-md-5 col-lg-5 mb-3">
        <label className="form-label" htmlFor="worksite">
          Vessel/Installation
        </label>
        <input
          type="text"
          className="form-control"
          id="worksite"
          value={formState.worksite}
          name="worksite"
          maxLength="64"
          onChange={onChange}
          required
        />
        <div className="invalid-feedback">Please provide a valid Vessel/Installation.</div>
      </div>

      <div className="col col-sm-6 col-md-3 col-lg-3 mb-3">
        <label htmlFor="diveType" className="form-label">
          Type of Dive
        </label>
        <select
          className="form-select"
          id="diveType"
          value={formState.diveType}
          onChange={onChange}
          name="diveType"
          required>
          <option value="" disabled>
            Choose...
          </option>
          {DIVE_TYPE.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">Please select a valid Type of Dive.</div>
      </div>
    </>
  );
}

DiveSiteForm.propTypes = {
  formState: PropTypes.object,
  onChange: PropTypes.func,
};
