import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Delete, Verified} from "@mui/icons-material";
import {Box, Chip, CircularProgress, Fab, Grid, IconButton, Tooltip, Typography, useMediaQuery} from "@mui/material";
import {useActor} from "@xstate/react";
import {AgGridReact} from "ag-grid-react";
import {AccountDetailsDocumentKind, AllowedBankCertificateFileExtension, BankAccountPreference} from "lib/data";
import {confirmDialog, getFileExtension} from "lib/helpers";
import MachineContext from "lib/MachineContext";
import {getBankAccountId} from "machine/serviceMachines/marfinMachineSet/bankAccount";

import AddBankAccountStepper from "./AddBankAccountStepper";

const ACTIONS_COLS = ["actions", "preference", "bankAccountCertificate"];
const CERTIFICATE_ACCEPT_INPUT = `.jpeg,, .jpg, .png, .pdf`;
const CERTIFICATE_MAX_SIZE = 500;

export default function BankAccounts() {
  const service = useContext(MachineContext);
  const [state, send] = useActor(service);
  const navigate = useNavigate();
  const location = useLocation();
  const gridRef = useRef(null);
  const uploadFileRef = useRef(null);
  const currentClickedRowRef = useRef(null);

  const [isGridApiReady, setIsGridApiReady] = useState(false);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  // const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const crewProfile = useMemo(
    () => state.context?.profileMachineRef?.state?.context.crewProfile,
    [state.context?.profileMachineRef?.state?.context]
  );
  const bankAccounts = useMemo(
    () => state.context.marfinMachineRef?.state?.context.bankAccounts,
    [state.context.marfinMachineRef?.state?.context]
  );
  const defaultColDef = useMemo(
    () => ({
      editable: false,
      filter: false,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      autoHeight: true,
    }),
    []
  );
  const columns = useMemo(
    () =>
      [
        {
          field: "label",
          colId: "beneficiaryNickname",
          wrapText: true,
          flex: 1,
          minWidth: 100,
          headerName: "Nickname / Beneficiary Name",
          valueGetter: ({data}) => {
            const beneficiary = JSON.parse(data.beneficiary);
            const nickname = data.label;

            return nickname || (beneficiary?.beneficiary_name ?? "");
          },
        },
        isSmallScreen
          ? {
              field: "accountNumber",
              headerName: "Account Number / IBAN",
              wrapText: true,
              minWidth: 100,
              flex: 1,
              valueGetter: ({data}) => (data.iban || data.accountNumber).toUpperCase(),
            }
          : undefined,
        // isMediumScreen
        //   ? {
        //       field: "swiftCode",
        //       headerName: "SWIFT / BIC code",
        //       flex: 1,
        //       valueFormatter: ({value}) => value.toUpperCase(),
        //     }
        //   : undefined,
        // TODO: do we need this column at all ?
        // isMediumScreen
        //   ? {
        //       field: "bank",
        //       headerName: "Bank name",
        //       wrapText: true,
        //       flex: 2,
        //       minWidth: 300,
        //       valueFormatter: ({value}) => {
        //         const _value = JSON.parse(value);
        //         const structuredAddress = _value?.structured_address;
        //         const country = structuredAddress?.country_name ?? "";
        //         const city = structuredAddress?.town_name ?? "";
        //         const address = !city ? country : `${country}, ${city}`;
        //         return (_value?.name ?? "") + ", " + address;
        //       },
        //     }
        //   : undefined,
        {
          field: "preference",
          colId: "preference",
          headerName: "Preference",
          width: 105,
          cellRenderer: ({data, context, value}) => {
            return value === BankAccountPreference.Primary ? (
              <Chip label="Primary" size="small" color="primary" />
            ) : (
              <Tooltip title="Set as Primary Bank Account">
                <Chip
                  label="Set Primary"
                  size="small"
                  color="primary"
                  variant="outlined"
                  clickable
                  onClick={() => context.onSetAsPrimaryClicked(data)}
                />
              </Tooltip>
            );
          },
        },
        {
          field: "bankAccountCertificate",
          colId: "bankAccountCertificate",
          headerName: "Bank Account Certificate",
          width: isSmallScreen ? 100 : 80,
          valueFormatter: ({data}) => data.bankAccountCertificate?.status,
          cellRenderer: ({data, context}) => {
            const {s3Uri, verified, isLoading = false} = data.bankAccountCertificate ?? {};

            return s3Uri ? (
              <Tooltip title={verified ? "Verified Bank Certificate" : "Bank Certificate"}>
                <Chip
                  clickable
                  label={"cert."}
                  size="small"
                  color={verified ? "success" : "primary"}
                  icon={verified && isSmallScreen ? <Verified /> : null}
                  onClick={() => context.onDownloadBankCertificateClicked({row: data})}
                  onDelete={() => context.onDeleteBankCertificateClicked({row: data})}
                />
              </Tooltip>
            ) : (
              <>
                <Tooltip title="Upload Bank Account Certificate">
                  <Chip
                    clickable
                    label="Upload"
                    size="small"
                    color="primary"
                    variant="outlined"
                    icon={isLoading ? <CircularProgress size={"1rem"} color="primary" /> : null}
                    onClick={() => {
                      currentClickedRowRef.current = data;
                      uploadFileRef.current?.click();
                    }}
                  />
                </Tooltip>
              </>
            );
          },
        },
        {
          colId: "actions",
          headerName: "",
          width: 40,
          cellRenderer: ({data, context}) => {
            return (
              <Tooltip title="Delete account">
                <IconButton color="error" onClick={() => context.onDeleteRowClicked(data)} sx={{p: 0}}>
                  <Delete />
                </IconButton>
              </Tooltip>
            );
          },
        },
      ].filter((item) => !!item),
    [isSmallScreen]
  );

  const [columnDefs] = useState([]);
  const [rowData, setRowData] = useState();
  const [isAddNewBankDetailsOpen, setIsAddNewBankDetailsOpen] = useState(false);
  const getRowId = useMemo(() => {
    return ({data}) => data.id;
  }, []);
  const onGridReady = useCallback(() => setIsGridApiReady(true), []);
  const onAddBankDetails = useCallback(() => setIsAddNewBankDetailsOpen(true), []);
  const onCloseClicked = useCallback(() => setIsAddNewBankDetailsOpen(false), []);

  const onCellClicked = useCallback(
    ({column, data}) => {
      if (!ACTIONS_COLS.some((item) => item === column.colId)) {
        navigate(`${location.pathname}/bank-accounts/${data.id}`);
      }
    },
    [location.pathname, navigate]
  );

  const onDeleteRowClicked = useCallback(
    async (data) => {
      const isPrimary = data.preference === BankAccountPreference.Primary;

      if (isPrimary && bankAccounts.length > 1) {
        const message =
          "Before deleting this Primary Account, please set a New Primary Account to maintain uninterrupted service.";
        send({type: "alert", data: {type: "error", message}});
        return;
      }

      const beneficiary = JSON.parse(data.beneficiary)?.beneficiary_name ?? "";
      const content = (
        <>
          <Typography gutterBottom>
            {"You are about to "} <strong>{"Delete"}</strong>
          </Typography>
          <Typography>
            {data.iban ? "IBAN: " : "Account number: "}{" "}
            <strong>{(data.iban || data.accountNumber).toUpperCase()}</strong>
          </Typography>
          <Typography gutterBottom>
            {"Beneficiary: "} <strong>{beneficiary}</strong>
          </Typography>
          <Typography>This process cannot be undone.</Typography>
        </>
      );
      const result = await confirmDialog({send, service, setup: {content, consent: true}});

      if (result === 1) {
        send({type: "DELETE_BANK_ACCOUNT", data: {accountNumber: data.accountNumber, swiftCode: data.swiftCode}});
      }
    },
    [bankAccounts, send, service]
  );

  const onSetAsPrimaryClicked = useCallback(
    async (data) => {
      const beneficiary = JSON.parse(data.beneficiary)?.beneficiary_name ?? "";
      const content = (
        <>
          <Typography gutterBottom>
            {"You are about to set new "} <strong>Primary Account</strong>:
          </Typography>
          <Typography>
            {data.iban ? "IBAN: " : "account number: "}{" "}
            <strong>{(data.iban || data.accountNumber).toUpperCase()}</strong>
          </Typography>
          <Typography>
            {"beneficiary: "} <strong>{beneficiary}</strong>
          </Typography>
        </>
      );
      const result = await confirmDialog({send, service, setup: {content, consent: true}});

      if (result === 1) {
        send({type: "SET_PRIMARY_BANK_ACCOUNT", data: {accountNumber: data.accountNumber, swiftCode: data.swiftCode}});
      }
    },
    [send, service]
  );

  const onUploadBankCertificateClicked = useCallback(
    ({file}) => {
      const fileSize = Math.ceil(file.size / 1024);
      const fileExt = getFileExtension(file.name).toLowerCase();
      const certificateExt = AllowedBankCertificateFileExtension[fileExt];

      if (fileSize > CERTIFICATE_MAX_SIZE) {
        const message = `The file you attempted to upload is too large. Please ensure your file is no larger than ${CERTIFICATE_MAX_SIZE}Kb and try again.`;
        send({type: "alert", data: {type: "error", message}});
      } else if (!certificateExt) {
        const message = `The file extension of the uploaded file is not allowed. Please ensure your file has one of the following extensions: [${Object.values(
          AllowedBankCertificateFileExtension
        ).join(", ")}], and try again.`;
        send({type: "alert", data: {type: "error", message}});
      } else {
        const row = currentClickedRowRef.current;
        const uploadUrlData = {
          accountNumber: row.accountNumber,
          documentKind: AccountDetailsDocumentKind.BankAccountCertificate,
          fileType: certificateExt,
          swiftCode: row.swiftCode,
        };

        gridRef.current.api.applyTransaction({
          update: [{...row, bankAccountCertificate: {...row.bankAccountCertificate, isLoading: true}}],
        });
        send({type: "UPLOAD_BANK_CERTIFICATE", data: {uploadUrlData, file}});
        currentClickedRowRef.current = null;
      }
    },
    [send]
  );

  const onFileChange = useCallback(
    ({target}) => {
      const file = target?.files[0];

      if (file) {
        onUploadBankCertificateClicked({file});
      }
    },
    [onUploadBankCertificateClicked]
  );

  const onDeleteBankCertificateClicked = useCallback(
    async ({row}) => {
      const beneficiary = JSON.parse(row.beneficiary)?.beneficiary_name ?? "";
      const content = (
        <>
          <p>
            {"You are about to Delete Bank Certificate for "}
            {row.iban ? "IBAN: " : "Account number: "}
            {(row.iban || row.accountNumber).toUpperCase()}
            {`, Beneficiary: "${beneficiary}"`}.
          </p>
          <p>This process cannot be undone.</p>
        </>
      );
      const result = await confirmDialog({send, service, setup: {content, consent: true}});

      if (result === 1) {
        send({
          type: "DELETE_BANK_ACCOUNT_CERTIFICATE",
          data: {accountNumber: row.accountNumber, swiftCode: row.swiftCode},
        });
      }
    },
    [send, service]
  );

  const onDownloadBankCertificateClicked = useCallback(
    ({row}) => {
      send({
        type: "DOWNLOAD_BANK_ACCOUNT_CERTIFICATE",
        data: {
          downloadUrlData: {
            accountNumber: row.accountNumber,
            swiftCode: row.swiftCode,
            documentKind: AccountDetailsDocumentKind.BankAccountCertificate,
          },
          fileKey: row.bankAccountCertificate.s3Uri,
        },
      });
    },
    [send]
  );

  useEffect(() => {
    setRowData(
      bankAccounts.map((item) => ({
        ...item,
        id: getBankAccountId(item, crewProfile.id),
      }))
    );
  }, [bankAccounts, crewProfile]);

  useEffect(() => {
    isGridApiReady && gridRef.current.api?.setColumnDefs(columns);
  }, [isGridApiReady, columns]);

  return (
    <Box sx={{display: "flex", height: 1, flexDirection: "column"}}>
      <Box sx={{width: 1}}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Fab
              color="primary"
              aria-label="add document"
              variant="extended"
              size="medium"
              onClick={onAddBankDetails}
              sx={{mb: 2}}>
              Add Beneficiary Bank Details
            </Fab>
          </Grid>
        </Grid>
        {isAddNewBankDetailsOpen && <AddBankAccountStepper isOpen={true} onClose={onCloseClicked} />}
      </Box>
      <Box sx={{flexGrow: 1, pb: 1}} className="ag-theme-material ag-theme-material-light-blue" id="bank-accounts">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          rowClass={"clickable"}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressLoadingOverlay={true}
          suppressCellFocus={true}
          onGridReady={onGridReady}
          getRowId={getRowId}
          onCellClicked={onCellClicked}
          context={{
            onDeleteRowClicked,
            onSetAsPrimaryClicked,
            onUploadBankCertificateClicked,
            onDeleteBankCertificateClicked,
            onDownloadBankCertificateClicked,
          }}
        />
      </Box>
      <input hidden type="file" ref={uploadFileRef} onChange={onFileChange} accept={CERTIFICATE_ACCEPT_INPUT} />
    </Box>
  );
}
