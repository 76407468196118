import React, {useContext, useMemo} from "react";
import {Grid, MenuItem} from "@mui/material";
import {useActor} from "@xstate/react";
import {subYears} from "date-fns";
import {FormInputDateRange, FormInputSelect} from "lib/components";
import MachineContext from "lib/MachineContext";
import PropTypes from "prop-types";

export default function RankSelectForm({control, watch}) {
  const [state] = useActor(useContext(MachineContext));
  const {profileMachineRef} = state.context;
  const {state: profileState} = profileMachineRef; // TODO: refactor with useMemo
  const {crewRanks} = profileState.context;

  const rules = {
    rankTitle: {required: "Rank name is required"},
  };

  const rangeLimits = useMemo(
    () => ({minFrom: subYears(new Date(), 60), maxFrom: new Date(), minTo: null, maxTo: new Date()}),
    []
  );

  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} sm={6}>
        <FormInputSelect
          name={"rankTitle"}
          control={control}
          label={"Crew Rank"}
          rules={rules.rankTitle}
          fullWidth
          options={
            crewRanks?.map(({id, title}) => (
              <MenuItem key={id} value={title}>
                {title}
              </MenuItem>
            )) || []
          }
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <FormInputDateRange
          control={control}
          watch={watch}
          dateFromLabel={"Date From"}
          dateToLabel={"Date To"}
          dateFromName="dateFrom"
          dateToName="dateTo"
          dateToRequired={false}
          rangeLimits={rangeLimits}
        />
      </Grid>
    </Grid>
  );
}

RankSelectForm.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
};
