import {API, graphqlOperation} from "aws-amplify";
import dayjs from "dayjs";
import {createDiverLog, createSeaLog} from "graphql/mutations";
import {getOverlapLogEntries} from "lib/helpers";

const getTotalSeaDays = (newSeaLog = []) =>
  newSeaLog.reduce((result, {dateFrom, dateTo, rankTitle}) => {
    const rankTotal = result[rankTitle] || 0;
    return !dateTo ? result : {...result, [rankTitle]: rankTotal + 1 + dayjs(dateTo).diff(dayjs(dateFrom), "day")};
  }, {});

const getDiverRunningTotals = (diverLog) => {
  if (!diverLog) return {};

  return diverLog.reduce(
    (result, logEntry) => {
      const {items, satTotalTime, satTotalDives, nonSatTotalTime, nonSatTotalDives} = result;
      const {diveType} = logEntry;
      const isSatDive = diveType.includes("sat");
      const totalSatDives = isSatDive ? satTotalDives + 1 : satTotalDives;
      const totalNonSatDives = isSatDive ? nonSatTotalDives : nonSatTotalDives + 1;
      let totalSatTime = null;
      let totalNonStaTime = null;
      let updatedLogEntry = {};

      if (isSatDive) {
        const [hh, mm] = logEntry.lockOutTime.split(":");
        const totalMm = satTotalTime.mm + parseInt(mm, 10);
        const totalHh = satTotalTime.hh + parseInt(hh, 10) + Math.floor(totalMm / 60);
        totalSatTime = {hh: totalHh, mm: totalMm % 60};
        updatedLogEntry = {...logEntry, satTotalTime: totalSatTime, satTotalDives: totalSatDives};
      } else {
        // const { diveDate, leftSurfaceTime, decoCompletedAt } = logEntry
        // const startTime = dayjs(`${diveDate}T${leftSurfaceTime}:00.000Z`)
        // const endTime = dayjs(`${diveDate}T${decoCompletedAt}:00.000Z`)
        // const diff = endTime.diff(startTime, 'minute')
        const totalMm = nonSatTotalTime.mm + logEntry.bottomTime;
        const totalHh = nonSatTotalTime.hh + Math.floor(totalMm / 60);
        totalNonStaTime = {hh: totalHh, mm: totalMm % 60};
        updatedLogEntry = {...logEntry, nonSatTotalTime: totalNonStaTime, nonSatTotalDives: totalNonSatDives};
      }

      return {
        items: [...items, updatedLogEntry],
        satTotalTime: totalSatTime || satTotalTime,
        satTotalDives: totalSatDives,
        nonSatTotalTime: totalNonStaTime || nonSatTotalTime,
        nonSatTotalDives: totalNonSatDives,
      };
    },
    {
      items: [],
      satTotalTime: {hh: 0, mm: 0},
      satTotalDives: 0,
      nonSatTotalTime: {hh: 0, mm: 0},
      nonSatTotalDives: 0,
    }
  );
};

const addSeaLogEntry = async ({crewSeaLogs, logData}) => {
  const hasOpenLog = !crewSeaLogs ? false : crewSeaLogs.items.some((item) => !item.dateTo);
  if (hasOpenLog && logData.dateTo === "") {
    return {
      type: "error",
      message: `Entry Not Added!
          Please, finalize Last Sealog Entry before adding entry without Date To`,
    };
  }

  const overlap = !crewSeaLogs ? [] : getOverlapLogEntries({entry: logData, logBook: crewSeaLogs.items});
  if (overlap.length > 0) {
    const overlapLines = overlap.map(
      ({dateFrom, rankTitle}, index) =>
        `${index + 1}) Date From: ${dayjs(dateFrom).format("DD-MMM-YY")} as ${rankTitle}`
    );
    const message = `Entry can not be added! This Entry Overlaps with following record(s):
      ${overlapLines.join("\n")}`;
    return {type: "error", message};
  }

  const response = await API.graphql(graphqlOperation(createSeaLog, {input: logData}));
  const {
    data: {createSeaLog: newEntry},
  } = response;
  return {newEntry, type: "success"};
};

const addDiverLogEntry = async (params) => {
  const {crewDiveLogs, logData} = params;
  console.log("addDiverLogEntry crewDiveLogs:", crewDiveLogs);

  const response = await API.graphql(graphqlOperation(createDiverLog, {input: logData}));
  console.log("RESPONSE:", response);
  const {
    data: {createDiverLog: newEntry},
  } = response;
  console.log("newEntry", newEntry);
  return {newEntry, type: "success"};
};

const addDiveSupervisorLogEntry = async ({crewDiveSupLogs, logData}) => {
  console.log("NOT IMPLEMENTED addDiveSupervisorLogEntry ", crewDiveSupLogs, logData);
};

export {addDiverLogEntry, addDiveSupervisorLogEntry, addSeaLogEntry, getDiverRunningTotals, getTotalSeaDays};
