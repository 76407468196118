import React from "react";
import {DownloadForOffline} from "@mui/icons-material";
import {IconButton, ListItem, ListItemText, Typography} from "@mui/material";
import {format, parseISO} from "date-fns";
import PropTypes from "prop-types";

export default function MessageListItem({event, userSub, employerName, employmentActions, onDownload}) {
  const {id, type, memo, createdAt, senderId, senderName, file} = event;
  const isOutgoingEvent = senderId === userSub;
  const memoBoxStyle = {px: 1.5, py: 1, borderRadius: "1rem"};
  const memoColor = {backgroundColor: isOutgoingEvent ? "primary.main" : "grey.700"};
  const memoTextStyle = {display: "inline-block", width: "auto", maxWidth: 1, overflowWrap: "break-word"};

  const memoToast = (
    <Typography color="white" variant="body2" sx={{...memoTextStyle, ...memoColor, ...memoBoxStyle}}>
      {memo}
    </Typography>
  );
  const fileBtn = (
    <IconButton aria-label="delete" onClick={onDownload({file, employerName})}>
      <DownloadForOffline sx={{color: "text.primary"}} fontSize="large" />
    </IconButton>
  );

  return (
    <ListItem key={id} sx={{width: "70%", marginLeft: isOutgoingEvent ? "auto" : 0}}>
      <ListItemText
        sx={{textAlign: isOutgoingEvent ? "end" : "start"}}
        primary={
          <>
            <Typography component="p" variant="subtitle2" color="text.primary">
              {employmentActions[type]?.description} {!!file && fileBtn}
            </Typography>
            {!!memo && memoToast}
          </>
        }
        secondary={
          <Typography variant="caption" color="text.secondary">
            {`from ${senderName}`}
            <Typography variant="caption" component="p" color="text.secondary">
              {format(parseISO(createdAt), "dd MMM yyyy HH:mm:ss")}
            </Typography>
          </Typography>
        }
      />
    </ListItem>
  );
}

MessageListItem.propTypes = {
  event: PropTypes.object,
  employmentActions: PropTypes.object,
  userSub: PropTypes.string,
  employerName: PropTypes.string,
  onDownload: PropTypes.func,
};
