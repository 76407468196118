import React, {useCallback, useContext, useEffect} from "react";
import {Box} from "@mui/material";
import {useActor} from "@xstate/react";
import {API, graphqlOperation} from "aws-amplify";
import {onCreateEmploymentUpdate} from "graphql/subscriptions";
import {MachineContext} from "lib";
import {ConfirmationModal, InfoToast, Spinner} from "lib/components";
import {loadSignatureFont} from "lib/helpers";

import {Footer, NavBar, TimeoutAlertModal} from "components";

import RouteSwitch from "./RouteSwitch";

export default function Main() {
  const machine = useContext(MachineContext);
  const [state, send] = useActor(machine);
  const {user} = state.context;

  const resetSignOutTimeout = useCallback(() => send("resetLastActiveTime"), [send]);
  const checkInactivityTime = useCallback(
    () => document.visibilityState === "visible" && send("checkInactivity"),
    [send]
  );

  useEffect(() => {
    if (!user) {
      document.removeEventListener("click", resetSignOutTimeout);
      document.removeEventListener("visibilitychange", checkInactivityTime);
    } else {
      document.addEventListener("click", resetSignOutTimeout);
      document.addEventListener("visibilitychange", checkInactivityTime);
    }
  }, [user, resetSignOutTimeout, checkInactivityTime]);

  useEffect(() => {
    const subscriptionOnCreateEmploymentEvent = !user?.attributes.sub
      ? null
      : API.graphql(graphqlOperation(onCreateEmploymentUpdate, {owner: user?.attributes.sub})).subscribe({
          next: ({value}) => {
            const {employmentId} = value.data.onCreateEmploymentUpdate;
            send({type: "employmentUpdated", data: employmentId});
          },
          error: (error) => send({type: "ERROR", data: error}),
        });

    return () => {
      subscriptionOnCreateEmploymentEvent?.unsubscribe();
    };
  }, [send, user?.attributes?.sub]);

  useEffect(() => {
    loadSignatureFont();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        height: {xs: "auto", md: "100vh"},
        width: 1,
        minWidth: 370,
        minHeight: {xs: "100vh", md: 300},
      }}>
      {!!user && <NavBar />}
      <RouteSwitch />
      <InfoToast />
      <ConfirmationModal />
      <TimeoutAlertModal />
      <Spinner />
      <Footer user={user?.attributes} />
    </Box>
  );
}
