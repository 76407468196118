import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import {Box, Grid} from "@mui/material";
import {parseISO, subYears} from "date-fns";
import {FormInputDate, FormInputPhone, FormInputSelect, FormInputText} from "lib/components";
import {
  BENEFICIARY_NAME_FIELD_NAME,
  BIRTH_DATE_FIELD_NAME,
  NICKNAME_FIELD_NAME,
  TELEPHONE_FIELD_NAME,
  TOWN_NAME_FIELD_NAME,
  VALIDATION_ERROR,
} from "lib/data";
import PropTypes from "prop-types";

const parseBirthDate = (dateString) => {
  return dateString ? parseISO(dateString) : null;
};
const BIRTH_DATE_FIELD_RANGE_LIMITS = {min: subYears(new Date(), 100), max: subYears(new Date(), 10)};

export default function BeneficiaryDetailsForm({
  disabled = false,
  countryTemplates,
  countriesOptions,
  setBeneficiaryValue,
  setBeneficiaryIsValid,
  savedBeneficiaryDetails,
  setGetBeneficiary,
  setResetBeneficiary,
  accounts,
}) {
  const initBeneficiaryDetails = useRef(savedBeneficiaryDetails?.beneficiaryDetails ?? null);
  const beneficiaryCountryFormMethods = useForm({
    defaultValues: {beneficiaryCountryCode: savedBeneficiaryDetails?.beneficiaryCountry?.code ?? ""},
  });
  const {control: beneficiaryCountryCotrol} = beneficiaryCountryFormMethods;
  const beneficiaryCountryCode = useWatch({control: beneficiaryCountryCotrol, name: "beneficiaryCountryCode"});
  const {control, formState, reset, trigger, getValues} = useForm({mode: "onChange"});
  const {isValid} = formState;
  const isBeneficiaryDetailsValid = useMemo(
    () => !!beneficiaryCountryCode && isValid,
    [beneficiaryCountryCode, isValid]
  );

  const currentCountryTemplate = useMemo(
    () =>
      !beneficiaryCountryCode
        ? null
        : countryTemplates.find((template) => template.countryCode === beneficiaryCountryCode),
    [beneficiaryCountryCode, countryTemplates]
  );

  const [beneficiaryTemplate, setBeneficiaryTemplate] = useState([]);

  const getFullBeneficiary = useCallback(
    () => ({
      beneficiaryDetails: getValues(),
      beneficiaryCountry: {
        code: currentCountryTemplate?.countryCode,
        name: currentCountryTemplate?.countryName,
      },
      beneficiaryTemplate: beneficiaryTemplate,
    }),
    [getValues, beneficiaryTemplate, currentCountryTemplate]
  );

  const rules = useMemo(
    () => ({
      [BENEFICIARY_NAME_FIELD_NAME]: {
        required: VALIDATION_ERROR.required,
        pattern: {
          value: /^[A-Za-z ]{1,120}$/,
          message: "Only Latin letters and space are allowed up to 120 characters",
        },
      },
      [TOWN_NAME_FIELD_NAME]: {
        required: VALIDATION_ERROR.required,
      },
      [NICKNAME_FIELD_NAME]: {
        validate: async (value) => {
          if (!value) return true;

          const regExp = /^(?![_.-])[\w-.]{3,20}(?<![_.-])$/i;
          const existingNickname = accounts.some((item) => item.label === value);
          return existingNickname
            ? "Account with this nickname already exists. Please choose a different one."
            : regExp.test(value) ||
                `Nickname must consist of 3 to 20 characters, including letters (a-z, A-Z), numbers (0-9) and symbols (_.-). It cannot start or end with a period, underscore, or hyphen.`;
        },
      },
    }),
    [accounts]
  );

  useEffect(() => {
    setBeneficiaryTemplate([]);
  }, [beneficiaryCountryCode, trigger]);

  useEffect(() => {
    if (currentCountryTemplate?.beneficiaryTemplate) {
      const {properties} = JSON.parse(currentCountryTemplate?.beneficiaryTemplate);
      const formFields = Object.keys(properties)
        .map((key) => ({...properties[key], name: key}))
        .filter((item) => !item.type !== "hidden");

      if (formFields.length) {
        const initFormState = initBeneficiaryDetails.current
          ? {
              ...initBeneficiaryDetails.current,
              [BIRTH_DATE_FIELD_NAME]: parseBirthDate(
                initBeneficiaryDetails.current ? initBeneficiaryDetails.current[BIRTH_DATE_FIELD_NAME] : null
              ),
            }
          : formFields.reduce(
              (res, item) => ({...res, [item.name]: item.name === BIRTH_DATE_FIELD_NAME ? null : ""}),
              {}
            );

        reset(initFormState);
        initBeneficiaryDetails.current = null;
      }
      setBeneficiaryTemplate(formFields);
    }
  }, [currentCountryTemplate, reset]);

  useEffect(() => {
    if (beneficiaryTemplate.length) {
      trigger();
    }
  }, [beneficiaryTemplate, trigger]);

  useEffect(() => {
    setBeneficiaryIsValid(isBeneficiaryDetailsValid);
  }, [isBeneficiaryDetailsValid, setBeneficiaryIsValid]);

  useEffect(() => {
    return () => {
      if (setBeneficiaryValue) {
        setBeneficiaryValue(getFullBeneficiary());
      }
    };
  }, [getFullBeneficiary, setBeneficiaryValue]);

  useEffect(() => {
    if (setGetBeneficiary) {
      setGetBeneficiary(() => () => getFullBeneficiary());
    }
  }, [getFullBeneficiary, setGetBeneficiary]);

  useEffect(() => {
    if (setResetBeneficiary) {
      setResetBeneficiary(() => (formState) => {
        reset({...formState, [BIRTH_DATE_FIELD_NAME]: parseBirthDate(formState[BIRTH_DATE_FIELD_NAME])});
      });
    }
  }, [setResetBeneficiary, reset]);

  return (
    <Box component="form" noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormInputSelect
            label={"Beneficiary Country"}
            name={"beneficiaryCountryCode"}
            control={beneficiaryCountryCotrol}
            options={countriesOptions}
            disabled={disabled}
            fullWidth
          />
        </Grid>
        {beneficiaryTemplate.map((item) => {
          return (
            <Grid item xs={12} sm={6} key={item.name}>
              {item.name === TELEPHONE_FIELD_NAME ? (
                <FormInputPhone
                  name={item.name}
                  control={control}
                  label={item.title}
                  required={false}
                  disabled={disabled}
                  fullWidth
                />
              ) : item.name === BIRTH_DATE_FIELD_NAME ? (
                <FormInputDate
                  control={control}
                  label={item.title}
                  name={item.name}
                  disabled={disabled}
                  required={false}
                  rangeLimits={BIRTH_DATE_FIELD_RANGE_LIMITS}
                  fullWidth
                />
              ) : (
                <FormInputText
                  name={item.name}
                  control={control}
                  label={item.title}
                  required={!!rules[item.name]?.required}
                  rules={rules[item.name]}
                  disabled={disabled}
                  fullWidth
                />
              )}
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}

BeneficiaryDetailsForm.propTypes = {
  disabled: PropTypes.bool,
  countryTemplates: PropTypes.array,
  countriesOptions: PropTypes.array,
  setBeneficiaryValue: PropTypes.func,
  setBeneficiaryIsValid: PropTypes.func.isRequired,
  savedBeneficiaryDetails: PropTypes.object,
  setGetBeneficiary: PropTypes.func,
  setResetBeneficiary: PropTypes.func,
  accounts: PropTypes.array,
};
