import React from "react";
import {Button, Grid} from "@mui/material";
import PropTypes from "prop-types";

export default function FormFooter({isDisabled = true, isValid, onDiscard, onEdit}) {
  return (
    <Grid container spacing={1} mt={1}>
      {!isDisabled && (
        <Grid item xs={12} sm="auto">
          <Button onClick={onDiscard} color="error" variant={"outlined"} fullWidth>
            Discard Changes
          </Button>
        </Grid>
      )}
      <Grid item xs />
      <Grid item xs={12} sm="auto">
        {isDisabled ? (
          <Button onClick={onEdit} variant={"contained"} color="primary" fullWidth>
            Edit Profile
          </Button>
        ) : (
          <Button type="submit" variant={"contained"} color="success" fullWidth disabled={!isValid}>
            Update Profile
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

FormFooter.propTypes = {
  isDisabled: PropTypes.bool,
  isValid: PropTypes.bool,
  onDiscard: PropTypes.func,
  onEdit: PropTypes.func,
};
