const BLOOD_GROUPS = [
  "O (I) Rh-",
  "O (I) Rh+",
  "A (II) Rh-",
  "A (II) Rh+",
  "B (III) Rh-",
  "B (III) Rh+",
  "AB (IV) Rh-",
  "AB (IV) Rh+",
];

export default BLOOD_GROUPS;
