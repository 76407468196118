import {Auth} from "aws-amplify";
import {UrqlClient} from "lib/helpers";
import {assign, createMachine} from "xstate";

import machineActions from "./machineActions";
import {machineDelays, machineGuards} from "./machineOptions";
import machineServices from "./machineServices";
import {authMachine} from "./serviceMachines";
// import dbFanWorker from "./dbFanWorker";

const appMachine = createMachine(
  {
    predictableActionArguments: true,
    initial: "checkingAuth",
    context: {
      user: null,
      accountBlockInfo: null,
      alert: null,
      subscriptions: null,
      notifications: null,
      isTourOpen: false,
      authTimeExp: null,
      confirmModalSetup: null,
      signedZipURL: null,
      isMachineWorking: false,
      isAuthMachineWorking: false,
    },
    states: {
      checkingAuth: {
        entry: ["machineStartsWorking"],
        exit: ["machineStopsWorking"],
        invoke: {
          src: "authChecker",
          onDone: [
            {target: "signingOut", cond: "isSignOutRequired"},
            {target: "loadingSubscriptions", actions: "updateUser"},
          ],
          onError: "loadingSubscriptions",
        },
      },
      unauthorized: {
        invoke: {
          id: "authMachine",
          src: authMachine,
          onDone: [
            {target: "loadingSubscriptions", cond: "noSubscriptionsLoaded", actions: "updateUser"},
            {target: "loadingAccountStatus", actions: "updateUser"},
          ],
        },
        on: {
          authMachineIsWorking: {actions: "authMachineIsWorking"},
          contactUs: "sendingContactUsRequest",
        },
      },
      loadingSubscriptions: {
        entry: ["machineStartsWorking"],
        exit: ["machineStopsWorking"],
        invoke: {
          src: "subscriptionsLoader",
          onDone: [
            {
              target: "loadingAccountStatus",
              cond: "isUserSignedIn",
              actions: "updateSubscriptions",
            },
            {target: "unauthorized", actions: "updateSubscriptions"},
          ],
          onError: {target: "unauthorized", actions: "updateError"},
        },
      },
      loadingAccountStatus: {
        entry: ["machineStartsWorking"],
        exit: ["machineStopsWorking"],
        invoke: {
          src: "accountStatusLoader",
          onDone: [
            {target: "loadingCrewData", cond: "isAccountNotBlocked"},
            {
              target: "signedInBlocked",
              actions: ["updateAccountBlockInfo", "spawnProfileMachine"],
            },
          ],
          onError: {target: "signingOut", actions: "updateError"},
        },
      },
      loadingCrewData: {
        entry: ["machineStartsWorking"],
        exit: ["machineStopsWorking"],
        invoke: {
          src: "crewDataLoader",
          onDone: {
            target: "signedIn",
            actions: ["updateCrewData", "spawnMachines"],
          },
          onError: {target: "signingOut", actions: "updateError"},
        },
      },
      signedInBlocked: {
        entry: ["clearStorageEmail"],
        exit: ["stopProfileMachine"],
        on: {
          signOut: "signingOut",
        },
      },
      signedIn: {
        initial: "idle",
        entry: ["clearStorageEmail"],
        exit: [
          "stopProfileMachine",
          "stopDocMachine",
          "stopLogMachine",
          "stopEmploymentMachine",
          "stopRemitterAuthMachine",
          "stopMarfinMachine",
        ],
        after: {
          AUTH_WARN_DELAY: {actions: "updateAuthWarning"},
          SIGN_OUT_DELAY: {target: "signingOut"},
        },
        on: {
          updateUser: {actions: "updateUser"},
          checkInactivity: {target: "signingOut", cond: "isSignOutRequired"},
          signOut: "signingOut",
          employmentUpdated: {actions: "forwardToEmploymentMachine"},
        },
        states: {
          idle: {
            after: {
              INACTIVITY_DELAY: {target: "checkingActivity"},
            },
            on: {
              updateProfile: {actions: "forwardToProfileMachine"},
              updateActiveNotifications: {actions: "forwardToProfileMachine"},
              checkout: {actions: "forwardToProfileMachine"},
              updateProfileImg: {actions: "forwardToProfileMachine"},
              deleteProfileImg: {actions: "forwardToProfileMachine"},
              resetEmployerUser: {actions: "forwardToProfileMachine"},

              uploadDoc: {actions: "forwardToDocsMachine"},
              updateCrewRankDocs: {actions: "forwardToDocsMachine"},
              deleteDoc: {actions: "forwardToDocsMachine"},
              updateCrewDoc: {actions: "forwardToDocsMachine"},
              loadMoreDescriptions: {actions: "forwardToDocsMachine"},

              loadVesselTypesList: {actions: "forwardToLogMachine"},
              addLogEntry: {actions: "forwardToLogMachine"},
              deleteLogEntry: {actions: "forwardToLogMachine"},
              editLogEntry: {actions: "forwardToLogMachine"},

              loadEmployments: {actions: "forwardToEmploymentMachine"},
              searchEmployer: {actions: "forwardToEmploymentMachine"},
              sendEmploymentEvent: {actions: "forwardToEmploymentMachine"},
              downloadEmploymentFile: {actions: "forwardToEmploymentMachine"},
              getEmploymentUpdates: {actions: "forwardToEmploymentMachine"},
              UPDATE_EMPLOYMENT: {actions: "forwardToEmploymentMachine"},

              LOGIN_REMITTER: {actions: "forwardToRemitterAuthMachine"},
              BACK_TO_TOTP_SETUP: {actions: "forwardToRemitterAuthMachine"},
              CANCEL_LOGIN_REMITTER: {actions: "forwardToRemitterAuthMachine"},
              CONTINUE_TOTP_SETUP: {actions: "forwardToRemitterAuthMachine"},
              VERIFY_TOTP: {actions: "forwardToRemitterAuthMachine"},
              CONFIRM_TOTP: {actions: "forwardToRemitterAuthMachine"},
              RESET_REMITTER_AUTH_ALERT: {actions: "forwardToRemitterAuthMachine"},

              INIT: {actions: "forwardToMarfinMachine"},
              LOAD_BANK_DETAILS_COUNTRY_TEMPLATES: {actions: "forwardToMarfinMachine"},
              ADD_BANK_DETAILS: {actions: "forwardToMarfinMachine"},
              LOAD_BANK_ACCOUNT: {actions: "forwardToMarfinMachine"},
              DELETE_BANK_ACCOUNT: {actions: "forwardToMarfinMachine"},
              SET_PRIMARY_BANK_ACCOUNT: {actions: "forwardToMarfinMachine"},
              UPDATE_BANK_ACCOUNT_BENEFICIARY: {actions: "forwardToMarfinMachine"},
              UPLOAD_BANK_CERTIFICATE: {actions: "forwardToMarfinMachine"},
              DELETE_BANK_ACCOUNT_CERTIFICATE: {actions: "forwardToMarfinMachine"},
              DOWNLOAD_BANK_ACCOUNT_CERTIFICATE: {actions: "forwardToMarfinMachine"},
              RESET_SELECTED_ACCOUNT: {actions: "forwardToMarfinMachine"},
              ADD_WALLET: {actions: "forwardToMarfinMachine"},
              DELETE_WALLET: {actions: "forwardToMarfinMachine"},
              SET_PRIMARY_WALLET: {actions: "forwardToMarfinMachine"},
              RESET_MARFIN_DATA: {actions: "forwardToMarfinMachine"},

              generateCV: "generatingCV",
              generateCVzip: "generatingCV",
              generateCVlink: "generatingCV",
              resetSignedZipURL: {actions: assign({signedZipURL: null})},

              loadAccountNotifications: "loadingAccountNotifications",
              requestProfileSetup: "sendingRequestProfileSetup",
              blockAccount: "blockingAccount",
              setIsTourOpen: {actions: assign({isTourOpen: (_, {data}) => data})},
              resetLastActiveTime: {actions: "resetLastActiveTime"},
              alert: {actions: assign({alert: (_, {data}) => data})},
              updateError: {actions: "updateError"},
              setupConfirmModal: {actions: assign({confirmModalSetup: (_, {data}) => data})},
              confirmModalDecision: {actions: "confirmModalDecision"},
              updateConsentConfirmModal: {actions: "updateConsentConfirmModal"},
              resetAlert: {actions: assign({alert: null})},
              contactUs: "sendingContactUsRequest",
              // dbFanWorker: "dbFanWorking",
            },
          },
          // dbFanWorking: {invoke: dbFanWorker},
          generatingCV: {
            entry: ["machineStartsWorking"],
            exit: ["machineStopsWorking"],
            invoke: {
              src: "cvGenerator",
              onDone: [
                {
                  target: "idle",
                  actions: "updateSignedZipURL",
                  cond: "isCvLinkGenerated",
                },
                {target: "idle", actions: "alertCVCreated"},
              ],
              onError: {target: "idle", actions: "updateError"},
            },
          },
          checkingActivity: {
            always: [{target: "idle", cond: "isUserActive"}, {target: "alertingUser"}],
          },
          alertingUser: {
            on: {
              staySignedIn: "idle",
            },
          },
          loadingAccountNotifications: {
            entry: ["machineStartsWorking"],
            exit: ["machineStopsWorking"],
            invoke: {
              src: "loadAccountNotifications",
              onDone: {target: "idle", actions: "updateNotifications"},
              onError: {target: "idle", actions: "updateError"},
            },
          },
          sendingRequestProfileSetup: {
            entry: ["machineStartsWorking"],
            exit: ["machineStopsWorking"],
            invoke: {
              src: "sendRequestProfileSetup",
              onDone: {target: "idle", actions: "updateAlert"},
              onError: {target: "idle", actions: "updateError"},
            },
          },
          blockingAccount: {
            entry: ["machineStartsWorking"],
            exit: ["machineStopsWorking"],
            invoke: {
              src: "blockAccount",
              onDone: {
                target: "idle",
                actions: ["updateAccountBlockInfo", "stopDocMachine", "stopLogMachine"],
              },
              onError: {target: "idle", actions: "updateError"},
            },
          },
          sendingContactUsRequest: {
            entry: ["machineStartsWorking"],
            exit: ["machineStopsWorking"],
            invoke: {
              src: "contactUsRequestSender",
              onDone: {target: "idle", actions: "updateAlert"},
              onError: {target: "idle", actions: "updateError"},
            },
          },
        },
      },
      signingOut: {
        entry: ["machineStartsWorking"],
        exit: ["machineStopsWorking"],
        invoke: {
          src: () => {
            UrqlClient.reset();
            return Auth.signOut();
          },
          onDone: {target: "unauthorized", actions: ["clearAllCrewData", "clearLastActiveTime"]},
          onError: {target: "unauthorized", actions: "updateError"},
        },
      },
      sendingContactUsRequest: {
        entry: ["machineStartsWorking"],
        exit: ["machineStopsWorking"],
        invoke: {
          src: "contactUsRequestSender",
          onDone: {target: "unauthorized", actions: "updateAlert"},
          onError: {target: "unauthorized", actions: "updateError"},
        },
      },
    },
  },
  {
    actions: machineActions,
    services: machineServices,
    delays: machineDelays,
    guards: machineGuards,
  }
);

export default appMachine;
