import React, {useContext, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {Box} from "@mui/material";
import {useActor} from "@xstate/react";
import MachineContext from "lib/MachineContext";
import PropTypes from "prop-types";

import FormFooter from "../submit/FormFooter";

import BloodEyeHairGenderForm from "./BloodEyeHairGenderForm";
import BodySizeForm from "./BodySizeForm";

export default function BiometricalForm() {
  const [state, send] = useActor(useContext(MachineContext));
  const {profileMachineRef} = state.context;
  const {state: profileState} = profileMachineRef; // TODO: refactor with useMemo
  const {crewProfile} = profileState.context;

  const [disabled, setDisabled] = useState(true);

  const initFormState = useMemo(() => {
    const {bloodGroup, gender, hairColor, eyesColor, coverallSize, shoeSize, weight} = crewProfile || {};

    return {
      bloodGroup: bloodGroup || "",
      gender: gender || "",
      hairColor: hairColor || "",
      eyesColor: eyesColor || "",
      coverallSize: coverallSize || "",
      shoeSize: shoeSize || "",
      weight: weight || "",
    };
  }, [crewProfile]);

  const methods = useForm({defaultValues: initFormState, mode: "all"});
  const {handleSubmit, control, reset, formState} = methods;
  const {isValid, isDirty, dirtyFields} = formState;

  const onDiscard = () => {
    reset(initFormState);
    setDisabled(true);
  };

  const onEdit = () => setDisabled(false);

  const onSubmit = (data) => {
    if (isValid && isDirty) {
      const dirtyFieldsKeys = Object.keys(dirtyFields);
      const updatedProfile = dirtyFieldsKeys.reduce((acc, key) => {
        acc[key] = data[key];
        return acc;
      }, {});
      const updateData = {updatedProfile};
      send({type: "updateProfile", data: updateData});
    }
  };

  useEffect(() => {
    !!crewProfile && reset(initFormState);
    !crewProfile || !crewProfile?.bloodGroup ? setDisabled(false) : setDisabled(true);
  }, [crewProfile, initFormState, reset]);

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <BloodEyeHairGenderForm control={control} disabled={disabled} />
      <BodySizeForm control={control} disabled={disabled} />
      <FormFooter isDisabled={disabled} onDiscard={onDiscard} onEdit={onEdit} isValid={isValid && isDirty} />
    </Box>
  );
}

BiometricalForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleChange: PropTypes.func,
  handleDiscard: PropTypes.func,
  activeTab: PropTypes.number,
  tabIndex: PropTypes.number,
};
