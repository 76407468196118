/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSubscriptionStatus = /* GraphQL */ `
  subscription OnCreateSubscriptionStatus(
    $filter: ModelSubscriptionSubscriptionStatusFilterInput
    $owner: String
  ) {
    onCreateSubscriptionStatus(filter: $filter, owner: $owner) {
      id
      isFree
      expiryDate
      customerId
      owner
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isCorporate
      tenantId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSubscriptionStatus = /* GraphQL */ `
  subscription OnUpdateSubscriptionStatus(
    $filter: ModelSubscriptionSubscriptionStatusFilterInput
    $owner: String
  ) {
    onUpdateSubscriptionStatus(filter: $filter, owner: $owner) {
      id
      isFree
      expiryDate
      customerId
      owner
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isCorporate
      tenantId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSubscriptionStatus = /* GraphQL */ `
  subscription OnDeleteSubscriptionStatus(
    $filter: ModelSubscriptionSubscriptionStatusFilterInput
    $owner: String
  ) {
    onDeleteSubscriptionStatus(filter: $filter, owner: $owner) {
      id
      isFree
      expiryDate
      customerId
      owner
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isCorporate
      tenantId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onCreateNotification(filter: $filter) {
      id
      description
      expiryInterval
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onUpdateNotification(filter: $filter) {
      id
      description
      expiryInterval
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification(
    $filter: ModelSubscriptionNotificationFilterInput
  ) {
    onDeleteNotification(filter: $filter) {
      id
      description
      expiryInterval
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateActiveNotification = /* GraphQL */ `
  subscription OnCreateActiveNotification(
    $filter: ModelSubscriptionActiveNotificationFilterInput
    $owner: String
  ) {
    onCreateActiveNotification(filter: $filter, owner: $owner) {
      id
      notificationId
      notification {
        id
        description
        expiryInterval
        type
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateActiveNotification = /* GraphQL */ `
  subscription OnUpdateActiveNotification(
    $filter: ModelSubscriptionActiveNotificationFilterInput
    $owner: String
  ) {
    onUpdateActiveNotification(filter: $filter, owner: $owner) {
      id
      notificationId
      notification {
        id
        description
        expiryInterval
        type
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteActiveNotification = /* GraphQL */ `
  subscription OnDeleteActiveNotification(
    $filter: ModelSubscriptionActiveNotificationFilterInput
    $owner: String
  ) {
    onDeleteActiveNotification(filter: $filter, owner: $owner) {
      id
      notificationId
      notification {
        id
        description
        expiryInterval
        type
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateBlockedAccount = /* GraphQL */ `
  subscription OnCreateBlockedAccount(
    $filter: ModelSubscriptionBlockedAccountFilterInput
    $owner: String
  ) {
    onCreateBlockedAccount(filter: $filter, owner: $owner) {
      id
      owner
      reason
      blockedDate
      isClosed
      closedDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateBlockedAccount = /* GraphQL */ `
  subscription OnUpdateBlockedAccount(
    $filter: ModelSubscriptionBlockedAccountFilterInput
    $owner: String
  ) {
    onUpdateBlockedAccount(filter: $filter, owner: $owner) {
      id
      owner
      reason
      blockedDate
      isClosed
      closedDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteBlockedAccount = /* GraphQL */ `
  subscription OnDeleteBlockedAccount(
    $filter: ModelSubscriptionBlockedAccountFilterInput
    $owner: String
  ) {
    onDeleteBlockedAccount(filter: $filter, owner: $owner) {
      id
      owner
      reason
      blockedDate
      isClosed
      closedDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreatePersonalDoc = /* GraphQL */ `
  subscription OnCreatePersonalDoc(
    $filter: ModelSubscriptionPersonalDocFilterInput
    $owner: String
  ) {
    onCreatePersonalDoc(filter: $filter, owner: $owner) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
        __typename
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdatePersonalDoc = /* GraphQL */ `
  subscription OnUpdatePersonalDoc(
    $filter: ModelSubscriptionPersonalDocFilterInput
    $owner: String
  ) {
    onUpdatePersonalDoc(filter: $filter, owner: $owner) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
        __typename
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeletePersonalDoc = /* GraphQL */ `
  subscription OnDeletePersonalDoc(
    $filter: ModelSubscriptionPersonalDocFilterInput
    $owner: String
  ) {
    onDeletePersonalDoc(filter: $filter, owner: $owner) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
        __typename
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateEmploymentUpdate = /* GraphQL */ `
  subscription OnCreateEmploymentUpdate(
    $filter: ModelSubscriptionEmploymentUpdateFilterInput
    $owner: String
  ) {
    onCreateEmploymentUpdate(filter: $filter, owner: $owner) {
      employmentId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateEmploymentUpdate = /* GraphQL */ `
  subscription OnUpdateEmploymentUpdate(
    $filter: ModelSubscriptionEmploymentUpdateFilterInput
    $owner: String
  ) {
    onUpdateEmploymentUpdate(filter: $filter, owner: $owner) {
      employmentId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteEmploymentUpdate = /* GraphQL */ `
  subscription OnDeleteEmploymentUpdate(
    $filter: ModelSubscriptionEmploymentUpdateFilterInput
    $owner: String
  ) {
    onDeleteEmploymentUpdate(filter: $filter, owner: $owner) {
      employmentId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateRankGroup = /* GraphQL */ `
  subscription OnCreateRankGroup(
    $filter: ModelSubscriptionRankGroupFilterInput
  ) {
    onCreateRankGroup(filter: $filter) {
      id
      title
      status
      subscriptionCharge
      subscriptionDetails
      subscriptionPriceId
      bucketSize
      hierarchy
      crewRanks {
        items {
          id
          title
          status
          hierarchy
          groupId
          department
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateRankGroup = /* GraphQL */ `
  subscription OnUpdateRankGroup(
    $filter: ModelSubscriptionRankGroupFilterInput
  ) {
    onUpdateRankGroup(filter: $filter) {
      id
      title
      status
      subscriptionCharge
      subscriptionDetails
      subscriptionPriceId
      bucketSize
      hierarchy
      crewRanks {
        items {
          id
          title
          status
          hierarchy
          groupId
          department
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteRankGroup = /* GraphQL */ `
  subscription OnDeleteRankGroup(
    $filter: ModelSubscriptionRankGroupFilterInput
  ) {
    onDeleteRankGroup(filter: $filter) {
      id
      title
      status
      subscriptionCharge
      subscriptionDetails
      subscriptionPriceId
      bucketSize
      hierarchy
      crewRanks {
        items {
          id
          title
          status
          hierarchy
          groupId
          department
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateCrewRank = /* GraphQL */ `
  subscription OnCreateCrewRank($filter: ModelSubscriptionCrewRankFilterInput) {
    onCreateCrewRank(filter: $filter) {
      id
      title
      status
      hierarchy
      groupId
      group {
        id
        title
        status
        subscriptionCharge
        subscriptionDetails
        subscriptionPriceId
        bucketSize
        hierarchy
        crewRanks {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      docs {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      designatedTitles {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      department
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateCrewRank = /* GraphQL */ `
  subscription OnUpdateCrewRank($filter: ModelSubscriptionCrewRankFilterInput) {
    onUpdateCrewRank(filter: $filter) {
      id
      title
      status
      hierarchy
      groupId
      group {
        id
        title
        status
        subscriptionCharge
        subscriptionDetails
        subscriptionPriceId
        bucketSize
        hierarchy
        crewRanks {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      docs {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      designatedTitles {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      department
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteCrewRank = /* GraphQL */ `
  subscription OnDeleteCrewRank($filter: ModelSubscriptionCrewRankFilterInput) {
    onDeleteCrewRank(filter: $filter) {
      id
      title
      status
      hierarchy
      groupId
      group {
        id
        title
        status
        subscriptionCharge
        subscriptionDetails
        subscriptionPriceId
        bucketSize
        hierarchy
        crewRanks {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      docs {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      designatedTitles {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      department
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDesignatedRankTitle = /* GraphQL */ `
  subscription OnCreateDesignatedRankTitle(
    $filter: ModelSubscriptionDesignatedRankTitleFilterInput
  ) {
    onCreateDesignatedRankTitle(filter: $filter) {
      id
      title
      crewRanks {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDesignatedRankTitle = /* GraphQL */ `
  subscription OnUpdateDesignatedRankTitle(
    $filter: ModelSubscriptionDesignatedRankTitleFilterInput
  ) {
    onUpdateDesignatedRankTitle(filter: $filter) {
      id
      title
      crewRanks {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDesignatedRankTitle = /* GraphQL */ `
  subscription OnDeleteDesignatedRankTitle(
    $filter: ModelSubscriptionDesignatedRankTitleFilterInput
  ) {
    onDeleteDesignatedRankTitle(filter: $filter) {
      id
      title
      crewRanks {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDesignatedRankTitleJoin = /* GraphQL */ `
  subscription OnCreateDesignatedRankTitleJoin(
    $filter: ModelSubscriptionDesignatedRankTitleJoinFilterInput
  ) {
    onCreateDesignatedRankTitleJoin(filter: $filter) {
      id
      rankTitleId
      rankId
      rank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      rankTitle {
        id
        title
        crewRanks {
          nextToken
          __typename
        }
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDesignatedRankTitleJoin = /* GraphQL */ `
  subscription OnUpdateDesignatedRankTitleJoin(
    $filter: ModelSubscriptionDesignatedRankTitleJoinFilterInput
  ) {
    onUpdateDesignatedRankTitleJoin(filter: $filter) {
      id
      rankTitleId
      rankId
      rank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      rankTitle {
        id
        title
        crewRanks {
          nextToken
          __typename
        }
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDesignatedRankTitleJoin = /* GraphQL */ `
  subscription OnDeleteDesignatedRankTitleJoin(
    $filter: ModelSubscriptionDesignatedRankTitleJoinFilterInput
  ) {
    onDeleteDesignatedRankTitleJoin(filter: $filter) {
      id
      rankTitleId
      rankId
      rank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      rankTitle {
        id
        title
        crewRanks {
          nextToken
          __typename
        }
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDoc = /* GraphQL */ `
  subscription OnCreateDoc($filter: ModelSubscriptionDocFilterInput) {
    onCreateDoc(filter: $filter) {
      id
      title
      descriptions {
        items {
          id
          content
          docId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      crewRanks {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      docType
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDoc = /* GraphQL */ `
  subscription OnUpdateDoc($filter: ModelSubscriptionDocFilterInput) {
    onUpdateDoc(filter: $filter) {
      id
      title
      descriptions {
        items {
          id
          content
          docId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      crewRanks {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      docType
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDoc = /* GraphQL */ `
  subscription OnDeleteDoc($filter: ModelSubscriptionDocFilterInput) {
    onDeleteDoc(filter: $filter) {
      id
      title
      descriptions {
        items {
          id
          content
          docId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      crewRanks {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      docType
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDocDescription = /* GraphQL */ `
  subscription OnCreateDocDescription(
    $filter: ModelSubscriptionDocDescriptionFilterInput
  ) {
    onCreateDocDescription(filter: $filter) {
      id
      content
      docId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDocDescription = /* GraphQL */ `
  subscription OnUpdateDocDescription(
    $filter: ModelSubscriptionDocDescriptionFilterInput
  ) {
    onUpdateDocDescription(filter: $filter) {
      id
      content
      docId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDocDescription = /* GraphQL */ `
  subscription OnDeleteDocDescription(
    $filter: ModelSubscriptionDocDescriptionFilterInput
  ) {
    onDeleteDocDescription(filter: $filter) {
      id
      content
      docId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDocCrewRankJoin = /* GraphQL */ `
  subscription OnCreateDocCrewRankJoin(
    $filter: ModelSubscriptionDocCrewRankJoinFilterInput
  ) {
    onCreateDocCrewRankJoin(filter: $filter) {
      id
      crewRankId
      crewRankTitle
      docId
      docTitle
      document {
        id
        title
        descriptions {
          nextToken
          __typename
        }
        crewRanks {
          nextToken
          __typename
        }
        docType
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      crewRank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDocCrewRankJoin = /* GraphQL */ `
  subscription OnUpdateDocCrewRankJoin(
    $filter: ModelSubscriptionDocCrewRankJoinFilterInput
  ) {
    onUpdateDocCrewRankJoin(filter: $filter) {
      id
      crewRankId
      crewRankTitle
      docId
      docTitle
      document {
        id
        title
        descriptions {
          nextToken
          __typename
        }
        crewRanks {
          nextToken
          __typename
        }
        docType
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      crewRank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDocCrewRankJoin = /* GraphQL */ `
  subscription OnDeleteDocCrewRankJoin(
    $filter: ModelSubscriptionDocCrewRankJoinFilterInput
  ) {
    onDeleteDocCrewRankJoin(filter: $filter) {
      id
      crewRankId
      crewRankTitle
      docId
      docTitle
      document {
        id
        title
        descriptions {
          nextToken
          __typename
        }
        crewRanks {
          nextToken
          __typename
        }
        docType
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      crewRank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSeaLog = /* GraphQL */ `
  subscription OnCreateSeaLog(
    $filter: ModelSubscriptionSeaLogFilterInput
    $owner: String
  ) {
    onCreateSeaLog(filter: $filter, owner: $owner) {
      id
      owner
      vesselName
      vesselType
      tonnage
      operationArea
      engagementPlace
      dischargePlace
      rankTitle
      dateFrom
      dateTo
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateSeaLog = /* GraphQL */ `
  subscription OnUpdateSeaLog(
    $filter: ModelSubscriptionSeaLogFilterInput
    $owner: String
  ) {
    onUpdateSeaLog(filter: $filter, owner: $owner) {
      id
      owner
      vesselName
      vesselType
      tonnage
      operationArea
      engagementPlace
      dischargePlace
      rankTitle
      dateFrom
      dateTo
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteSeaLog = /* GraphQL */ `
  subscription OnDeleteSeaLog(
    $filter: ModelSubscriptionSeaLogFilterInput
    $owner: String
  ) {
    onDeleteSeaLog(filter: $filter, owner: $owner) {
      id
      owner
      vesselName
      vesselType
      tonnage
      operationArea
      engagementPlace
      dischargePlace
      rankTitle
      dateFrom
      dateTo
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDiverLog = /* GraphQL */ `
  subscription OnCreateDiverLog(
    $filter: ModelSubscriptionDiverLogFilterInput
    $owner: String
  ) {
    onCreateDiverLog(filter: $filter, owner: $owner) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      diveType
      measureUnit
      helmetType
      gasMixture
      workDescription
      decoSchedules
      incidents
      remarks
      divingSupName
      storageDepth
      maxDepth
      bellLockOff
      leftBell
      returnedBell
      lockOutTime
      bellLockOn
      leftSurfaceTime
      bottomTime
      decoCompletedAt
      surfaceDecoInterval
      chamberTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDiverLog = /* GraphQL */ `
  subscription OnUpdateDiverLog(
    $filter: ModelSubscriptionDiverLogFilterInput
    $owner: String
  ) {
    onUpdateDiverLog(filter: $filter, owner: $owner) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      diveType
      measureUnit
      helmetType
      gasMixture
      workDescription
      decoSchedules
      incidents
      remarks
      divingSupName
      storageDepth
      maxDepth
      bellLockOff
      leftBell
      returnedBell
      lockOutTime
      bellLockOn
      leftSurfaceTime
      bottomTime
      decoCompletedAt
      surfaceDecoInterval
      chamberTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDiverLog = /* GraphQL */ `
  subscription OnDeleteDiverLog(
    $filter: ModelSubscriptionDiverLogFilterInput
    $owner: String
  ) {
    onDeleteDiverLog(filter: $filter, owner: $owner) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      diveType
      measureUnit
      helmetType
      gasMixture
      workDescription
      decoSchedules
      incidents
      remarks
      divingSupName
      storageDepth
      maxDepth
      bellLockOff
      leftBell
      returnedBell
      lockOutTime
      bellLockOn
      leftSurfaceTime
      bottomTime
      decoCompletedAt
      surfaceDecoInterval
      chamberTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateDiveSupLog = /* GraphQL */ `
  subscription OnCreateDiveSupLog(
    $filter: ModelSubscriptionDiveSupLogFilterInput
    $owner: String
  ) {
    onCreateDiveSupLog(filter: $filter, owner: $owner) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      supervisionType
      diveType
      unitRatio
      incidents
      remarks
      contractorRepTitle
      contractorRepName
      supervisedDives {
        items {
          id
          storageDepth
          excursionDepth
          diversNum
          decoRange
          diveDuration
          workDescription
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDiveSupLog = /* GraphQL */ `
  subscription OnUpdateDiveSupLog(
    $filter: ModelSubscriptionDiveSupLogFilterInput
    $owner: String
  ) {
    onUpdateDiveSupLog(filter: $filter, owner: $owner) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      supervisionType
      diveType
      unitRatio
      incidents
      remarks
      contractorRepTitle
      contractorRepName
      supervisedDives {
        items {
          id
          storageDepth
          excursionDepth
          diversNum
          decoRange
          diveDuration
          workDescription
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDiveSupLog = /* GraphQL */ `
  subscription OnDeleteDiveSupLog(
    $filter: ModelSubscriptionDiveSupLogFilterInput
    $owner: String
  ) {
    onDeleteDiveSupLog(filter: $filter, owner: $owner) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      supervisionType
      diveType
      unitRatio
      incidents
      remarks
      contractorRepTitle
      contractorRepName
      supervisedDives {
        items {
          id
          storageDepth
          excursionDepth
          diversNum
          decoRange
          diveDuration
          workDescription
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateSupDiveInfo = /* GraphQL */ `
  subscription OnCreateSupDiveInfo(
    $filter: ModelSubscriptionSupDiveInfoFilterInput
    $owner: String
  ) {
    onCreateSupDiveInfo(filter: $filter, owner: $owner) {
      id
      storageDepth
      excursionDepth
      diversNum
      decoRange
      diveDuration
      workDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onUpdateSupDiveInfo = /* GraphQL */ `
  subscription OnUpdateSupDiveInfo(
    $filter: ModelSubscriptionSupDiveInfoFilterInput
    $owner: String
  ) {
    onUpdateSupDiveInfo(filter: $filter, owner: $owner) {
      id
      storageDepth
      excursionDepth
      diversNum
      decoRange
      diveDuration
      workDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const onDeleteSupDiveInfo = /* GraphQL */ `
  subscription OnDeleteSupDiveInfo(
    $filter: ModelSubscriptionSupDiveInfoFilterInput
    $owner: String
  ) {
    onDeleteSupDiveInfo(filter: $filter, owner: $owner) {
      id
      storageDepth
      excursionDepth
      diversNum
      decoRange
      diveDuration
      workDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
