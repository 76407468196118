import React from "react";
import {Box} from "@mui/material";

export default function NotFound() {
  const styles = {
    flexGrow: 1,
    display: "flex",
    px: {xs: "1rem", sm: "2rem"},
    backgroundImage: `url(${"/images/not-found.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "0 100%",
  };
  return <Box sx={{...styles}} />;
}
