import React, {useCallback, useContext, useEffect, useMemo, useReducer, useState} from "react";
import {useActor} from "@xstate/react";
import {shareAcknowledgements} from "lib/data";
import {formReducer} from "lib/helpers";
import MachineContext from "lib/MachineContext";
import PropTypes from "prop-types";

import {NoEmployerUserFound, SearchForm, ShareAccount} from "./tools";

const type = "SHARE_ACCOUNT";

export default function EmploymentRequest({pickedEmployment}) {
  const [state, send] = useActor(useContext(MachineContext));
  const {profileMachineRef, employmentMachineRef} = state.context;
  const {employerUser} = employmentMachineRef?.state.context || {};
  const {crewProfile} = profileMachineRef?.state.context || {};
  const {firstName, lastName} = crewProfile || {};
  const {email, exists, tenantName} = employerUser || {};
  const {employerName, employmentId} = pickedEmployment || {};
  const [isSearchDisabled, setIsSearchDisabled] = useState(!!employerUser || !!pickedEmployment);

  const initialFormState = useMemo(
    () =>
      shareAcknowledgements().reduce((result, _, index) => {
        result[`acknowledgement${index + 1}`] = false;
        return result;
      }, {}),
    []
  );

  const onChange = useCallback(({target}) => dispatch({type: target.name, data: target.checked}), []);
  const [formState, dispatch] = useReducer(formReducer, initialFormState);

  const onResetSearch = useCallback(() => {
    setIsSearchDisabled(false);
    send({type: "resetEmployerUser"});
    dispatch({type: "reset", data: initialFormState});
  }, [send, initialFormState]);

  const onSearch = useCallback((email) => send({type: "searchEmployer", data: email}), [send]);

  const onShare = useCallback(
    (memo) => {
      setIsSearchDisabled(false);
      const userData = {firstName, lastName};
      send({type: "sendEmploymentEvent", data: {memo, type, employmentId, userData}});
      dispatch({type: "reset", data: initialFormState});
    },
    [send, firstName, lastName, initialFormState, employmentId]
  );

  useEffect(() => setIsSearchDisabled(!!employerUser), [employerUser, send]);

  useEffect(() => {
    return () => {
      send({type: "resetEmployerUser"});
    };
  }, [send]);

  return (
    <div
      className="modal fade p-0"
      id="newRequest"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="newRequestLabel"
      aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="newRequestLabel">
              New Employment Request
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={onResetSearch}
              aria-label="Close"
            />
          </div>
          <div className="modal-body">
            {!!pickedEmployment || (isSearchDisabled && !!employerUser && exists) ? (
              <ShareAccount
                employerName={tenantName || employerName}
                formState={formState}
                onChange={onChange}
                handleShare={onShare}
                handleBack={onResetSearch}
                showBackBtn={!pickedEmployment}
              />
            ) : (
              <>
                <p>
                  You can Share your <strong>CrewBook</strong> Account with Crew Manager of a Company, registered at{" "}
                  <strong>ShipBook</strong>
                </p>
                <SearchForm isSearchDisabled={isSearchDisabled} handleSearch={onSearch} />
                {isSearchDisabled && !exists && (
                  <NoEmployerUserFound handleResetSearch={onResetSearch} email={email} onLinkClicked={onResetSearch} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

EmploymentRequest.propTypes = {
  pickedEmployment: PropTypes.object,
};
