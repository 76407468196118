import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {Box} from "@mui/material";
import {useActor} from "@xstate/react";
import {formatISO, parseISO} from "date-fns";
import MachineContext from "lib/MachineContext";

import FormFooter from "../submit/FormFooter";

import AddressForm from "./AddressForm";
import UserForm from "./UserForm";

const initialFormState = {
  firstName: "",
  midName: "",
  lastName: "",
  birthDate: null,
  birthPlace: "",
  address: "",
  city: "",
  state: "",
  country: "",
  nationality: "",
  religion: "",
  nearestAirport: "",
  crewRankId: "",
};

export default function PersonalForm() {
  const [state, send] = useActor(useContext(MachineContext));

  const crewProfile = useMemo(
    () => state.context.profileMachineRef?.state.context?.crewProfile,
    [state.context.profileMachineRef?.state.context?.crewProfile]
  );

  const [disabled, setDisabled] = useState(true);

  const initFormState = useCallback(
    ({
      firstName,
      midName,
      lastName,
      birthDate,
      nationality,
      birthPlace,
      religion,
      nearestAirport,
      address,
      city,
      state,
      country,
      crewRankId,
    }) => ({
      firstName: firstName || "",
      midName: midName || "",
      lastName: lastName || "",
      birthDate: !birthDate ? null : parseISO(birthDate),
      birthPlace: birthPlace || "",
      nationality: nationality || "",
      crewRankId: crewRankId || "",
      address: address || "",
      city: city || "",
      state: state || "",
      country: country || "",
      religion: religion || "",
      nearestAirport: nearestAirport || "",
    }),
    []
  );

  const {handleSubmit, control, reset, formState, trigger} = useForm({
    defaultValues: initialFormState,
    mode: "all",
  });
  const {isValid, isDirty, dirtyFields} = formState;

  const onDiscard = () => {
    reset();
    setDisabled(true);
  };

  const onEdit = () => {
    setDisabled(false);
    trigger();
  };

  const onSubmit = (data) => {
    if (isValid && isDirty) {
      const dirtyFieldsKeys = Object.keys(dirtyFields);
      const isNew = !crewProfile;
      const updates = dirtyFieldsKeys.reduce((acc, key) => {
        acc[key] = data[key];
        return acc;
      }, {});
      const {birthDate, ...restData} = updates;
      const updatedProfile = !birthDate
        ? restData
        : {...restData, birthDate: formatISO(birthDate, {representation: "date"})};
      const updateData = {isNew, updatedProfile};
      send({type: "updateProfile", data: updateData});
    }
  };

  useEffect(() => {
    if (crewProfile) {
      reset(initFormState(crewProfile));
      setDisabled(!!crewProfile);
    }
  }, [crewProfile, initFormState, reset]);

  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <UserForm control={control} disabled={disabled} />
      <AddressForm control={control} disabled={disabled} />
      <FormFooter isDisabled={disabled} isValid={isValid && isDirty} onDiscard={onDiscard} onEdit={onEdit} />
    </Box>
  );
}
