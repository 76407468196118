import dayjs from "dayjs";

import unbreakableStack from "./unbreakableStack";

const naNullishData = (data) => data.map((item) => (!item || item === "" ? "N/A" : item));

const getTableRows = (docs) => {
  return docs.map(({doc, docDescription, title, docNum, issueDate, expiryDate, issuePlace}) => {
    const [docNumNA, issueDateNA, expiryDateNA, issuePlaceNA] = naNullishData([
      docNum,
      issueDate,
      expiryDate,
      issuePlace,
    ]);
    const dateFrom = dayjs(issueDateNA).isValid() ? dayjs(issueDateNA).format("DD-MMM-YY") : issueDateNA;
    const dateTo = dayjs(expiryDateNA).isValid() ? dayjs(expiryDateNA).format("DD-MMM-YY") : expiryDateNA;
    const docTitle = !doc?.title
      ? title
      : !title
      ? `${doc.title}-${docDescription.content}`
      : `${doc.title}-${docDescription.content}-${title}`;

    return [
      {text: docTitle, style: "tdLight"},
      {text: docNumNA, style: "tdLight"},
      {text: dateFrom, style: "tdLight"},
      {text: dateTo, style: "tdLight"},
      {text: issuePlaceNA, style: "tdLight"},
    ];
  });
};

export default function docsTables({requiredDocs, personalDocs}) {
  const widths = ["*", "*", 65, 65, "*"];
  const styles = {style: "th2", fillColor: "#eeeeff", alignment: "center"};
  const filteredPersonalDocs = personalDocs?.items.filter((item) => !!item.includedInCV) || [];
  const filteredRequiredDocs = requiredDocs.items?.filter((item) => !!item.includedInCV) || [];
  const {main, travel, other} = filteredRequiredDocs.reduce(
    (result, current) => {
      const {
        docNum,
        doc: {docType},
      } = current;
      if (!docNum) {
        return result;
      }
      const {main, travel, other} = result;
      return docType === "ID"
        ? {main, travel: [...travel, current], other}
        : ["STCW", "STCW_SPEC"].includes(docType)
        ? {main: [...main, current], travel, other}
        : {main, travel, other: [...other, current]};
    },
    {main: [], travel: [], other: []}
  );

  return [
    {text: "", pageOrientation: "landscape", pageBreak: "before"},
    unbreakableStack({
      tableTitle: "STCW STANDARD CERTIFICATES:",
      widths,
      body: [
        [
          {text: "Document Title", ...styles},
          {text: "Number", ...styles},
          {text: "Issued", ...styles},
          {text: "Expiry", ...styles},
          {text: "Place of Issue", ...styles},
        ],
        ...getTableRows(main),
      ],
    }),
    unbreakableStack({
      tableTitle: "TRAVEL DOCUMENTS:",
      widths,
      body: [
        [
          {text: "Document Title", ...styles},
          {text: "Number", ...styles},
          {text: "Issued", ...styles},
          {text: "Expiry", ...styles},
          {text: "Place of Issue", ...styles},
        ],
        ...getTableRows(travel),
      ],
    }),
    unbreakableStack({
      tableTitle: "OTHER CERTIFICATES OF PROFICIENCY:",
      widths,
      body: [
        [
          {text: "Document Title", ...styles},
          {text: "Number", ...styles},
          {text: "Issued", ...styles},
          {text: "Expiry", ...styles},
          {text: "Place of Issue", ...styles},
        ],
        ...getTableRows([...other, ...filteredPersonalDocs]),
      ],
    }),
  ];
}
