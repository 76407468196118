import React, {useCallback, useEffect, useState} from "react";
import {Checkbox, FormControl, FormControlLabel, FormHelperText, InputLabel, OutlinedInput} from "@mui/material";
import {red} from "@mui/material/colors";
import PropTypes from "prop-types";

export default function ValidationFormField(props) {
  const [error, setError] = useState(false);
  const {
    fieldType = "TEXT",
    inputRef,
    isValid,
    shrink,
    required,
    type,
    onChange,
    startAdornment,
    endAdornment,
    multiline = false,
    rows = 1,
    form,
    fullWidth,
    helperText,
    label,
    id,
    size = "medium",
    value,
    ...inputProps
  } = props;

  const validateInput = useCallback(
    (input) => {
      const isInputValid = isValid ? isValid(input.value) : input.checkValidity();
      const isFormValidated = form?.classList?.contains("was-validated") || false;

      setError(isFormValidated && !isInputValid);
      !!isValid && (!isInputValid ? input.setCustomValidity("Invalid input") : input.setCustomValidity(""));
    },
    [form, isValid]
  );

  useEffect(() => {
    const observer = new MutationObserver((events) => {
      const {attributeName} = events[0];
      const input = document.getElementById(id);
      attributeName === "class" && !!input && validateInput(input);
    });

    !!form && observer.observe(form, {attributes: true});
    return () => observer.disconnect();
  }, [form, id, validateInput]);

  useEffect(() => {
    const input = document.getElementById(id);
    !!input && validateInput(input);
  }, [value, id, validateInput]);

  return (
    <FormControl error={error} fullWidth={fullWidth} size={size} sx={{mb: "1rem"}}>
      {fieldType === "TEXT" ? (
        <>
          <InputLabel htmlFor={id} shrink={shrink}>
            {label}
          </InputLabel>
          <OutlinedInput
            id={id}
            label={label}
            value={value}
            inputRef={inputRef}
            required={required}
            type={type}
            onChange={onChange}
            inputProps={inputProps}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            multiline={multiline}
            rows={rows}
            notched={shrink}
          />
        </>
      ) : fieldType === "CHECKBOX" ? (
        <FormControlLabel
          label={label}
          control={
            <Checkbox
              inputRef={inputRef}
              id={id}
              name="checkbox"
              required={required}
              onChange={onChange}
              inputProps={inputProps}
              checked={value}
              sx={{color: error ? red[700] : ""}}
            />
          }
        />
      ) : (
        <></>
      )}
      {error && <FormHelperText id="component-error-text">{helperText}</FormHelperText>}
    </FormControl>
  );
}

ValidationFormField.propTypes = {
  fieldType: PropTypes.string,
  inputRef: PropTypes.func,
  isValid: PropTypes.func,
  required: PropTypes.bool,
  shrink: PropTypes.bool,
  type: PropTypes.string,
  onChange: PropTypes.func,
  startAdornment: PropTypes.node,
  endAdornment: PropTypes.node,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  form: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  id: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};
