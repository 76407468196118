import {assign, sendParent} from "xstate";

const actions = {
  updateUser: assign({
    user: (_, {data}) => data,
  }),
  updateTotpSetup: assign({
    totpSetup: (_, {data}) => data,
  }),
  resetTotpSetup: assign({
    totpSetup: null,
  }),
  updateSuccess: assign({
    alert: (_, {data}) => ({type: "success", message: data}),
  }),
  updateError: assign({
    alert: (_, {data, type}) => {
      const message =
        data?.errors?.map((error) => error.message).join(". ") || data?.message || data?.stack || `${data}.${type}`;
      return {type: "error", message};
    },
  }),
  sendUserToParent: sendParent(({user}) => ({type: "updateUser", data: user})),
  resetError: assign({alert: null}),
  machineStartsWorking: assign({isMachineWorking: () => true}),
  machineStopsWorking: assign({isMachineWorking: () => false}),
  logger: (context, event) => console.log("REMITTER AUTH MACHINE STATE EVENT CTX", event, context),
};

export default actions;
