import dayjs from "dayjs";

const personalDetailsTable = (profile) => {
  const {
    address,
    birthDate,
    birthPlace,
    bloodGroup,
    city,
    country,
    coverallSize,
    crewRank: {title: crewRank},
    email,
    englishLangLevel,
    eyesColor,
    firstName,
    gender = "",
    hairColor,
    lastName,
    maritalStatus,
    midName = "",
    nationality,
    nearestAirport,
    otherLanguage1 = "",
    otherLanguage2,
    phone,
    religion,
    shoeSize,
    state = "",
    weight,
  } = profile;
  const otherLanguages = otherLanguage2 ? `${otherLanguage1}, ${otherLanguage2}` : otherLanguage1;

  return {
    headerRows: 0,
    widths: ["30%", "*"],
    body: [
      [
        {text: "Full Name", style: "th1"},
        {text: `${firstName} ${midName} ${lastName}`.toUpperCase(), style: "td"},
      ],
      [
        {text: "Rank", style: "th1"},
        {text: crewRank.toUpperCase(), style: "td"},
      ],
      [
        {text: "Date of Birth", style: "th1"},
        {text: `${dayjs(birthDate).format("DD-MMM-YY").toUpperCase()}`, style: "td"},
      ],
      [
        {text: "Place of Birth", style: "th1"},
        {text: birthPlace.toUpperCase(), style: "td"},
      ],
      [
        {text: "Nationality", style: "th1"},
        {text: nationality.toUpperCase(), style: "td"},
      ],
      [
        {text: "Marital Status", style: "th1"},
        {text: maritalStatus.toUpperCase(), style: "td"},
      ],
      [
        {text: "Religion", style: "th1"},
        {text: religion.toUpperCase(), style: "td"},
      ],
      [
        {text: "Email", style: "th1"},
        {text: email.toUpperCase(), style: "td"},
      ],
      [
        {text: "Mobile No", style: "th1"},
        {text: phone, style: "td"},
      ],
      [
        {text: "Address", style: "th1"},
        {text: `${address}, ${city} ${state}, ${country}`.toUpperCase(), style: "td"},
      ],
      [
        {text: "Nearest Airport", style: "th1"},
        {text: nearestAirport.toUpperCase(), style: "td"},
      ],
      [
        {text: "Gender", style: "th1"},
        {text: gender.toUpperCase(), style: "td"},
      ],
      [
        {text: "Blood Group", style: "th1"},
        {text: bloodGroup, style: "td"},
      ],
      [
        {text: "Hair Color", style: "th1"},
        {text: hairColor.toUpperCase(), style: "td"},
      ],
      [
        {text: "Eyes Color", style: "th1"},
        {text: eyesColor.toUpperCase(), style: "td"},
      ],
      [
        {text: "Weight, kg", style: "th1"},
        {text: weight, style: "td"},
      ],
      [
        {text: "Coverall Size PPE (EU/USA)", style: "th1"},
        {text: coverallSize, style: "td"},
      ],
      [
        {text: "Shoe Size (EU/USA)", style: "th1"},
        {text: shoeSize, style: "td"},
      ],
      [
        {text: "English Language Level", style: "th1"},
        {text: englishLangLevel.toUpperCase(), style: "td"},
      ],
      [
        {text: "Other Languages", style: "th1"},
        {text: otherLanguages.toUpperCase(), style: "td"},
      ],
    ],
  };
};

export default personalDetailsTable;
