import React, {useContext} from "react";
import {Box, Divider, List, ListItem, ListItemText, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {AuthLink} from "lib/components";
import MachineContext from "lib/MachineContext";

import DashboardCard from "./DashboardCard";

export default function LogsNotes() {
  const [state] = useActor(useContext(MachineContext));
  const {crewLogsMachineRef} = state.context;
  const {crewSeaLogs} = crewLogsMachineRef.state.context;
  const hasLogs = !!crewSeaLogs && crewSeaLogs?.items.length > 0;
  const totalDays = !hasLogs ? 0 : Object.values(crewSeaLogs.totalSeaDays).reduce((result, value) => result + value, 0);
  const totals = !hasLogs
    ? null
    : Object.keys(crewSeaLogs.totalSeaDays).map((rank) => (
        <ListItem
          key={rank}
          disablePadding
          disableGutters
          sx={{m: 0}}
          secondaryAction={`${crewSeaLogs.totalSeaDays[rank]}`}>
          <ListItemText primary={`${rank}:`} />
        </ListItem>
      ));

  return (
    <DashboardCard title={"My Logs"} className="tour-step-logs-notifications">
      {hasLogs && (
        <Box>
          <ListItem disablePadding disableGutters sx={{m: 0}} secondaryAction={`${totalDays}`}>
            <ListItemText primary={`Total Sea Days:`} />
          </ListItem>
          <Divider />
          <List sx={{width: 1, pt: 0}}>{totals}</List>
          <Divider />
        </Box>
      )}
      <Typography>
        Keep your <AuthLink to="/crewlogs">CrewLogs</AuthLink> up to date!
      </Typography>
    </DashboardCard>
  );
}
