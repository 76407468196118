import React, {useRef, useState} from "react";
import {Box, Paper, Typography} from "@mui/material";
import {validateEmail} from "lib/helpers";
import PropTypes from "prop-types";

import {EmailInput, ErrorNote, FormFooter} from "../common";

export default function ResetPasswordForm({alert, onSubmit, onBackToSignIn}) {
  const formRef = useRef(null);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const onChange = ({currentTarget}) => setEmail(currentTarget.value);

  const handleBack = () => onBackToSignIn();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    formRef.current.classList.add("was-validated");

    if (formRef.current.checkValidity()) {
      const {isValid, message} = validateEmail(email);
      isValid ? onSubmit(email) : setError(message);
      formRef.current.classList.remove("was-validated");
    }
  };

  return (
    <Paper elevation={16} sx={{p: 2}}>
      <Typography variant="h6" align="center" mb={3}>
        Reset your password
      </Typography>
      <Box component="form" ref={formRef} noValidate onSubmit={handleSubmit}>
        <EmailInput form={formRef.current} name={"email"} value={email} onChange={onChange} />
        <ErrorNote {...{alert, error}} />
        <FormFooter backBtnTitle={"Back to Sign In"} onBackClicked={handleBack} sumbitBtnTitle={"Send Code"} />
      </Box>
    </Paper>
  );
}

ResetPasswordForm.propTypes = {
  alert: PropTypes.object,
  onSubmit: PropTypes.func,
  onBackToSignIn: PropTypes.func,
};
