/* eslint-disable max-len */
import React from "react";

const shareAcknowledgements = (employerName = "") => [
  <p key={1}>
    I understand that by clicking this checkbox, I am sharing my CrewBook CV, including Personal, Biomentrical and
    Additional details from my Profile, my CrewBook Seaman Log data, and list of CrewBook Documents I am in possession
    of with the <strong>ShipBook</strong> registered company <strong>{employerName}</strong>.
  </p>,
  <p key={2}>
    I understand that by clicking this checkbox, I am sharing my CrewBook Documents, including Required and Other
    Documents with the <strong>ShipBook</strong> registered company <strong>{employerName}</strong>.
  </p>,
  <p key={3}>
    I understand that by clicking this checkbox my CV, Required and Other Documents may be downloaded by{" "}
    <strong>ShipBook</strong> registered company <strong>{employerName}</strong> for employment purposes.
  </p>,
];

export default shareAcknowledgements;
