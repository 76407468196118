import React from "react";
import PropTypes from "prop-types";

export default function ContractorForm({formState, onChange}) {
  return (
    <>
      <div className="col-12 col-sm-7 col-md-4 mb-3">
        <label className="form-label" htmlFor="contractorName">
          Contractor Name
        </label>
        <input
          type="text"
          className="form-control"
          id="contractorName"
          value={formState.contractorName}
          name="contractorName"
          maxLength="64"
          onChange={onChange}
          required
        />
        <div className="invalid-feedback">Please provide a valid Contractor Name.</div>
      </div>

      <div className="col-xs-12 col-md-5 mb-3">
        <label className="form-label" htmlFor="contractorAddress">
          Contractor Address
        </label>
        <input
          type="text"
          className="form-control"
          id="contractorAddress"
          value={formState.contractorAddress}
          name="contractorAddress"
          maxLength="64"
          onChange={onChange}
          required
        />
        <div className="invalid-feedback">Please provide a valid Contractor Address.</div>
      </div>
    </>
  );
}

ContractorForm.propTypes = {
  formState: PropTypes.object,
  onChange: PropTypes.func,
};
