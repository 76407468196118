import {Storage} from 'aws-amplify';
import {readImg} from 'lib/helpers';

const getPhotoData = async (fileKey) => {
  const {Body} = await Storage.get(fileKey, {download: true, level: 'protected'});
  const image = await readImg(Body);
  return image.split(',').pop();
};

const createVcfFile = async (params) => {
  const {firstName, midName, lastName, title, phoneNumber,
    email, country, profileImgKey} = params;
  const photoData = !profileImgKey ? '' : await getPhotoData(profileImgKey);

  return `BEGIN:VCARD
VERSION:4.0
FN:${firstName}${!!midName && midName !== '' ? ` ${midName}`: ''} ${lastName}
N:${lastName};${firstName};${midName}
PHOTO;ENCODING=b;TYPE=image/jpeg:${photoData}
ADR;TYPE=home:;;;;;;${country}
TEL;TYPE=voice,cell:tel:${phoneNumber}
EMAIL;TYPE=internet,home:${email}
ROLE:${title}
REV:${Date.now()}
END:VCARD`;
};

export default createVcfFile;
