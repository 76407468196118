import React, {useContext} from "react";
import {useActor} from "@xstate/react";
import MachineContext from "lib/MachineContext";

import AuthForm from "./AuthForm";

export default function Authentication() {
  const [state] = useActor(useContext(MachineContext));

  return !state.children.authMachine ? <></> : <AuthForm authMachine={state.children.authMachine} />;
}
