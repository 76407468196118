import React from "react";
import {confirmDialog} from "lib/helpers";

export default async function checkExistingDoc({crewDocs, formState, send, service}) {
  if (!crewDocs) return {result: 1, existingDoc: null};

  const isRequired = formState.docId !== "Personal";
  const {requiredDocs, personalDocs} = crewDocs;
  let result = 1;

  const existingDoc = isRequired
    ? requiredDocs?.items.find(
        ({docDescriptionId, doc}) => docDescriptionId === formState.docDescriptionId && doc.docType !== "ID"
      )
    : personalDocs?.items.find((item) => item.title === formState.title);

  if (existingDoc) {
    const docTitle = existingDoc?.title || `${existingDoc.doc.title} > ${existingDoc.docDescription.content}`;
    const content = !isRequired ? (
      <div>
        <p>You are about to update your existing document:</p>
        <p>
          &#34;<strong>{docTitle}</strong>&#34;.
        </p>
        <p>It will be replaced with new uploading document. Would you like to continue?</p>
      </div>
    ) : (
      <div>
        <p>You are updating existing document:</p>
        <p>
          &#34;<strong>{docTitle}</strong>&#34;.
        </p>
        <p>
          It will be replaced with new uploading document. If you wish to keep old version, choose Archive and Upload.
          The old version will be saved in Other Documents.
        </p>
      </div>
    );

    const setup = {content, thirdOption: "Archive and Upload", confirmTitle: "Upload", consent: true};
    result = await confirmDialog({send, service, setup});
  }

  return {result, existingDoc};
}
