import React, {useContext, useEffect, useState} from "react";
import {Link, Navigate, useLocation} from "react-router-dom";
import {Box} from "@mui/material";
import {useActor} from "@xstate/react";
import {appSettings, MachineContext} from "lib";

const START_PROFILE_LINKS = ["/profile", "/profile/personal-details"];

export default function AuthRoute({children}) {
  const location = useLocation();
  const [state, send] = useActor(useContext(MachineContext));
  const {accountBlockInfo, profileMachineRef} = state.context;
  const [layout, setLayout] = useState(<></>);

  useEffect(() => {
    const crewProfile = profileMachineRef?.state.context?.crewProfile;
    const target = location.pathname;
    const isSubscriptionPaid =
      !!profileMachineRef?.state.context?.crewSubscription && !profileMachineRef.state.context.crewSubscription.isFree;
    const isSubscriptionFulfilled = !appSettings.subscriptionRequiredRoutes.includes(target) || isSubscriptionPaid;
    const redirectHome = () => {
      const message =
        !!crewProfile && !isSubscriptionFulfilled ? (
          <div>
            <p>This Service is available with paid Subscription Plan only.</p>
            <p>
              Activate your <Link to="/subscription">Subscription</Link> now to enjoy full range of service.
            </p>
          </div>
        ) : (
          `Start with setting up your Profile.`
        );
      send({type: "alert", data: {type: "error", message}});
      return <Navigate to="/" />;
    };
    const nextLayout =
      state.value === "unauthorized" || !!accountBlockInfo ? (
        <Navigate to="/" />
      ) : !profileMachineRef ? (
        <Box sx={{flexGrow: 1}}></Box>
      ) : (!!crewProfile && !!isSubscriptionFulfilled) || (!crewProfile && START_PROFILE_LINKS.includes(target)) ? (
        children
      ) : (
        redirectHome()
      );

    setLayout(nextLayout);
  }, [state.value, send, children, location.pathname, accountBlockInfo, profileMachineRef]);

  return layout;
}
