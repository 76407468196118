import React, {useContext, useReducer} from "react";
import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
import {useActor} from "@xstate/react";
import {format, parseISO} from "date-fns";
import {MachineContext} from "lib";
import {CLOSE_ACC_ACKNOWLEDGEMENTS} from "lib/data";
import {formReducer} from "lib/helpers";

export default function MyAccount() {
  const [state, send] = useActor(useContext(MachineContext));
  const {user, accountBlockInfo, profileMachineRef} = state.context;
  const {state: profileState} = profileMachineRef; // TODO: refactor with useMemo
  const {crewProfile, crewSubscription} = profileState.context;
  const initialFormState = {acknowledge1: false, acknowledge2: false, acknowledge3: false, acknowledge4: false};
  const [formState, dispatch] = useReducer(formReducer, initialFormState);

  const onChange = (name) => () => dispatch({type: name, data: !formState[name]});

  const subscription = `${crewSubscription.isFree ? "Free trial" : ""} ${crewProfile?.crewRank.group.title || ""}`;
  const accountSettingsData = {
    "User name": user.attributes.email,
    "Phone number": user.attributes.phone_number,
    "Created on": format(parseISO(user.attributes["custom:createdAt"]), "dd MMM yyyy"),
    "Active subscription": subscription,
    "Subscription expires on": format(parseISO(crewSubscription.expiryDate), "dd MMM yyyy"),
  };
  const listItems = (dataObject) =>
    Object.keys(dataObject).map((key, index) => (
      <ListItem key={index} divider secondaryAction={dataObject[key]}>
        <ListItemText primary={`${key}:`} />
      </ListItem>
    ));

  const acknowledgementsItems = CLOSE_ACC_ACKNOWLEDGEMENTS.map(({name, text}) => (
    <ListItem key={name} sx={{p: {xs: 0, sm: 1}}}>
      <ListItemButton role={undefined} onClick={onChange(name)} dense sx={{p: {xs: 0, sm: 1}}}>
        <ListItemIcon sx={{minWidth: {xs: "1.5rem", sm: "3rem"}}}>
          <Checkbox
            edge="start"
            id={name}
            checked={!!accountBlockInfo || formState[name]}
            tabIndex={-1}
            disabled={!!accountBlockInfo}
          />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  ));

  const onCloseAccountClick = () => send({type: "blockAccount", data: 0});

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        p: 2,
        overflow: "auto",
        height: {xs: "auto", md: 1},
      }}>
      <Typography variant="h6" gutterBottom>
        My Account
      </Typography>
      <List subheader={<ListSubheader>Account Settings</ListSubheader>}>{listItems(accountSettingsData)}</List>
      <List subheader={<ListSubheader>Close Account</ListSubheader>}>{acknowledgementsItems}</List>
      <Box sx={{display: "flex", justifyContent: "flex-end"}}>
        <Button
          variant="contained"
          onClick={onCloseAccountClick}
          disabled={Object.values(formState).includes(false)}
          sx={{width: {xs: 1, sm: "auto"}}}>
          Close Account
        </Button>
      </Box>
    </Box>
  );
}
