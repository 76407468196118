import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {Box, CircularProgress, Grid, MenuItem, Stack, TextField, Typography} from "@mui/material";
import {useActor, useSelector} from "@xstate/react";
import {formatISO} from "date-fns";
import {EditToolbar} from "lib/components";
import {BIRTH_DATE_FIELD_NAME} from "lib/data";
import {sortBy} from "lib/helpers";
import MachineContext from "lib/MachineContext";
import {matchAccount} from "machine/serviceMachines/marfinMachineSet/bankAccount";

import BeneficiaryDetailsForm from "./BeneficiaryDetailsForm";

export default function BankAccount() {
  const [state, send] = useActor(useContext(MachineContext));
  const {accountId} = useParams();
  const crewProfile = useMemo(
    () => state.context?.profileMachineRef?.state?.context.crewProfile,
    [state.context?.profileMachineRef?.state?.context]
  );
  const marfinMachineStateValue = useSelector(state.context.marfinMachineRef, (state) => state?.toStrings().pop());
  const remitterAuthMachineStateValue = useSelector(state.context.remitterAuthMachineRef, (state) =>
    state?.toStrings().pop()
  );
  const bankDetails = useMemo(
    () => state.context.marfinMachineRef?.state?.context.bankAccount,
    [state.context.marfinMachineRef?.state?.context]
  );
  const bankAccounts = useMemo(
    () => state.context.marfinMachineRef?.state?.context.bankAccounts,
    [state.context.marfinMachineRef?.state?.context]
  );
  const otherAccounts = useMemo(
    () => (bankDetails ? bankAccounts.filter((item) => !matchAccount(item, bankDetails)) : []),
    [bankAccounts, bankDetails]
  );

  const countryTemplates = useMemo(
    () => state.context.marfinMachineRef?.state.context.countryTemplates ?? [],
    [state.context.marfinMachineRef?.state.context.countryTemplates]
  );
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const countriesOptions = useMemo(() => {
    return countryTemplates.sort(sortBy("countryName")).map(({countryCode, countryName}) => {
      return (
        <MenuItem key={countryCode} value={countryCode}>
          {countryName}
        </MenuItem>
      );
    });
  }, [countryTemplates]);

  const [isInitialised, setIsInitialised] = useState(false);
  const [isBeneficiaryValid, setIsBeneficiaryValid] = useState(false);
  const [getBeneficiary, setGetBeneficiary] = useState(() => null);
  const [resetBeneficiary, setResetBeneficiary] = useState(() => null);

  const bank = useMemo(() => (bankDetails?.bank ? JSON.parse(bankDetails.bank) : null), [bankDetails]);
  const beneficiary = useMemo(
    () => (bankDetails?.beneficiary ? JSON.parse(bankDetails.beneficiary) : null),
    [bankDetails]
  );

  const accountTemplate = useMemo(() => {
    if (bank && countryTemplates.length > 0) {
      const bankCountry = bank.structured_address.country_code.toUpperCase();
      const countryTemplate = countryTemplates.find((item) => item.countryCode.toUpperCase() === bankCountry);

      if (countryTemplate?.accountTemplate) {
        return JSON.parse(countryTemplate.accountTemplate).properties;
      }
    }
    return null;
  }, [bank, countryTemplates]);

  const beneficiaryTemplate = useMemo(() => {
    if (beneficiary && countryTemplates.length > 0) {
      const beneficiaryCountry = beneficiary.country_code.toUpperCase();
      const countryTemplate = countryTemplates.find((item) => item.countryCode.toUpperCase() === beneficiaryCountry);

      if (countryTemplate?.beneficiaryTemplate) {
        return JSON.parse(countryTemplate.beneficiaryTemplate).properties;
      }
    }
    return null;
  }, [beneficiary, countryTemplates]);

  const savedBeneficiaryDetails = useMemo(
    () =>
      !beneficiary || !beneficiaryTemplate
        ? null
        : {
            beneficiaryDetails: beneficiary,
            beneficiaryCountry: beneficiary
              ? {
                  code: beneficiary.country_code?.toLowerCase(),
                  name: beneficiary.country_name,
                }
              : null,
            beneficiaryTemplate: beneficiaryTemplate,
          },
    [beneficiary, beneficiaryTemplate]
  );

  const accountDetailsFields = useMemo(() => {
    if (bankDetails && accountTemplate) {
      const fields = Object.keys(accountTemplate)
        .map((key) => ({...accountTemplate[key], name: key, value: bankDetails[key] ?? ""}))
        .filter((item) => item.type !== "hidden");
      return fields;
    }

    return [];
  }, [accountTemplate, bankDetails]);

  const bankFields = useMemo(() => {
    if (bank) {
      const bankName = bank.name;
      const department = bank.department ? `, ${bank.department}` : "";
      const {street_name, building_number, unit_number, post_code, town_name, country_subdivision_name, country_name} =
        bank.structured_address;
      const address = [
        `${building_number ? `${building_number} ` : ""}${street_name ?? ""}`,
        `${unit_number ? ` app. ${unit_number}` : ""}`,
        `${town_name ? ` ${town_name}` : ""}`,
        `${post_code ? ` ${post_code}` : ""}`,
        `${country_subdivision_name ? ` ${country_subdivision_name}` : ""}`,
        `${country_name ? ` ${country_name}` : ""}`,
      ];

      return [
        {name: "name", value: `${bankName}${department}`, title: "Bank name"},
        {name: "address", value: address.filter((item) => item !== "").join(", "), title: "Bank address"},
      ];
    }
    return [];
  }, [bank]);

  const editFunctions = useMemo(
    () => ({
      edit: () => {
        setIsEditModeOn(true);
      },
      save: () => {
        const fullBeneficiary = getBeneficiary();
        const updateData = {
          accountNumber: bankDetails.accountNumber,
          beneficiary: JSON.stringify({
            ...fullBeneficiary.beneficiaryDetails,
            [BIRTH_DATE_FIELD_NAME]: fullBeneficiary.beneficiaryDetails[BIRTH_DATE_FIELD_NAME]
              ? formatISO(fullBeneficiary.beneficiaryDetails[BIRTH_DATE_FIELD_NAME])
              : "",
          }),
          swiftCode: bankDetails.swiftCode,
        };

        send({type: "UPDATE_BANK_ACCOUNT_BENEFICIARY", data: updateData});
        setIsEditModeOn(false);
      },
      cancel: () => {
        resetBeneficiary(beneficiary);
        setIsEditModeOn(false);
      },
    }),
    [bankDetails, beneficiary, getBeneficiary, resetBeneficiary, send]
  );

  const handleEditBeneficiary = useCallback(
    (action) => {
      if (editFunctions[action]) {
        editFunctions[action]();
      }
    },
    [editFunctions]
  );

  useEffect(() => {
    if (accountId && marfinMachineStateValue === "idle" && !bankDetails) {
      send({type: "LOAD_BANK_ACCOUNT", data: {accountId, crewProfileId: crewProfile.id}});
    }
  }, [accountId, bankDetails, crewProfile, marfinMachineStateValue, send]);

  useEffect(() => {
    if (remitterAuthMachineStateValue === "authorised" && marfinMachineStateValue === "stanby" && !isInitialised) {
      send("INIT");
      setIsInitialised(true);
    }
  }, [isInitialised, send, remitterAuthMachineStateValue, marfinMachineStateValue]);

  useEffect(() => {
    return () => {
      send({type: "RESET_SELECTED_ACCOUNT"});
    };
  }, [send]);

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      useFlexGap
      spacing={0}
      sx={{height: 1}}>
      <>
        <Box sx={{width: 1}}>
          <Typography variant="subtitle1" gutterBottom>
            Account ditails
          </Typography>
        </Box>
        <Grid container spacing={1} mb={1}>
          {accountDetailsFields.map(({name, value, title}) => (
            <Grid item key={name} xs={12} sm={6} lg={4}>
              <TextField
                label={title}
                value={value}
                InputLabelProps={{shrink: true}}
                variant="standard"
                size="small"
                disabled
                multiline
                fullWidth
              />
            </Grid>
          ))}
          {bankFields.map(({name, value, title}) => (
            <Grid item key={name} xs={12} md={6}>
              <TextField
                label={title}
                value={value}
                InputLabelProps={{shrink: true}}
                variant="standard"
                size="small"
                disabled
                multiline
                fullWidth
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            {(!accountDetailsFields.length || !bankFields.length) && <CircularProgress color="inherit" size={30} />}
          </Grid>
        </Grid>
      </>

      <Box sx={{width: 1, pt: 2}}>
        <Grid container spacing={0} justifyContent="space-between">
          <Grid item>
            <Typography variant="subtitle1" gutterBottom>
              Beneficiary ditails
            </Typography>
          </Grid>
          <Grid item xs>
            <EditToolbar
              canEdit={true}
              isEditModeOn={isEditModeOn}
              canSave={isBeneficiaryValid}
              handleEdit={handleEditBeneficiary}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{flexGrow: 1, mt: 1, mb: 2}}>
        {!beneficiaryTemplate || !savedBeneficiaryDetails ? (
          <CircularProgress color="inherit" size={30} />
        ) : (
          <BeneficiaryDetailsForm
            disabled={!isEditModeOn}
            countryTemplates={countryTemplates}
            countriesOptions={countriesOptions}
            setGetBeneficiary={setGetBeneficiary}
            setResetBeneficiary={setResetBeneficiary}
            setBeneficiaryIsValid={setIsBeneficiaryValid}
            savedBeneficiaryDetails={savedBeneficiaryDetails}
            accounts={otherAccounts}
          />
        )}
      </Box>
    </Stack>
  );
}
