import {API, graphqlOperation} from "aws-amplify";
import {
  deleteDiverLog,
  deleteDiveSupLog,
  deleteSeaLog,
  updateDiverLog,
  updateDiveSupLog,
  updateSeaLog,
} from "graphql/mutations";
import {listVesselTypes} from "graphql/queries";
import {dateToString, removeNullishProps} from "lib/helpers";

import {addDiverLogEntry, addDiveSupervisorLogEntry, addSeaLogEntry} from "./helpers";

const seaLogEntryMessage = (newEntry) => {
  const {dateFrom, dateTo} = newEntry;
  return `Entry Log From: ${dateToString(dateFrom)} To: ${dateToString(dateTo)}`;
};

const loadVesselTypes = async () => {
  const response = await API.graphql(graphqlOperation(listVesselTypes));
  const {
    data: {listVesselTypes: list},
  } = response;
  return list;
};

const addLogEntry = async (data) => {
  const {context, logType, logData: newLog} = data;
  const {crewSeaLogs, crewDiveLogs, crewDiveSupLogs} = context;
  const logData = removeNullishProps(newLog);
  const result =
    logType === "SeaLog"
      ? await addSeaLogEntry({logData, crewSeaLogs})
      : logType === "DiverLog"
      ? await addDiverLogEntry({crewDiveLogs, logData})
      : logType === "DiveSupLog"
      ? await addDiveSupervisorLogEntry({crewDiveSupLogs, logData})
      : null;
  if (!result) {
    const message = "Something went wrong. please Try again or contact us for technical support.";
    return {alert: {type: "error", message}};
  }
  const {type, newEntry} = result;
  return type === "error"
    ? {alert: {type, message: result?.message}}
    : {
        newEntry,
        logType,
        alert: {
          type,
          message:
            logType === "SeaLog"
              ? `${seaLogEntryMessage(newEntry)} added successfully!`
              : `Entry Log on ${dateToString(newEntry.diveDate)} added successfully!`,
        },
      };
};

const deleteLogEntry = async ({logType, logId}) => {
  let deletedEntry = null;
  let message = "";
  switch (logType) {
    case "SeaLog": {
      const response = await API.graphql(graphqlOperation(deleteSeaLog, {input: {id: logId}}));
      deletedEntry = response.data.deleteSeaLog;
      message = `${seaLogEntryMessage(deletedEntry)} removed successfully!`;
      break;
    }
    case "DiverLog": {
      const response = await API.graphql(graphqlOperation(deleteDiverLog, {input: {id: logId}}));
      deletedEntry = response.data.deleteDiverLog;
      message = `Entry Log on Date: ${dateToString(deletedEntry.diveDate)} removed successfully!`;
      break;
    }
    case "DiveSupLog": {
      const response = await API.graphql(graphqlOperation(deleteDiveSupLog, {input: {id: logId}}));
      deletedEntry = response.data.deleteDiveSupLog;
      message = `Entry Log on Date: ${dateToString(deletedEntry.diveDate)} removed successfully!`;
      break;
    }
    default:
      message = `Something went wrong! Entry Log was not removed! Please try again or contact us for technical support`;
      return {alert: {type: "error", message}};
  }
  return {logType, logId, alert: {type: "success", message}};
};

const editLogEntry = async ({newEntry, logType}) => {
  let updatedLogEntry = null;
  let message = "";
  switch (logType) {
    case "SeaLog": {
      const response = await API.graphql(graphqlOperation(updateSeaLog, {input: newEntry}));
      updatedLogEntry = response.data.updateSeaLog;
      message = `${seaLogEntryMessage(updatedLogEntry)} updated successfully!`;
      break;
    }
    case "DiverLog": {
      const response = await API.graphql(graphqlOperation(updateDiverLog, {input: newEntry}));
      updatedLogEntry = response.data.updateDiverLog;
      message = `Entry Log on Date: ${dateToString(updatedLogEntry.diveDate)} updated successfully!`;
      break;
    }
    case "DiveSupLog": {
      const response = await API.graphql(graphqlOperation(updateDiveSupLog, {input: newEntry}));
      updatedLogEntry = response.data.updateDiveSupLog;
      message = `Entry Log on Date: ${dateToString(updatedLogEntry.diveDate)} updated successfully!`;
      break;
    }
    default:
      message = `Something went wrong! Entry Log was not removed! Please try again or contact us for technical support`;
      return {alert: {type: "error", message}};
  }
  return {updatedLogEntry, logType, alert: {type: "success", message}};
};

export {addLogEntry, deleteLogEntry, editLogEntry, loadVesselTypes};
