import React, {useContext} from "react";
import {Box, Grid} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useActor} from "@xstate/react";
import {MachineContext} from "lib";

import AppTour from "../appTour/AppTour";

import {BlockedAccountNote, CheckList, DocsNotes, LogsNotes, ProfileNotes} from "./notes";

const dashboardItemStyle = {height: 1, p: 0.5, display: "flex", overflow: "hidden"};

export default function Dashboard() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [state] = useActor(useContext(MachineContext));
  const {accountBlockInfo, profileMachineRef} = state.context;
  const {state: profileState} = profileMachineRef; // TODO: refactor with useMemo
  const {crewProfile} = profileState.context;
  const content = accountBlockInfo ? (
    <BlockedAccountNote accountBlockInfo={accountBlockInfo} />
  ) : (
    <Box
      sx={{
        flexGrow: 1,
        p: 1,
        flexDirection: "column",
        display: "flex",
        overflow: "hidden",
      }}>
      {!isSmallScreen && <AppTour />}
      <Grid container spacing={1} sx={{height: 1, mt: 0, overflow: "hidden"}}>
        <Grid item xs={12} sm={6} md={4} sx={dashboardItemStyle}>
          {!crewProfile ? <ProfileNotes /> : <CheckList />}
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={dashboardItemStyle}>
          <DocsNotes />
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={dashboardItemStyle}>
          <LogsNotes />
        </Grid>
      </Grid>
    </Box>
  );

  return content;
}
