const CLOSE_ACC_ACKNOWLEDGEMENTS = [
  {
    name: "acknowledge1",
    text: `I understand that by clicking this checkbox, I am closing my CrewBook account.
    The closure of my CrewBook account serves as notice to CrewBook that I wish to terminate
    any other services with CrewBook
    that might governs my CrewBook account, solely with respect to that CrewBook account.`,
  },
  {
    name: "acknowledge2",
    text: `I understand when cancelling an annual subscription, all future charges associated
    with future years of my subscription will be cancelled. I understand that I will not receive a refund,
    prorated or otherwise, for the remainder of the annual term.`,
  },
  {
    name: "acknowledge3",
    text: `I acknowledge that I may reopen my CrewBook account only within 60 days of my account closure
    (the "Post-Closure Period"). If I reopen my account during the Post-Closure Period,
    I agree that the same terms will govern my access to and use of CrewBook services through
    my reopened CrewBook account. If I choose not to reopen my account after the Post-Closure Period,
    any content remaining in my CrewBook account will be deleted.`,
  },
  {
    name: "acknowledge4",
    text: `I understand that after the Post-Closure Period I will no longer be able to reopen my closed account.`,
  },
];

export default CLOSE_ACC_ACKNOWLEDGEMENTS;
