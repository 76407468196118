import React, {useContext, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Box, Grid, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {MachineContext} from "lib";

import SubscriptionCard from "./SubscriptionCard";

const gridItemStyle = {height: 1, p: 0.5, display: "flex"};

export default function Subscription() {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, send] = useActor(useContext(MachineContext));
  const {subscriptions, profileMachineRef} = state.context;
  const crewRank = !profileMachineRef ? {} : profileMachineRef.state.context?.crewProfile?.crewRank || {};
  const subscriptionCards = !subscriptions
    ? []
    : subscriptions?.map(({title, subscriptionCharge, id, subscriptionDetails}) => (
        <Grid key={id} item xs={12} sm={6} md={3} sx={gridItemStyle}>
          <SubscriptionCard
            {...{title, subscriptionCharge, subscriptionDetails, isCurrent: crewRank?.group?.id === id}}
          />
        </Grid>
      ));

  useEffect(() => {
    if (location.search !== "") {
      const [key, value] = location.search.replace("?", "").split("=");
      const isSuccess = key === "success" && value === "true";
      const type = isSuccess ? "success" : "error";
      const message = !isSuccess
        ? "Your Payment was not successfull. Please, try again or contact us for assistance."
        : `Your Payment is processing. Your Subscription will be updated after payment confirmation.
      Please check your email for the detailed invoice.`;

      send({type: "alert", data: {type, message}});
      navigate("/subscription", {replace: true});
    }
  }, [location.search, send, navigate]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        p: 2,
        overflow: "auto",
        height: {xs: "auto", md: 1},
      }}>
      <Typography variant="h6" gutterBottom>
        Subscription Plans
      </Typography>
      <Grid container spacing={1} sx={{mt: 0}} direction="row" alignItems="stretch">
        {subscriptionCards}
      </Grid>
    </Box>
  );
}
