import React from "react";
import {Switch} from "@mui/material";
import {styled} from "@mui/material/styles";

const CustomSwitch = styled((props) => <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />)(
  () => ({
    // theme
    width: 44,
    height: 26,
    padding: 0,
    marginRight: "0.5rem",
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
    },
  })
);

export default CustomSwitch;
