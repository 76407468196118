import React, {useContext, useState} from "react";
import {Add, Remove} from "@mui/icons-material";
import {Box, Collapse, Fab, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {isDiver, isDivingSupervisor} from "lib/helpers";
import MachineContext from "lib/MachineContext";

import {diverLogsColumn, seaLogsColumns} from "./configurations";
import DiverLogForm from "./DiverLogForm";
import {DiverLogTable, SeaLogTable} from "./logTables";
import SeaLogForm from "./SeaLogForm";

export default function CrewLogs() {
  const [state] = useActor(useContext(MachineContext));
  const {profileMachineRef} = state.context;
  const {state: profileState} = profileMachineRef; // TODO: refactor with useMemo
  const {
    crewProfile: {crewRank},
  } = profileState.context;

  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleUploadChecked = () => setIsCollapsed((state) => !state);
  const onCancel = () => handleUploadChecked();

  const isCrewDiver = isDiver(crewRank.groupId);
  const isCrewDivingSup = isDivingSupervisor(crewRank.groupId);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        p: 1,
        overflow: "auto",
        height: {xs: "auto", md: 1},
      }}>
      <Box sx={{mt: 1}}>
        <Fab color="primary" aria-label="add document" variant="extended" size="medium" onClick={handleUploadChecked}>
          {isCollapsed ? <Add /> : <Remove />}Add Log Entry
        </Fab>
        <Collapse in={!isCollapsed}>
          {isCrewDivingSup ? (
            <h5>Supervisor Log Form is under construction</h5>
          ) : isCrewDiver ? (
            <DiverLogForm />
          ) : (
            <SeaLogForm isCollapsed={isCollapsed} onCancel={onCancel} />
          )}
        </Collapse>
      </Box>
      <Box sx={{mt: 3}}>
        <Typography variant="subtitle1">{isCrewDiver ? "Diving Logbook" : "Seaman Logbook"}</Typography>
        {isCrewDiver ? <DiverLogTable columns={diverLogsColumn} /> : <SeaLogTable columns={seaLogsColumns} />}
      </Box>
    </Box>
  );
}
