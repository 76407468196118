/* eslint-disable max-len */
import React from "react";
import {Link} from "react-router-dom";
import {Box} from "@mui/material";
import {PoliciesStyledBox} from "lib/components";

export default function DataPrivacy() {
  return (
    <PoliciesStyledBox>
      <Box sx={{width: {xs: "100%", sm: "90%"}}}>
        <h3>Data Privacy</h3>
        <p>Last updated: February 22, 2022</p>
        <p>
          At Maritime IT Solutions LLC, our customers’ trust is our top priority. We are continually monitoring the
          privacy regulatory and legislative landscape to identify changes. Maintaining customer trust is an ongoing
          commitment. We strive to inform you of the privacy and data security policies, practices, and technologies
          we’ve put in place. Our commitments include:
        </p>
        <ul>
          <li>
            <strong>Access:</strong> As a customer, you maintain full control of your content and data that you upload
            to the CrewBook. Powered by Amazon Web Services, we provide an advanced set of access, encryption, and
            logging features to help you do this securely. APIs, provided by AWS, are configuring access control
            permissions for any of the services developed and deployed to run CrewBook service. We do not access or use
            your content for any purpose without your agreement. We never use your content or derive information from it
            for marketing or advertising purposes.
          </li>
          <li>
            <strong>Storage:</strong> We choose the Amazon S3 service in which your content is stored. AWS S3 is an
            object storage built to retrieve any amount of data from anywhere with leading class of data durability to
            meet fluctuating needs with 99.99999999999% (11 9s) of failure tolerance. AWS S3 protect your data with
            unmatched security, compliance, and audit capabilities. We will not move or replicate your data without your
            agreement, except as necessary to comply with the law or a binding order of a government body.
          </li>
          <li>
            <strong>Security:</strong> Powered by AWS,CrewBook provides you industry-leading encryption features to
            protect your content in transit and at rest. These data protection features include:
            <ul>
              <li>Data encryptions with all AWS services, configured for CrewBook.</li>
              <li>
                AWS Key Management Service to provide centralized control over cryptographic keys used to protect your
                data.
              </li>
            </ul>
          </li>
          <li>
            <strong>Disclosure of customer content:</strong> We will not disclose customers’ content unless we are
            required to do so to comply with the law or a binding order of a government body.
          </li>
          <li>
            <strong>Security Assurance:</strong> We have developed a security assurance program that uses best practices
            for global privacy and data protection to help you operate securely within CrewBook, using the best of AWS
            security control environment.
          </li>
        </ul>
        <h3>Contact Us</h3>
        <p>If you have any questions about Data Privacy, You can contact us:</p>
        <ul>
          <li>
            <p>
              By email: <a href="mailto:watchkeeper@crewbook.app">watchkeeper@crewbook.app</a>
            </p>
          </li>
          <li>
            <p>
              By visiting this page on our website: <Link to="/contact">https://www.crewbook.app/contact</Link>
            </p>
          </li>
        </ul>
      </Box>
    </PoliciesStyledBox>
  );
}
