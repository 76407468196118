import React, {useEffect, useMemo, useRef, useState} from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {appSettings} from "lib";
import PropTypes from "prop-types";

dayjs.extend(customParseFormat);
const dateRegex = /^[0-9]{4}[-]{1}[0-9]{2}[-]{1}[0-9]{2}$/g;

export default function DateRangeForm(props) {
  const {
    dateFromState,
    dateToState,
    dateFromTitle,
    dateToTitle,
    dateToMax,
    cssClassFrom = "",
    cssClassTo = "",
    onChange,
    hasDateToToggle = false,
  } = props;
  const {isIOS} = appSettings;
  const dateFromRef = useRef(null);
  const dateToRef = useRef(null);
  const dateToCheckRef = useRef(null);
  const initFeedback = useMemo(
    () => ({
      dateFrom: "Please provide a valid Date.",
      dateTo: "Please provide a valid Date.",
    }),
    []
  );
  const [feedback, setFeedback] = useState(initFeedback);
  const [dateFromMax, setDateFromMax] = useState(dayjs().format("YYYY-MM-DD"));
  const [dateToMin, setDateToMin] = useState(null);
  const onHasDateToChange = () => {
    if (dateToCheckRef.current.checked) {
      dateToRef.current.value = "";
    }
    dateToRef.current.toggleAttribute("required");
  };

  const checkDatesLimits = (dateFrom, dateTo) => {
    if (dateFrom.isValid() && dateTo.isValid()) {
      const customValidityFrom =
        dateFrom.isAfter(dateTo) || dateFrom.isAfter(dayjs())
          ? `${dateFromTitle} must be earlier than ${dateToTitle} and not future date.`
          : "";
      const customValidityTo = dateFrom.isAfter(dateTo) ? `${dateFromTitle} must be earlier than ${dateToTitle}.` : "";

      dateFromRef.current.setCustomValidity(customValidityFrom);
      dateToRef.current.setCustomValidity(customValidityTo);
      setFeedback({dateFrom: customValidityFrom, dateTo: customValidityTo});
    }
  };

  const handleChange = (event) => {
    const {
      target: {value, type, id},
    } = event;
    if (type === "text") {
      const newdDate = dateRegex.test(value) ? dayjs(value, "YYYY-MM-DD", true) : null;
      const inpElem = document.getElementById(id);
      const customValidity =
        value === "" && inpElem.name === "dateTo"
          ? ""
          : !newdDate || !newdDate.isValid()
          ? "Please provide a valid Date (YYYY-MM-DD)."
          : inpElem.name === "dateFrom" && newdDate.isAfter(dayjs())
          ? "Please provide a valid present/past date (YYYY-MM-DD)."
          : "";

      inpElem.setCustomValidity(customValidity);
      setFeedback((feedback) => ({...feedback, [inpElem.name]: customValidity}));

      const dateFrom = dayjs(dateFromRef.current.value, "YYYY-MM-DD", true);
      const dateTo = dayjs(dateToRef.current.value, "YYYY-MM-DD", true);
      checkDatesLimits(dateFrom, dateTo);
      onChange({
        target: {
          name: inpElem.name,
          value: !!newdDate && newdDate.isValid() ? newdDate.format("YYYY-MM-DD") : value,
        },
      });
    } else {
      const dateFrom = dayjs(dateFromRef.current.value);
      const dateTo = dayjs(dateToRef.current.value);

      if (isIOS) {
        checkDatesLimits(dateFrom, dateTo);
      } else {
        setDateFromMax(dateTo.isValid() ? dateTo.format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD"));
        setDateToMin(dateFrom.isValid() ? dateFrom.format("YYYY-MM-DD") : "");
      }
      onChange(event);
    }
  };

  useEffect(() => {
    if (dateFromState === "") {
      setDateFromMax(dayjs().format("YYYY-MM-DD"));
      setDateToMin(null);
      setFeedback(initFeedback);
      dateToCheckRef.current?.checked && dateToCheckRef.current.click();
    }
  }, [dateFromState, initFeedback]);

  return (
    <>
      <div className={`mb-3 ${cssClassFrom}`}>
        <label className="form-label" htmlFor="dateFrom">
          {dateFromTitle}
        </label>
        <input
          className="form-control"
          ref={dateFromRef}
          type="date"
          id="dateFrom"
          value={dateFromState}
          max={dateFromMax}
          name="dateFrom"
          onChange={handleChange}
          placeholder="YYYY-MM-DD"
          required
        />
        <div className="invalid-feedback">{feedback.dateFrom}</div>
      </div>

      <div id="dateToBlock" className={`mb-3 collapse show ${cssClassTo}`}>
        <label className="form-label" htmlFor="dateTo">
          {dateToTitle}
        </label>
        <input
          className="form-control"
          ref={dateToRef}
          type="date"
          id="dateTo"
          value={dateToState}
          min={dateToMin}
          max={dateToMax || ""}
          name="dateTo"
          onChange={handleChange}
          placeholder="YYYY-MM-DD"
          required={hasDateToToggle}
        />
        <div className="invalid-feedback">{feedback.dateTo}</div>
      </div>

      {hasDateToToggle && (
        <div className="col-12">
          <div className="form-check mb-2">
            <label
              className="form-check-label text-dark"
              htmlFor="noDateTo"
              data-bs-toggle="collapse"
              data-bs-target="#dateToBlock"
              aria-expanded="false"
              aria-controls="dateToBlock">
              <input
                className="form-check-input"
                type="checkbox"
                id="noDateTo"
                ref={dateToCheckRef}
                onChange={onHasDateToChange}
              />
              This Document has No {dateToTitle}
            </label>
          </div>
        </div>
      )}
    </>
  );
}

DateRangeForm.propTypes = {
  dateFromState: PropTypes.string,
  dateToState: PropTypes.string,
  dateFromTitle: PropTypes.string,
  dateToTitle: PropTypes.string,
  dateToMax: PropTypes.string,
  cssClassFrom: PropTypes.string,
  cssClassTo: PropTypes.string,
  onChange: PropTypes.func,
  hasDateToToggle: PropTypes.bool,
};
