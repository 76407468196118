import React from "react";
import {Box} from "@mui/material";
import PropTypes from "prop-types";

export default function PoliciesStyledBox(props) {
  const {children} = props;
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        justifyContent: "center",
        textAlign: "justify",
        textJustify: "inner-word",
        p: {xs: "1rem", sm: "2rem"},
        overflow: "auto",
        height: {xs: "auto", md: 1},
        fontSize: "0.8rem",
      }}
      {...props}>
      {children}
    </Box>
  );
}

PoliciesStyledBox.propTypes = {
  children: PropTypes.node,
};
