import dayjs from "dayjs";

const rowFormatter = ({type, value}) => {
  switch (type) {
    case "string":
      return value ? value : "-";
    case "number":
      return value ? parseFloat(value) : "-";
    case "date": {
      const date = dayjs(value);
      return date.isValid() ? date.format("DD MMM YYYY") : "-";
    }
    default:
      return value;
  }
};

export default rowFormatter;
