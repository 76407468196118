import React, {useEffect} from "react";
import {CssBaseline} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {useMachine} from "@xstate/react";
import {MachineContext, mainCssTheme} from "lib";
import appMachine from "machine/appMachine";

import {Main} from "components/";

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-material.min.css"; // Optional theme CSS
import "scss/index.scss";

function App() {
  const [, , service] = useMachine(appMachine);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <MachineContext.Provider value={service}>
      <ThemeProvider theme={mainCssTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <Main />
        </LocalizationProvider>
      </ThemeProvider>
    </MachineContext.Provider>
  );
}

export default App;
