import React, {useContext, useEffect, useState} from "react";
import {useActor} from "@xstate/react";
import {Table} from "lib/components";
import {confirmDialog, dateToString} from "lib/helpers";
import MachineContext from "lib/MachineContext";
import PropTypes from "prop-types";

import DateToConfirmModal from "./DateToConfirmModal";

export default function SeaLogTable({columns}) {
  const service = useContext(MachineContext);
  const [state, send] = useActor(service);
  const {crewLogsMachineRef} = state.context;
  const {state: logState} = crewLogsMachineRef;
  const {crewSeaLogs} = logState.context;

  const [records, setRecords] = useState([]);
  const [isDateToConfirmVisible, setIsDateToConfirmVisible] = useState(false);
  const [openLogEntry, setOpenLogEntry] = useState(null);

  const handleDateToConfirmClose = () => {
    setIsDateToConfirmVisible(false);
    setOpenLogEntry(null);
  };

  const updateDateTo = (id, newDateTo) => {
    send({type: "editLogEntry", data: {newEntry: {id, dateTo: newDateTo}, logType: "SeaLog"}});
    handleDateToConfirmClose();
  };

  async function onRowDelete(row) {
    const {dateFrom, dateTo, rankTitle, id} = row;
    const content = (
      <div>
        <p>You are about to delete Log Entry:</p>
        <p>
          <strong>From:</strong> {dateToString(dateFrom)}
          <strong>{` `} To:</strong> {dateToString(dateTo)}
          {` `} as <strong>{rankTitle}</strong>?
        </p>
        <p>This process cannot be undone.</p>
      </div>
    );

    const result = await confirmDialog({send, service, setup: {content, consent: true}});
    result === 1 && send({type: "deleteLogEntry", data: {logType: "SeaLog", logId: id}});
  }

  const onRowEdit = async (row) => {
    setOpenLogEntry(row);
    setIsDateToConfirmVisible(true);
  };

  useEffect(() => {
    const logRecords = crewSeaLogs?.items || [];
    setRecords(logRecords);
  }, [crewSeaLogs]);

  return (
    <div className="col-12" style={{fontSize: "0.9rem"}}>
      <Table rows={records} columns={columns} onRowDelete={onRowDelete} onRowEdit={onRowEdit} />
      <DateToConfirmModal
        isVisible={isDateToConfirmVisible}
        logEntry={openLogEntry}
        handleSave={updateDateTo}
        handleDiscard={handleDateToConfirmClose}
      />
    </div>
  );
}

SeaLogTable.propTypes = {
  columns: PropTypes.array,
};
