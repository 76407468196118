import React, {useRef} from "react";
import {shareAcknowledgements} from "lib/data";
import PropTypes from "prop-types";

export default function ShareAccount(props) {
  const {employerName, formState, onChange, handleShare, handleBack, showBackBtn} = props;
  const memoRef = useRef(null);
  const onShare = () => handleShare(memoRef.current.value);

  const acknowledgementItems = shareAcknowledgements(employerName).map((item, index) => {
    const name = `acknowledgement${index + 1}`;
    return (
      <div className="mt-3 row border-bottom" key={name}>
        <div className="col-auto d-flex align-items-center">
          <input
            className="form-check-input"
            type="checkbox"
            id={name}
            name={name}
            checked={formState[name]}
            onChange={onChange}
          />
        </div>
        <div className="col text-start">{item}</div>
      </div>
    );
  });

  return (
    <div>
      <p>
        You are about to share your <strong>CrewBook</strong> account with <strong>ShipBook</strong> registered company:{" "}
        <strong> {employerName}</strong>.
      </p>
      {acknowledgementItems}
      <textarea className="form-control mt-3" ref={memoRef} rows="2" placeholder="message to crew manager" />
      <div className="row justify-content-between mt-3">
        <div className="col-auto">
          {showBackBtn && (
            <button className="btn btn-secondary" onClick={handleBack}>
              Back to Search
            </button>
          )}
        </div>
        <div className="col-auto">
          <button
            className="btn btn-danger"
            onClick={onShare}
            data-bs-dismiss="modal"
            disabled={Object.values(formState).includes(false)}>
            Share CrewBook Account
          </button>
        </div>
      </div>
    </div>
  );
}

ShareAccount.propTypes = {
  employerName: PropTypes.string,
  formState: PropTypes.object,
  onChange: PropTypes.func,
  handleShare: PropTypes.func,
  handleBack: PropTypes.func,
  showBackBtn: PropTypes.bool,
};
