/* eslint-disable max-len */
import React from "react";

const rejectActions = ["WITHDRAW_REQUEST", "REJECT_REQUEST", "REJECT_OFFER", "REJECT_CONTRACT"];
const contractAcceptAction = ["ACCEPT_CONTRACT"];
const offerAcceptAction = ["ACCEPT_OFFER"];
const deleteAction = ["DELETE_REQUEST"];

const actionConfirmMessage = (params) => {
  const {employmentActions, action, employerName, setConsentChecked} = params;
  const actionType = rejectActions.includes(action)
    ? "REJECTION"
    : contractAcceptAction.includes(action)
    ? "ACCEPT_CONTRACT"
    : offerAcceptAction.includes(action)
    ? "ACCEPT_OFFER"
    : deleteAction.includes(action)
    ? "REMOVAL"
    : "OTHER";
  const beginning = (
    <p>
      You are about to <strong>{employmentActions[action].actionTitle}</strong>.
    </p>
  );
  const onChange = ({target}) => setConsentChecked(target.checked);

  const messages = {
    REJECTION: (
      <div>
        {beginning}
        <p>
          This will cancel Sharing your CrewBook Account with Employer:&emsp;<strong>{employerName}</strong>.
        </p>
      </div>
    ),
    ACCEPT_OFFER: (
      <div>
        {beginning}
        <p>
          Employer: <strong>{employerName}</strong>.
        </p>
        <div className="mt-3 row border-bottom">
          <div className="col-auto d-flex align-items-center">
            <input className="form-check-input" type="checkbox" id="accept_consent" onChange={onChange} />
          </div>
          <div className="col text-start">
            I understand that by clicking this checkbox, I agree with the terms and conditions of the Offer and
            authorize CrewBook to sign the Offer with my electionic signature.
          </div>
        </div>
      </div>
    ),
    ACCEPT_CONTRACT: (
      <div>
        {beginning}
        <p>
          Employer: <strong>{employerName}</strong>.
        </p>
        <div className="mt-3 row border-bottom">
          <div className="col-auto d-flex align-items-center">
            <input className="form-check-input" type="checkbox" id="accept_consent" onChange={onChange} />
          </div>
          <div className="col text-start">
            I understand that by clicking this checkbox, I agree with the terms and conditions of the Contract and
            authorize CrewBook to sign the Contract with my electionic signature.
          </div>
        </div>
      </div>
    ),
    REMOVAL: (
      <div>
        {beginning}
        <p>
          All history of employment events with Employer:&nbsp;<strong>{employerName}</strong>&emsp; will be deleted.
        </p>
      </div>
    ),
    OTHER: (
      <div>
        {beginning}
        <p>
          Employer: <strong>{employerName}</strong>.
        </p>
      </div>
    ),
  };
  return messages[actionType];
};

export default actionConfirmMessage;
