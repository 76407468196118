import React from "react";
import {ValidationFormField} from "lib/components";
import PropTypes from "prop-types";

export default function PhoneFieldInput(props) {
  const {form, name, value, title = "Phone Number", onChange} = props;

  return (
    <ValidationFormField
      form={form}
      type="tel"
      required
      fullWidth
      id={name}
      label={title}
      size="small"
      name={name}
      value={value}
      onChange={onChange}
      helperText="Invalid phone number"
      shrink={true}
    />
  );
}

PhoneFieldInput.propTypes = {
  form: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  onChange: PropTypes.func,
};
