import ReactDOMServer from "react-dom/server";
import {Storage} from "aws-amplify";
import {customPdfFonts, getSvgSignature, readImg, removeNullishProps} from "lib/helpers";
import customVfsFonts from "lib/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import docsTables from "./docsTables";
import emerDetailsTable from "./emerDetailsTable";
import logsTables from "./logsTables";
import personalDetailsTable from "./personalDetailsTable";

pdfMake.vfs = {...pdfFonts.pdfMake.vfs, ...customVfsFonts};

const styles = {
  title: {fontSize: 17, bold: true, alignment: "center", margin: [0, 30, 0, 10]},
  h1: {fontSize: 13, bold: true, alignment: "left", margin: [0, 25, 0, 5]},
  th1: {fontSize: 11, bold: false, alignment: "left", color: "#535659", margin: [0, 0, 0, 0]},
  th2: {fontSize: 11, bold: true, alignment: "left", color: "#333333", margin: [0, 0, 0, 0]},
  td: {fontSize: 11, bold: true, color: "#333333", margin: [0, 0, 0, 0]},
  footer: {fontSize: 9, bold: false, alignment: "center", color: "#737373", margin: [0, 20, 0, 0]},
  tdLight: {color: "#535659"},
};

const readLogo = (url) => fetch(url).then((response) => response.text());

const getImage = async (fileKey) => {
  const {Body} = await Storage.get(fileKey, {download: true, level: "protected"});
  const image = await readImg(Body);

  return image;
};

export default async function cvGenerator(params) {
  const {crewProfile, crewSeaLogs, crewDocs, profileImgKey} = params;
  const {requiredDocs, personalDocs} = crewDocs;
  const profile = removeNullishProps(crewProfile);
  const {firstName, lastName, signatureHash} = profile;
  const profileImg = !profileImgKey ? null : await getImage(profileImgKey);
  const signatureSvg = await getSvgSignature({firstName, lastName, hashNumber: signatureHash});
  const signatureSvgString = ReactDOMServer.renderToStaticMarkup(signatureSvg);
  const logsItems = crewSeaLogs && crewSeaLogs.items?.length > 0 ? logsTables({crewSeaLogs}) : [];
  const svgFileString = await readLogo("/images/logoGray.svg");
  const info = {title: `CV-${lastName}`, author: "CrewBook", subject: `CV-${lastName}`};
  const footerColumns = (currentPage, pageCount) => [
    {text: "", style: {alignment: "left", width: 150, margin: [0, 0, 0, 0]}},
    {
      text: [`${currentPage.toString()} of ${pageCount}. Generated by CrewBook \nhttps://www.crewbook.app`],
      style: "footer",
    },
    {svg: signatureSvgString, fit: [150, 50], style: {alignment: "right", width: 150, margin: [0, 0, 0, 0]}},
  ];
  const headerTable = {
    headerRows: 0,
    widths: ["*", "*"],
    body: [
      [
        {svg: svgFileString, width: 90},
        !profileImg ? {} : {image: profileImg, fit: [90, 90], style: {alignment: "right"}},
      ],
    ],
  };

  const docDefinition = {
    pageSize: "A4",
    pageMargins: [60, 60, 30, 60],
    pageOrientation: "portrait",
    fontSize: 11,
    info,
    footer: (currentPage, pageCount) => ({columns: footerColumns(currentPage, pageCount)}),
    content: [
      {layout: "noBorders", table: headerTable},
      {text: "CURRICULUM VITAE / APPLICATION FORM", style: "title"},
      {text: "CREW DETAILS:", style: "h1"},
      {table: personalDetailsTable(profile)},
      {
        stack: [{text: "EMERGENCY CONTACT DETAILS:", style: "h1"}, {table: emerDetailsTable(profile)}],
        unbreakable: true,
      },
      ...docsTables({requiredDocs, personalDocs}),
      ...logsItems,
    ],
    pageBreakBefore(currentNode, followingNodesOnPage) {
      const {headlineLevel, startPosition} = currentNode;
      return (
        (headlineLevel === 1 && followingNodesOnPage?.length === 0) ||
        (headlineLevel === 1 &&
          followingNodesOnPage[0]?.pageNumbers.length > 1 &&
          startPosition.top > startPosition.pageInnerHeight / 2)
      );
    },
    styles,
  };

  return await pdfMake.createPdf(docDefinition, null, customPdfFonts);
}
