import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {NavLink, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {AccountBalance, NavigateNext, Receipt, RequestPage} from "@mui/icons-material";
import {Box, Breadcrumbs, Link, Tab, Tabs, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {MachineContext} from "lib";
import {withAuthProtection} from "lib/components/AuthProtector/AuthProtector";

import {getBankAccountId} from "../../machine/serviceMachines/marfinMachineSet/bankAccount";

function Finance() {
  const [state, send] = useActor(useContext(MachineContext));
  const location = useLocation();
  const {accountId} = useParams();
  const navigate = useNavigate();

  const crewProfile = useMemo(
    () => state.context?.profileMachineRef?.state?.context.crewProfile,
    [state.context?.profileMachineRef?.state?.context]
  );
  const bankAccounts = useMemo(
    () => state.context.marfinMachineRef?.state?.context.bankAccounts,
    [state.context.marfinMachineRef?.state?.context]
  );

  const breadcrumbNameMap = useMemo(() => {
    const account =
      accountId && crewProfile?.id
        ? bankAccounts.find((item) => getBankAccountId(item, crewProfile.id) === accountId)
        : undefined;
    return {
      "/finance": "Finance",
      "/finance/payment-details": "Payment Details",
      "/finance/payment-details/bank-accounts": "Bank Accounts",
      [`/finance/payment-details/bank-accounts/${accountId ?? ""}`]:
        "Bank Account Details" + (account?.label ? `: ${account.label}` : ""),
      "/finance/pay-slips": "Pay Slips",
      "/finance/claims": "Claims",
    };
  }, [accountId, bankAccounts, crewProfile]);

  const breadcrumbs = useMemo(() => {
    const pathnames = location.pathname.split("/").filter((x) => x);

    return pathnames.map((value, index) => {
      const isLast = index === pathnames.length - 1;
      const to = `/${pathnames.slice(0, index + 1).join("/")}`;
      return isLast ? (
        <Typography color="text.primary" key={to}>
          {breadcrumbNameMap[to]}
        </Typography>
      ) : (
        <Link component={NavLink} underline="hover" color="inherit" to={to} key={to}>
          {breadcrumbNameMap[to]}
        </Link>
      );
    });
  }, [breadcrumbNameMap, location.pathname]);

  const tabsInfo = useMemo(
    () => [
      {
        label: "Payment Details",
        disabled: false,
        icon: <AccountBalance />,
        index: 0,
        to: "/finance/payment-details",
      },
      {
        label: "Pay Slips",
        disabled: !crewProfile,
        icon: <Receipt />,
        index: 1,
        to: "/finance/pay-slips",
      },
      {
        label: "Claims",
        disabled: !crewProfile,
        icon: <RequestPage />,
        index: 2,
        to: "/finance/claims",
      },
    ],
    [crewProfile]
  );
  const [activeTab, setActiveTab] = useState(tabsInfo.find((tab) => tab.to === location.pathname)?.index ?? 0);
  const handleTabClick = useCallback(
    (_, newValue) => {
      setActiveTab(newValue);
      navigate(tabsInfo[newValue].to);
    },
    [navigate, tabsInfo]
  );

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);

      if (element) {
        element.scrollIntoView({behavior: "smooth"});
      }
    }
  }, [location]);

  useEffect(() => {
    return () => {
      send({type: "RESET_MARFIN_DATA"});
    };
  }, [send]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 1,
        height: {xs: "auto", md: 1},
        overflow: "hidden",
      }}>
      <Box sx={{borderBottom: 1, borderColor: "divider", mb: 1}}>
        <Tabs value={activeTab} onChange={handleTabClick} variant="fullWidth">
          {tabsInfo.map(({label, icon, index, disabled}) => (
            <Tab
              key={index}
              icon={icon}
              disabled={disabled}
              label={
                <Typography color="text.secondary" variant="span" sx={{fontSize: "0.7rem"}}>
                  {label}
                </Typography>
              }
            />
          ))}
        </Tabs>
      </Box>

      <Breadcrumbs separator={<NavigateNext fontSize="small" />} sx={{mb: 2, mx: 2}}>
        {breadcrumbs}
      </Breadcrumbs>

      <Box sx={{flexGrow: 1, m: {xs: 0, sm: 1}, ml: {xs: 1, sm: 2}, overflow: "auto"}}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default withAuthProtection(Finance);
