import React, {useContext} from "react";
import {Done} from "@mui/icons-material";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {blue} from "@mui/material/colors";
import {useActor} from "@xstate/react";
import {differenceInCalendarDays, format, isBefore, parseISO} from "date-fns";
import {MachineContext} from "lib";
import PropTypes from "prop-types";

export default function SubscriptionCard(props) {
  const {title, subscriptionCharge, subscriptionDetails = [], isCurrent = false} = props;
  const [state, send] = useActor(useContext(MachineContext));
  const {accountBlockInfo, user, profileMachineRef} = state.context;
  const {expiryDate, isFree} = profileMachineRef?.state.context.crewSubscription || {};
  const expiryDateParsed = !expiryDate ? null : parseISO(expiryDate);
  const daysLeft = !expiryDate
    ? 0
    : isBefore(expiryDateParsed, new Date())
    ? -1
    : differenceInCalendarDays(expiryDateParsed, new Date());
  const details = !expiryDate
    ? ""
    : daysLeft < 0
    ? `Your subscription expired on ${format(expiryDateParsed, "dd MMM yyyy")}`
    : `You have ${daysLeft} days left in your ${isFree ? "Free Trial" : "Subscription"}.`;
  const cardHeaderStyle = isCurrent
    ? {color: "white", bgcolor: "primary.main"}
    : {color: "text.primary", bgcolor: blue[50]};

  const handleActivateClick = () => send("checkout");

  const activateButton = (
    <Button fullWidth variant="contained" onClick={handleActivateClick}>
      {isFree ? "Activate Now" : "Renew Now"}
    </Button>
  );

  const activeSubscription =
    !accountBlockInfo || accountBlockInfo.reason === 1 ? (
      <>
        <Typography align="center" variant="button">
          {details}
        </Typography>
        {(isFree || daysLeft <= 7) && !!user && activateButton}
      </>
    ) : (
      <></>
    );

  return (
    <Card sx={{flexGrow: 1, display: " flex", flexDirection: "column"}}>
      <CardHeader title={title} titleTypographyProps={{variant: "h6", align: "center"}} sx={cardHeaderStyle} />
      <CardContent sx={{position: "relative", minHeight: "12rem", flexGrow: 1}}>
        <Typography variant="h6">
          ${subscriptionCharge.toFixed(2)}
          <Typography variant="subtitle2" component="span">
            /mo, billed annually
          </Typography>
        </Typography>
        <List dense>
          {subscriptionDetails.map((item, index) => (
            <ListItem
              key={index}
              disablePadding
              sx={{
                mb: 1,
                alignItems: "flex-start",
              }}>
              <ListItemIcon sx={{minWidth: "1.5rem", pt: 0.5}}>
                <Done sx={{color: "text.primary", fontSize: "1rem"}} />
              </ListItemIcon>
              <ListItemText primary={item} sx={{width: "auto"}} />
            </ListItem>
          ))}
        </List>
        {!!isCurrent && activeSubscription}
      </CardContent>
    </Card>
  );
}

SubscriptionCard.propTypes = {
  title: PropTypes.string,
  subscriptionCharge: PropTypes.number,
  subscriptionDetails: PropTypes.array,
  isCurrent: PropTypes.bool,
};
