import React, {useContext} from "react";
import {Checkbox, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {useActor} from "@xstate/react";
import MachineContext from "lib/MachineContext";

import DashboardCard from "./DashboardCard";

export default function CheckList() {
  const [state] = useActor(useContext(MachineContext));
  const {crewDocsMachineRef} = state.context;
  const {crewDocs, crewRankDocs} = crewDocsMachineRef?.state.context || [];
  const {requiredDocs} = crewDocs || {};
  const allRequiredDocs = crewRankDocs
    ?.filter(({document}) => document.docType === "STCW")
    .map(({document}) => {
      const hasDoc = requiredDocs?.items.some((item) => item.docId === document.id);
      return (
        <ListItem key={document.id} disablePadding disableGutters divider sx={{m: 0}}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={hasDoc}
              tabIndex={-1}
              disableRipple
              sx={{color: "red", "&.Mui-checked": {color: "inherit"}}}
            />
          </ListItemIcon>
          <ListItemText primary={document.title} />
        </ListItem>
      );
    });

  return (
    <DashboardCard title={"My STCW Docs CheckList"} className="tour-step-checklist">
      <List sx={{width: 1, pt: 0}}>{allRequiredDocs}</List>
    </DashboardCard>
  );
}
