import {createMachine} from "xstate";

import actions from "./actions";
import services from "./services";

const guards = {
  areCountryTemplatesNotLoaded: ({countryTemplates}) => !countryTemplates,
};

const marfinMachine = createMachine({
  id: "marfinMachine",
  predictableActionArguments: true,
  initial: "stanby",
  context: {
    isMachineWorking: false,
    bankAccounts: [],
    eWallets: [],
    eWalletProviders: [],
    countryTemplates: null,
    bankAccount: null,
  },
  on: {
    RESET_SELECTED_ACCOUNT: {actions: actions.resetSelectedAccount},
    RESET_MARFIN_DATA: {target: "stanby", actions: actions.resetAllData},
  },
  states: {
    stanby: {
      on: {
        INIT: "initialising",
      },
    },
    initialising: {
      entry: [actions.machineStartsWorking],
      exit: [actions.machineStopsWorking],
      invoke: services.initialize,
      on: {
        SUCCESS: {
          target: "idle",
          actions: [actions.updateBankAccounts, actions.updateWallets, actions.updateWalletProviders],
        },
        ERROR: {target: "stanby", actions: actions.sendError},
      },
    },
    idle: {
      on: {
        LOAD_BANK_DETAILS_COUNTRY_TEMPLATES: {
          target: "loadingCountryTemplates",
          cond: guards.areCountryTemplatesNotLoaded,
        },
        ADD_BANK_DETAILS: {target: "creatingAccount"},
        DELETE_BANK_ACCOUNT: {target: "deletingAccount"},
        SET_PRIMARY_BANK_ACCOUNT: {target: "updatingAccountPreference"},
        LOAD_BANK_ACCOUNT: {target: "loadingAccount"},
        UPDATE_BANK_ACCOUNT_BENEFICIARY: {target: "updatingAccountBeneficiary"},
        UPLOAD_BANK_CERTIFICATE: {target: "uploadingBankCertificate"},
        DELETE_BANK_ACCOUNT_CERTIFICATE: {target: "deletingBankCertificate"},
        DOWNLOAD_BANK_ACCOUNT_CERTIFICATE: {target: "downloadingBankCertificate"},
        ADD_WALLET: {target: "creatingWallet"},
        DELETE_WALLET: {target: "deletingWallet"},
        SET_PRIMARY_WALLET: {target: "updatingWalletPreference"},
      },
    },
    loadingCountryTemplates: {
      entry: [actions.machineStartsWorking],
      exit: [actions.machineStopsWorking],
      invoke: services.getCountryTemplatesList,
      on: {
        SUCCESS: {target: "idle", actions: actions.updateCountryTemplates},
        ERROR: {target: "idle", actions: actions.sendError},
      },
    },
    creatingAccount: {
      entry: [actions.machineStartsWorking],
      exit: [actions.machineStopsWorking],
      invoke: services.createAccount,
      on: {
        SUCCESS: {target: "idle", actions: actions.addBankAccount},
        ERROR: {target: "idle", actions: actions.sendError},
      },
    },
    loadingAccount: {
      entry: [actions.machineStartsWorking],
      exit: [actions.machineStopsWorking],
      invoke: services.getBankAccount,
      on: {
        SUCCESS: {target: "idle", actions: actions.updateSelectedAccount},
        ERROR: {target: "idle", actions: actions.sendError},
      },
    },
    deletingAccount: {
      entry: [actions.machineStartsWorking],
      exit: [actions.machineStopsWorking],
      invoke: services.deleteAccount,
      on: {
        SUCCESS: {target: "idle", actions: actions.deleteBankAccount},
        ERROR: {target: "idle", actions: actions.sendError},
      },
    },
    updatingAccountPreference: {
      entry: [actions.machineStartsWorking],
      exit: [actions.machineStopsWorking],
      invoke: services.setPreferredAccount,
      on: {
        SUCCESS: {target: "idle", actions: actions.updatePreferedAccount},
        ERROR: {target: "idle", actions: actions.sendError},
      },
    },
    updatingAccountBeneficiary: {
      entry: [actions.machineStartsWorking],
      exit: [actions.machineStopsWorking],
      invoke: services.updateAccountBeneficiary,
      on: {
        SUCCESS: {target: "idle", actions: actions.updateSelectedAccount},
        ERROR: {target: "idle", actions: actions.sendError},
      },
    },
    uploadingBankCertificate: {
      entry: [actions.machineStartsWorking],
      exit: [actions.machineStopsWorking],
      invoke: services.uploadBankCertificate,
      on: {
        SUCCESS: {target: "idle", actions: actions.updateSelectedAccount},
        ERROR: {target: "idle", actions: actions.sendError},
      },
    },
    downloadingBankCertificate: {
      entry: [actions.machineStartsWorking],
      exit: [actions.machineStopsWorking],
      invoke: services.downloadBankCertificate,
      on: {
        SUCCESS: {target: "idle"},
        ERROR: {target: "idle", actions: actions.sendError},
      },
    },
    deletingBankCertificate: {
      entry: [actions.machineStartsWorking],
      exit: [actions.machineStopsWorking],
      invoke: services.deleteBankCertificate,
      on: {
        SUCCESS: {target: "idle", actions: actions.updateSelectedAccount},
        ERROR: {target: "idle", actions: actions.sendError},
      },
    },
    creatingWallet: {
      entry: [actions.machineStartsWorking],
      exit: [actions.machineStopsWorking],
      invoke: services.createWallet,
      on: {
        SUCCESS: {target: "idle", actions: actions.addWallet},
        ERROR: {target: "idle", actions: actions.sendError},
      },
    },
    deletingWallet: {
      entry: [actions.machineStartsWorking],
      exit: [actions.machineStopsWorking],
      invoke: services.deleteWallet,
      on: {
        SUCCESS: {target: "idle", actions: actions.deleteWallet},
        ERROR: {target: "idle", actions: actions.sendError},
      },
    },
    updatingWalletPreference: {
      entry: [actions.machineStartsWorking],
      exit: [actions.machineStopsWorking],
      invoke: services.setPreferredWallet,
      on: {
        SUCCESS: {target: "idle", actions: actions.updatePreferedWallet},
        ERROR: {target: "idle", actions: actions.sendError},
      },
    },
  },
});

export default marfinMachine;
