import React from "react";
import {Alert, Box} from "@mui/material";

export default function PaySlips() {
  return (
    <Box sx={{width: 1, p: 3}}>
      <Alert severity="info">
        We are working hard to bring you new and exciting content! This page is currently under construction and will be
        available soon. Thank you for your patience and understanding.
      </Alert>
    </Box>
  );
}
