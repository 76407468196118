import React from "react";
import {Grid, Typography} from "@mui/material";
import {FormInputText} from "lib/components";
import PropTypes from "prop-types";

export default function EmergencyContact({control, disabled}) {
  const namesPattern = /^[A-Za-z .-]{1,64}$/;
  const namesPatternMessage = "Only latin letters and .- are allowed";
  const addressPattern = /^[\w .,-/]{1,64}$/;
  const addressPatternMessage = "Only alphanumeric characters and .,-/ are allowed";

  const rules = {
    emerContactName: {
      required: "Emergency Contact full name is required",
      pattern: {value: namesPattern, message: namesPatternMessage},
    },
    emerContactRelation: {
      required: "Emergency Contact relationship is required",
      pattern: {value: namesPattern, message: namesPatternMessage},
    },
    emerContactPhone: {
      required: "Nearest Airport is required",
      pattern: {value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-0-9]{4,20}$/, message: "Phone number is invalid"},
    },
    emerContactEmail: {
      pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, message: "Email is invalid"},
    },
    emerContactAddress: {
      required: "Nearest Airport is required",
      pattern: {value: addressPattern, message: addressPatternMessage},
    },
  };

  return (
    <Grid container spacing={{xs: 2, sm: 3}} mb={3}>
      <Grid item xs={12}>
        <Typography variant="button">Emergency Contact Details</Typography>
      </Grid>

      <Grid item xs={12} sm={5}>
        <FormInputText
          name={"emerContactName"}
          control={control}
          label={"Full name"}
          fullWidth
          rules={rules.emerContactName}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormInputText
          name={"emerContactRelation"}
          control={control}
          label={"Relationship"}
          fullWidth
          rules={rules.emerContactRelation}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInputText
          name={"emerContactPhone"}
          control={control}
          label={"Phone number"}
          fullWidth
          rules={rules.emerContactPhone}
          type="tel"
          disabled={disabled}
          placeholder="+123-45-678-98-76"
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <FormInputText
          name={"emerContactEmail"}
          control={control}
          label={"Email"}
          fullWidth
          rules={rules.emerContactEmail}
          type="email"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={7}>
        <FormInputText
          name={"emerContactAddress"}
          control={control}
          label={"Address"}
          fullWidth
          rules={rules.emerContactAddress}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

EmergencyContact.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
};
