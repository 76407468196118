import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Divider, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {AuthLink} from "lib/components";
import MachineContext from "lib/MachineContext";

import DashboardCard from "./DashboardCard";

export default function ProfileNotes() {
  const [state, send] = useActor(useContext(MachineContext));
  const {profileMachineRef} = state.context;
  const {state: profileState} = profileMachineRef; // TODO: refactor with useMemo
  const {crewProfile} = profileState.context;
  const [profileNote, setProfileNote] = useState("");

  useEffect(() => {
    const onRequestProfileSetup = () => send("requestProfileSetup");

    const detailsMissing = (part) => (
      <>
        Add your {part} Deatils to <AuthLink to="/profile">Profile</AuthLink>
      </>
    );
    const noProfileNotes = (
      <Box>
        <Typography gutterBottom>
          Start with setting up your <AuthLink to="/profile"> Profile</AuthLink>
        </Typography>
        <Divider />
        <Button variant="outlined" fullWidth sx={{mt: 3, mb: 2}} onClick={onRequestProfileSetup}>
          Request Profile Setup
        </Button>
        <Typography>for as low as $15.</Typography>
      </Box>
    );
    const profileMessage = !crewProfile ? (
      noProfileNotes
    ) : !crewProfile.firstName ? (
      detailsMissing("Personal")
    ) : !crewProfile.bloodGroup ? (
      detailsMissing("Biometrical")
    ) : !crewProfile.englishLangLevel ? (
      detailsMissing("Additional")
    ) : (
      <Typography>
        Your <AuthLink to="/profile"> Profile</AuthLink> is 100% Complete!
      </Typography>
    );

    setProfileNote(profileMessage);
  }, [crewProfile, send]);

  return (
    <DashboardCard title={"My Profile"} className="tour-step-profile-notifications">
      {profileNote}
    </DashboardCard>
  );
}
