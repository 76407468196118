import axios from "axios";

const endpoint = "https://w3vvdzvh4f.execute-api.ap-south-1.amazonaws.com/default/crewbookContactUsSES-dev";

export async function sendContactUsRequest(messageData) {
  const {userName, userEmail, subject, message} = messageData;
  const body = {senderName: userName, senderEmail: userEmail, subject, message};

  await axios({method: "post", url: endpoint, data: body});

  const successMessage =
    "Your message was sent succesfully! The response to your request will be provided within 24 hours.";

  return {type: "success", message: successMessage};
}

export async function sendRequestProfileSetup(userEmail) {
  const subject = "Request profile setup";
  const message = `User email: ${userEmail} is requesting profile setup service.`;
  const body = {senderName: "New user", senderEmail: userEmail, subject, message};

  await axios({method: "post", url: endpoint, data: body});

  const successMessage = `Your account setup request was sent succesfully!
  Our Watchkeeper will contact you by email to initiate CrewBook account setup within 24hours`;

  return {type: "success", message: successMessage};
}
