import marfin from "marfin-exports";
import {cacheExchange, createClient, fetchExchange} from "urql";

import getErrorMessage from "../error";
import {Is} from "../is";

import cognitoAuthExchange from "./cognitoAuthExchange";

const DEFAULT_ERROR = "Something went wrong! Try again or contact us for technical support";

const QueryType = Object.freeze({
  QUERY: "QUERY",
  MUTATION: "MUTATION",
});

class UrqlClient {
  static _instanceCache;
  static get instance() {
    if (!this._instanceCache) {
      this._instanceCache = new this().client;
    }

    return this._instanceCache;
  }

  static async execute(type, query, queryName, variables, options = {}) {
    if (type !== QueryType.QUERY && type !== QueryType.MUTATION) {
      return {
        error: {message: `Unable to execute request:${queryName}! Please try again or contact us for technical suppor`},
      };
    }

    return (
      type === QueryType.QUERY
        ? UrqlClient.instance.query(query, variables, options)
        : UrqlClient.instance.mutation(query, variables)
    )
      .toPromise()
      .then((response) => {
        const errorMessage = response.error
          ? getErrorMessage({
              errors: [...(response.error.graphQLErrors ?? []), response.error.networkError].filter(Is.defined),
            })
          : response.data[queryName]?.message;

        return errorMessage || !response.data[queryName]?.data
          ? {error: {message: errorMessage ?? DEFAULT_ERROR}}
          : {data: response.data[queryName]?.data};
      })
      .catch((error) => {
        return {error};
      });
  }

  static reset() {
    this._instanceCache = null;
  }

  client;

  constructor() {
    this.client = createClient({
      url: marfin.graphqlEndpoint.prod,
      exchanges: [cacheExchange, cognitoAuthExchange, fetchExchange],
    });
  }

  invoke() {}
}

export default UrqlClient;
export {DEFAULT_ERROR, QueryType};
