import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Delete} from "@mui/icons-material";
import {Box, Chip, Fab, Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {AgGridReact} from "ag-grid-react";
import {WALLET_ID_FIELD_NAME, WALLET_PROVIDER_ID_FIELD_NAME, WalletPreference} from "lib/data";
import {confirmDialog} from "lib/helpers";
import MachineContext from "lib/MachineContext";
import {getWalletId} from "machine/serviceMachines/marfinMachineSet/eWallet";

import AddWalletDialog from "components/finance/wallets/AddWalletDialog";

export default function EWallets() {
  const service = useContext(MachineContext);
  const [state, send] = useActor(service);
  const gridRef = useRef(null);

  const [isGridApiReady, setIsGridApiReady] = useState(false);

  const crewProfile = useMemo(
    () => state.context?.profileMachineRef?.state?.context.crewProfile,
    [state.context?.profileMachineRef?.state?.context.crewProfile]
  );
  const eWallets = useMemo(
    () => state.context.marfinMachineRef?.state?.context.eWallets,
    [state.context.marfinMachineRef?.state?.context.eWallets]
  );
  const defaultColDef = useMemo(
    () => ({
      editable: false,
      filter: false,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      autoHeight: true,
    }),
    []
  );

  const onDeleteRowClicked = useCallback(
    async (data) => {
      const isPrimary = data.preference === WalletPreference.Primary;

      if (isPrimary && eWallets.length > 1) {
        const message =
          "Before deleting this Primary E-Wallet, please set a New Primary E-Wallet to maintain uninterrupted service.";
        send({type: "alert", data: {type: "error", message}});
        return;
      }

      const content = (
        <>
          <Typography gutterBottom>
            {"You are about to "} <strong>{"Delete"}</strong>
          </Typography>
          <Typography>
            {"E-Wallet ID: "} <strong>{data[WALLET_ID_FIELD_NAME]?.toUpperCase()}</strong>
          </Typography>
          <Typography gutterBottom>
            {"Provider: "} <strong>{data.provider.name}</strong>
          </Typography>
          <Typography>This process cannot be undone.</Typography>
        </>
      );
      const result = await confirmDialog({send, service, setup: {content, consent: true}});

      if (result === 1) {
        send({
          type: "DELETE_WALLET",
          data: {
            [WALLET_PROVIDER_ID_FIELD_NAME]: data.provider.id,
            [WALLET_ID_FIELD_NAME]: data[WALLET_ID_FIELD_NAME],
          },
        });
      }
    },
    [eWallets, send, service]
  );

  const onSetAsPrimaryClicked = useCallback(
    async (data) => {
      const eWalletID = data[WALLET_ID_FIELD_NAME].toUpperCase();
      const content = (
        <>
          <Typography gutterBottom>
            {"You are about to set new "} <strong>Primary E-Wallet</strong>:
          </Typography>
          <Typography>
            {"eWallet ID: "} <strong>{eWalletID}</strong>
          </Typography>
          <Typography>
            {"provider: "} <strong>{data.provider.name}</strong>
          </Typography>
        </>
      );
      const result = await confirmDialog({send, service, setup: {content, consent: true}});

      if (result === 1) {
        send({
          type: "SET_PRIMARY_WALLET",
          data: {
            [WALLET_PROVIDER_ID_FIELD_NAME]: data.provider.id,
            [WALLET_ID_FIELD_NAME]: data[WALLET_ID_FIELD_NAME],
          },
        });
      }
    },
    [send, service]
  );

  const columns = useMemo(
    () =>
      [
        {
          field: "walletId",
          headerName: "eWallet ID",
          flex: 1,
          valueFormatter: ({value}) => value.toUpperCase(),
        },
        {
          field: "provider.name",
          headerName: "Service Provider",
          flex: 1,
        },
        {
          field: "preference",
          colId: "preference",
          headerName: "Preference",
          width: 105,
          cellRenderer: ({data, value}) => {
            return value === WalletPreference.Primary ? (
              <Chip label="Primary" size="small" color="primary" />
            ) : (
              <Tooltip title="Set as Primary Wallet">
                <Chip
                  label="Set Primary"
                  size="small"
                  color="primary"
                  variant="outlined"
                  clickable
                  onClick={() => onSetAsPrimaryClicked(data)}
                />
              </Tooltip>
            );
          },
        },
        {
          colId: "actions",
          headerName: "",
          width: 40,
          cellRenderer: ({data}) => {
            return (
              <Tooltip title="Delete wallet">
                <IconButton color="error" onClick={() => onDeleteRowClicked(data)} sx={{p: 0}}>
                  <Delete />
                </IconButton>
              </Tooltip>
            );
          },
        },
      ].filter((item) => !!item),
    [onDeleteRowClicked, onSetAsPrimaryClicked]
  );

  const [columnDefs] = useState([]);
  const [rowData, setRowData] = useState();
  const [isAddNewWalletOpen, setIsAddNewWalletOpen] = useState(false);
  const getRowId = useMemo(() => {
    return ({data}) => data.id;
  }, []);

  const onGridReady = useCallback(() => setIsGridApiReady(true), []);

  const onAddEWallet = useCallback(() => setIsAddNewWalletOpen(true), []);
  const onCloseClicked = useCallback(() => setIsAddNewWalletOpen(false), []);

  const onModelUpdated = useCallback(({columnApi}) => {
    columnApi.autoSizeColumns(["beneficiary", "nickname", "accountNumber", "swiftCode"], false);
  }, []);

  useEffect(() => {
    setRowData(eWallets.map((item) => ({...item, id: getWalletId(item)})));
  }, [eWallets, crewProfile]);

  useEffect(() => {
    isGridApiReady && gridRef.current.api?.setColumnDefs(columns);
  }, [isGridApiReady, columns]);

  return (
    <Box sx={{display: "flex", height: 1, flexDirection: "column"}}>
      <Box sx={{width: 1}}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Fab
              color="primary"
              aria-label="add document"
              variant="extended"
              size="medium"
              onClick={onAddEWallet}
              sx={{mb: 2}}>
              Add E-Wallet
            </Fab>
          </Grid>
        </Grid>
        {isAddNewWalletOpen && <AddWalletDialog isOpen={true} onClose={onCloseClicked} eWallets={eWallets} />}
      </Box>
      <Box sx={{flexGrow: 1, pb: 1}} className="ag-theme-material ag-theme-material-light-blue" id="wallets">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          suppressLoadingOverlay={true}
          suppressCellFocus={true}
          onGridReady={onGridReady}
          onModelUpdated={onModelUpdated}
          getRowId={getRowId}
          context={{
            onDeleteRowClicked,
            onSetAsPrimaryClicked,
          }}
        />
      </Box>
    </Box>
  );
}
