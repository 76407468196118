import React from "react";
import {Card, CardContent, CardHeader, CardMedia} from "@mui/material";
import {blue} from "@mui/material/colors";
import {makeStyles} from "@mui/styles";
import PropTypes from "prop-types";

const imgStyle = makeStyles({
  img: {
    position: "absolute",
    bottom: "0.5rem",
    right: "0.5rem",
    width: "auto",
    maxHeight: "50%",
    maxWidth: "50%",
    filter: "opacity(0.08)",
  },
});

export default function DashboardCard({children, title, className, imgSrc, imgAlt}) {
  const classes = imgStyle();

  return (
    <Card className={className} sx={{overflow: "auto", flexGrow: 1, display: " flex", flexDirection: "column"}}>
      <CardHeader title={title} titleTypographyProps={{variant: "button"}} sx={{bgcolor: blue[50]}} />
      <CardContent sx={{position: "relative", minHeight: "12rem", flexGrow: 1, bgcolor: "#F9FCFF"}}>
        {!!imgSrc && <CardMedia component="img" alt={imgAlt} src={imgSrc} className={classes.img} />}
        {children}
      </CardContent>
    </Card>
  );
}

DashboardCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  imgAlt: PropTypes.string,
};
