import {API, graphqlOperation, Storage} from "aws-amplify";
import {saveAs} from "file-saver";
import {getEmploymentDetails, listShipCrewEmployments} from "graphql/customQueries";
import {updateShipCrewEmployment} from "graphql/mutations";
import {getEmployerCrewManager, getShortEmploymentDetails, listEmploymentEventInfo} from "graphql/queries";
import skipEmptyProps from "lib/helpers/skipEmptyProps";

import {addEmployment, addEmploymentEvent, clearEmploymentUpdates, saveSignedDocument} from "./helpers";

const services = {
  employerFinder: async (_, {data: email}) => {
    const response = await API.graphql(graphqlOperation(getEmployerCrewManager, {email}));
    const {getEmployerCrewManager: employerUser} = response.data;
    if (employerUser.error) {
      throw new Error(employerUser.error);
    }
    return {employerUser};
  },
  employmentsLoader: async () => {
    const employmentsResponse = await API.graphql(graphqlOperation(listShipCrewEmployments));
    const {listShipCrewEmployments: employments} = employmentsResponse.data;
    const eventInfosResponse = await API.graphql(graphqlOperation(listEmploymentEventInfo));
    const {listEmploymentEventInfo: list} = eventInfosResponse.data;
    const {items} = JSON.parse(list);
    const employmentActions = items.reduce((result, item) => {
      const {action, actionTitle, description} = item;
      result[action] = {description, actionTitle};
      return result;
    }, {});
    return {employments: employments.items, employmentActions};
  },
  employmentDetailsLoader: async ({employmentUpdates}, {data: employmentId}) => {
    const response = await API.graphql(graphqlOperation(getEmploymentDetails, {id: employmentId}));
    const {getEmploymentDetails: employment} = response.data;
    employmentUpdates.has(employmentId) && (await clearEmploymentUpdates(employmentId));
    return {updatedEmployment: employment};
  },
  loadNewEmploymentData: async (_, {data: employmentId}) => {
    const response = await API.graphql(graphqlOperation(getShortEmploymentDetails, {id: employmentId}));
    const {getShortEmploymentDetails: newEmployment} = response.data;
    return {newEmployment};
  },
  processEmploymentUpdates: async ({unprocessedEvents, employmentUpdates, employments}) => {
    const unprocessedUpdates = unprocessedEvents.filter((item) => item.type === "employmentUpdated");

    if (unprocessedUpdates.length === 0) return {unprocessedEvents, employmentUpdates};

    const {NEW_UPDATES, NEW_EMPLOYMENTS} = await unprocessedUpdates.reduce(async (result, {data: employmentId}) => {
      const existing = employments.some((item) => item.id === employmentId);
      let newEmployment = null;
      if (!existing) {
        const response = await API.graphql(graphqlOperation(getShortEmploymentDetails, {id: employmentId}));
        newEmployment = response.data.getShortEmploymentDetails;
      }
      const {NEW_UPDATES, NEW_EMPLOYMENTS} = await result;
      NEW_UPDATES.add(employmentId);
      !!newEmployment && NEW_EMPLOYMENTS.push(newEmployment);
      return {NEW_UPDATES, NEW_EMPLOYMENTS};
    }, Promise.resolve({NEW_UPDATES: new Set(employmentUpdates), NEW_EMPLOYMENTS: []}));

    return {
      unprocessedEvents: unprocessedEvents.filter((item) => item.type !== "employmentUpdated"),
      employmentUpdates: NEW_UPDATES,
      newEmployments: NEW_EMPLOYMENTS,
    };
  },
  eventSender: async (context, {data}) => {
    const {type, memo, employmentId, userData} = data;
    const {employments, employerUser} = context;
    const currentEmployment = employmentId
      ? employments.find((item) => item.id === employmentId)
      : employments.find((item) => item.tenantId === employerUser?.tenantId);
    const {tenantId} = employerUser || currentEmployment;
    const {firstName, lastName, signatureHash} = userData;
    const senderName = `${firstName} ${lastName}`;
    let input = {senderName, memo, type, tenantId, employmentId};

    switch (type) {
      case "SHARE_ACCOUNT": {
        const {employmentStatus, employerName} = currentEmployment || {};
        if (!!currentEmployment && !["UNEMPLOYED_REQUESTED", "WITHDRAWN"].includes(employmentStatus.status)) {
          const message = `Your have already Shared your Account with ${employerName}.
          Find it in the requests section below to send a message.`;
          throw new Error(message);
        }
        const employment = currentEmployment || (await addEmployment({tenantId}));
        input = {...input, employmentId: employment.id};
        break;
      }
      case "ACCEPT_OFFER":
      case "ACCEPT_CONTRACT": {
        const user = {firstName, lastName, hashNumber: signatureHash};
        const fileObj = await saveSignedDocument({employments, employmentId, type, user});
        input = {...input, file: fileObj};
        break;
      }
      default:
        break;
    }

    const newEvent = await addEmploymentEvent(input);
    const message =
      type === "INQUIRY"
        ? "Your Request was sent successfully"
        : "Your request is processing. Employment Status will be updated shortly.";
    const employmentEvents = !currentEmployment
      ? null
      : {
          items: [...currentEmployment.employmentEvents.items, newEvent],
          nextToken: currentEmployment.employmentEvents.nextToken,
        };
    const updatedEmployment = !currentEmployment ? null : {...currentEmployment, employmentEvents};
    return {alert: {type: "success", message}, updatedEmployment};
  },
  downloadFile: async (_, {data: {file, employerName}}) => {
    const fileName = file.key.split("/").pop();
    const {Body} = await Storage.get(file.key, {download: true, level: "protected"});
    saveAs(Body, `${employerName}_${fileName}`);
    return;
  },
  updateEmployment: async (_, {data: {employmentIds, salaryPaymentMethod}}) => {
    const updateTasks = employmentIds.map(async (employmentId) => {
      const response = await API.graphql(
        graphqlOperation(updateShipCrewEmployment, {input: {employmentId, salaryPaymentMethod}})
      );
      return skipEmptyProps(response.data.updateShipCrewEmployment);
    });
    return await Promise.all(updateTasks);
  },
};

export default services;
