import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Close, KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  MobileStepper,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useActor} from "@xstate/react";
import {formatISO} from "date-fns";
import {MachineContext} from "lib";
import {
  ACCOUNT_NUMBER_FIELD_NAME,
  BIRTH_DATE_FIELD_NAME,
  COUNTRY_CODE_FIELD_NAME,
  COUNTRY_NAME_FIELD_NAME,
  SWIFT_CODE_FIELD_NAME,
} from "lib/data";
import {sortBy} from "lib/helpers";
import {Is} from "lib/helpers/is";
import PropTypes from "prop-types";

import BeneficiaryAccountDetailsForm from "./BeneficiaryAccountDetailsForm";
import BeneficiaryDetailsForm from "./BeneficiaryDetailsForm";
import ConfirmBankAccountDetailsForm from "./ConfirmBankAccountDetailsForm";

export default function AddBankAccountStepper({isOpen, onClose}) {
  const theme = useTheme();
  const mdUpScreenMatches = useMediaQuery(theme.breakpoints.up("md"));
  const service = useContext(MachineContext);
  const [state, send] = useActor(service);
  const [activeStep, setActiveStep] = useState(0);

  const [accountDetails, setAccountDetails] = useState(null);
  const [isAccountDetailsValid, setIsAccountDetailsValid] = useState(false);

  const [beneficiary, setBeneficiary] = useState(null);
  const [isBeneficiaryValid, setIsBeneficiaryValid] = useState(false);

  const countryTemplates = useMemo(
    () => state.context.marfinMachineRef?.state.context.countryTemplates ?? [],
    [state.context.marfinMachineRef?.state.context.countryTemplates]
  );
  const bankAccounts = useMemo(
    () => state.context.marfinMachineRef?.state?.context.bankAccounts,
    [state.context.marfinMachineRef?.state?.context]
  );

  const countriesOptions = useMemo(() => {
    return countryTemplates.sort(sortBy("countryName")).map(({countryCode, countryName}) => {
      return (
        <MenuItem key={countryCode} value={countryCode}>
          {countryName}
        </MenuItem>
      );
    });
  }, [countryTemplates]);

  const canContinue = useMemo(() => {
    return activeStep === 0 ? isAccountDetailsValid : activeStep === 1 ? isBeneficiaryValid : true;
  }, [activeStep, isBeneficiaryValid, isAccountDetailsValid]);

  const steps = useMemo(
    () => [
      {
        label: "Beneficiary Bank Account",
        canContinue: canContinue,
        component: (
          <BeneficiaryAccountDetailsForm
            countryTemplates={countryTemplates}
            countriesOptions={countriesOptions}
            setAccountDetails={setAccountDetails}
            setIsAccountDetailsValid={setIsAccountDetailsValid}
            savedAccountDetails={accountDetails}
          />
        ),
      },
      {
        label: "Beneficiary Address",
        canContinue: canContinue,
        component: (
          <BeneficiaryDetailsForm
            countryTemplates={countryTemplates}
            countriesOptions={countriesOptions}
            setBeneficiaryValue={setBeneficiary}
            setBeneficiaryIsValid={setIsBeneficiaryValid}
            savedBeneficiaryDetails={beneficiary}
            accounts={bankAccounts}
          />
        ),
      },
      {
        label: "Confirm",
        canContinue: canContinue,
        component: <ConfirmBankAccountDetailsForm accountDetails={accountDetails} beneficiary={beneficiary} />,
      },
    ],
    [accountDetails, bankAccounts, beneficiary, canContinue, countriesOptions, countryTemplates]
  );
  const maxSteps = useMemo(() => steps.length, [steps]);
  const isLastStep = useMemo(() => activeStep === maxSteps - 1, [activeStep, maxSteps]);
  const nextBtnLabel = !isLastStep ? "Next" : "Add Bank Account";
  const stepperLabels = useMemo(
    () =>
      steps.map(({label}, index) => (
        <Step key={index}>
          <StepLabel>{mdUpScreenMatches || activeStep === index ? label : "..."}</StepLabel>
        </Step>
      )),
    [activeStep, mdUpScreenMatches, steps]
  );
  const handleNext = useCallback(() => setActiveStep((prevActiveStep) => prevActiveStep + 1), []);
  const handleBack = useCallback(() => setActiveStep((prevActiveStep) => prevActiveStep - 1), []);

  const handleClose = useCallback(() => {
    setActiveStep(0);
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(() => {
    const _swiftCode = accountDetails.bankDetails?.bank.bic;
    const _accountNumber =
      accountDetails.accountDetails[ACCOUNT_NUMBER_FIELD_NAME] ||
      accountDetails.bankDetails?.validation?.account_number;
    const _accountDetails = {
      ...accountDetails.accountTemplate.reduce((res, item) => ({...res, [item.name]: ""}), {}),
      ...accountDetails.accountDetails,
      [SWIFT_CODE_FIELD_NAME]: _swiftCode,
      [ACCOUNT_NUMBER_FIELD_NAME]: _accountNumber,
    };
    const _bank = accountDetails.bankDetails?.bank;
    const _beneficiaryDetails = Object.entries(beneficiary.beneficiaryDetails).reduce((acc, [key, value]) => {
      acc[key] = Is.defined(value) ? value : "";
      return acc;
    }, {});
    const _beneficiary = {
      ..._beneficiaryDetails,
      [BIRTH_DATE_FIELD_NAME]: beneficiary.beneficiaryDetails.BIRTH_DATE_FIELD_NAME
        ? formatISO(beneficiary.beneficiaryDetails.BIRTH_DATE_FIELD_NAME)
        : "",
      [COUNTRY_CODE_FIELD_NAME]: beneficiary.beneficiaryCountry?.code?.toUpperCase(),
      [COUNTRY_NAME_FIELD_NAME]: beneficiary.beneficiaryCountry?.name?.toUpperCase(),
    };
    const bankDetails = {
      ..._accountDetails,
      beneficiary: JSON.stringify(_beneficiary),
      bank: JSON.stringify(_bank),
    };

    send({type: "ADD_BANK_DETAILS", data: bankDetails});
    onClose();
  }, [accountDetails, beneficiary, onClose, send]);

  useEffect(() => {
    isOpen && countryTemplates.length == 0 && send("LOAD_BANK_DETAILS_COUNTRY_TEMPLATES");
  }, [countryTemplates, isOpen, send]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      fullWidth
      maxWidth="md"
      fullScreen={!mdUpScreenMatches}>
      <DialogTitle sx={{p: 1.5}}>
        New Bank Account Details
        <IconButton
          onClick={handleClose}
          sx={{position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
          <Close />
        </IconButton>
      </DialogTitle>

      <Stepper activeStep={activeStep}>{stepperLabels}</Stepper>

      <DialogContent sx={{p: 1.5, display: "flex", flexDirection: "column", minHeight: "15rem"}}>
        <Box sx={{flexGrow: 1, mt: 1, mb: 2}}>{steps[activeStep].component}</Box>
      </DialogContent>
      <MobileStepper
        variant="progress"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            color={isLastStep ? "success" : "inherit"}
            onClick={!isLastStep ? handleNext : onSubmit}
            data-confirm={1}
            disabled={!steps[activeStep].canContinue}
            endIcon={isLastStep ? null : <KeyboardArrowRight />}>
            {nextBtnLabel}
          </Button>
        }
        backButton={
          activeStep === 0 ? (
            <Button color={"error"} onClick={handleClose}>
              {"Cancel"}
            </Button>
          ) : (
            <Button onClick={handleBack} startIcon={<KeyboardArrowLeft />}>
              {"Back"}
            </Button>
          )
        }
        sx={{bgcolor: "background.stepper", color: "primary.main", fontWeight: 500}}
      />
    </Dialog>
  );
}

AddBankAccountStepper.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
