import {API, Auth, graphqlOperation} from "aws-amplify";

import {listNotifications} from "../graphql/queries";

import {
  blockUserAccount,
  generateCV,
  getCrewData,
  loadAccountData,
  loadSubscriptions,
  sendContactUsRequest,
  sendRequestProfileSetup,
} from "./machineServices/";

const machineServices = {
  subscriptionsLoader: ({user}) => loadSubscriptions(user?.attributes),
  authChecker: () => Auth.currentAuthenticatedUser(),
  accountStatusLoader: ({user}) => loadAccountData(user.attributes.sub),
  crewDataLoader: ({user}) => getCrewData(user.attributes.sub, user.attributes["custom:createdAt"]),
  blockAccount: ({user}, {data}) => blockUserAccount({owner: user.attributes.sub, reason: data}),
  contactUsRequestSender: (_, {data}) => sendContactUsRequest(data),
  sendRequestProfileSetup: ({user}) => sendRequestProfileSetup(user.attributes.email),
  loadAccountNotifications: async () => {
    const response = await API.graphql(graphqlOperation(listNotifications));
    const {
      data: {
        listNotifications: {items: notifications},
      },
    } = response;
    return {notifications};
  },
  cvGenerator: (context, {type}) => {
    const {user, profileMachineRef, crewDocsMachineRef, crewLogsMachineRef} = context;
    const {email, phone_number: phone} = user.attributes;
    const {crewProfile, profileImgKey} = profileMachineRef.state.context;
    const {crewDocs} = crewDocsMachineRef.state.context;
    const {crewSeaLogs} = crewLogsMachineRef.state.context;
    const fullCrewProfile = {...crewProfile, email, phone};
    return generateCV({type, crewProfile: fullCrewProfile, crewSeaLogs, crewDocs, profileImgKey});
  },
};

export default machineServices;
