import {assign, sendParent} from "xstate";

import {getTotalDocsVolume} from "./services";

const actions = {
  updateDocs: assign({
    crewDocs: ({crewDocs = {}}, {data: {newDoc, isNew, newArchiveDoc}}) => {
      const isRequired = !!newDoc.docId && newDoc.docId !== "";
      const requiredDocs = crewDocs?.requiredDocs?.items || [];
      const personalDocs = crewDocs?.personalDocs?.items || [];
      const newRequiredDocs = !isRequired
        ? requiredDocs
        : isNew
        ? [...requiredDocs, newDoc]
        : requiredDocs.map((crewDoc) => (crewDoc.id === newDoc.id ? newDoc : crewDoc));
      const newPersonalDocs = newArchiveDoc
        ? [...personalDocs, newArchiveDoc]
        : !isRequired
        ? [...personalDocs, newDoc]
        : personalDocs;
      const totalDocsVolume = getTotalDocsVolume([...newRequiredDocs, ...newPersonalDocs]);
      return {
        requiredDocs: {items: newRequiredDocs},
        personalDocs: {items: newPersonalDocs},
        totalDocsVolume,
      };
    },
  }),
  updateCrewRankDocs: assign({
    crewRankDocs: (_, {data: {newCrewRanksDocs}}) => newCrewRanksDocs,
  }),
  removeDoc: assign({
    crewDocs: ({crewDocs = {}}, {data: {removedDoc}}) => {
      const isRequired = !!removedDoc.docId && removedDoc.docId !== "";
      const requiredDocs = crewDocs?.requiredDocs?.items || [];
      const personalDocs = crewDocs?.personalDocs?.items || [];
      const newRequiredDocs = !isRequired ? requiredDocs : requiredDocs.filter((item) => item.id !== removedDoc.id);
      const newPersonalDocs = !isRequired ? personalDocs.filter((item) => item.id !== removedDoc.id) : personalDocs;
      const totalDocsVolume = getTotalDocsVolume([...newRequiredDocs, ...newPersonalDocs]);
      return {
        requiredDocs: {items: newRequiredDocs},
        personalDocs: {items: newPersonalDocs},
        totalDocsVolume,
      };
    },
  }),
  updateDoc: assign({
    crewDocs: ({crewDocs = {}}, {data: {updatedDoc, isRequired}}) => {
      const requiredDocs = crewDocs?.requiredDocs?.items || [];
      const personalDocs = crewDocs?.personalDocs?.items || [];
      const newRequiredDocs = !isRequired
        ? requiredDocs
        : requiredDocs.map((item) => (item.id === updatedDoc.id ? updatedDoc : item));
      const newPersonalDocs = isRequired
        ? personalDocs
        : personalDocs.map((item) => (item.id === updatedDoc.id ? updatedDoc : item));
      const totalDocsVolume = getTotalDocsVolume([...newRequiredDocs, ...newPersonalDocs]);

      return {
        requiredDocs: {items: newRequiredDocs},
        personalDocs: {items: newPersonalDocs},
        totalDocsVolume,
      };
    },
  }),
  addDocDescriptions: assign({
    crewRankDocs: ({crewRankDocs}, {data: {docId, descriptions, nextToken}}) =>
      crewRankDocs.map((item) =>
        item.document.id !== docId
          ? item
          : {
              document: {
                ...item.document,
                descriptions: {items: [...item.document.descriptions.items, ...descriptions]},
                nextToken,
              },
            }
      ),
  }),
  sendUploaded: sendParent((_, {data: {newDoc}}) => {
    const message = `Document "${newDoc?.title || newDoc?.doc.title}" uploaded successfully!`;
    return {type: `alert`, data: {type: "success", message}};
  }),
  sendProfileImgUploaded: sendParent((_, {data: {profileImgKey}}) => ({
    type: "updateProfileImg",
    data: {profileImgKey},
  })),
  sendDownloaded: sendParent(() => ({
    type: `alert`,
    data: {
      type: "success",
      message: `Download finished. Check your Download folder.`,
    },
  })),
  sendDeleted: sendParent((_, {data: {removedDoc}}) => {
    const message = `Document "${removedDoc?.title || removedDoc?.doc.title}" deleted successfully!`;
    return {type: `alert`, data: {type: "success", message}};
  }),
  sendProfileImgDeleted: sendParent({type: "deleteProfileImg"}),
  sendError: sendParent((_, {data}) => ({type: "updateError", data: data})),
  machineStartsWorking: assign({isMachineWorking: () => true}),
  machineStopsWorking: assign({isMachineWorking: () => false}),
  logger: (context, event, {state}) => console.log("DOCS MACHINE EVENT", state.value, event, context),
};

export default actions;
