import React from "react";
import {Grid, MenuItem} from "@mui/material";
import {FormInputSelect, FormInputText} from "lib/components";
import {COUNTRIES} from "lib/data";
import PropTypes from "prop-types";

export default function AddressForm({control, disabled, hideNearestAirport}) {
  const addressPattern = /^[\w .,-/]{1,120}$/;
  const addressPatternMessage = "Only alphanumeric characters and .,-/ are allowed";

  const rules = {
    address: {required: "Address is required", pattern: {value: addressPattern, message: addressPatternMessage}},
    city: {required: "City is required", pattern: {value: addressPattern, message: addressPatternMessage}},
    state: {pattern: {value: addressPattern, message: addressPatternMessage}},
    country: {required: "Country is required"},
    nearestAirport: {
      required: hideNearestAirport ? false : "Nearest Airport is required",
      pattern: {value: /^[-\w\s]{1,120}$/, message: "Only alphanumeric characters are allowed"},
    },
  };

  return (
    <Grid container spacing={{xs: 2, sm: 3}} mb={3}>
      <Grid item xs={12} sm={8}>
        <FormInputText
          name={"address"}
          control={control}
          label={"Address"}
          fullWidth
          rules={rules.address}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormInputText
          name={"city"}
          control={control}
          label={"City"}
          fullWidth
          rules={rules.city}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} sm={hideNearestAirport ? 7 : 4}>
        <FormInputText
          name={"state"}
          control={control}
          label={"State / Province"}
          fullWidth
          rules={rules.state}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} sm={5}>
        <FormInputSelect
          name={"country"}
          control={control}
          label={"Country"}
          fullWidth
          rules={rules.country}
          disabled={disabled}
          options={
            COUNTRIES.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            )) || []
          }
        />
      </Grid>

      <Grid item xs={12} sm={3} hidden={hideNearestAirport}>
        <FormInputText
          name={"nearestAirport"}
          control={control}
          label={"Nearest Airport"}
          fullWidth
          rules={rules.nearestAirport}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

AddressForm.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
  hideNearestAirport: PropTypes.bool,
};

AddressForm.defaultProps = {
  hideNearestAirport: false,
};
