import {getBankAccount, getCountryTemplate} from "graphqlMarfin/queries";
import {UrqlClient} from "lib/helpers";
import {QueryType} from "lib/helpers/urqlClient/urqlClient";
import {v5 as uuidv5} from "uuid";

export function getBankAccountId(accountDetails, profileId) {
  return accountDetails.accountNumber
    ? uuidv5(`${accountDetails.accountNumber}-${accountDetails.swiftCode}`, profileId)
    : undefined;
}

export async function getCountryTemplates(countries) {
  try {
    const countryTemplatesRequests = countries.map((countryCode) =>
      UrqlClient.execute(QueryType.QUERY, getCountryTemplate, "getCountryTemplate", {countryCode})
    );
    const countryTemplates = await Promise.all(countryTemplatesRequests);
    const error = countryTemplates.find((item) => !!item.error)?.error;

    if (error) {
      return {error};
    }
    return {countryTemplates: countryTemplates.map((item) => item.data)};
  } catch (error) {
    return {error};
  }
}

export function matchAccount(account1, account2) {
  return account1.accountNumber === account2.accountNumber && account1.swiftCode === account2.swiftCode;
}

export function pollGetBankAccountApi(accountNumber, swiftCode, interval, condition) {
  return new Promise((resolve, reject) => {
    function attempt() {
      UrqlClient.execute(
        QueryType.QUERY,
        getBankAccount,
        "account",
        {accountNumber, swiftCode},
        {requestPolicy: "cache-and-network"}
      )
        .then((response) => {
          if (condition(response)) {
            resolve(response);
          } else {
            setTimeout(attempt, interval / 2);
          }
        })
        .catch(reject);
    }
    setTimeout(attempt, interval);
  });
}
