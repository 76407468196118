import React from "react";

const personalDocsCols = (onIncludedInCVChanged) => [
  {
    field: "title",
    type: "string",
    title: "Title",
  },
  {
    field: "docNum",
    type: "string",
    title: "Number",
  },
  {
    field: "issueDate",
    type: "date",
    title: "Issued",
  },
  {
    field: "expiryDate",
    type: "date",
    title: "Expire",
  },
  {
    field: "includedInCV",
    type: "boolean",
    title: "Include in CV",
    valueGetter: ({row: {includedInCV, id, docId}}) => {
      const onChange = ({target}) =>
        onIncludedInCVChanged({isRequired: !!docId, newDocData: {id, [target.name]: target.checked}});
      return (
        <input
          className="form-check-input"
          type="checkbox"
          id={`includedInCV_${id}`}
          name="includedInCV"
          checked={includedInCV}
          onChange={onChange}
        />
      );
    },
  },
];

export default personalDocsCols;
