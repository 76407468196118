import {gql} from "urql";

export const listCountryTemplates = gql`
  query ListCountryTemplates {
    listCountryTemplates {
      ... on Error {
        message
      }
      ... on QueryListCountryTemplatesSuccess {
        data {
          accountTemplate
          beneficiaryTemplate
          countryCode
          countryName
          ibanStatus
          sepaMember
        }
      }
    }
  }
`;
export const getCountryTemplate = gql`
  query getCountryTemplate($countryCode: String!) {
    getCountryTemplate(countryCode: $countryCode) {
      ... on Error {
        message
      }
      ... on QueryGetCountryTemplateSuccess {
        data {
          accountTemplate
          beneficiaryTemplate
          countryCode
          countryName
          ibanStatus
          sepaMember
        }
      }
    }
  }
`;

export const listBankAccounts = gql`
  query Accounts {
    accounts {
      ... on Error {
        message
      }
      ... on QueryAccountsSuccess {
        data {
          accountNumber
          bank
          iban
          swiftCode
          beneficiary
          preference
          label
          bankAccountCertificate {
            ... on MissingBankAccountCertificate {
              status
            }
            ... on ProvidedBankAccountCertificate {
              s3Uri
              status
            }
            ... on VerifiedBankAccountCertificate {
              s3Uri
              status
              verified {
                on
                by {
                  email
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const getBankAccount = gql`
  query Account($accountNumber: String!, $swiftCode: String!) {
    account(accountNumber: $accountNumber, swiftCode: $swiftCode) {
      __typename
      ... on Error {
        message
      }
      ... on QueryAccountSuccess {
        data {
          accountNumber
          bank
          iban
          swiftCode
          routingCode
          beneficiary
          preference
          label
          bankAccountCertificate {
            ... on MissingBankAccountCertificate {
              status
            }
            ... on ProvidedBankAccountCertificate {
              s3Uri
              status
            }
            ... on VerifiedBankAccountCertificate {
              s3Uri
              status
              verified {
                on
                by {
                  email
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const validateBic = gql`
  query ValidateBic($swiftCode: String!) {
    validateBic(swiftCode: $swiftCode) {
      ... on Error {
        message
      }
      ... on QueryValidateBicSuccess {
        data {
          bank
          swiftCode
        }
      }
    }
  }
`;

export const validateIban = gql`
  query ValidateIban($iban: String!) {
    validateIban(iban: $iban) {
      ... on Error {
        message
      }
      ... on QueryValidateIbanSuccess {
        data {
          bank
          iban
          swiftCode
          validation
        }
      }
    }
  }
`;

export const getUploadUrl = gql`
  query GetUploadUrl(
    $accountNumber: String!
    $documentKind: DocumentKind!
    $fileType: AllowedFileExtension!
    $swiftCode: String!
  ) {
    getUploadUrl(
      accountNumber: $accountNumber
      documentKind: $documentKind
      fileType: $fileType
      swiftCode: $swiftCode
    ) {
      __typename
      ... on Error {
        message
      }
      ... on QueryGetUploadUrlSuccess {
        data {
          s3Url
        }
      }
    }
  }
`;

export const getDownloadUrl = gql`
  query GetDownloadUrl($accountNumber: String!, $documentKind: DocumentKind!, $swiftCode: String!) {
    getDownloadUrl(accountNumber: $accountNumber, documentKind: $documentKind, swiftCode: $swiftCode) {
      __typename
      ... on Error {
        message
      }
      ... on QueryGetDownloadUrlSuccess {
        data {
          s3Url
        }
      }
    }
  }
`;

export const listWallets = gql`
  query Wallets {
    wallets {
      ... on Error {
        message
      }
      ... on QueryWalletsSuccess {
        data {
          poolId
          preference
          profileId
          walletId
          provider {
            id
            name
            walletIdDescription
          }
        }
      }
    }
  }
`;

export const listWalletProviders = gql`
  query Providers {
    providers {
      ... on QueryProvidersSuccess {
        data {
          id
          name
          walletIdDescription
        }
      }
      ... on Error {
        message
      }
    }
  }
`;
