import React from "react";
import PropTypes from "prop-types";

export default function Footer({pagerSetup, onPageSizeChanged, onPagerClick}) {
  const {pageSizes, currentPageSize, totalRows, totalCols, totalPages, currentPage} = pagerSetup;
  const handlePagerClick = ({currentTarget}) => {
    const nextPage =
      currentTarget.name === "prev"
        ? currentPage - 1
        : currentTarget.name === "next"
        ? currentPage + 1
        : parseInt(currentTarget.name);
    onPagerClick(nextPage);
  };
  const handleChange = ({currentTarget}) => onPageSizeChanged(currentTarget.value);

  const pagination = [...Array(totalPages).keys()].map((pageNum) => (
    <li key={pageNum} className={`page-item ${pageNum === currentPage ? "active" : ""}`}>
      <button className="page-link" disabled={currentPage === pageNum} name={pageNum} onClick={handlePagerClick}>
        {pageNum + 1}
      </button>
    </li>
  ));

  return (
    <tr>
      <td colSpan={totalCols}>
        <div className="row mx-0">
          <div className="col-auto px-0">
            <select
              className="form-select"
              aria-label="page size select"
              onChange={handleChange}
              value={currentPageSize}
              style={{width: "4.5rem"}}>
              {" "}
              {pageSizes.map((size) => (
                <option key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
          </div>

          <div className="col d-flex justify-content-end align-items-center px-0">
            <p className="my-auto me-3">
              Page {currentPage + 1} of {totalPages} <span className="text-muted">({totalRows} items)</span>
            </p>
            <nav aria-label="table pagination">
              <ul className="pagination justify-content-end my-auto">
                <li className={`page-item ${currentPage === 0 ? "disabled" : ""}`}>
                  <button className="page-link" disabled={currentPage === 0} name="prev" onClick={handlePagerClick}>
                    <span aria-hidden="true">&laquo;</span>
                  </button>
                </li>
                {pagination}
                <li className={`page-item ${currentPage === totalPages - 1 ? "disabled" : ""}`}>
                  <button
                    className="page-link"
                    disabled={currentPage === totalPages - 1}
                    name="next"
                    onClick={handlePagerClick}>
                    <span aria-hidden="true">&raquo;</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </td>
    </tr>
  );
}

Footer.propTypes = {
  pagerSetup: PropTypes.object,
  onPageSizeChanged: PropTypes.func,
  onPagerClick: PropTypes.func,
};
