const ACTIV_LIMIT_MIN = 29;

const minPassedSinceActive = () => {
  const now = Date.now();
  const lastActiveTime = localStorage.getItem("lastActiveTime") || now;
  return Math.ceil((now - lastActiveTime) / 1000 / 60);
};

const machineDelays = {
  INACTIVITY_DELAY: () => {
    const timer = ACTIV_LIMIT_MIN - minPassedSinceActive();
    return timer * 60000;
  },
  AUTH_WARN_DELAY: ({authTimeExp}) => {
    const now = Math.ceil(Date.now() / 1000);
    const authWarnDelay = authTimeExp - now - 360; // WARN 6 min before
    return authWarnDelay * 1000;
  },
  SIGN_OUT_DELAY: ({authTimeExp}) => {
    const now = Math.ceil(Date.now() / 1000);
    const signOutDelay = authTimeExp - now - 30; // SIGN OUT 30sec before
    return signOutDelay * 1000;
  },
};

const machineGuards = {
  isAccountNotBlocked: (_, {data: {accountBlockInfo}}) => !accountBlockInfo,
  isSignOutRequired: ({authTimeExp}, {data}) => {
    const {signInUserSession} = data || {};
    const now = Math.ceil(Date.now() / 1000);
    const expTime = signInUserSession?.accessToken.payload.exp || authTimeExp;
    const authTimeLeft = (expTime - now) / 60;
    const timePassedSinceActive = minPassedSinceActive();
    return authTimeLeft < 6 || timePassedSinceActive > ACTIV_LIMIT_MIN;
  },
  isUserActive: () => {
    const timePassedSinceActive = minPassedSinceActive();
    return timePassedSinceActive < ACTIV_LIMIT_MIN;
  },
  isUserSignedIn: ({user}) => !!user && !!user?.attributes?.sub,
  noSubscriptionsLoaded: ({subscriptions}) => !subscriptions || subscriptions.length === 0,
  isCvLinkGenerated: (_, {data: {type}}) => type === "generateCVlink",
};

export {machineDelays, machineGuards};
