import React, {useContext, useEffect, useReducer, useRef} from "react";
import {Box, Button, Grid, Paper, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {ValidationFormField} from "lib/components";
import {formReducer} from "lib/helpers";
import MachineContext from "lib/MachineContext";

export default function ContactUs() {
  const styles = {
    flexGrow: 1,
    display: "flex",
    px: {xs: "1rem", sm: "2rem"},
    backgroundImage: `url(${"/images/lighthouse.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "0% 50%",
  };
  const [state, send] = useActor(useContext(MachineContext));
  const {user, profileMachineRef} = state.context;
  const crewProfile = profileMachineRef?.state.context.crewProfile;
  const formRef = useRef(null);

  const initialFormState = ({fullname = "", email = ""}) => ({
    userName: fullname,
    userEmail: email,
    subject: "",
    message: "",
  });
  const [formState, dispatch] = useReducer(formReducer, initialFormState({}));
  const onChange = ({currentTarget}) => dispatch({type: currentTarget.name, data: currentTarget.value});

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    formRef.current.classList.add("was-validated");

    if (formRef.current.checkValidity()) {
      send({type: "contactUs", data: formState});
      const fullname = !crewProfile ? "" : `${crewProfile.firstName}  ${crewProfile.lastName}`;
      const email = user?.attributes.email || "";
      dispatch({type: "reset", data: initialFormState({fullname, email})});
      formRef.current.classList.remove("was-validated");
    }
  };

  useEffect(() => {
    dispatch({type: "userName", data: !crewProfile ? "" : `${crewProfile.firstName}  ${crewProfile.lastName}`});
    dispatch({type: "userEmail", data: user?.attributes.email || ""});
  }, [user, crewProfile]);

  return (
    <Box sx={{...styles}}>
      <Typography variant="h4" mt={2} sx={{position: "absolute"}}>
        shipbook
      </Typography>
      <Grid
        container
        spacing={1}
        sx={{flexGrow: 1, alignItems: "center", justifyContent: {xs: "center", sm: "flex-end"}}}>
        <Grid item xs={12} sm={10} md={8} lg={6} xl={5} sx={{display: "flex", justifyContent: "center", zIndex: 1000}}>
          <Box sx={{flexGrow: 1, maxWidth: "32rem", my: 1}}>
            <Paper elevation={16} sx={{p: 2}}>
              <Typography variant="h6" align="center" mb={3}>
                Contact Us
              </Typography>
              <Box component="form" ref={formRef} noValidate onSubmit={onSubmit}>
                <ValidationFormField
                  form={formRef.current}
                  type="text"
                  id="userName"
                  label="Name"
                  size="small"
                  name="userName"
                  value={formState.userName}
                  onChange={onChange}
                  required
                  fullWidth
                  helperText="Invalid name"
                  readOnly={!crewProfile?.firstName ? false : true}
                />
                <ValidationFormField
                  form={formRef.current}
                  type="email"
                  id="userEmail"
                  label="Email address"
                  size="small"
                  name="userEmail"
                  value={formState.userEmail}
                  onChange={onChange}
                  readOnly={!user ? false : true}
                  required
                  fullWidth
                  helperText="Invalid email"
                />
                <ValidationFormField
                  form={formRef.current}
                  type="text"
                  id="subject"
                  label="Subject"
                  size="small"
                  name="subject"
                  value={formState.subject}
                  onChange={onChange}
                  required
                  fullWidth
                  helperText="Invalid subject"
                />
                <ValidationFormField
                  multiline
                  rows={4}
                  form={formRef.current}
                  type="text"
                  id="message"
                  label="Message"
                  size="small"
                  name="message"
                  value={formState.message}
                  onChange={onChange}
                  required
                  fullWidth
                  helperText="Invalid message"
                />
                <Button type="submit" variant="contained" sx={{width: 1}}>
                  Send message
                </Button>
              </Box>
            </Paper>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
