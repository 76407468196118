import React from "react";
import {Grid, MenuItem} from "@mui/material";
import {FormInputSelect, FormInputText} from "lib/components";
import {MARITAL_STATUS} from "lib/data";
import PropTypes from "prop-types";

export default function FamilyDetailsForm({control, disabled}) {
  const namesPattern = /^[A-Za-z .-]{1,64}$/;
  const namesPatternMessage = "Only latin letters and .- are allowed";

  const rules = {
    maritalStatus: {required: "Marital Status is required"},
    fatherName: {
      pattern: {value: namesPattern, message: namesPatternMessage},
    },
    motherName: {
      pattern: {value: namesPattern, message: namesPatternMessage},
    },
  };

  return (
    <Grid container spacing={{xs: 2, sm: 3}} mb={3}>
      <Grid item xs={12} sm={4} lg={2}>
        <FormInputSelect
          name={"maritalStatus"}
          control={control}
          label={"Marital Status"}
          fullWidth
          rules={rules.maritalStatus}
          disabled={disabled}
          options={MARITAL_STATUS.map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={5}>
        <FormInputText
          name={"fatherName"}
          control={control}
          label={"Father Name"}
          fullWidth
          rules={rules.fatherName}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4} lg={5}>
        <FormInputText
          name={"motherName"}
          control={control}
          label={"Mother Name"}
          fullWidth
          rules={rules.motherName}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  );
}

FamilyDetailsForm.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
};
