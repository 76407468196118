import React, {useContext} from "react";
import {Box, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import MachineContext from "lib/MachineContext";

import Dashboard from "../dashboard/Dashboard";
import Authentication from "../userAuthentication/Authentication";

export default function Home() {
  const [state] = useActor(useContext(MachineContext));
  const {profileMachineRef} = state.context;

  const styles = {
    flexGrow: 1,
    display: "flex",
    px: {xs: 1, sm: 3},
    py: {xs: 1, sm: 3},
    backgroundImage: `url(${"/images/sailors.svg"})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",
  };

  return (
    <>
      {state.matches("signedIn") || (state.matches("signedInBlocked") && !!profileMachineRef) ? (
        <Dashboard />
      ) : state.matches("unauthorized") ? (
        <Box sx={{...styles}}>
          <Typography variant="h3" sx={{position: "absolute"}}>
            crewbook
          </Typography>
          <Authentication />
        </Box>
      ) : (
        <Box sx={{flexGrow: 1}}></Box>
      )}
    </>
  );
}
