// contractorName
// diveLocation
// worksite
// measureUnit
// helmetType
// gasMixture
// workDescription
// decoSchedules
// incidents
// remarks
// divingSupName
// storageDepth
// maxDepth
// bellLockOff
// leftBell
// returnedBell
// lockOutTime
// bellLockOn
// leftSurfaceTime
// bottomTime
// decoCompletedAt

const diverLogsColumn = [
  {
    field: "diveDate",
    type: "date",
    title: "Date of Dive",
  },
  {
    field: "diveType",
    type: "string",
    title: "Type of Dive",
  },
  {
    field: "diveTime",
    type: "string",
    title: "Time of Dive",
    valueGetter: ({row: {diveType, bottomTime, lockOutTime}}) =>
      diveType.includes("sat") ? lockOutTime : `${bottomTime}min`,
  },
  {
    field: "runningTotal",
    type: "string",
    title: "Running Total",
    valueGetter: ({row: {diveType, nonSatTotalTime, satTotalTime}}) => {
      const isSatDive = diveType.includes("sat");
      const {hh, mm} = nonSatTotalTime || satTotalTime;
      return `${isSatDive ? "sat dives" : "non-sat dives"} ${hh}:${mm}`;
    },
  },
];

export default diverLogsColumn;
