export default function formReducer(state, action) {
  switch (action.type) {
    case "reset":
      return {...state, ...action.data};
    case "disabled":
      return {...state, disabled: !state.disabled};
    default:
      return {...state, [action.type]: action.data};
  }
}
