/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */

import React, {useEffect, useRef, useState} from "react";
import {Tooltip} from "bootstrap";
import {cloudDownloadIcon, deleteIcon, editIcon} from "lib/icons";
import PropTypes from "prop-types";

import rowFormatter from "./rowFormatter";

export default function Row({columns, row, onDelete, onEdit, onDownload, styleSetup}) {
  const [tooltip, setTooltip] = useState(null);
  const rowRef = useRef(null);
  const onRowDelete = () => onDelete(row);
  const onDocDownload = () => onDownload(row);
  const onRowEdit = () => onEdit(row);
  const onMouseOver = () => tooltip?.show();
  const onMouseOut = () => tooltip?.hide();

  const rowLayout = () => {
    let isRowEditable = false;
    const layout = columns.map(({type, valueGetter, isEditable, field}) => {
      isRowEditable = isEditable ? isRowEditable || isEditable({row}) : isRowEditable;

      const value = valueGetter ? valueGetter({row}) : row[field];
      const style = {
        textAlign: !value ? "center" : type === "number" ? "right" : type === "boolean" ? "center" : "left",
        paddingRight: !!value && type === "number" ? "1rem" : "0.3rem",
      };

      return (
        <td key={field} style={style}>
          {rowFormatter({type, value})}
        </td>
      );
    });

    !!onEdit && isRowEditable
      ? layout.push(
          <td key="onRowEdit" style={{minWidth: "3rem", width: "3rem"}} onClick={onRowEdit}>
            <div className="icon-edit">{editIcon}</div>
          </td>
        )
      : !!onEdit && layout.push(<td key="onRowEdit"></td>);
    return layout;
  };

  useEffect(() => {
    const tooltipObj = new Tooltip(rowRef.current, {
      container: "body",
      boundary: "window",
      trigger: "manual",
    });
    setTooltip(tooltipObj);

    return () => {
      tooltipObj.dispose();
    };
  }, []);

  return (
    <tr
      ref={rowRef}
      className={styleSetup.class}
      data-bs-toggle="tooltip"
      title={styleSetup.tooltip ? styleSetup.tooltip : ""}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}>
      {rowLayout()}

      {!!onDelete && (
        <td key="onRowDelete" style={{minWidth: "3rem", width: "3rem"}} onClick={onRowDelete}>
          <div className="icon-delete">{deleteIcon}</div>
        </td>
      )}
      {!!onDownload && (
        <td key="onDocDownload" style={{minWidth: "3rem", width: "3rem"}} onClick={onDocDownload}>
          <div className="icon-download">{cloudDownloadIcon}</div>
        </td>
      )}
    </tr>
  );
}

Row.propTypes = {
  columns: PropTypes.array,
  row: PropTypes.object,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onDownload: PropTypes.func,
  styleSetup: PropTypes.object,
};
