import React from "react";
import {Link} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import {format, parseISO} from "date-fns";
import PropTypes from "prop-types";

export default function BlockedAccountNote({accountBlockInfo}) {
  const {blockedDate, reason} = accountBlockInfo;
  const date = format(parseISO(blockedDate), "dd MMM yyyy");

  const reasonContent = {
    0: (
      <Box>
        <p>Your request to CLOSE ACCOUNT has been received succesfully on {date}!</p>
        <p>Account will be disabled during 24 hours.</p>
        <p>You have 60 days of &#34;Post-Closure Period&#34; to request account reopening.</p>
        <p>
          To reopen - send a request through <Link to="/contact">Contact Us</Link> form or by email:{" "}
          <a href="mailto:watchkeeper@crewbook.app">watchkeeper@crewbook.app</a>
        </p>
      </Box>
    ),
    1: (
      <Box>
        <p>Your Account was blocked on {date}.</p>
        <p>
          To continue using your account, please, renew <Link to="/subscription"> Subscription</Link>.
        </p>
        <p>You have 60 days of &#34;Post-Closure Period&#34; to reacivate your account.</p>
        <p>
          If you choose not to renew Subscription during the Post-Closure Period, any content remaining in your CrewBook
          account will be deleted.
        </p>
      </Box>
    ),
  };

  const styles = {
    color: "error.main",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    p: 2,
    overflow: "auto",
    height: {xs: "auto", md: 1},
  };

  return (
    <Box sx={styles}>
      <Typography variant="h6" gutterBottom>
        Account is blocked...
      </Typography>
      {reasonContent[reason]}
    </Box>
  );
}

BlockedAccountNote.propTypes = {
  accountBlockInfo: PropTypes.object,
};
