import dayjs from "dayjs";

import totalSeadaysTableBody from "./totalSeadaysTable";
import unbreakableStack from "./unbreakableStack";

const naNullishData = (data) => data.map((item) => (!item || item === "" ? "N/A" : item));

export default function logsTables({crewSeaLogs}) {
  const styles = {style: "th2", fillColor: "#eeeeff", alignment: "center"};
  const logEntries = [...crewSeaLogs.items].reduce((result, logItem) => {
    const {
      vesselName,
      vesselType,
      tonnage,
      operationArea,
      rankTitle,
      dateFrom,
      dateTo,
      engagementPlace,
      dischargePlace,
      company,
    } = logItem;

    if (!dateTo) {
      return result;
    }

    const [vesselTypeNA, tonnageNA] = naNullishData([vesselType, tonnage]);
    const from = `${dayjs(dateFrom).format("DD-MMM-YY")} ${engagementPlace || ""}`;
    const to = `${dayjs(dateTo).format("DD-MMM-YY")} ${dischargePlace || ""}`;
    const totalDays = 1 + dayjs(dateTo).diff(dayjs(dateFrom), "day");

    return [
      ...result,
      [
        {text: vesselName, style: "tdLight"},
        {text: vesselTypeNA, style: "tdLight"},
        {text: rankTitle, style: "tdLight"},
        {text: tonnageNA, style: "tdLight"},
        {text: from, style: "tdLight"},
        {text: `${to}`, style: "tdLight"},
        {text: totalDays, style: "tdLight", alignment: "right", margin: [0, 0, 5, 0]},
        {text: operationArea, style: "tdLight"},
        {text: company, style: "tdLight"},
      ],
    ];
  }, []);

  return [
    {text: "", pageOrientation: "landscape", pageBreak: "before"},
    unbreakableStack({
      tableTitle: "SEA SERVICE:",
      widths: ["auto", "auto", "auto", "auto", 60, 60, "auto", "auto", "*"],
      body: [
        [
          {text: "Vessel Name", ...styles},
          {text: "Vessel Type", ...styles},
          {text: "Rank", ...styles},
          {text: "GRT", ...styles},
          {text: "Sign On", ...styles},
          {text: "Sign Off", ...styles},
          {text: "Total Days", ...styles},
          {text: "Area of Operations", ...styles},
          {text: "Company", ...styles},
        ],
        ...logEntries,
      ],
    }),
    unbreakableStack({
      tableTitle: "SEA SERVICE TOTALS:",
      widths: ["*", "auto"],
      body: totalSeadaysTableBody({totalSeaDays: crewSeaLogs.totalSeaDays}),
    }),
  ];
}
