import {assign, sendParent} from "xstate";

const actions = {
  updateProfile: assign({
    crewProfile: ({crewProfile}, {data: {newProfile}}) => (!newProfile ? crewProfile : newProfile),
    crewRanks: ({crewRanks}, {data: {newCrewRanks}}) => (newCrewRanks ? newCrewRanks : crewRanks),
  }),
  sendUpdateCrewRankDocs: sendParent((_, {data: {newCrewRanksDocs}}) => ({
    type: "updateCrewRankDocs",
    data: {newCrewRanksDocs},
  })),
  updateProfileImg: assign({
    profileImgKey: ({profileImgKey}, {data: {profileImgKey: newKey}}) => (!newKey ? profileImgKey : newKey),
  }),
  deleteProfileImg: assign({
    profileImgKey: null,
  }),
  updateActiveNotifications: assign({
    crewProfile: ({crewProfile}, {data: {updatedSetup}}) => ({...crewProfile, notifications: {items: updatedSetup}}),
  }),
  sendAlert: sendParent((_, {data: {alert}}) => ({type: "alert", data: alert})),
  sendError: sendParent((_, {data}) => ({type: "updateError", data: data})),
  machineStartsWorking: assign({isMachineWorking: () => true}),
  machineStopsWorking: assign({isMachineWorking: () => false}),
  logger: (context, event, p, pp) => console.log("PROFILE Machine STATE EVENT CTX", event, context, p, pp),
};

export default actions;
