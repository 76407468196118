import {createMachine} from "xstate";

import actions from "./actions";
import guards from "./guards";
import services from "./services";

const employmentMachine = ({owner, employmentUpdates = new Set()}) =>
  createMachine(
    {
      id: "employmentMachine",
      predictableActionArguments: true,
      initial: "idle",
      context: {
        crewId: owner,
        employments: [],
        employmentUpdates,
        employmentActions: null,
        unprocessedEvents: [],
        employerUser: null,
        isMachineWorking: false,
      },
      on: {
        employmentUpdated: [
          {actions: "addEmploymentUpdate", cond: "employmentExists"},
          {target: "loadingNewEmploymentData", cond: "idleState"},
          {actions: "addUnprocessedEvent"},
        ],
      },
      states: {
        idle: {
          always: {
            target: "processingEmploymentUpdates",
            cond: "hasEmploymentUpdateEvents",
          },
          on: {
            loadEmployments: {
              target: "loadingEmployments",
              cond: "employmentsNotLoaded",
            },
            getEmploymentUpdates: "loadingEmploymentDetails",
            searchEmployer: "searchingEmployer",
            resetEmployerUser: {actions: "resetEmployerUser"},
            sendEmploymentEvent: "sendingEvent",
            downloadEmploymentFile: "downloadingFile",
            UPDATE_EMPLOYMENT: "updatingEmployment",
          },
        },
        loadingEmployments: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: "employmentsLoader",
            onDone: {target: "idle", actions: "resetEmployments"},
            onError: {target: "idle", actions: "sendError"},
          },
        },
        searchingEmployer: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: "employerFinder",
            onDone: {target: "idle", actions: "updateEmployerUser"},
            onError: {target: "idle", actions: "sendError"},
          },
        },
        loadingEmploymentDetails: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: "employmentDetailsLoader",
            onDone: {target: "idle", actions: "updateEmployment"},
            onError: {target: "idle", actions: "sendError"},
          },
        },
        loadingNewEmploymentData: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: "loadNewEmploymentData",
            onDone: {target: "idle", actions: "addEmployment"},
            onError: {target: "idle", actions: "sendError"},
          },
        },
        processingEmploymentUpdates: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: "processEmploymentUpdates",
            onDone: {target: "idle", actions: "resetEmploymentUpdates"},
            onError: {target: "idle", actions: "sendError"},
          },
        },
        sendingEvent: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: "eventSender",
            onDone: {target: "idle", actions: ["sendAlert", "updateEmployment"]},
            onError: {target: "idle", actions: "sendError"},
          },
        },
        downloadingFile: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: "downloadFile",
            onDone: {target: "idle"},
            onError: {target: "idle", actions: "sendError"},
          },
        },
        updatingEmployment: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: services.updateEmployment,
            onDone: {target: "idle", actions: actions.patchEmployments},
            onError: {target: "idle", actions: "sendError"},
          },
        },
      },
    },
    {services, actions, guards}
  );

export default employmentMachine;
