import React from "react";
import pdfFonts from "pdfmake/build/vfs_fonts";

import customVfsFonts from "../vfs_fonts";

import base64ToArrayBuffer from "./base64ToArrayBuffer";

const hexToRgb = (hex) =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => "#" + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

const maxFontSize = 30;
const robotoFontArrayBufer = base64ToArrayBuffer(pdfFonts.pdfMake.vfs["Roboto-Regular.ttf"]);
const signatureFontArrayBufer = base64ToArrayBuffer(customVfsFonts["HerrVonMuellerhoff.woff"]);

const fontFamily = "Herr Von Muellerhoff";
const signatureFont = new FontFace(fontFamily, signatureFontArrayBufer);

const customPdfFonts = {
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf",
  },
  [fontFamily]: {
    normal: "HerrVonMuellerhoff.woff",
  },
};

const loadSignatureFont = async () => {
  await signatureFont.load();
  document.fonts.add(signatureFont);
};

const getFontSizeForWidth = ({signatureText, maxWidth}) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const startFontSize = 13;
  let currentStep = 0;

  const fontSizeForWidth = (fontSize) => {
    if (!maxWidth) return fontSize;
    if (fontSize > maxFontSize) return maxFontSize;

    context.font = `normal normal ${fontSize}px "${fontFamily}"`;
    const {width} = context.measureText(signatureText);
    const step = width < maxWidth ? 1 : -1;

    if (currentStep === 0 || currentStep === step) {
      currentStep = step;
      return fontSizeForWidth(fontSize + step);
    }
    return fontSize + step;
  };

  const fontSize = fontSizeForWidth(startFontSize);
  return fontSize;
};

const getSignatureText = ({firstName, lastName}) =>
  `${firstName.slice(0, 1).toUpperCase()}.${lastName.slice(0, 1).toUpperCase()}${lastName.slice(1).toLowerCase()}`;

const signatureSize = {width: 150, height: 50};
const textX = 13;
const signatureX = 10;
const signatureY = 32;
const signedByFontSize = 7;
const signedByY = 6;
const signedByText = "signed by:";
const hashFontSize = 6;
const hashY = 48;
const fillColorHex = "#737373";
const fillColorRgb = hexToRgb(fillColorHex).map((num) => num / 255);
const svgPath = "M 12,5 A 10 20 0 1 0 12,45";

const getSignatureFontSize = async ({firstName, lastName}) => {
  const signatureText = getSignatureText({firstName, lastName});
  const maxWidth = signatureSize.width - 2 * signatureX;
  const fontSize = getFontSizeForWidth({signatureText, maxWidth});
  return {fontSize, signatureText};
};

const getSvgSignature = async ({firstName, lastName, hashNumber}) => {
  const {fontSize, signatureText} = await getSignatureFontSize({firstName, lastName});
  const {width, height} = signatureSize;

  const svgElement = (
    <svg viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg" style={{width: "100%", height: "auto"}}>
      <text x={textX} y={signedByY} fill={fillColorHex} fontSize={`${signedByFontSize}px`} fontFamily={"Roboto"}>
        {signedByText}
      </text>
      <text x={signatureX} y={signatureY} fontSize={`${fontSize}px`} fontFamily={fontFamily}>
        {signatureText}
      </text>
      <text x={textX} y={hashY} fill={fillColorHex} fontSize={`${hashFontSize}px`} fontFamily={"Roboto"}>
        {hashNumber.toUpperCase()}
      </text>
      <path fill="none" stroke={fillColorHex} strokeWidth="1" d={svgPath} />
    </svg>
  );

  return svgElement;
};

const getPdfLibSignature = async ({firstName, lastName, hashNumber}) => {
  const {fontSize, signatureText} = await getSignatureFontSize({firstName, lastName});
  const {height} = signatureSize;

  const signedBy = {
    text: signedByText,
    options: {
      x: textX,
      y: height - signedByY,
      size: signedByFontSize,
      fontType: "standard",
      color: fillColorRgb,
    },
  };

  const signature = {
    text: signatureText,
    options: {
      x: signatureX,
      y: height - signatureY,
      size: fontSize,
      fontType: "signature",
    },
  };

  const hash = {
    text: hashNumber.toUpperCase(),
    options: {
      x: textX,
      y: height - hashY,
      size: signedByFontSize,
      fontType: "standard",
      color: fillColorRgb,
    },
  };

  return {textLines: [signedBy, signature, hash], path: {stroke: svgPath, color: fillColorRgb}};
};

export {
  customPdfFonts,
  getPdfLibSignature,
  getSignatureText,
  getSvgSignature,
  loadSignatureFont,
  robotoFontArrayBufer,
  signatureFontArrayBufer,
  signatureSize,
};
