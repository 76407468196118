import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

const getOverlapLogEntries = ({entry, logBook}) => {
  const {dateFrom, dateTo} = entry;
  const entryFrom = dayjs(dateFrom);
  const entryTo = dayjs(dateTo).isValid() ? dayjs(dateTo) : null;

  return logBook.reduce((overlap, logEntry) => {
    const {dateFrom, dateTo} = logEntry;

    if (dateTo) {
      const isBetween =
        dayjs(entryFrom).isBetween(dateFrom, dateTo, "day", "[]") ||
        (!!entryTo && dayjs(entryTo).isBetween(dateFrom, dateTo, "day", "[]"));
      isBetween && overlap.push(logEntry);
    } else {
      const isBefore = dayjs(entryFrom).isBefore(dateFrom, "day") && dayjs(entryTo).isBefore(dateFrom, "day");
      !isBefore && overlap.push(logEntry);
    }
    return overlap;
  }, []);
};

export default getOverlapLogEntries;
