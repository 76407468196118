import React, {useContext, useEffect, useRef, useState} from "react";
import {Grid, MenuItem} from "@mui/material";
import {useActor} from "@xstate/react";
import {appSettings} from "lib";
import {FormInputSelect, FormInputText} from "lib/components";
import MachineContext from "lib/MachineContext";
import PropTypes from "prop-types";
const rules = {
  docId: {required: "Document is required"},
  docDescriptionId: {required: "Document Description is required"},
  title: {
    required: "Document Description is required",
    pattern: {value: /^[\w .,-/]{1,64}$/, message: "Only alphanumeric characters and .,-/ are allowed"},
  },
};

export default function DocSelectForm({control, setValue, isCollapsed, docCategory, watch}) {
  const [state, send] = useActor(useContext(MachineContext));
  const {crewDocsMachineRef} = state.context;
  const {crewRankDocs} = crewDocsMachineRef?.state.context || [];
  const [docsOptions, setDocsOptions] = useState([]);
  const [decriptionOptions, setDecriptionOptions] = useState([]);
  const isRequired = docCategory !== "other";
  const currentDocCategory = useRef(null);
  const watchDocId = watch("docId");
  const {passportPhotoDocId} = appSettings;

  useEffect(() => {
    if (isCollapsed) return;
    const docFilterType = {ids: ["ID"], "non-stcw": ["NON_STCW"], stcw: ["STCW", "STCW_SPEC"]};
    if (docCategory === "other") {
      setDocsOptions([
        <MenuItem key={"Personal"} value={"Personal"}>
          Other Document
        </MenuItem>,
      ]);
      setValue("docId", "Personal");
    } else if (currentDocCategory.current !== docCategory) {
      setValue("docId", "");
      const docs =
        crewRankDocs
          ?.filter(({document}) => docFilterType[docCategory].includes(document.docType))
          .map(({document}) => (
            <MenuItem key={document.id} value={document.id}>
              {document.title}
            </MenuItem>
          )) || [];
      setDocsOptions(docs);
    }
    currentDocCategory.current = docCategory;
  }, [crewRankDocs, isRequired, docCategory, isCollapsed, setValue]);

  useEffect(() => {
    setValue("docDescriptionId", "");
    if (watchDocId === "" || isCollapsed) return;
    if (isRequired) {
      const {document} = crewRankDocs?.find(({document}) => document.id === watchDocId) || {};
      if (!document) return;
      const {
        id,
        descriptions: {nextToken, items},
      } = document;
      if (nextToken) {
        return send({type: "loadMoreDescriptions", data: {docId: id, nextToken}});
      }
      setDecriptionOptions(
        items.map(({id, content}) => (
          <MenuItem key={id} value={id}>
            {content}
          </MenuItem>
        ))
      );
      items.length === 1 && setValue("docDescriptionId", items[0].id);
    }
  }, [watchDocId, crewRankDocs, isRequired, send, isCollapsed, setValue]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormInputSelect
          name={"docId"}
          control={control}
          label={"Choose Document"}
          rules={rules.docId}
          fullWidth
          options={docsOptions}
          disabled={docCategory === "other"}
        />
      </Grid>
      <Grid item xs={12}>
        {isRequired ? (
          <FormInputSelect
            name={"docDescriptionId"}
            control={control}
            label={"Choose Description"}
            rules={rules.docDescriptionId}
            fullWidth
            options={decriptionOptions}
            disabled={watchDocId === ""}
          />
        ) : (
          <FormInputText name={"title"} control={control} label={"Description"} fullWidth rules={rules.title} />
        )}
      </Grid>
      {docCategory === "ids" && watchDocId !== passportPhotoDocId && (
        <Grid item xs={12}>
          <FormInputText
            name={"title"}
            control={control}
            label={"Additional description"}
            fullWidth
            rules={rules.title}
          />
        </Grid>
      )}
    </Grid>
  );
}

DocSelectForm.propTypes = {
  control: PropTypes.object,
  docCategory: PropTypes.string,
  setValue: PropTypes.func,
  isCollapsed: PropTypes.bool,
  watch: PropTypes.func,
};
