import React, {useCallback, useContext, useEffect, useReducer, useState} from "react";
import {useActor} from "@xstate/react";
import dayjs from "dayjs";
import {DateForm} from "lib/components"; // Table
import {formReducer} from "lib/helpers";
import MachineContext from "lib/MachineContext";

// import diverLogsColumn from './configurations/diverLogsColumn'
import {ContractorForm, DiveSiteForm, NonSatDiveForm, RemarksForm, SatDiveForm, WorkDescription} from "./diverLogForms";

export default function DiverLogForm() {
  const [state, send] = useActor(useContext(MachineContext));
  const {crewLogsMachineRef} = state.context;
  const {state: logState} = crewLogsMachineRef;
  const {crewDiveLogs} = logState.context;

  const [lastRecord, setLastRecord] = useState(null);
  const initialFormState = {
    diveDate: "",
    contractorName: "",
    contractorAddress: "",
    diveLocation: "",
    worksite: "",
    diveType: "",
    measureUnit: "m",
    helmetType: "",
    gasMixture: "",
    workDescription: "",
    decoSchedules: "",
    incidents: "",
    remarks: "",
    divingSupName: "",
    storageDepth: "",
    maxDepth: "",
    bellLockOff: "",
    leftBell: "",
    returnedBell: "",
    lockOutTime: "",
    bellLockOn: "",
    leftSurfaceTime: "",
    bottomTime: "",
    decoCompletedAt: "",
    surfaceDecoInterval: "",
    chamberTime: "0:0",
  };
  const [formState, dispatch] = useReducer(formReducer, initialFormState);

  const onChange = useCallback(({target}) => dispatch({type: target.name, data: target.value}), []);
  const resetForm = () => dispatch({type: "reset", data: initialFormState});
  const handleClone = () => {
    // const { vesselName, vesselType, tonnage, operationArea, company } = lastRecord
    // const [operationCountry, ...area] = operationArea.split(', ')
    // dispatch({
    //   type: 'reset',
    //   data: {
    //     vesselName: vesselName || '', vesselType: vesselType || '',
    //     tonnage: tonnage || '', operationCountry: operationCountry || '',
    //     operationArea: area.join(', ') || '', company: company || ''
    //   }
    // })
  };

  const onSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    form.classList.add("was-validated");

    if (form.checkValidity()) {
      const data = {crewDiveLogs, logData: formState, logType: "DiverLog"};
      send({type: "addLogEntry", data});
      dispatch({type: "reset", data: initialFormState});
      form.classList.remove("was-validated");
    }
  };

  useEffect(() => setLastRecord(crewDiveLogs?.items[0] || null), [crewDiveLogs]);

  return (
    <>
      <div className="col-xs-12 mb-3" style={{fontSize: "0.9rem"}}>
        <h6 className="table-title text-danger">Last Log Entry</h6>
        ...
        {/* <Table rows={!lastRecord ? [] : [lastRecord]} columns={logsColumns} showFooter={false} /> */}
      </div>
      <form
        onSubmit={onSubmit}
        noValidate
        className="row needs-validation justify-content-start"
        style={{fontSize: "0.85rem"}}>
        <div className="col">
          <div className="row">
            <DateForm
              dateState={formState.diveDate}
              dateName="diveDate"
              dateTitle="Date of Dive"
              cssClass="col-12 col-sm-5 col-md-3 col-lg-3 mb-3"
              min={dayjs().subtract(50, "year")}
              max={dayjs()}
              onChange={onChange}
              disabled={formState.disabled}
            />
            <ContractorForm {...{formState, onChange}} />
            <DiveSiteForm {...{formState, onChange}} />
            {formState.diveType.includes("sat") ? (
              <SatDiveForm {...{formState, onChange}} />
            ) : (
              <NonSatDiveForm {...{formState, onChange}} />
            )}
            <WorkDescription {...{formState, onChange}} />
            <RemarksForm {...{formState, onChange}} />
          </div>

          <div className="row mt-3">
            <div className="col-sm-6 col-md-auto bg-white mb-3">
              <button
                type="button"
                className="w-100 btn btn-outline-danger"
                disabled={!lastRecord}
                onClick={handleClone}>
                Clone Last Entry
              </button>
            </div>
            <div className="col-sm-6 col-md-auto bg-white mb-3">
              <button type="button" className="w-100 btn btn-outline-secondary" onClick={resetForm}>
                Reset Log Entry
              </button>
            </div>
            <div className="col-xs-12 col-md-auto ms-auto">
              <button type="submit" className="w-100 btn btn-primary">
                Add Log Entry
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
