import dayjs from "dayjs";
import {assign, sendParent} from "xstate";

import {getDiverRunningTotals, getTotalSeaDays} from "./helpers";

const logsActions = {
  updateVesselTypes: assign({
    vesselTypes: (_, {data}) => data,
  }),
  addLogEntry: assign(({crewSeaLogs, crewDiveLogs, crewDiveSupLogs}, {data}) => {
    const {newEntry, logType, alert} = data;

    if (logType === "SeaLog") {
      const oldLogs = crewSeaLogs?.items || [];
      const newLogEntries = [...oldLogs, newEntry].sort((logA, logB) =>
        dayjs(logA.dateFrom).isBefore(dayjs(logB.dateFrom)) ? 1 : -1
      );
      const logs = {items: newLogEntries, totalSeaDays: getTotalSeaDays(newLogEntries)};
      return {crewSeaLogs: logs, alert};
    }
    if (logType === "DiverLog") {
      const oldLogs = crewDiveLogs?.items || [];
      console.log("ADD NEW ENTRY to DiverLog!");
      return {crewDiveLogs: getDiverRunningTotals([...oldLogs, newEntry]), alert};
    }
    if (logType === "DiveSupLog") {
      const oldLogs = crewDiveSupLogs?.items || [];
      console.log("ADD NEW ENTRY to DiveSupLog!");
      return {crewDiveSupLogs: oldLogs, alert};
    }
    return {alert};
  }),
  deleteLogEntry: assign(({crewSeaLogs, crewDiveLogs, crewDiveSupLogs}, {data}) => {
    const {logType, logId, alert} = data;
    if (logType === "SeaLog") {
      const newLogEntries = crewSeaLogs.items.filter((log) => log.id !== logId);
      const newLog = {items: newLogEntries, totalSeaDays: getTotalSeaDays(newLogEntries)};
      return {crewSeaLogs: newLog, alert};
    }
    if (logType === "DiverLog") {
      const newLogEntries = crewDiveLogs.items.filter((log) => log.id !== logId);
      return {crewDiveLogs: getDiverRunningTotals(newLogEntries), alert};
    }
    if (logType === "DiveSupLog") {
      const newLogEntries = crewDiveSupLogs.items.filter((log) => log.id !== logId);
      const newLog = {items: newLogEntries};
      return {crewDiveSupLogs: newLog, alert};
    }
    return {alert};
  }),
  updateLogEntry: assign(({crewSeaLogs, crewDiveLogs, crewDiveSupLogs}, {data}) => {
    const {updatedLogEntry, logType, alert} = data;
    if (logType === "SeaLog") {
      const newLogEntries = crewSeaLogs.items.map((log) => (log.id === updatedLogEntry.id ? updatedLogEntry : log));
      const newLog = {items: newLogEntries, totalSeaDays: getTotalSeaDays(newLogEntries)};
      return {crewSeaLogs: newLog, alert};
    }
    if (logType === "DiverLog") {
      const newLogEntries = crewDiveLogs.items.map((log) => (log.id === updatedLogEntry.id ? updatedLogEntry : log));
      return {crewDiveLogs: getDiverRunningTotals(newLogEntries), alert};
    }
    if (logType === "DiveSupLog") {
      const newLogEntries = crewDiveSupLogs.items.map((log) => (log.id === updatedLogEntry.id ? updatedLogEntry : log));
      return {crewDiveSupLogs: {items: newLogEntries}, alert};
    }
    return {alert};
  }),
  updateSeaLogEntry: assign({
    crewSeaLogs: ({crewSeaLogs}, {data: {updatedLogEntry}}) => {
      const newLogEntries = crewSeaLogs.items.map((log) => (log.id === updatedLogEntry.id ? updatedLogEntry : log));
      return {items: newLogEntries, totalSeaDays: getTotalSeaDays(newLogEntries)};
    },
    alert: (_, {data: {alert}}) => alert,
  }),
  sendAlert: sendParent((_, {data: {alert}}) => ({type: "alert", data: alert})),
  sendError: sendParent((_, {data}) => ({type: "updateError", data: data})),
  machineStartsWorking: assign({isMachineWorking: () => true}),
  machineStopsWorking: assign({isMachineWorking: () => false}),
  logger: (context, event, {state}) => console.log("LOGS MACHINE EVENT", state.value, event, context),
};

export default logsActions;
