import React, {forwardRef, useMemo} from "react";
import {Controller} from "react-hook-form";
import {isPossiblePhoneNumber} from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import {FormControl, FormHelperText, InputLabel, OutlinedInput} from "@mui/material";
import PropTypes from "prop-types";

export default function FormInputPhone(props) {
  const {
    name,
    control,
    label,
    fullWidth,
    size = "small",
    required = true,
    shrink,
    disabled = false,
    readOnly = false,
    ...inputProps
  } = props;

  const rules = {
    validate: (value) => {
      const isValidePhone = !!value && isPossiblePhoneNumber(value);
      return !required && !value ? true : isValidePhone || "Enter a valid phone number";
    },
  };

  const CustomPhoneInput = useMemo(() => {
    const PhoneInputWithInputRef = (params, ref) => {
      return <OutlinedInput {...params} inputRef={ref} />;
    };

    return forwardRef(PhoneInputWithInputRef);
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({field: {onChange, onBlur, value, name, ref}, fieldState: {invalid, error}}) => (
        <FormControl error={invalid} fullWidth={fullWidth} size={size}>
          <InputLabel htmlFor={name} shrink={shrink}>
            {label}
          </InputLabel>

          <Input
            value={value}
            onChange={onChange}
            notched={shrink}
            inputRef={ref}
            inputComponent={CustomPhoneInput}
            international={true}
            onBlur={onBlur}
            disabled={disabled}
            readOnly={readOnly}
            inputProps={inputProps}
            label={label}
          />

          {error && <FormHelperText>{error?.message}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}

FormInputPhone.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  shrink: PropTypes.bool,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.string,
};
