import React, {useContext, useMemo} from "react";
import {Grid, MenuItem} from "@mui/material";
import {useActor} from "@xstate/react";
import {subYears} from "date-fns";
import {FormInputDate, FormInputSelect, FormInputText} from "lib/components";
import {NATIONALITIES, RELOGIONS} from "lib/data";
import MachineContext from "lib/MachineContext";
import PropTypes from "prop-types";

export default function UserForm({control, disabled}) {
  const [state] = useActor(useContext(MachineContext));
  const {profileMachineRef} = state.context;
  const rangeLimits = {min: subYears(new Date(), 80), max: subYears(new Date(), 15)};
  const namesPattern = /^[A-Za-z .-]{1,64}$/;
  const addressPattern = /^[\w .,-/]{1,64}$/;
  const namesPatternMessage = "Only latin letters and .- are allowed";
  const addressPatternMessage = "Only alphanumeric characters and .,-/ are allowed";

  const crewRanks = useMemo(
    () => profileMachineRef?.state?.context?.crewRanks,
    [profileMachineRef?.state?.context?.crewRanks]
  );

  const rules = {
    firstName: {required: "First name is required", pattern: {value: namesPattern, message: namesPatternMessage}},
    midName: {pattern: {value: namesPattern, message: namesPatternMessage}},
    lastName: {required: "Last name is required", pattern: {value: namesPattern, message: namesPatternMessage}},
    crewRankId: {required: "Crew rank is required"},
    birthPlace: {
      required: "Place of birth is required",
      pattern: {value: addressPattern, message: addressPatternMessage},
    },
    nationality: {required: "First name is required"},
    religion: {required: "First name is required"},
  };

  return (
    <Grid container spacing={{xs: 2, sm: 3}} mb={3}>
      <Grid item xs={12} sm={4} md={4}>
        <FormInputText
          name={"firstName"}
          control={control}
          label={"First Name"}
          fullWidth
          rules={rules.firstName}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <FormInputText
          name={"midName"}
          control={control}
          label={"Middle name"}
          fullWidth
          rules={rules.midName}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={4} md={4}>
        <FormInputText
          name={"lastName"}
          control={control}
          label={"Last name"}
          fullWidth
          rules={rules.lastName}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <FormInputSelect
          name={"crewRankId"}
          control={control}
          label={"Crew Rank"}
          fullWidth
          rules={rules.crewRankId}
          disabled={disabled}
          options={
            crewRanks?.map(({id, title}) => (
              <MenuItem key={id} value={id}>
                {title}
              </MenuItem>
            )) || []
          }
        />
      </Grid>

      <Grid item xs={12} sm={4} md={4}>
        <FormInputDate
          control={control}
          label={"Date of Birth"}
          name={"birthDate"}
          disabled={disabled}
          rangeLimits={rangeLimits}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInputText
          name={"birthPlace"}
          control={control}
          label={"Place of Birth (Country, City)"}
          fullWidth
          rules={rules.birthPlace}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInputSelect
          name={"nationality"}
          control={control}
          label={"Nationality"}
          fullWidth
          rules={rules.nationality}
          disabled={disabled}
          options={
            NATIONALITIES.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            )) || []
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInputSelect
          name={"religion"}
          control={control}
          label={"Religion"}
          fullWidth
          rules={rules.religion}
          disabled={disabled}
          options={
            RELOGIONS.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            )) || []
          }
        />
      </Grid>
    </Grid>
  );
}

UserForm.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
};
