/* eslint-disable import/no-named-as-default */
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import {Amplify} from "aws-amplify";
import {ErrorBoundary} from "lib/components";

import App from "./App";
import awsExports from "./aws-exports";

import "scss/customBootstrap.scss";
// import reportWebVitals from './reportWebVitals'

Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Router>
        <App />
      </Router>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
