import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {Box, Button, Grid, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {formatISO} from "date-fns";
import {Table} from "lib/components";
import MachineContext from "lib/MachineContext";
import PropTypes from "prop-types";

import seaLogsColumns from "./configurations/seaLogsColumns";
import {OperationForm, RankSelectForm, VesselForm} from "./seaLogForms";

const initialFormState = {
  vesselName: "",
  vesselType: "",
  tonnage: "",
  operationCountry: "",
  operationArea: "",
  rankTitle: "",
  company: "",
  dateFrom: null,
  dateTo: null,
  engagementPlace: "",
  dischargePlace: "",
};

export default function SeaLogForm({isCollapsed}) {
  const [state, send] = useActor(useContext(MachineContext));
  const {
    context: {crewLogsMachineRef},
  } = state;
  const {state: logState} = crewLogsMachineRef;
  const {crewSeaLogs, vesselTypes} = logState.context;

  const methods = useForm({defaultValues: initialFormState, mode: "all"});
  const {handleSubmit, watch, control, reset, formState, setValue, getValues, trigger} = methods;
  const {isValid, isSubmitted, isSubmitSuccessful, isDirty, dirtyFields} = formState;

  const [lastRecord, setLastRecord] = useState(null);

  const handleClone = () => {
    const {vesselName, vesselType, tonnage, operationArea, engagementPlace, dischargePlace, company} = lastRecord;
    const [operationCountry, ...areas] = operationArea?.split(", ") || [];
    setValue("vesselName", vesselName || "", {shouldDirty: !!vesselName});
    setValue("vesselType", vesselType || "", {shouldDirty: !!vesselType});
    setValue("tonnage", tonnage || "", {shouldDirty: !!tonnage});
    setValue("company", company || "", {shouldDirty: !!company});
    setValue("operationCountry", operationCountry || "", {shouldDirty: !!operationCountry});
    setValue("operationArea", areas.length > 0 ? areas.join(", ") : "", {shouldDirty: areas.length > 0});
    setValue("engagementPlace", engagementPlace || "", {shouldDirty: !!engagementPlace});
    setValue("dischargePlace", dischargePlace || "", {shouldDirty: !!dischargePlace});
  };

  const handleReset = () => reset(initialFormState);

  const onSubmit = (data) => {
    if (isValid) {
      const {operationArea: area, operationCountry: country, dateFrom: from, dateTo: to, ...input} = data;
      const operationArea = country !== "" ? `${country}${area !== "" ? `, ${area}` : ""}` : "";
      const dateFrom = formatISO(from, {representation: "date"});
      const dateTo = to ? formatISO(to, {representation: "date"}) : null;
      const logData = {...input, operationArea, dateFrom, dateTo};
      send({type: "addLogEntry", data: {logType: "SeaLog", logData}});
    }
  };

  useEffect(() => setLastRecord(crewSeaLogs?.items[0] || null), [crewSeaLogs]);

  useEffect(() => {
    return () => isDirty && reset(initialFormState);
  }, [isCollapsed, isDirty, reset]);

  useEffect(() => {
    isSubmitted && isSubmitSuccessful && reset(initialFormState);
  }, [isSubmitted, isSubmitSuccessful, reset]);

  useEffect(() => {
    logState.value === "displaying" && !vesselTypes && send({type: "loadVesselTypesList"});
  }, [send, vesselTypes, logState.value]);

  return (
    <Box sx={{mt: 3}}>
      <Typography variant="subtitle1" color="error">
        Last Log Entry
      </Typography>
      <div className="col-xs-12 mb-3" style={{fontSize: "0.9rem"}}>
        <Table rows={!lastRecord ? [] : [lastRecord]} columns={seaLogsColumns} showFooter={false} />
      </div>
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <RankSelectForm control={control} watch={watch} />
        <OperationForm
          control={control}
          watch={watch}
          getValues={getValues}
          trigger={trigger}
          dirtyFields={dirtyFields}
        />
        <VesselForm control={control} vesselTypes={vesselTypes} />
        <Grid container spacing={1} mt={1}>
          <Grid item xs="auto">
            <Button disabled={!lastRecord} onClick={handleClone} variant={"outlined"} color="error">
              Clone Last Entry
            </Button>
          </Grid>
          <Grid item xs="auto">
            <Button onClick={handleReset} variant={"outlined"}>
              Reset Log Entry
            </Button>
          </Grid>
          <Grid item xs />
          <Grid item xs="auto">
            <Button type="submit" variant={"contained"}>
              Add Log Entry
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

SeaLogForm.propTypes = {
  isCollapsed: PropTypes.bool,
};
