/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const verifyRemitterToken = /* GraphQL */ `
  query VerifyRemitterToken($code: String!) {
    verifyRemitterToken(code: $code)
  }
`;
export const getEmployerCrewManager = /* GraphQL */ `
  query GetEmployerCrewManager($email: AWSEmail!) {
    getEmployerCrewManager(email: $email) {
      email
      exists
      fullname
      tenantId
      tenantName
      error
      __typename
    }
  }
`;
export const getEmploymentDetails = /* GraphQL */ `
  query GetEmploymentDetails($id: ID!) {
    getEmploymentDetails(id: $id) {
      id
      tenantId
      employerName
      employmentStatus {
        status
        description
        crewActions
        __typename
      }
      designatedRankTitle
      vesselName
      crewTrainingMatrix
      contract {
        signedDate
        expiryDate
        rotationOn
        rotationOff
        contractFile {
          bucket
          key
          region
          __typename
        }
        offerFile {
          bucket
          key
          region
          __typename
        }
        __typename
      }
      dayRates {
        currency
        dayRates {
          status
          rate
          __typename
        }
        ON_TRAVEL
        ON_QUARANTINE
        ON_BOARD
        ON_SICK_LEAVE
        ON_TRAINING
        ON_STANDBY
        __typename
      }
      employmentEvents {
        items {
          id
          tenantId
          shipCrewId
          senderId
          senderName
          memo
          type
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      salaryPaymentMethod
      __typename
    }
  }
`;
export const getShortEmploymentDetails = /* GraphQL */ `
  query GetShortEmploymentDetails($id: ID!) {
    getShortEmploymentDetails(id: $id) {
      id
      tenantId
      employerName
      employmentStatus {
        status
        description
        crewActions
        __typename
      }
      designatedRankTitle
      vesselName
      crewTrainingMatrix
      contract {
        signedDate
        expiryDate
        rotationOn
        rotationOff
        contractFile {
          bucket
          key
          region
          __typename
        }
        offerFile {
          bucket
          key
          region
          __typename
        }
        __typename
      }
      dayRates {
        currency
        dayRates {
          status
          rate
          __typename
        }
        ON_TRAVEL
        ON_QUARANTINE
        ON_BOARD
        ON_SICK_LEAVE
        ON_TRAINING
        ON_STANDBY
        __typename
      }
      employmentEvents {
        items {
          id
          tenantId
          shipCrewId
          senderId
          senderName
          memo
          type
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      salaryPaymentMethod
      __typename
    }
  }
`;
export const listEmploymentEventInfo = /* GraphQL */ `
  query ListEmploymentEventInfo {
    listEmploymentEventInfo
  }
`;
export const listShipCrewEmployments = /* GraphQL */ `
  query ListShipCrewEmployments {
    listShipCrewEmployments {
      items {
        id
        tenantId
        employerName
        employmentStatus {
          status
          description
          crewActions
          __typename
        }
        designatedRankTitle
        vesselName
        crewTrainingMatrix
        contract {
          signedDate
          expiryDate
          rotationOn
          rotationOff
          __typename
        }
        dayRates {
          currency
          ON_TRAVEL
          ON_QUARANTINE
          ON_BOARD
          ON_SICK_LEAVE
          ON_TRAINING
          ON_STANDBY
          __typename
        }
        employmentEvents {
          nextToken
          __typename
        }
        salaryPaymentMethod
        __typename
      }
      nextToken
      updates
      __typename
    }
  }
`;
export const listVesselTypes = /* GraphQL */ `
  query ListVesselTypes {
    listVesselTypes
  }
`;
export const getSubscriptionStatus = /* GraphQL */ `
  query GetSubscriptionStatus($id: ID!) {
    getSubscriptionStatus(id: $id) {
      id
      isFree
      expiryDate
      customerId
      owner
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isCorporate
      tenantId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSubscriptionStatuses = /* GraphQL */ `
  query ListSubscriptionStatuses(
    $filter: ModelSubscriptionStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscriptionStatuses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isFree
        expiryDate
        customerId
        owner
        crewProfile {
          nextToken
          __typename
        }
        isCorporate
        tenantId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const subscriptionsByExpiryDate = /* GraphQL */ `
  query SubscriptionsByExpiryDate(
    $expiryDate: AWSDate!
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionsByExpiryDate(
      expiryDate: $expiryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isFree
        expiryDate
        customerId
        owner
        crewProfile {
          nextToken
          __typename
        }
        isCorporate
        tenantId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const subscriptionsByCustomerId = /* GraphQL */ `
  query SubscriptionsByCustomerId(
    $customerId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionsByCustomerId(
      customerId: $customerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isFree
        expiryDate
        customerId
        owner
        crewProfile {
          nextToken
          __typename
        }
        isCorporate
        tenantId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const subscriptionByOwner = /* GraphQL */ `
  query SubscriptionByOwner(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSubscriptionStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subscriptionByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        isFree
        expiryDate
        customerId
        owner
        crewProfile {
          nextToken
          __typename
        }
        isCorporate
        tenantId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      description
      expiryInterval
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        expiryInterval
        type
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getActiveNotification = /* GraphQL */ `
  query GetActiveNotification($id: ID!) {
    getActiveNotification(id: $id) {
      id
      notificationId
      notification {
        id
        description
        expiryInterval
        type
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listActiveNotifications = /* GraphQL */ `
  query ListActiveNotifications(
    $filter: ModelActiveNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActiveNotifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        notificationId
        notification {
          id
          description
          expiryInterval
          type
          createdAt
          updatedAt
          __typename
        }
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBlockedAccount = /* GraphQL */ `
  query GetBlockedAccount($id: ID!) {
    getBlockedAccount(id: $id) {
      id
      owner
      reason
      blockedDate
      isClosed
      closedDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listBlockedAccounts = /* GraphQL */ `
  query ListBlockedAccounts(
    $filter: ModelBlockedAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlockedAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        reason
        blockedDate
        isClosed
        closedDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const blockedAccountByOwner = /* GraphQL */ `
  query BlockedAccountByOwner(
    $owner: String!
    $sortDirection: ModelSortDirection
    $filter: ModelBlockedAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    blockedAccountByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        reason
        blockedDate
        isClosed
        closedDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const accountsByBlockedDate = /* GraphQL */ `
  query AccountsByBlockedDate(
    $blockedDate: AWSDate!
    $sortDirection: ModelSortDirection
    $filter: ModelBlockedAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    accountsByBlockedDate(
      blockedDate: $blockedDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        reason
        blockedDate
        isClosed
        closedDate
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCrewDoc = /* GraphQL */ `
  query GetCrewDoc($id: ID!) {
    getCrewDoc(id: $id) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
        __typename
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      docId
      doc {
        id
        title
        descriptions {
          nextToken
          __typename
        }
        crewRanks {
          nextToken
          __typename
        }
        docType
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      docDescriptionId
      docDescription {
        id
        content
        docId
        createdAt
        updatedAt
        __typename
      }
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCrewDocs = /* GraphQL */ `
  query ListCrewDocs(
    $filter: ModelCrewDocFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrewDocs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        issueDate
        expiryDate
        file {
          bucket
          key
          region
          __typename
        }
        docSize
        docNum
        issuePlace
        includedInCV
        title
        docId
        doc {
          id
          title
          docType
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docDescriptionId
        docDescription {
          id
          content
          docId
          createdAt
          updatedAt
          __typename
        }
        crewProfile {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const requiredDocsByOwner = /* GraphQL */ `
  query RequiredDocsByOwner(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCrewDocFilterInput
    $limit: Int
    $nextToken: String
  ) {
    requiredDocsByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        issueDate
        expiryDate
        file {
          bucket
          key
          region
          __typename
        }
        docSize
        docNum
        issuePlace
        includedInCV
        title
        docId
        doc {
          id
          title
          docType
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docDescriptionId
        docDescription {
          id
          content
          docId
          createdAt
          updatedAt
          __typename
        }
        crewProfile {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const crewDocsByOwnerByDocId = /* GraphQL */ `
  query CrewDocsByOwnerByDocId(
    $owner: ID!
    $docId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCrewDocFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crewDocsByOwnerByDocId(
      owner: $owner
      docId: $docId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        issueDate
        expiryDate
        file {
          bucket
          key
          region
          __typename
        }
        docSize
        docNum
        issuePlace
        includedInCV
        title
        docId
        doc {
          id
          title
          docType
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docDescriptionId
        docDescription {
          id
          content
          docId
          createdAt
          updatedAt
          __typename
        }
        crewProfile {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const crewDocsByExpiryDate = /* GraphQL */ `
  query CrewDocsByExpiryDate(
    $expiryDate: AWSDate!
    $sortDirection: ModelSortDirection
    $filter: ModelCrewDocFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crewDocsByExpiryDate(
      expiryDate: $expiryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        issueDate
        expiryDate
        file {
          bucket
          key
          region
          __typename
        }
        docSize
        docNum
        issuePlace
        includedInCV
        title
        docId
        doc {
          id
          title
          docType
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docDescriptionId
        docDescription {
          id
          content
          docId
          createdAt
          updatedAt
          __typename
        }
        crewProfile {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getPersonalDoc = /* GraphQL */ `
  query GetPersonalDoc($id: ID!) {
    getPersonalDoc(id: $id) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
        __typename
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listPersonalDocs = /* GraphQL */ `
  query ListPersonalDocs(
    $filter: ModelPersonalDocFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonalDocs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        issueDate
        expiryDate
        file {
          bucket
          key
          region
          __typename
        }
        docSize
        docNum
        issuePlace
        includedInCV
        title
        crewProfile {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const personalDocsByExpiryDate = /* GraphQL */ `
  query PersonalDocsByExpiryDate(
    $expiryDate: AWSDate!
    $sortDirection: ModelSortDirection
    $filter: ModelPersonalDocFilterInput
    $limit: Int
    $nextToken: String
  ) {
    personalDocsByExpiryDate(
      expiryDate: $expiryDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        issueDate
        expiryDate
        file {
          bucket
          key
          region
          __typename
        }
        docSize
        docNum
        issuePlace
        includedInCV
        title
        crewProfile {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getEmploymentUpdate = /* GraphQL */ `
  query GetEmploymentUpdate($employmentId: ID!) {
    getEmploymentUpdate(employmentId: $employmentId) {
      employmentId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listEmploymentUpdates = /* GraphQL */ `
  query ListEmploymentUpdates(
    $employmentId: ID
    $filter: ModelEmploymentUpdateFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmploymentUpdates(
      employmentId: $employmentId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        employmentId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const employmentUpdatesByOwner = /* GraphQL */ `
  query EmploymentUpdatesByOwner(
    $owner: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelEmploymentUpdateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    employmentUpdatesByOwner(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        employmentId
        owner
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCrewProfile = /* GraphQL */ `
  query GetCrewProfile($id: ID!) {
    getCrewProfile(id: $id) {
      id
      owner
      employerId
      firstName
      midName
      lastName
      birthDate
      birthPlace
      address
      city
      state
      country
      emerContactName
      emerContactRelation
      emerContactPhone
      emerContactAddress
      emerContactEmail
      nextOfKin
      nationality
      fatherName
      motherName
      nearestAirport
      maritalStatus
      religion
      numberOfChildren
      weight
      bloodGroup
      eyesColor
      hairColor
      gender
      coverallSize
      shoeSize
      englishLangLevel
      otherLanguage1
      otherLanguage2
      crewRankId
      crewRank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      docs {
        items {
          id
          owner
          issueDate
          expiryDate
          docSize
          docNum
          issuePlace
          includedInCV
          title
          docId
          docDescriptionId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      personalDocs {
        items {
          id
          owner
          issueDate
          expiryDate
          docSize
          docNum
          issuePlace
          includedInCV
          title
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      seaLog {
        items {
          id
          owner
          vesselName
          vesselType
          tonnage
          operationArea
          engagementPlace
          dischargePlace
          rankTitle
          dateFrom
          dateTo
          company
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diveLog {
        items {
          id
          owner
          diveDate
          contractorName
          contractorAddress
          diveLocation
          worksite
          diveType
          measureUnit
          helmetType
          gasMixture
          workDescription
          decoSchedules
          incidents
          remarks
          divingSupName
          storageDepth
          maxDepth
          bellLockOff
          leftBell
          returnedBell
          lockOutTime
          bellLockOn
          leftSurfaceTime
          bottomTime
          decoCompletedAt
          surfaceDecoInterval
          chamberTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diveSupLog {
        items {
          id
          owner
          diveDate
          contractorName
          contractorAddress
          diveLocation
          worksite
          supervisionType
          diveType
          unitRatio
          incidents
          remarks
          contractorRepTitle
          contractorRepName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications {
        items {
          id
          notificationId
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscriptions {
        items {
          id
          isFree
          expiryDate
          customerId
          owner
          isCorporate
          tenantId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      signatureHash
      employmentUpdates {
        items {
          employmentId
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isOnCrewmarket
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCrewProfiles = /* GraphQL */ `
  query ListCrewProfiles(
    $filter: ModelCrewProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrewProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        employerId
        firstName
        midName
        lastName
        birthDate
        birthPlace
        address
        city
        state
        country
        emerContactName
        emerContactRelation
        emerContactPhone
        emerContactAddress
        emerContactEmail
        nextOfKin
        nationality
        fatherName
        motherName
        nearestAirport
        maritalStatus
        religion
        numberOfChildren
        weight
        bloodGroup
        eyesColor
        hairColor
        gender
        coverallSize
        shoeSize
        englishLangLevel
        otherLanguage1
        otherLanguage2
        crewRankId
        crewRank {
          id
          title
          status
          hierarchy
          groupId
          department
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        personalDocs {
          nextToken
          __typename
        }
        seaLog {
          nextToken
          __typename
        }
        diveLog {
          nextToken
          __typename
        }
        diveSupLog {
          nextToken
          __typename
        }
        notifications {
          nextToken
          __typename
        }
        subscriptions {
          nextToken
          __typename
        }
        signatureHash
        employmentUpdates {
          nextToken
          __typename
        }
        isOnCrewmarket
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const crewByRank = /* GraphQL */ `
  query CrewByRank(
    $crewRankId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCrewProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crewByRank(
      crewRankId: $crewRankId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        employerId
        firstName
        midName
        lastName
        birthDate
        birthPlace
        address
        city
        state
        country
        emerContactName
        emerContactRelation
        emerContactPhone
        emerContactAddress
        emerContactEmail
        nextOfKin
        nationality
        fatherName
        motherName
        nearestAirport
        maritalStatus
        religion
        numberOfChildren
        weight
        bloodGroup
        eyesColor
        hairColor
        gender
        coverallSize
        shoeSize
        englishLangLevel
        otherLanguage1
        otherLanguage2
        crewRankId
        crewRank {
          id
          title
          status
          hierarchy
          groupId
          department
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        personalDocs {
          nextToken
          __typename
        }
        seaLog {
          nextToken
          __typename
        }
        diveLog {
          nextToken
          __typename
        }
        diveSupLog {
          nextToken
          __typename
        }
        notifications {
          nextToken
          __typename
        }
        subscriptions {
          nextToken
          __typename
        }
        signatureHash
        employmentUpdates {
          nextToken
          __typename
        }
        isOnCrewmarket
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const crewmarketStatus = /* GraphQL */ `
  query CrewmarketStatus(
    $isOnCrewmarket: IsOnCrewmarketStatus!
    $sortDirection: ModelSortDirection
    $filter: ModelCrewProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    crewmarketStatus(
      isOnCrewmarket: $isOnCrewmarket
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        employerId
        firstName
        midName
        lastName
        birthDate
        birthPlace
        address
        city
        state
        country
        emerContactName
        emerContactRelation
        emerContactPhone
        emerContactAddress
        emerContactEmail
        nextOfKin
        nationality
        fatherName
        motherName
        nearestAirport
        maritalStatus
        religion
        numberOfChildren
        weight
        bloodGroup
        eyesColor
        hairColor
        gender
        coverallSize
        shoeSize
        englishLangLevel
        otherLanguage1
        otherLanguage2
        crewRankId
        crewRank {
          id
          title
          status
          hierarchy
          groupId
          department
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        personalDocs {
          nextToken
          __typename
        }
        seaLog {
          nextToken
          __typename
        }
        diveLog {
          nextToken
          __typename
        }
        diveSupLog {
          nextToken
          __typename
        }
        notifications {
          nextToken
          __typename
        }
        subscriptions {
          nextToken
          __typename
        }
        signatureHash
        employmentUpdates {
          nextToken
          __typename
        }
        isOnCrewmarket
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRankGroup = /* GraphQL */ `
  query GetRankGroup($id: ID!) {
    getRankGroup(id: $id) {
      id
      title
      status
      subscriptionCharge
      subscriptionDetails
      subscriptionPriceId
      bucketSize
      hierarchy
      crewRanks {
        items {
          id
          title
          status
          hierarchy
          groupId
          department
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRankGroups = /* GraphQL */ `
  query ListRankGroups(
    $filter: ModelRankGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRankGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        subscriptionCharge
        subscriptionDetails
        subscriptionPriceId
        bucketSize
        hierarchy
        crewRanks {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rankGroupsByCharge = /* GraphQL */ `
  query RankGroupsByCharge(
    $status: String!
    $subscriptionCharge: ModelFloatKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRankGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rankGroupsByCharge(
      status: $status
      subscriptionCharge: $subscriptionCharge
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        status
        subscriptionCharge
        subscriptionDetails
        subscriptionPriceId
        bucketSize
        hierarchy
        crewRanks {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getCrewRank = /* GraphQL */ `
  query GetCrewRank($id: ID!) {
    getCrewRank(id: $id) {
      id
      title
      status
      hierarchy
      groupId
      group {
        id
        title
        status
        subscriptionCharge
        subscriptionDetails
        subscriptionPriceId
        bucketSize
        hierarchy
        crewRanks {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      docs {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      designatedTitles {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      department
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listCrewRanks = /* GraphQL */ `
  query ListCrewRanks(
    $filter: ModelCrewRankFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCrewRanks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const ranksByTitle = /* GraphQL */ `
  query RanksByTitle(
    $status: String!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCrewRankFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ranksByTitle(
      status: $status
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const rankByGroup = /* GraphQL */ `
  query RankByGroup(
    $groupId: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCrewRankFilterInput
    $limit: Int
    $nextToken: String
  ) {
    rankByGroup(
      groupId: $groupId
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDesignatedRankTitle = /* GraphQL */ `
  query GetDesignatedRankTitle($id: ID!) {
    getDesignatedRankTitle(id: $id) {
      id
      title
      crewRanks {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDesignatedRankTitles = /* GraphQL */ `
  query ListDesignatedRankTitles(
    $filter: ModelDesignatedRankTitleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDesignatedRankTitles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        crewRanks {
          nextToken
          __typename
        }
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDoc = /* GraphQL */ `
  query GetDoc($id: ID!) {
    getDoc(id: $id) {
      id
      title
      descriptions {
        items {
          id
          content
          docId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      crewRanks {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      docType
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDocs = /* GraphQL */ `
  query ListDocs(
    $filter: ModelDocFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        descriptions {
          nextToken
          __typename
        }
        crewRanks {
          nextToken
          __typename
        }
        docType
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDocDescription = /* GraphQL */ `
  query GetDocDescription($id: ID!) {
    getDocDescription(id: $id) {
      id
      content
      docId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDocDescriptions = /* GraphQL */ `
  query ListDocDescriptions(
    $filter: ModelDocDescriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocDescriptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        docId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const descriptionByDoc = /* GraphQL */ `
  query DescriptionByDoc(
    $docId: ID!
    $content: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocDescriptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    descriptionByDoc(
      docId: $docId
      content: $content
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        docId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSeaLog = /* GraphQL */ `
  query GetSeaLog($id: ID!) {
    getSeaLog(id: $id) {
      id
      owner
      vesselName
      vesselType
      tonnage
      operationArea
      engagementPlace
      dischargePlace
      rankTitle
      dateFrom
      dateTo
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listSeaLogs = /* GraphQL */ `
  query ListSeaLogs(
    $filter: ModelSeaLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSeaLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        vesselName
        vesselType
        tonnage
        operationArea
        engagementPlace
        dischargePlace
        rankTitle
        dateFrom
        dateTo
        company
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDiverLog = /* GraphQL */ `
  query GetDiverLog($id: ID!) {
    getDiverLog(id: $id) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      diveType
      measureUnit
      helmetType
      gasMixture
      workDescription
      decoSchedules
      incidents
      remarks
      divingSupName
      storageDepth
      maxDepth
      bellLockOff
      leftBell
      returnedBell
      lockOutTime
      bellLockOn
      leftSurfaceTime
      bottomTime
      decoCompletedAt
      surfaceDecoInterval
      chamberTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDiverLogs = /* GraphQL */ `
  query ListDiverLogs(
    $filter: ModelDiverLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiverLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        diveDate
        contractorName
        contractorAddress
        diveLocation
        worksite
        diveType
        measureUnit
        helmetType
        gasMixture
        workDescription
        decoSchedules
        incidents
        remarks
        divingSupName
        storageDepth
        maxDepth
        bellLockOff
        leftBell
        returnedBell
        lockOutTime
        bellLockOn
        leftSurfaceTime
        bottomTime
        decoCompletedAt
        surfaceDecoInterval
        chamberTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const diverLogByOwner = /* GraphQL */ `
  query DiverLogByOwner(
    $owner: ID!
    $diveDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDiverLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    diverLogByOwner(
      owner: $owner
      diveDate: $diveDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        diveDate
        contractorName
        contractorAddress
        diveLocation
        worksite
        diveType
        measureUnit
        helmetType
        gasMixture
        workDescription
        decoSchedules
        incidents
        remarks
        divingSupName
        storageDepth
        maxDepth
        bellLockOff
        leftBell
        returnedBell
        lockOutTime
        bellLockOn
        leftSurfaceTime
        bottomTime
        decoCompletedAt
        surfaceDecoInterval
        chamberTime
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDiveSupLog = /* GraphQL */ `
  query GetDiveSupLog($id: ID!) {
    getDiveSupLog(id: $id) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      supervisionType
      diveType
      unitRatio
      incidents
      remarks
      contractorRepTitle
      contractorRepName
      supervisedDives {
        items {
          id
          storageDepth
          excursionDepth
          diversNum
          decoRange
          diveDuration
          workDescription
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listDiveSupLogs = /* GraphQL */ `
  query ListDiveSupLogs(
    $filter: ModelDiveSupLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiveSupLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        diveDate
        contractorName
        contractorAddress
        diveLocation
        worksite
        supervisionType
        diveType
        unitRatio
        incidents
        remarks
        contractorRepTitle
        contractorRepName
        supervisedDives {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const diveSupLogByOwner = /* GraphQL */ `
  query DiveSupLogByOwner(
    $owner: ID!
    $diveDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDiveSupLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    diveSupLogByOwner(
      owner: $owner
      diveDate: $diveDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        diveDate
        contractorName
        contractorAddress
        diveLocation
        worksite
        supervisionType
        diveType
        unitRatio
        incidents
        remarks
        contractorRepTitle
        contractorRepName
        supervisedDives {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSupDiveInfo = /* GraphQL */ `
  query GetSupDiveInfo($id: ID!) {
    getSupDiveInfo(id: $id) {
      id
      storageDepth
      excursionDepth
      diversNum
      decoRange
      diveDuration
      workDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSupDiveInfos = /* GraphQL */ `
  query ListSupDiveInfos(
    $filter: ModelSupDiveInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSupDiveInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        storageDepth
        excursionDepth
        diversNum
        decoRange
        diveDuration
        workDescription
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
