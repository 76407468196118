import {saveAs} from "file-saver";
import {
  createAccount,
  createWallet,
  deleteAccount,
  deleteBankCertificate,
  deleteWallet,
  setPreferredAccount,
  setPreferredWallet,
  updateBeneficiary,
} from "graphqlMarfin/mutations";
import {
  getBankAccount,
  getDownloadUrl,
  getUploadUrl,
  listBankAccounts,
  listCountryTemplates,
  listWalletProviders,
  listWallets,
} from "graphqlMarfin/queries";
import {getFileExtension} from "lib/helpers";
import {Is} from "lib/helpers/is";
import UrqlClient, {DEFAULT_ERROR, QueryType} from "lib/helpers/urqlClient/urqlClient";
import {getBankAccountId, pollGetBankAccountApi} from "machine/serviceMachines/marfinMachineSet/bankAccount";

const services = {
  initialize: {
    src: () => (sendBack) => {
      const listBankAccountsTask = UrqlClient.execute(QueryType.QUERY, listBankAccounts, "accounts");
      const listWalletsTask = UrqlClient.execute(QueryType.QUERY, listWallets, "wallets");
      const listWalletProvidersTask = UrqlClient.execute(QueryType.QUERY, listWalletProviders, "providers");

      Promise.all([listBankAccountsTask, listWalletsTask, listWalletProvidersTask])
        .then(([bankAccountsResponse, walletsResponse, eWalletProvidersResponse]) => {
          const {error: bankAccountsError, data: bankAccounts = []} = bankAccountsResponse;
          const {error: walletsError, data: eWallets = []} = walletsResponse;
          const {error: walletProvidersError, data: eWalletProviders = []} = eWalletProvidersResponse;
          const errorMessage =
            bankAccountsError || walletsError || walletProvidersError
              ? [bankAccountsError?.message, walletsError?.message, walletProvidersError?.message]
                  .filter((item) => !!item)
                  .join(", ")
              : undefined;

          !errorMessage
            ? sendBack({type: "SUCCESS", data: {bankAccounts, eWallets, eWalletProviders}})
            : sendBack({type: "ERROR", data: {message: errorMessage}});
        })
        .catch((data) => sendBack({type: "ERROR", data}));
    },
  },
  getCountryTemplatesList: {
    src: () => (sendBack) => {
      UrqlClient.execute(QueryType.QUERY, listCountryTemplates, "listCountryTemplates")
        .then(({error, data}) => {
          !error
            ? sendBack({type: "SUCCESS", data: data ?? []})
            : sendBack({type: "ERROR", data: {message: error.message}});
        })
        .catch((data) => sendBack({type: "ERROR", data}));
    },
  },
  createAccount: {
    src:
      (_, {data}) =>
      (sendBack) => {
        UrqlClient.execute(QueryType.MUTATION, createAccount, "createAccount", data)
          .then(({error, data}) => {
            !error
              ? sendBack({type: "SUCCESS", data: data})
              : sendBack({type: "ERROR", data: {message: error.message}});
          })
          .catch((data) => sendBack({type: "ERROR", data}));
      },
  },
  getBankAccount: {
    src:
      ({bankAccounts}, {data: {accountId, crewProfileId}}) =>
      (sendBack) => {
        const account =
          accountId && crewProfileId
            ? bankAccounts.find((item) => getBankAccountId(item, crewProfileId) === accountId)
            : undefined;

        if (!account) {
          sendBack({
            type: "ERROR",
            data: {
              message:
                "Something went wrong! Account details not loaded. Try again or contact us for technical support.",
            },
          });
        } else {
          const listCountryTemplatesTask = UrqlClient.execute(
            QueryType.QUERY,
            listCountryTemplates,
            "listCountryTemplates"
          );
          const getBankAccountTask = UrqlClient.execute(QueryType.QUERY, getBankAccount, "account", {
            accountNumber: account.accountNumber,
            swiftCode: account.swiftCode,
          });

          Promise.all([listCountryTemplatesTask, getBankAccountTask])
            .then(([countryTemplatesReponse, bankAccountResponse]) => {
              const {error: countryTemplatesError, data: countryTemplates} = countryTemplatesReponse;
              const {error: bankAccountError, data: bankAccount} = bankAccountResponse;

              !countryTemplatesError && !bankAccountError
                ? sendBack({type: "SUCCESS", data: {countryTemplates, bankAccount}})
                : sendBack({
                    type: "ERROR",
                    data: {message: countryTemplatesError?.message ?? bankAccountError?.message},
                  });
            })
            .catch((data) => sendBack({type: "ERROR", data}));
        }
      },
  },
  deleteAccount: {
    src:
      (_, {data}) =>
      (sendBack) => {
        UrqlClient.execute(QueryType.MUTATION, deleteAccount, "deleteAccount", data)
          .then(({error, data}) => {
            !error
              ? sendBack({type: "SUCCESS", data: data})
              : sendBack({type: "ERROR", data: {message: error.message}});
          })
          .catch((data) => sendBack({type: "ERROR", data}));
      },
  },
  setPreferredAccount: {
    src:
      (_, {data}) =>
      (sendBack) => {
        UrqlClient.execute(QueryType.MUTATION, setPreferredAccount, "setPreferred", data)
          .then(({error, data}) => {
            !error
              ? sendBack({type: "SUCCESS", data: data})
              : sendBack({type: "ERROR", data: {message: error.message}});
          })
          .catch((data) => sendBack({type: "ERROR", data}));
      },
  },
  updateAccountBeneficiary: {
    src:
      (_, {data}) =>
      (sendBack) => {
        UrqlClient.execute(QueryType.MUTATION, updateBeneficiary, "updateBeneficiary", data)
          .then(({error, data}) => {
            !error
              ? sendBack({type: "SUCCESS", data: {bankAccount: data}})
              : sendBack({type: "ERROR", data: {message: error.message}});
          })
          .catch((data) => sendBack({type: "ERROR", data}));
      },
  },
  uploadBankCertificate: {
    src:
      (_, {data: {uploadUrlData, file}}) =>
      (sendBack) => {
        UrqlClient.execute(QueryType.QUERY, getUploadUrl, "getUploadUrl", uploadUrlData)
          .then(async ({error, data}) => {
            if (error ?? !data.s3Url) {
              throw new Error(error.message ?? DEFAULT_ERROR);
            } else {
              return fetch(data.s3Url, {method: "PUT", body: file});
            }
          })
          .then((response) =>
            response.status === 200
              ? pollGetBankAccountApi(
                  uploadUrlData.accountNumber,
                  uploadUrlData.swiftCode,
                  1000,
                  (respone) => Is.defined(respone.error) || Is.defined(respone.data?.bankAccountCertificate?.s3Uri)
                )
              : Promise.reject({message: `Certificate upload failed. ${DEFAULT_ERROR}`})
          )
          .then(({error, data: bankAccount}) => {
            !error
              ? sendBack({type: "SUCCESS", data: {bankAccount}})
              : sendBack({
                  type: "ERROR",
                  data: {message: error?.message},
                });
          })
          .catch((data) => sendBack({type: "ERROR", data}));
      },
  },
  downloadBankCertificate: {
    src:
      (_, {data: {downloadUrlData, fileKey}}) =>
      (sendBack) => {
        UrqlClient.execute(QueryType.QUERY, getDownloadUrl, "getDownloadUrl", downloadUrlData)
          .then(({error, data}) => {
            if (error ?? !data.s3Url) {
              throw new Error(error.message ?? DEFAULT_ERROR);
            } else {
              return fetch(data.s3Url, {method: "GET"});
            }
          })
          .then((response) => response.blob())
          .then((file) => {
            saveAs(file, `Bank_certificate_${downloadUrlData.accountNumber}.${getFileExtension(fileKey)}`);
            sendBack({type: "SUCCESS"});
          })
          .catch((data) => sendBack({type: "ERROR", data}));
      },
  },
  deleteBankCertificate: {
    src:
      (_, {data}) =>
      (sendBack) => {
        UrqlClient.execute(QueryType.MUTATION, deleteBankCertificate, "deleteCertificate", data)
          .then(({error, data}) => {
            !error
              ? sendBack({type: "SUCCESS", data: {bankAccount: data}})
              : sendBack({type: "ERROR", data: {message: error.message}});
          })
          .catch((data) => sendBack({type: "ERROR", data}));
      },
  },
  createWallet: {
    src:
      (_, {data}) =>
      (sendBack) => {
        UrqlClient.execute(QueryType.MUTATION, createWallet, "createWallet", data)
          .then(({error, data}) => {
            !error
              ? sendBack({type: "SUCCESS", data: data})
              : sendBack({type: "ERROR", data: {message: error.message}});
          })
          .catch((data) => sendBack({type: "ERROR", data}));
      },
  },
  deleteWallet: {
    src:
      (_, {data}) =>
      (sendBack) => {
        UrqlClient.execute(QueryType.MUTATION, deleteWallet, "deleteWallet", data)
          .then(({error, data}) => {
            !error
              ? sendBack({type: "SUCCESS", data: data})
              : sendBack({type: "ERROR", data: {message: error.message}});
          })
          .catch((data) => sendBack({type: "ERROR", data}));
      },
  },
  setPreferredWallet: {
    src:
      (_, {data}) =>
      (sendBack) => {
        UrqlClient.execute(QueryType.MUTATION, setPreferredWallet, "setPreferredWallet", data)
          .then(({error, data}) => {
            !error
              ? sendBack({type: "SUCCESS", data: data})
              : sendBack({type: "ERROR", data: {message: error.message}});
          })
          .catch((data) => sendBack({type: "ERROR", data}));
      },
  },
};

export default services;
