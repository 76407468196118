import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import Footer from "./Footer";
import Header from "./Header";
import Row from "./Row";
import sortData from "./sortData";

import "scss/customeTable.scss";

export default function Table(props) {
  const {columns, rows, rowStyle, onRowDelete, onRowEdit, onDocDownload, showFooter = true} = props;
  const totalCols = columns.length + (onRowDelete ? 1 : 0) + (onRowEdit ? 1 : 0) + (onDocDownload ? 1 : 0);
  const [tableData, setTableData] = useState(rows);
  const [tbody, setTbody] = useState(null);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [pagerSetup, setPagerSetup] = useState({
    pageSizes: [7, 10, 15, 20],
    currentPageSize: 7,
    totalRows: 0,
    totalPages: 1,
    currentPage: 0,
  });

  const onHeaderClick = (column) => {
    if (!!sortedColumn && sortedColumn.field !== column.field) {
      sortedColumn.sorted = 0;
    }
    column.sorted = column.sorted ? -column.sorted : 1;

    const sortedData = sortData({rows: tableData, column});
    setTableData(sortedData);
    setSortedColumn(column);
  };
  const onPagerClick = (nextActivePage) => setPagerSetup((setup) => ({...setup, currentPage: nextActivePage}));
  const onPageSizeChanged = (pageSize) =>
    setPagerSetup((setup) => ({
      ...setup,
      currentPageSize: pageSize,
      totalPages: Math.max(1, Math.ceil(rows?.length / pageSize)),
    }));
  const thead = columns.map((column) => <Header key={column.field} column={column} onClick={onHeaderClick} />);

  useEffect(() => {
    setTableData(rows);
    setPagerSetup((setup) => ({
      ...setup,
      totalRows: rows?.length || 0,
      totalPages: Math.max(1, Math.ceil(rows?.length / setup.currentPageSize)),
    }));
  }, [rows, columns.length]);

  useEffect(() => {
    const rowStyleSetup = (row) => (rowStyle ? rowStyle(row) : {});
    const {currentPageSize, currentPage} = pagerSetup;
    const pageData = tableData.slice(currentPageSize * currentPage, currentPageSize * currentPage + currentPageSize);

    setTbody(
      pageData.length === 0 ? (
        <tr className="text-center text-danger mx-auto">
          <td colSpan={totalCols}>no data...</td>
        </tr>
      ) : (
        pageData.map((row) => (
          <Row
            key={row.id}
            styleSetup={rowStyleSetup(row)}
            columns={columns}
            row={row}
            onEdit={onRowEdit || null}
            onDelete={onRowDelete || null}
            onDownload={onDocDownload || null}
          />
        ))
      )
    );
  }, [tableData, columns, totalCols, onRowDelete, onRowEdit, onDocDownload, rowStyle, pagerSetup]);

  return (
    <div className="custome-table table-responsive bg-light pb-0">
      <table className="table table-hover mb-0">
        <thead>
          <tr>
            {thead}
            {!!onRowEdit && <th scope="col"></th>}
            {!!onRowDelete && <th scope="col"></th>}
            {!!onDocDownload && <th scope="col"></th>}
          </tr>
        </thead>
        <tbody>{tbody}</tbody>
        {showFooter && (
          <tfoot>
            <Footer
              pagerSetup={{...pagerSetup, totalCols}}
              onPagerClick={onPagerClick}
              onPageSizeChanged={onPageSizeChanged}
            />
          </tfoot>
        )}
      </table>
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  rowStyle: PropTypes.func,
  onRowDelete: PropTypes.func,
  onRowEdit: PropTypes.func,
  onDocDownload: PropTypes.func,
  showFooter: PropTypes.bool,
};
