/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createShipCrewEmployment = /* GraphQL */ `
  mutation CreateShipCrewEmployment($input: CreateShipCrewEmploymentInput!) {
    createShipCrewEmployment(input: $input) {
      id
      tenantId
      employerName
      employmentStatus {
        status
        description
        crewActions
        __typename
      }
      designatedRankTitle
      vesselName
      crewTrainingMatrix
      contract {
        signedDate
        expiryDate
        rotationOn
        rotationOff
        contractFile {
          bucket
          key
          region
          __typename
        }
        offerFile {
          bucket
          key
          region
          __typename
        }
        __typename
      }
      dayRates {
        currency
        dayRates {
          status
          rate
          __typename
        }
        ON_TRAVEL
        ON_QUARANTINE
        ON_BOARD
        ON_SICK_LEAVE
        ON_TRAINING
        ON_STANDBY
        __typename
      }
      employmentEvents {
        items {
          id
          tenantId
          shipCrewId
          senderId
          senderName
          memo
          type
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      salaryPaymentMethod
      __typename
    }
  }
`;
export const createShipCrewEmploymentEvent = /* GraphQL */ `
  mutation CreateShipCrewEmploymentEvent(
    $input: CreateShipCrewEmploymentEventInput!
  ) {
    createShipCrewEmploymentEvent(input: $input) {
      id
      tenantId
      shipCrewId
      senderId
      senderName
      memo
      file {
        bucket
        key
        region
        __typename
      }
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateShipCrewEmployment = /* GraphQL */ `
  mutation UpdateShipCrewEmployment($input: UpdateShipCrewEmploymentInput!) {
    updateShipCrewEmployment(input: $input) {
      id
      tenantId
      employerName
      employmentStatus {
        status
        description
        crewActions
        __typename
      }
      designatedRankTitle
      vesselName
      crewTrainingMatrix
      contract {
        signedDate
        expiryDate
        rotationOn
        rotationOff
        contractFile {
          bucket
          key
          region
          __typename
        }
        offerFile {
          bucket
          key
          region
          __typename
        }
        __typename
      }
      dayRates {
        currency
        dayRates {
          status
          rate
          __typename
        }
        ON_TRAVEL
        ON_QUARANTINE
        ON_BOARD
        ON_SICK_LEAVE
        ON_TRAINING
        ON_STANDBY
        __typename
      }
      employmentEvents {
        items {
          id
          tenantId
          shipCrewId
          senderId
          senderName
          memo
          type
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      salaryPaymentMethod
      __typename
    }
  }
`;
export const deleteProfileField = /* GraphQL */ `
  mutation DeleteProfileField($input: DeleteProfileFieldInput!) {
    deleteProfileField(input: $input)
  }
`;
export const createSubscriptionStatus = /* GraphQL */ `
  mutation CreateSubscriptionStatus(
    $input: CreateSubscriptionStatusInput!
    $condition: ModelSubscriptionStatusConditionInput
  ) {
    createSubscriptionStatus(input: $input, condition: $condition) {
      id
      isFree
      expiryDate
      customerId
      owner
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isCorporate
      tenantId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSubscriptionStatus = /* GraphQL */ `
  mutation UpdateSubscriptionStatus(
    $input: UpdateSubscriptionStatusInput!
    $condition: ModelSubscriptionStatusConditionInput
  ) {
    updateSubscriptionStatus(input: $input, condition: $condition) {
      id
      isFree
      expiryDate
      customerId
      owner
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isCorporate
      tenantId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSubscriptionStatus = /* GraphQL */ `
  mutation DeleteSubscriptionStatus(
    $input: DeleteSubscriptionStatusInput!
    $condition: ModelSubscriptionStatusConditionInput
  ) {
    deleteSubscriptionStatus(input: $input, condition: $condition) {
      id
      isFree
      expiryDate
      customerId
      owner
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isCorporate
      tenantId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      description
      expiryInterval
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      description
      expiryInterval
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      description
      expiryInterval
      type
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createActiveNotification = /* GraphQL */ `
  mutation CreateActiveNotification(
    $input: CreateActiveNotificationInput!
    $condition: ModelActiveNotificationConditionInput
  ) {
    createActiveNotification(input: $input, condition: $condition) {
      id
      notificationId
      notification {
        id
        description
        expiryInterval
        type
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateActiveNotification = /* GraphQL */ `
  mutation UpdateActiveNotification(
    $input: UpdateActiveNotificationInput!
    $condition: ModelActiveNotificationConditionInput
  ) {
    updateActiveNotification(input: $input, condition: $condition) {
      id
      notificationId
      notification {
        id
        description
        expiryInterval
        type
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteActiveNotification = /* GraphQL */ `
  mutation DeleteActiveNotification(
    $input: DeleteActiveNotificationInput!
    $condition: ModelActiveNotificationConditionInput
  ) {
    deleteActiveNotification(input: $input, condition: $condition) {
      id
      notificationId
      notification {
        id
        description
        expiryInterval
        type
        createdAt
        updatedAt
        __typename
      }
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createBlockedAccount = /* GraphQL */ `
  mutation CreateBlockedAccount(
    $input: CreateBlockedAccountInput!
    $condition: ModelBlockedAccountConditionInput
  ) {
    createBlockedAccount(input: $input, condition: $condition) {
      id
      owner
      reason
      blockedDate
      isClosed
      closedDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateBlockedAccount = /* GraphQL */ `
  mutation UpdateBlockedAccount(
    $input: UpdateBlockedAccountInput!
    $condition: ModelBlockedAccountConditionInput
  ) {
    updateBlockedAccount(input: $input, condition: $condition) {
      id
      owner
      reason
      blockedDate
      isClosed
      closedDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteBlockedAccount = /* GraphQL */ `
  mutation DeleteBlockedAccount(
    $input: DeleteBlockedAccountInput!
    $condition: ModelBlockedAccountConditionInput
  ) {
    deleteBlockedAccount(input: $input, condition: $condition) {
      id
      owner
      reason
      blockedDate
      isClosed
      closedDate
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCrewDoc = /* GraphQL */ `
  mutation CreateCrewDoc(
    $input: CreateCrewDocInput!
    $condition: ModelCrewDocConditionInput
  ) {
    createCrewDoc(input: $input, condition: $condition) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
        __typename
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      docId
      doc {
        id
        title
        descriptions {
          nextToken
          __typename
        }
        crewRanks {
          nextToken
          __typename
        }
        docType
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      docDescriptionId
      docDescription {
        id
        content
        docId
        createdAt
        updatedAt
        __typename
      }
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCrewDoc = /* GraphQL */ `
  mutation UpdateCrewDoc(
    $input: UpdateCrewDocInput!
    $condition: ModelCrewDocConditionInput
  ) {
    updateCrewDoc(input: $input, condition: $condition) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
        __typename
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      docId
      doc {
        id
        title
        descriptions {
          nextToken
          __typename
        }
        crewRanks {
          nextToken
          __typename
        }
        docType
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      docDescriptionId
      docDescription {
        id
        content
        docId
        createdAt
        updatedAt
        __typename
      }
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCrewDoc = /* GraphQL */ `
  mutation DeleteCrewDoc(
    $input: DeleteCrewDocInput!
    $condition: ModelCrewDocConditionInput
  ) {
    deleteCrewDoc(input: $input, condition: $condition) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
        __typename
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      docId
      doc {
        id
        title
        descriptions {
          nextToken
          __typename
        }
        crewRanks {
          nextToken
          __typename
        }
        docType
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      docDescriptionId
      docDescription {
        id
        content
        docId
        createdAt
        updatedAt
        __typename
      }
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPersonalDoc = /* GraphQL */ `
  mutation CreatePersonalDoc(
    $input: CreatePersonalDocInput!
    $condition: ModelPersonalDocConditionInput
  ) {
    createPersonalDoc(input: $input, condition: $condition) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
        __typename
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePersonalDoc = /* GraphQL */ `
  mutation UpdatePersonalDoc(
    $input: UpdatePersonalDocInput!
    $condition: ModelPersonalDocConditionInput
  ) {
    updatePersonalDoc(input: $input, condition: $condition) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
        __typename
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePersonalDoc = /* GraphQL */ `
  mutation DeletePersonalDoc(
    $input: DeletePersonalDocInput!
    $condition: ModelPersonalDocConditionInput
  ) {
    deletePersonalDoc(input: $input, condition: $condition) {
      id
      owner
      issueDate
      expiryDate
      file {
        bucket
        key
        region
        __typename
      }
      docSize
      docNum
      issuePlace
      includedInCV
      title
      crewProfile {
        items {
          id
          owner
          employerId
          firstName
          midName
          lastName
          birthDate
          birthPlace
          address
          city
          state
          country
          emerContactName
          emerContactRelation
          emerContactPhone
          emerContactAddress
          emerContactEmail
          nextOfKin
          nationality
          fatherName
          motherName
          nearestAirport
          maritalStatus
          religion
          numberOfChildren
          weight
          bloodGroup
          eyesColor
          hairColor
          gender
          coverallSize
          shoeSize
          englishLangLevel
          otherLanguage1
          otherLanguage2
          crewRankId
          signatureHash
          isOnCrewmarket
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmploymentUpdate = /* GraphQL */ `
  mutation CreateEmploymentUpdate(
    $input: CreateEmploymentUpdateInput!
    $condition: ModelEmploymentUpdateConditionInput
  ) {
    createEmploymentUpdate(input: $input, condition: $condition) {
      employmentId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEmploymentUpdate = /* GraphQL */ `
  mutation UpdateEmploymentUpdate(
    $input: UpdateEmploymentUpdateInput!
    $condition: ModelEmploymentUpdateConditionInput
  ) {
    updateEmploymentUpdate(input: $input, condition: $condition) {
      employmentId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEmploymentUpdate = /* GraphQL */ `
  mutation DeleteEmploymentUpdate(
    $input: DeleteEmploymentUpdateInput!
    $condition: ModelEmploymentUpdateConditionInput
  ) {
    deleteEmploymentUpdate(input: $input, condition: $condition) {
      employmentId
      owner
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCrewProfile = /* GraphQL */ `
  mutation CreateCrewProfile(
    $input: CreateCrewProfileInput!
    $condition: ModelCrewProfileConditionInput
  ) {
    createCrewProfile(input: $input, condition: $condition) {
      id
      owner
      employerId
      firstName
      midName
      lastName
      birthDate
      birthPlace
      address
      city
      state
      country
      emerContactName
      emerContactRelation
      emerContactPhone
      emerContactAddress
      emerContactEmail
      nextOfKin
      nationality
      fatherName
      motherName
      nearestAirport
      maritalStatus
      religion
      numberOfChildren
      weight
      bloodGroup
      eyesColor
      hairColor
      gender
      coverallSize
      shoeSize
      englishLangLevel
      otherLanguage1
      otherLanguage2
      crewRankId
      crewRank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      docs {
        items {
          id
          owner
          issueDate
          expiryDate
          docSize
          docNum
          issuePlace
          includedInCV
          title
          docId
          docDescriptionId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      personalDocs {
        items {
          id
          owner
          issueDate
          expiryDate
          docSize
          docNum
          issuePlace
          includedInCV
          title
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      seaLog {
        items {
          id
          owner
          vesselName
          vesselType
          tonnage
          operationArea
          engagementPlace
          dischargePlace
          rankTitle
          dateFrom
          dateTo
          company
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diveLog {
        items {
          id
          owner
          diveDate
          contractorName
          contractorAddress
          diveLocation
          worksite
          diveType
          measureUnit
          helmetType
          gasMixture
          workDescription
          decoSchedules
          incidents
          remarks
          divingSupName
          storageDepth
          maxDepth
          bellLockOff
          leftBell
          returnedBell
          lockOutTime
          bellLockOn
          leftSurfaceTime
          bottomTime
          decoCompletedAt
          surfaceDecoInterval
          chamberTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diveSupLog {
        items {
          id
          owner
          diveDate
          contractorName
          contractorAddress
          diveLocation
          worksite
          supervisionType
          diveType
          unitRatio
          incidents
          remarks
          contractorRepTitle
          contractorRepName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications {
        items {
          id
          notificationId
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscriptions {
        items {
          id
          isFree
          expiryDate
          customerId
          owner
          isCorporate
          tenantId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      signatureHash
      employmentUpdates {
        items {
          employmentId
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isOnCrewmarket
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCrewProfile = /* GraphQL */ `
  mutation UpdateCrewProfile(
    $input: UpdateCrewProfileInput!
    $condition: ModelCrewProfileConditionInput
  ) {
    updateCrewProfile(input: $input, condition: $condition) {
      id
      owner
      employerId
      firstName
      midName
      lastName
      birthDate
      birthPlace
      address
      city
      state
      country
      emerContactName
      emerContactRelation
      emerContactPhone
      emerContactAddress
      emerContactEmail
      nextOfKin
      nationality
      fatherName
      motherName
      nearestAirport
      maritalStatus
      religion
      numberOfChildren
      weight
      bloodGroup
      eyesColor
      hairColor
      gender
      coverallSize
      shoeSize
      englishLangLevel
      otherLanguage1
      otherLanguage2
      crewRankId
      crewRank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      docs {
        items {
          id
          owner
          issueDate
          expiryDate
          docSize
          docNum
          issuePlace
          includedInCV
          title
          docId
          docDescriptionId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      personalDocs {
        items {
          id
          owner
          issueDate
          expiryDate
          docSize
          docNum
          issuePlace
          includedInCV
          title
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      seaLog {
        items {
          id
          owner
          vesselName
          vesselType
          tonnage
          operationArea
          engagementPlace
          dischargePlace
          rankTitle
          dateFrom
          dateTo
          company
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diveLog {
        items {
          id
          owner
          diveDate
          contractorName
          contractorAddress
          diveLocation
          worksite
          diveType
          measureUnit
          helmetType
          gasMixture
          workDescription
          decoSchedules
          incidents
          remarks
          divingSupName
          storageDepth
          maxDepth
          bellLockOff
          leftBell
          returnedBell
          lockOutTime
          bellLockOn
          leftSurfaceTime
          bottomTime
          decoCompletedAt
          surfaceDecoInterval
          chamberTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diveSupLog {
        items {
          id
          owner
          diveDate
          contractorName
          contractorAddress
          diveLocation
          worksite
          supervisionType
          diveType
          unitRatio
          incidents
          remarks
          contractorRepTitle
          contractorRepName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications {
        items {
          id
          notificationId
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscriptions {
        items {
          id
          isFree
          expiryDate
          customerId
          owner
          isCorporate
          tenantId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      signatureHash
      employmentUpdates {
        items {
          employmentId
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isOnCrewmarket
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCrewProfile = /* GraphQL */ `
  mutation DeleteCrewProfile(
    $input: DeleteCrewProfileInput!
    $condition: ModelCrewProfileConditionInput
  ) {
    deleteCrewProfile(input: $input, condition: $condition) {
      id
      owner
      employerId
      firstName
      midName
      lastName
      birthDate
      birthPlace
      address
      city
      state
      country
      emerContactName
      emerContactRelation
      emerContactPhone
      emerContactAddress
      emerContactEmail
      nextOfKin
      nationality
      fatherName
      motherName
      nearestAirport
      maritalStatus
      religion
      numberOfChildren
      weight
      bloodGroup
      eyesColor
      hairColor
      gender
      coverallSize
      shoeSize
      englishLangLevel
      otherLanguage1
      otherLanguage2
      crewRankId
      crewRank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      docs {
        items {
          id
          owner
          issueDate
          expiryDate
          docSize
          docNum
          issuePlace
          includedInCV
          title
          docId
          docDescriptionId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      personalDocs {
        items {
          id
          owner
          issueDate
          expiryDate
          docSize
          docNum
          issuePlace
          includedInCV
          title
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      seaLog {
        items {
          id
          owner
          vesselName
          vesselType
          tonnage
          operationArea
          engagementPlace
          dischargePlace
          rankTitle
          dateFrom
          dateTo
          company
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diveLog {
        items {
          id
          owner
          diveDate
          contractorName
          contractorAddress
          diveLocation
          worksite
          diveType
          measureUnit
          helmetType
          gasMixture
          workDescription
          decoSchedules
          incidents
          remarks
          divingSupName
          storageDepth
          maxDepth
          bellLockOff
          leftBell
          returnedBell
          lockOutTime
          bellLockOn
          leftSurfaceTime
          bottomTime
          decoCompletedAt
          surfaceDecoInterval
          chamberTime
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      diveSupLog {
        items {
          id
          owner
          diveDate
          contractorName
          contractorAddress
          diveLocation
          worksite
          supervisionType
          diveType
          unitRatio
          incidents
          remarks
          contractorRepTitle
          contractorRepName
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      notifications {
        items {
          id
          notificationId
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      subscriptions {
        items {
          id
          isFree
          expiryDate
          customerId
          owner
          isCorporate
          tenantId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      signatureHash
      employmentUpdates {
        items {
          employmentId
          owner
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      isOnCrewmarket
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRankGroup = /* GraphQL */ `
  mutation CreateRankGroup(
    $input: CreateRankGroupInput!
    $condition: ModelRankGroupConditionInput
  ) {
    createRankGroup(input: $input, condition: $condition) {
      id
      title
      status
      subscriptionCharge
      subscriptionDetails
      subscriptionPriceId
      bucketSize
      hierarchy
      crewRanks {
        items {
          id
          title
          status
          hierarchy
          groupId
          department
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRankGroup = /* GraphQL */ `
  mutation UpdateRankGroup(
    $input: UpdateRankGroupInput!
    $condition: ModelRankGroupConditionInput
  ) {
    updateRankGroup(input: $input, condition: $condition) {
      id
      title
      status
      subscriptionCharge
      subscriptionDetails
      subscriptionPriceId
      bucketSize
      hierarchy
      crewRanks {
        items {
          id
          title
          status
          hierarchy
          groupId
          department
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRankGroup = /* GraphQL */ `
  mutation DeleteRankGroup(
    $input: DeleteRankGroupInput!
    $condition: ModelRankGroupConditionInput
  ) {
    deleteRankGroup(input: $input, condition: $condition) {
      id
      title
      status
      subscriptionCharge
      subscriptionDetails
      subscriptionPriceId
      bucketSize
      hierarchy
      crewRanks {
        items {
          id
          title
          status
          hierarchy
          groupId
          department
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCrewRank = /* GraphQL */ `
  mutation CreateCrewRank(
    $input: CreateCrewRankInput!
    $condition: ModelCrewRankConditionInput
  ) {
    createCrewRank(input: $input, condition: $condition) {
      id
      title
      status
      hierarchy
      groupId
      group {
        id
        title
        status
        subscriptionCharge
        subscriptionDetails
        subscriptionPriceId
        bucketSize
        hierarchy
        crewRanks {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      docs {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      designatedTitles {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      department
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCrewRank = /* GraphQL */ `
  mutation UpdateCrewRank(
    $input: UpdateCrewRankInput!
    $condition: ModelCrewRankConditionInput
  ) {
    updateCrewRank(input: $input, condition: $condition) {
      id
      title
      status
      hierarchy
      groupId
      group {
        id
        title
        status
        subscriptionCharge
        subscriptionDetails
        subscriptionPriceId
        bucketSize
        hierarchy
        crewRanks {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      docs {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      designatedTitles {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      department
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCrewRank = /* GraphQL */ `
  mutation DeleteCrewRank(
    $input: DeleteCrewRankInput!
    $condition: ModelCrewRankConditionInput
  ) {
    deleteCrewRank(input: $input, condition: $condition) {
      id
      title
      status
      hierarchy
      groupId
      group {
        id
        title
        status
        subscriptionCharge
        subscriptionDetails
        subscriptionPriceId
        bucketSize
        hierarchy
        crewRanks {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      docs {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      designatedTitles {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      department
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDesignatedRankTitle = /* GraphQL */ `
  mutation CreateDesignatedRankTitle(
    $input: CreateDesignatedRankTitleInput!
    $condition: ModelDesignatedRankTitleConditionInput
  ) {
    createDesignatedRankTitle(input: $input, condition: $condition) {
      id
      title
      crewRanks {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDesignatedRankTitle = /* GraphQL */ `
  mutation UpdateDesignatedRankTitle(
    $input: UpdateDesignatedRankTitleInput!
    $condition: ModelDesignatedRankTitleConditionInput
  ) {
    updateDesignatedRankTitle(input: $input, condition: $condition) {
      id
      title
      crewRanks {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDesignatedRankTitle = /* GraphQL */ `
  mutation DeleteDesignatedRankTitle(
    $input: DeleteDesignatedRankTitleInput!
    $condition: ModelDesignatedRankTitleConditionInput
  ) {
    deleteDesignatedRankTitle(input: $input, condition: $condition) {
      id
      title
      crewRanks {
        items {
          id
          rankTitleId
          rankId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDesignatedRankTitleJoin = /* GraphQL */ `
  mutation CreateDesignatedRankTitleJoin(
    $input: CreateDesignatedRankTitleJoinInput!
    $condition: ModelDesignatedRankTitleJoinConditionInput
  ) {
    createDesignatedRankTitleJoin(input: $input, condition: $condition) {
      id
      rankTitleId
      rankId
      rank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      rankTitle {
        id
        title
        crewRanks {
          nextToken
          __typename
        }
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDesignatedRankTitleJoin = /* GraphQL */ `
  mutation UpdateDesignatedRankTitleJoin(
    $input: UpdateDesignatedRankTitleJoinInput!
    $condition: ModelDesignatedRankTitleJoinConditionInput
  ) {
    updateDesignatedRankTitleJoin(input: $input, condition: $condition) {
      id
      rankTitleId
      rankId
      rank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      rankTitle {
        id
        title
        crewRanks {
          nextToken
          __typename
        }
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDesignatedRankTitleJoin = /* GraphQL */ `
  mutation DeleteDesignatedRankTitleJoin(
    $input: DeleteDesignatedRankTitleJoinInput!
    $condition: ModelDesignatedRankTitleJoinConditionInput
  ) {
    deleteDesignatedRankTitleJoin(input: $input, condition: $condition) {
      id
      rankTitleId
      rankId
      rank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      rankTitle {
        id
        title
        crewRanks {
          nextToken
          __typename
        }
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDoc = /* GraphQL */ `
  mutation CreateDoc(
    $input: CreateDocInput!
    $condition: ModelDocConditionInput
  ) {
    createDoc(input: $input, condition: $condition) {
      id
      title
      descriptions {
        items {
          id
          content
          docId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      crewRanks {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      docType
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDoc = /* GraphQL */ `
  mutation UpdateDoc(
    $input: UpdateDocInput!
    $condition: ModelDocConditionInput
  ) {
    updateDoc(input: $input, condition: $condition) {
      id
      title
      descriptions {
        items {
          id
          content
          docId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      crewRanks {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      docType
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDoc = /* GraphQL */ `
  mutation DeleteDoc(
    $input: DeleteDocInput!
    $condition: ModelDocConditionInput
  ) {
    deleteDoc(input: $input, condition: $condition) {
      id
      title
      descriptions {
        items {
          id
          content
          docId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      crewRanks {
        items {
          id
          crewRankId
          crewRankTitle
          docId
          docTitle
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      docType
      hierarchy
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDocDescription = /* GraphQL */ `
  mutation CreateDocDescription(
    $input: CreateDocDescriptionInput!
    $condition: ModelDocDescriptionConditionInput
  ) {
    createDocDescription(input: $input, condition: $condition) {
      id
      content
      docId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocDescription = /* GraphQL */ `
  mutation UpdateDocDescription(
    $input: UpdateDocDescriptionInput!
    $condition: ModelDocDescriptionConditionInput
  ) {
    updateDocDescription(input: $input, condition: $condition) {
      id
      content
      docId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocDescription = /* GraphQL */ `
  mutation DeleteDocDescription(
    $input: DeleteDocDescriptionInput!
    $condition: ModelDocDescriptionConditionInput
  ) {
    deleteDocDescription(input: $input, condition: $condition) {
      id
      content
      docId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDocCrewRankJoin = /* GraphQL */ `
  mutation CreateDocCrewRankJoin(
    $input: CreateDocCrewRankJoinInput!
    $condition: ModelDocCrewRankJoinConditionInput
  ) {
    createDocCrewRankJoin(input: $input, condition: $condition) {
      id
      crewRankId
      crewRankTitle
      docId
      docTitle
      document {
        id
        title
        descriptions {
          nextToken
          __typename
        }
        crewRanks {
          nextToken
          __typename
        }
        docType
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      crewRank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDocCrewRankJoin = /* GraphQL */ `
  mutation UpdateDocCrewRankJoin(
    $input: UpdateDocCrewRankJoinInput!
    $condition: ModelDocCrewRankJoinConditionInput
  ) {
    updateDocCrewRankJoin(input: $input, condition: $condition) {
      id
      crewRankId
      crewRankTitle
      docId
      docTitle
      document {
        id
        title
        descriptions {
          nextToken
          __typename
        }
        crewRanks {
          nextToken
          __typename
        }
        docType
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      crewRank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDocCrewRankJoin = /* GraphQL */ `
  mutation DeleteDocCrewRankJoin(
    $input: DeleteDocCrewRankJoinInput!
    $condition: ModelDocCrewRankJoinConditionInput
  ) {
    deleteDocCrewRankJoin(input: $input, condition: $condition) {
      id
      crewRankId
      crewRankTitle
      docId
      docTitle
      document {
        id
        title
        descriptions {
          nextToken
          __typename
        }
        crewRanks {
          nextToken
          __typename
        }
        docType
        hierarchy
        createdAt
        updatedAt
        __typename
      }
      crewRank {
        id
        title
        status
        hierarchy
        groupId
        group {
          id
          title
          status
          subscriptionCharge
          subscriptionDetails
          subscriptionPriceId
          bucketSize
          hierarchy
          createdAt
          updatedAt
          __typename
        }
        docs {
          nextToken
          __typename
        }
        designatedTitles {
          nextToken
          __typename
        }
        department
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSeaLog = /* GraphQL */ `
  mutation CreateSeaLog(
    $input: CreateSeaLogInput!
    $condition: ModelSeaLogConditionInput
  ) {
    createSeaLog(input: $input, condition: $condition) {
      id
      owner
      vesselName
      vesselType
      tonnage
      operationArea
      engagementPlace
      dischargePlace
      rankTitle
      dateFrom
      dateTo
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSeaLog = /* GraphQL */ `
  mutation UpdateSeaLog(
    $input: UpdateSeaLogInput!
    $condition: ModelSeaLogConditionInput
  ) {
    updateSeaLog(input: $input, condition: $condition) {
      id
      owner
      vesselName
      vesselType
      tonnage
      operationArea
      engagementPlace
      dischargePlace
      rankTitle
      dateFrom
      dateTo
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSeaLog = /* GraphQL */ `
  mutation DeleteSeaLog(
    $input: DeleteSeaLogInput!
    $condition: ModelSeaLogConditionInput
  ) {
    deleteSeaLog(input: $input, condition: $condition) {
      id
      owner
      vesselName
      vesselType
      tonnage
      operationArea
      engagementPlace
      dischargePlace
      rankTitle
      dateFrom
      dateTo
      company
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDiverLog = /* GraphQL */ `
  mutation CreateDiverLog(
    $input: CreateDiverLogInput!
    $condition: ModelDiverLogConditionInput
  ) {
    createDiverLog(input: $input, condition: $condition) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      diveType
      measureUnit
      helmetType
      gasMixture
      workDescription
      decoSchedules
      incidents
      remarks
      divingSupName
      storageDepth
      maxDepth
      bellLockOff
      leftBell
      returnedBell
      lockOutTime
      bellLockOn
      leftSurfaceTime
      bottomTime
      decoCompletedAt
      surfaceDecoInterval
      chamberTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDiverLog = /* GraphQL */ `
  mutation UpdateDiverLog(
    $input: UpdateDiverLogInput!
    $condition: ModelDiverLogConditionInput
  ) {
    updateDiverLog(input: $input, condition: $condition) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      diveType
      measureUnit
      helmetType
      gasMixture
      workDescription
      decoSchedules
      incidents
      remarks
      divingSupName
      storageDepth
      maxDepth
      bellLockOff
      leftBell
      returnedBell
      lockOutTime
      bellLockOn
      leftSurfaceTime
      bottomTime
      decoCompletedAt
      surfaceDecoInterval
      chamberTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDiverLog = /* GraphQL */ `
  mutation DeleteDiverLog(
    $input: DeleteDiverLogInput!
    $condition: ModelDiverLogConditionInput
  ) {
    deleteDiverLog(input: $input, condition: $condition) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      diveType
      measureUnit
      helmetType
      gasMixture
      workDescription
      decoSchedules
      incidents
      remarks
      divingSupName
      storageDepth
      maxDepth
      bellLockOff
      leftBell
      returnedBell
      lockOutTime
      bellLockOn
      leftSurfaceTime
      bottomTime
      decoCompletedAt
      surfaceDecoInterval
      chamberTime
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDiveSupLog = /* GraphQL */ `
  mutation CreateDiveSupLog(
    $input: CreateDiveSupLogInput!
    $condition: ModelDiveSupLogConditionInput
  ) {
    createDiveSupLog(input: $input, condition: $condition) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      supervisionType
      diveType
      unitRatio
      incidents
      remarks
      contractorRepTitle
      contractorRepName
      supervisedDives {
        items {
          id
          storageDepth
          excursionDepth
          diversNum
          decoRange
          diveDuration
          workDescription
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDiveSupLog = /* GraphQL */ `
  mutation UpdateDiveSupLog(
    $input: UpdateDiveSupLogInput!
    $condition: ModelDiveSupLogConditionInput
  ) {
    updateDiveSupLog(input: $input, condition: $condition) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      supervisionType
      diveType
      unitRatio
      incidents
      remarks
      contractorRepTitle
      contractorRepName
      supervisedDives {
        items {
          id
          storageDepth
          excursionDepth
          diversNum
          decoRange
          diveDuration
          workDescription
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDiveSupLog = /* GraphQL */ `
  mutation DeleteDiveSupLog(
    $input: DeleteDiveSupLogInput!
    $condition: ModelDiveSupLogConditionInput
  ) {
    deleteDiveSupLog(input: $input, condition: $condition) {
      id
      owner
      diveDate
      contractorName
      contractorAddress
      diveLocation
      worksite
      supervisionType
      diveType
      unitRatio
      incidents
      remarks
      contractorRepTitle
      contractorRepName
      supervisedDives {
        items {
          id
          storageDepth
          excursionDepth
          diversNum
          decoRange
          diveDuration
          workDescription
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSupDiveInfo = /* GraphQL */ `
  mutation CreateSupDiveInfo(
    $input: CreateSupDiveInfoInput!
    $condition: ModelSupDiveInfoConditionInput
  ) {
    createSupDiveInfo(input: $input, condition: $condition) {
      id
      storageDepth
      excursionDepth
      diversNum
      decoRange
      diveDuration
      workDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSupDiveInfo = /* GraphQL */ `
  mutation UpdateSupDiveInfo(
    $input: UpdateSupDiveInfoInput!
    $condition: ModelSupDiveInfoConditionInput
  ) {
    updateSupDiveInfo(input: $input, condition: $condition) {
      id
      storageDepth
      excursionDepth
      diversNum
      decoRange
      diveDuration
      workDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSupDiveInfo = /* GraphQL */ `
  mutation DeleteSupDiveInfo(
    $input: DeleteSupDiveInfoInput!
    $condition: ModelSupDiveInfoConditionInput
  ) {
    deleteSupDiveInfo(input: $input, condition: $condition) {
      id
      storageDepth
      excursionDepth
      diversNum
      decoRange
      diveDuration
      workDescription
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
