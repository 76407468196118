import React, {useContext, useEffect, useState} from "react";
import Joyride, {ACTIONS} from "react-joyride";
import {useActor} from "@xstate/react";
import MachineContext from "lib/MachineContext";

const tourSteps = () => {
  const stepSelector = document.querySelector(".tour-step-checklist")
    ? {
        selector: ".tour-step-checklist",
        content: "Your required Docs guidance.",
      }
    : {
        selector: ".tour-step-profile-notifications",
        content: "Your Prifile notifications.",
      };

  return [
    {
      target: ".tour-step-profile",
      content: "Start with filling out your CrewProfile.",
    },
    {
      target: ".tour-step-crewdocs",
      content: "Upload your CrewDocs to securely store and manage them.",
    },
    {
      target: ".tour-step-crewlogs",
      content: "Fill out your CrewLogs to keep track of your sea service time.",
    },
    {
      target: ".tour-step-employment",
      content: "Manage your employment applications.",
    },
    {
      target: stepSelector.selector,
      content: stepSelector.content,
    },
    {
      target: ".tour-step-docs-notifications",
      content: "Your Docs notifications.",
    },
    {
      target: ".tour-step-logs-notifications",
      content: "Your Sealog notifications, including sea service time",
    },
  ];
};

export default function AppTour() {
  const [state, send] = useActor(useContext(MachineContext));
  const {
    context: {isTourOpen},
  } = state;
  const [steps, setSteps] = useState([]);
  const [run, setRun] = useState(isTourOpen);

  const handleJoyrideCallback = ({action}) => action === ACTIONS.CLOSE && send({type: "setIsTourOpen", data: false});

  useEffect(() => {
    isTourOpen && setSteps(() => tourSteps());
    setRun(isTourOpen);
  }, [isTourOpen]);

  return (
    <Joyride
      run={run}
      steps={steps}
      disableScrolling={true}
      showProgress={true}
      continuous={true}
      callback={handleJoyrideCallback}
      styles={{
        tooltip: {
          padding: "20px 0",
          borderRadius: "10px",
          width: "auto",
          maxWidth: "400px",
        },
        tooltipContent: {
          padding: "0px 25px",
          color: "darkblue",
        },
        tooltipFooter: {
          padding: "0 25px",
          marginTop: "10px",
        },
        buttonClose: {
          padding: "8px",
          width: "12px",
          height: "12px",
        },
      }}
    />
  );
}
