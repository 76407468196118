import {Is} from "./is";

export default function skipEmptyProps(value) {
  if (!Is.defined(value) || !(value instanceof Object)) {
    return value;
  }

  Object.keys(value).forEach((key) => {
    if (!Is.defined(value[key])) {
      delete value[key];
    }
  });

  return value;
}
