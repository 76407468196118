import React, {forwardRef} from "react";
import {NavLink} from "react-router-dom";
import {Box, IconButton, Link, Stack, Typography} from "@mui/material";
import {blue, deepPurple} from "@mui/material/colors";
import {format} from "date-fns";
import PropTypes from "prop-types";

function LinkIconBtn(props, ref) {
  const {label} = props;
  return (
    <IconButton color="inherit" size="small" sx={{fontSize: "0.9rem", p: 0, ">a.active": {fontWeight: 600}}}>
      <Link px={2} py={1} ref={ref} component={NavLink} color="inherit" underline="none" {...props} end>
        {label}
      </Link>
    </IconButton>
  );
}

const Item = forwardRef(LinkIconBtn);

export default function Footer({user}) {
  const bgcolor = !user ? deepPurple[100] : blue[50];

  return (
    <Box sx={{width: "100%", bgcolor, justifyContent: "center"}}>
      <Stack direction="row" spacing={1} justifyContent="center" alignItems="center" sx={{flexWrap: "wrap"}}>
        <Item label={"Home"} to={"/"} />
        <Item label={"About"} to={"/about"} />
        <Item label={"Contact us"} to={"/contact"} />
        <Item label={"*Subscription"} to={"/subscription"} />
        <Item label={"Terms and Conditions"} to={"/terms"} />
        <Item label={"Data Privacy"} to={"/data-privacy"} />
        <Item label={"Privacy Policy"} to={"/privacy"} />
        <Item label={"Cookie Policy"} to={"/cookies"} />
      </Stack>
      <Typography variant="caption" align="center" component="p" gutterBottom>
        ©{format(new Date(), "yyyy")} CrewBook, Maritime IT Solutions LLC, all rights reserved
      </Typography>
    </Box>
  );
}

Footer.propTypes = {
  user: PropTypes.object,
};

LinkIconBtn.propTypes = {
  label: PropTypes.node,
};
