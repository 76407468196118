import React, {useCallback, useContext, useMemo, useState} from "react";
import {Add, Remove} from "@mui/icons-material";
import {Box, Fab, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import MachineContext from "lib/MachineContext";

import CalendarLink from "./CalendarLink";
import {personalDocsCols, requiredDocsCols} from "./configurations";
import DocsTable from "./DocsTable";
import DocUpload from "./DocUpload";

import "scss/crewDocs.scss";

export default function CrewDocs() {
  const [state, send] = useActor(useContext(MachineContext));
  const {crewDocsMachineRef} = state.context;
  const {crewDocs} = crewDocsMachineRef?.state.context || {};
  const [activeTab, setActiveTab] = useState("ids");
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleTabClick = ({target}) => {
    setActiveTab(target.id.replace("-tab", ""));
    !isCollapsed && handleUploadChecked();
  };

  const handleUploadChecked = () => setIsCollapsed((state) => !state);
  const onUploadCancel = () => handleUploadChecked();
  const onIncludedInCVChanged = useCallback((data) => send({type: "updateCrewDoc", data}), [send]);

  const columns = useMemo(
    () => ({
      other: personalDocsCols(onIncludedInCVChanged),
      ids: requiredDocsCols(onIncludedInCVChanged),
      stcw: requiredDocsCols(),
      "non-stcw": requiredDocsCols(),
    }),
    [onIncludedInCVChanged]
  );

  const allDocs = useMemo(
    () =>
      !crewDocs?.requiredDocs?.items
        ? {ids: [], stcw: [], nonStcw: []}
        : crewDocs?.requiredDocs?.items.reduce(
            (res, item) => {
              switch (item.doc.docType) {
                case "ID":
                  res.ids = [...res.ids, item];
                  break;
                case "STCW":
                case "STCW_SPEC":
                  res.stcw = [...res.stcw, item];
                  break;
                case "NON_STCW":
                  res.nonStcw = [...res.nonStcw, item];
                  break;
                default:
                  break;
              }
              return res;
            },
            {ids: [], stcw: [], nonStcw: []}
          ),
    [crewDocs?.requiredDocs]
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        p: 1,
        overflow: "auto",
        height: {xs: "auto", md: 1},
      }}>
      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            className="nav-link active"
            id="ids-tab"
            data-bs-toggle="tab"
            data-bs-target="#ids"
            type="button"
            role="tab"
            aria-controls="ids"
            aria-selected="true"
            onClick={handleTabClick}>
            IDs{" "}
          </button>
          <button
            className="nav-link"
            id="stcw-tab"
            data-bs-toggle="tab"
            data-bs-target="#stcw"
            type="button"
            role="tab"
            aria-controls="stcw"
            aria-selected="true"
            onClick={handleTabClick}>
            STCW{" "}
          </button>
          <button
            className="nav-link"
            id="non-stcw-tab"
            data-bs-toggle="tab"
            data-bs-target="#non-stcw"
            type="button"
            role="tab"
            aria-controls="non-stcw"
            aria-selected="true"
            onClick={handleTabClick}>
            Non STCW{" "}
          </button>
          <button
            className="nav-link"
            id="other-tab"
            data-bs-toggle="tab"
            data-bs-target="#other"
            type="button"
            role="tab"
            aria-controls="other"
            aria-selected="false"
            onClick={handleTabClick}>
            Other{" "}
          </button>
          <CalendarLink />
        </div>
      </nav>
      <div className="tab-content mt-3" id="nav-tabContent">
        <Fab
          color="primary"
          aria-label="add document"
          variant="extended"
          size="medium"
          onClick={handleUploadChecked}
          sx={{mb: 3}}>
          {isCollapsed ? <Add /> : <Remove />}Add New Document
        </Fab>
        <DocUpload docCategory={activeTab} isCollapsed={isCollapsed} onCancel={onUploadCancel} />

        <div className="tab-pane fade show active" id="ids" role="tabpanel" aria-labelledby="ids-tab">
          <Typography variant="subtitle1">IDs and other Travel Documents</Typography>
          <DocsTable columns={columns.ids} rows={allDocs.ids} />
        </div>
        <div className="tab-pane fade" id="stcw" role="tabpanel" aria-labelledby="stcw-tab">
          <Typography variant="subtitle1">STCW Standards Required Documents </Typography>
          <DocsTable columns={columns.stcw} rows={allDocs.stcw} />
        </div>
        <div className="tab-pane fade" id="non-stcw" role="tabpanel" aria-labelledby="non-stcw-tab">
          <Typography variant="subtitle1">Maritime Industry (Non-STCW) Required Documents</Typography>
          <DocsTable columns={columns["non-stcw"]} rows={allDocs.nonStcw} />
        </div>
        <div className="tab-pane fade" id="other" role="tabpanel" aria-labelledby="other-tab">
          <Typography variant="subtitle1">Other Professional Documents, including expired</Typography>
          <DocsTable columns={columns.other} rows={crewDocs?.personalDocs?.items || []} />
        </div>
      </div>
    </Box>
  );
}
