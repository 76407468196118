import React from "react";
import {Grid, MenuItem} from "@mui/material";
import {FormInputSelect} from "lib/components";
import {BLOOD_GROUPS, EYE_COLOR, GENDER, HAIR_COLOR} from "lib/data";
import PropTypes from "prop-types";

export default function BloodEyeHairGenderForm({control, disabled}) {
  const rules = {
    bloodGroup: {required: "Blood Group is required"},
    gender: {required: "Gender is required"},
    eyesColor: {required: "Eye Color is required"},
    hairColor: {required: "Hair Color is required"},
  };

  return (
    <>
      <Grid container spacing={{xs: 2, sm: 3}} mb={3}>
        <Grid item xs={12} sm={8} lg={4}>
          <FormInputSelect
            name={"gender"}
            control={control}
            label={"Gender"}
            fullWidth
            rules={rules.gender}
            disabled={disabled}
            options={GENDER.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          />
        </Grid>
      </Grid>
      <Grid container spacing={{xs: 2, sm: 3}} mb={3}>
        <Grid item xs={12} sm={4}>
          <FormInputSelect
            name={"bloodGroup"}
            control={control}
            label={"Blood Group"}
            fullWidth
            rules={rules.bloodGroup}
            disabled={disabled}
            options={BLOOD_GROUPS.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormInputSelect
            name={"eyesColor"}
            control={control}
            label={"Eye Color"}
            fullWidth
            rules={rules.eyesColor}
            disabled={disabled}
            options={EYE_COLOR.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <FormInputSelect
            name={"hairColor"}
            control={control}
            label={"Hair Color"}
            fullWidth
            rules={rules.hairColor}
            disabled={disabled}
            options={HAIR_COLOR.map((name) => (
              <MenuItem key={name} value={name}>
                {name}
              </MenuItem>
            ))}
          />
        </Grid>
      </Grid>
    </>
  );
}

BloodEyeHairGenderForm.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
};
