const RELOGIONS = [
  "African Traditional & Diasporic",
  "Agnostic",
  "Atheist",
  "Baha'i",
  "Buddhism",
  "Cao Dai",
  "Chinese traditional religion",
  "Christianity",
  "Hinduism",
  "Islam",
  "Jainism",
  "Juche",
  "Judaism",
  "Neo-Paganism",
  "Nonreligious",
  "Rastafarianism",
  "Secular",
  "Shinto",
  "Sikhism",
  "Spiritism",
  "Tenrikyo",
  "Unitarian-Universalism",
  "Zoroastrianism",
  "Primal-Indigenous",
  "Other",
];

export default RELOGIONS;
