/* eslint-disable new-cap */
import {Storage} from "aws-amplify";
import {saveAs} from "file-saver";
import JSZip from "jszip";
import {getFileExtension} from "lib/helpers";

import cvGenerator from "./cvGenerator/cvGenerator";

const getPDFBlob = (pdfDoc) => {
  return new Promise((resolve) => pdfDoc.getBlob((blob) => resolve(blob)));
};

const getAllDocsFiles = async (docs) =>
  Promise.all(
    docs.map(({doc, title, file}) => {
      const fileName = `${doc?.title || title}.${getFileExtension(file.key)}`;
      return Storage.get(file.key, {download: true, level: "protected"}).then(({Body}) => ({fileName, file: Body}));
    })
  );

const generateCV = async ({type, crewProfile, crewSeaLogs, crewDocs, profileImgKey}) => {
  const pdfDoc = await cvGenerator({crewProfile, crewSeaLogs, crewDocs, profileImgKey});

  if (type === "generateCV") {
    pdfDoc.download(`CV-${crewProfile.lastName}.pdf`);
    return {type};
  }

  const cvBlob = await getPDFBlob(pdfDoc);
  const zip = JSZip();
  zip.folder("CV-Package").file(`CV-${crewProfile.lastName}.pdf`, cvBlob);
  const allDocsFiles = await getAllDocsFiles([...crewDocs.requiredDocs.items, ...crewDocs.personalDocs.items]);
  allDocsFiles.forEach(({fileName, file}) => zip.file(`CV-Package/Documents/${fileName}`, file));
  const zipBlob = await zip.generateAsync({type: "blob"});
  const zipName = "cv-package.zip";

  if (type === "generateCVzip") {
    saveAs(zipBlob, zipName);
    return {type};
  }

  const {key} = await Storage.put(`CV/${zipName}`, zipBlob, {level: "protected", contentType: zipBlob.type});
  const signedZipURL = await Storage.get(key, {level: "protected", expires: 900});
  return {type, signedZipURL};
};

export default generateCV;
