import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Box, Button, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {withAuthProtection} from "lib/components/AuthProtector/AuthProtector";
import {actionConfirmMessage, confirmDialog} from "lib/helpers";
import MachineContext from "lib/MachineContext";

import {EmploymentBlock, EmploymentRequest} from ".";

import "scss/employment.scss";

// export default function Employment() {
function Employment() {
  const newEmploymentBtnRef = useRef(null);
  const [pickedEmployment, setPickedEmployment] = useState(null);
  const service = useContext(MachineContext);
  const [state, send] = useActor(service);
  const {profileMachineRef, crewDocsMachineRef, employmentMachineRef} = state.context;
  const {crewDocs} = crewDocsMachineRef?.state.context || [];
  const {crewProfile} = profileMachineRef?.state.context || {};
  const {firstName, lastName, signatureHash} = crewProfile || {};
  const {employments, employmentActions} = employmentMachineRef?.state.context || {};
  const isCvAvailable =
    !!crewProfile &&
    !!crewProfile.bloodGroup &&
    !!crewProfile.englishLangLevel &&
    crewDocs?.requiredDocs?.items?.length > 0;

  const [consentChecked, setConsentChecked] = useState(false);
  const [expanded, setExpanded] = useState(null);
  const onNewRequestClick = () => setPickedEmployment(null);

  const onActionClick = useCallback(
    async ({action, employmentId}) => {
      const employerName = employments.find((item) => item.id === employmentId)?.employerName;
      if (action === "SHARE_ACCOUNT") {
        newEmploymentBtnRef.current.click();
        setPickedEmployment({employerName, employmentId});
      } else {
        const consentNeeded = ["ACCEPT_CONTRACT", "ACCEPT_OFFER"].includes(action);
        const params = {employmentActions, action, employerName, setConsentChecked};
        const content = actionConfirmMessage(params);
        const setup = !consentNeeded ? {content, consent: true} : {content};
        const result = await confirmDialog({send, service, setup});
        const userData = {firstName, lastName, signatureHash};
        result === 1 && send({type: "sendEmploymentEvent", data: {employmentId, type: action, userData}});
        setConsentChecked(false);
      }
    },
    [employmentActions, employments, firstName, lastName, signatureHash, send, service]
  );

  const onDownload = useCallback((data) => () => send({type: "downloadEmploymentFile", data}), [send]);

  const handleCollapse = useCallback(({isExpanded, employmentId}) => {
    setExpanded(isExpanded ? employmentId : null);
  }, []);

  const employmentRecords = useMemo(
    () =>
      employments?.map((employment) => (
        <EmploymentBlock
          employment={employment}
          key={employment.id}
          onActionClick={onActionClick}
          onDownload={onDownload}
          expanded={expanded}
          handleCollapse={handleCollapse}
        />
      )),
    [employments, expanded, onActionClick, handleCollapse, onDownload]
  );

  useEffect(() => {
    send({type: "updateConsentConfirmModal", data: consentChecked});
  }, [consentChecked, send]);

  useEffect(() => {
    !employmentActions && send({type: "loadEmployments"});
  }, [employmentActions, send]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        p: 1,
        overflow: "auto",
        height: {xs: "auto", md: 1},
      }}>
      <Box>
        <Button
          ref={newEmploymentBtnRef}
          variant="outlined"
          color="secondary"
          data-bs-toggle="modal"
          data-bs-target="#newRequest"
          sx={{mb: {xs: 1, md: 0}}}
          disabled={!isCvAvailable}
          onClick={onNewRequestClick}>
          Add Employment Request
        </Button>
      </Box>
      {!isCvAvailable && (
        <Typography variant="h6" sx={{mt: 3}} color="error" gutterBottom>
          Fill in all required entry fields in your Profile (Personal, Biometrical and Additional Details) and upload
          your required certificates to activate Employment Requests functionality.
        </Typography>
      )}
      <Typography variant="button" sx={{mt: 3}} gutterBottom>
        Employment Requests
      </Typography>
      <div>{employmentRecords}</div>
      <EmploymentRequest pickedEmployment={pickedEmployment} />
    </Box>
  );
}

export default withAuthProtection(Employment);
