const removeNullishProps = (obj) => {
  const newObj = {...obj};

  for (const [key, value] of Object.entries(newObj)) {
    if (value === null || value === undefined || value === "") {
      Reflect.deleteProperty(newObj, key);
    }
  }
  return newObj;
};

export default removeNullishProps;
