const BENEFICIARY_NAME_FIELD_NAME = "beneficiary_name";
const TOWN_NAME_FIELD_NAME = "town_name";
const TELEPHONE_FIELD_NAME = "telephone";
const NICKNAME_FIELD_NAME = "bank_details_label";
const BIRTH_DATE_FIELD_NAME = "date_of_birth";

export {
  BENEFICIARY_NAME_FIELD_NAME,
  BIRTH_DATE_FIELD_NAME,
  NICKNAME_FIELD_NAME,
  TELEPHONE_FIELD_NAME,
  TOWN_NAME_FIELD_NAME,
};
