import React, {useCallback, useContext, useEffect, useState} from "react";
import {Box, Button, IconButton, List, ListItem, ListItemText, Switch, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {MachineContext} from "lib";

export default function Notifications() {
  const [state, send] = useActor(useContext(MachineContext));
  const {profileMachineRef, notifications} = state.context;
  const {state: profileState} = profileMachineRef; // TODO: refactor with useMemo
  const {crewProfile} = profileState.context;
  const [hasChanged, setHasChanged] = useState(false);
  const [allNotifications, setAllNotifications] = useState([]);

  const onChange = ({target: {id}}) => {
    const crewActiveNotifications = crewProfile?.notifications?.items || [];
    const {list, isChanged} = allNotifications.reduce(
      (acc, item) => {
        const {list, isChanged} = acc;
        const {id: itemId, isActive} = item;
        const isCurrentlyActive = crewActiveNotifications.some(({notificationId}) => notificationId === itemId);
        const newIsActive = itemId === id ? !isActive : isActive;
        const newListItem = itemId === id ? {...item, isActive: newIsActive} : item;
        const updatedChange = isChanged || isCurrentlyActive !== newIsActive;
        return {isChanged: updatedChange, list: [...list, newListItem]};
      },
      {isChanged: false, list: []}
    );
    setAllNotifications(list);
    setHasChanged(isChanged);
  };

  const onSaveClick = () => send({type: "updateActiveNotifications", data: allNotifications});

  const resetAllNotifications = useCallback(
    (availableNotifications) => {
      const crewActiveNotifications = crewProfile?.notifications?.items || [];
      const list = availableNotifications.map((item) => {
        const {description, id} = item;
        const isCurrentlyActive = crewActiveNotifications.some(({notificationId}) => notificationId === id);
        return {id, description, isActive: isCurrentlyActive};
      }, []);

      setAllNotifications(list);
    },
    [crewProfile?.notifications]
  );

  const notificationItem = ({id, description, isActive}) => (
    <ListItem
      key={id}
      divider
      secondaryAction={
        <IconButton edge="end" aria-label="delete">
          <Switch edge="end" id={id} onChange={onChange} checked={isActive} />
        </IconButton>
      }>
      <ListItemText primary={description} sx={{width: "auto"}} />
    </ListItem>
  );

  useEffect(() => {
    !notifications ? send("loadAccountNotifications") : !!notifications && resetAllNotifications(notifications);
  }, [resetAllNotifications, notifications, send]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        p: 2,
        overflow: "auto",
        height: {xs: "auto", md: 1},
      }}>
      <Typography variant="h6" gutterBottom>
        Email Notifications Settings
      </Typography>
      <List>{allNotifications.map(notificationItem)}</List>
      <Box sx={{display: "flex", justifyContent: "flex-end"}}>
        <Button variant="contained" onClick={onSaveClick} disabled={!hasChanged} sx={{width: {xs: 1, sm: "auto"}}}>
          Save Settings
        </Button>
      </Box>
    </Box>
  );
}
