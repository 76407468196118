import {createMachine} from "xstate";

import {actions, guards, services} from ".";

const authMachine = createMachine(
  {
    id: "authMachine",
    predictableActionArguments: true,
    initial: "signIn",
    context: {
      user: null,
      userEmail: null,
      alert: null,
      isMachineWorking: false,
    },
    states: {
      signIn: {
        initial: "loginInput",
        on: {
          resetPassword: {target: "passwordReset", actions: ["resetAlert"]},
          signUp: {target: "signUp", actions: ["resetAlert"]},
          done: [
            {
              target: "passwordReset.changePassword",
              cond: "isNewPasswordRequired",
              actions: "updateUser",
            },
            {target: "success", actions: "updateUser"},
          ],
          error: [
            {target: "signUp.confirmation", cond: "isUserNotConfirmed"},
            {
              target: "passwordReset.verifyPasswordReset",
              cond: "isPasswordResetRequired",
            },
            {target: ".loginInput", actions: "updateError"},
          ],
        },
        states: {
          loginInput: {
            on: {
              signIn: {target: "authentication", actions: "updateUserEmail"},
            },
          },
          authentication: {
            entry: ["machineStartsWorking", "sendParentMachineStartsWorking"],
            exit: ["machineStopsWorking", "sendParentMachineStopsWorking"],
            invoke: services.signIn,
          },
        },
      },
      passwordReset: {
        initial: "emailInput",
        on: {
          backToSignIn: "signIn",
          forgotPasswordSent: ".verifyPasswordReset",
          forgotPasswordError: {
            target: ".emailInput",
            actions: "updateError",
          },
          passwordResetDone: {target: "signIn", actions: "updateSuccess"},
          passwordResetError: {target: "signIn", actions: "updateError"},
          completeNewPasswordError: {
            target: ".changePassword",
            actions: "updateError",
          },
        },
        states: {
          emailInput: {
            on: {
              sendCode: {
                target: "forgotPassword",
                actions: "updateUserEmail",
              },
            },
          },
          forgotPassword: {
            entry: ["machineStartsWorking", "sendParentMachineStartsWorking"],
            exit: ["machineStopsWorking", "sendParentMachineStopsWorking"],
            invoke: services.forgotPassword,
          },
          verifyPasswordReset: {
            on: {
              confirm: {target: "passwordSubmit", actions: ["resetAlert"]},
            },
          },
          passwordSubmit: {
            entry: ["machineStartsWorking", "sendParentMachineStartsWorking"],
            exit: ["machineStopsWorking", "sendParentMachineStopsWorking"],
            invoke: services.forgotPasswordSubmit,
          },
          changePassword: {
            on: {
              confirm: {
                target: "changePasswordSubmit",
                actions: ["resetAlert"],
              },
            },
          },
          changePasswordSubmit: {
            entry: ["machineStartsWorking", "sendParentMachineStartsWorking"],
            exit: ["machineStopsWorking", "sendParentMachineStopsWorking"],
            invoke: services.completeNewPassword,
          },
        },
      },
      signUp: {
        exit: ["resetAlert"],
        initial: "registration",
        on: {
          signIn: "signIn",
          backToSignIn: {target: "signIn", actions: ["resetAlert"]},
          confirmationSent: ".confirmation",
          signUpError: {target: ".registration", actions: "updateError"},
          confirmSignUpError: {target: "signIn", actions: "updateError"},
        },
        states: {
          registration: {
            on: {
              signUp: {target: "creation", actions: "updateUserEmail"},
            },
          },
          creation: {
            entry: ["machineStartsWorking", "sendParentMachineStartsWorking"],
            exit: ["machineStopsWorking", "sendParentMachineStopsWorking"],
            invoke: services.signUp,
          },
          confirmation: {
            on: {
              back: {target: "registration", actions: ["resetAlert"]},
              resendCode: {target: "resendingCode", actions: ["resetAlert"]},
              confirm: {target: "confirmingSignUp", actions: ["resetAlert"]},
            },
          },
          confirmingSignUp: {
            entry: ["machineStartsWorking", "sendParentMachineStartsWorking"],
            exit: ["machineStopsWorking", "sendParentMachineStopsWorking"],
            invoke: services.confirmSignUp,
          },
          resendingCode: {
            entry: ["machineStartsWorking", "sendParentMachineStartsWorking"],
            exit: ["machineStopsWorking", "sendParentMachineStopsWorking"],
            invoke: services.resendSignUp,
          },
        },
      },
      success: {
        type: "final",
        data: ({user}) => ({...user}),
      },
    },
  },
  {actions, guards}
);

export default authMachine;
