import React, {useCallback, useState} from "react";
import {Typography} from "@mui/material";
import {ValidationFormField} from "lib/components";
import PropTypes from "prop-types";

export default function ConsentCheckbox({form, name, fieldType}) {
  const [checked, setChecked] = useState(false);
  const title = (
    <Typography variant="body2">I agree to CrewBook Terms and Conditions, Privacy and Cookie Policy.</Typography>
  );
  const readOnly = false;

  const onChange = useCallback(() => setChecked((state) => !state), []);

  const isValid = useCallback(() => {
    const input = document.getElementById(name);

    return input.checked;
  }, [name]);

  return (
    <ValidationFormField
      fieldType={fieldType}
      isValid={isValid}
      form={form}
      required
      fullWidth
      value={checked}
      onChange={onChange}
      id={name}
      label={title}
      size="small"
      name={name}
      readOnly={readOnly}
      helperText="Your consent is required to Create an Account"
    />
  );
}

ConsentCheckbox.propTypes = {
  form: PropTypes.object,
  fieldType: PropTypes.string,
  name: PropTypes.string,
};
