import React, {useCallback, useContext, useEffect, useMemo} from "react";
import {useForm, useWatch} from "react-hook-form";
import {Close} from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  MobileStepper,
} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useActor} from "@xstate/react";
import {MachineContext} from "lib";
import {FormInputSelect, FormInputText} from "lib/components";
import {SAFE_INPUT_REGEX, WALLET_ID_FIELD_NAME, WALLET_PROVIDER_ID_FIELD_NAME} from "lib/data";
import {delay} from "lib/helpers";
import {matchWallet} from "machine/serviceMachines/marfinMachineSet/eWallet";
import PropTypes from "prop-types";

const WALLET_PROVIDER_FIELD_NAME = "provider";

const initialFormState = {
  [WALLET_ID_FIELD_NAME]: "",
  [WALLET_PROVIDER_FIELD_NAME]: "",
};

export default function AddWalletDialog({isOpen, onClose, eWallets}) {
  const theme = useTheme();
  const mdUpScreenMatches = useMediaQuery(theme.breakpoints.up("md"));
  const service = useContext(MachineContext);
  const [state, send] = useActor(service);

  const eWalletProviders = useMemo(
    () => state.context.marfinMachineRef?.state?.context.eWalletProviders,
    [state.context.marfinMachineRef?.state?.context?.eWalletProviders]
  );

  const {handleSubmit, getValues, control, formState, trigger} = useForm({
    defaultValues: initialFormState,
    mode: "all",
  });
  const {isValid} = formState;

  const provider = useWatch({control: control, name: WALLET_PROVIDER_FIELD_NAME});

  const providerOptions = useMemo(
    () =>
      eWalletProviders.map((provider) => (
        <MenuItem key={provider.id} value={JSON.stringify(provider)}>
          {provider.name}
        </MenuItem>
      )),
    [eWalletProviders]
  );

  const rules = useMemo(
    () => ({
      [WALLET_PROVIDER_FIELD_NAME]: {
        validate: (value) => {
          return !value ? "Service Provider is required" : true;
        },
      },
      [WALLET_ID_FIELD_NAME]: {
        validate: (value) => {
          if (!value) {
            return "Wallet ID is required";
          }

          const provider = !getValues(WALLET_PROVIDER_FIELD_NAME)
            ? null
            : JSON.parse(getValues(WALLET_PROVIDER_FIELD_NAME));
          const isExisting = !provider
            ? false
            : eWallets.some((item) => matchWallet(item, {[WALLET_ID_FIELD_NAME]: value, provider: provider}));
          const isValidText = SAFE_INPUT_REGEX.test(value);

          return isExisting ? "Wallet ID already added" : !isValidText ? "Invalid Symbols in Wallet ID" : true;
        },
      },
    }),
    [eWallets, getValues]
  );

  const handleClose = useCallback(() => onClose(), [onClose]);

  const onSubmit = useCallback(
    (data) => {
      delay(100).then(() => {
        if (isValid) {
          const walletDetails = {
            [WALLET_PROVIDER_ID_FIELD_NAME]: JSON.parse(data[WALLET_PROVIDER_FIELD_NAME]).id,
            [WALLET_ID_FIELD_NAME]: data[WALLET_ID_FIELD_NAME],
          };
          send({type: "ADD_WALLET", data: walletDetails});
          onClose();
        }
      });
    },
    [isValid, onClose, send]
  );

  useEffect(() => {
    if (!!provider && !!getValues(WALLET_ID_FIELD_NAME)) {
      trigger(WALLET_ID_FIELD_NAME);
    }
  }, [getValues, provider, trigger]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      fullWidth
      maxWidth="sm"
      fullScreen={!mdUpScreenMatches}>
      <DialogTitle sx={{p: 1.5}}>
        New Bank Account Details
        <IconButton
          onClick={handleClose}
          sx={{position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500]}}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{p: 1.5, display: "flex", flexDirection: "column", minHeight: "15rem"}}>
        <Box sx={{flexGrow: 1, mt: 1, mb: 2}}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormInputSelect
                name={WALLET_PROVIDER_FIELD_NAME}
                control={control}
                label={"Service Provider"}
                options={providerOptions}
                rules={rules[WALLET_PROVIDER_FIELD_NAME]}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name={WALLET_ID_FIELD_NAME}
                control={control}
                label={"Wallet ID"}
                rules={rules[WALLET_ID_FIELD_NAME]}
                autocapitalize={true}
                autoComplete={"off"}
                fullWidth
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <MobileStepper
        variant="progress"
        steps={1}
        position="static"
        activeStep={0}
        backButton={
          <Button color={"error"} onClick={handleClose}>
            {"Cancel"}
          </Button>
        }
        nextButton={
          <Button color={"success"} onClick={handleSubmit(onSubmit)}>
            {"Add Wallet"}
          </Button>
        }
        sx={{
          bgcolor: "background.stepper",
          color: "primary.main",
          fontWeight: 500,
          "& .MuiLinearProgress-root": {backgroundColor: "transparent"},
          "& .MuiLinearProgress-bar": {backgroundColor: "transparent"},
        }}
      />
    </Dialog>
  );
}

AddWalletDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  eWallets: PropTypes.array,
};
