import React, {useEffect, useState} from "react";
import {Controller} from "react-hook-form";
import {Grid} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {isAfter, isBefore, isValid, set} from "date-fns";
import PropTypes from "prop-types";

const resetValue = {hours: 0, minutes: 0, seconds: 0, milliseconds: 0};

export default function FormInputDateRange(props) {
  const {control, watch, dateFromLabel, dateToLabel, disabled} = props;
  const {dateFromName, dateToName, size = "small", showDateTo = true} = props;
  const {dateToRequired = true, rangeLimits} = props;

  const [limits, setLimits] = useState({
    min: rangeLimits.minTo,
    max: rangeLimits.maxFrom,
  });
  const watchDateFrom = watch(dateFromName);
  const watchDateTo = watch(dateToName);

  useEffect(() => {
    if (!watchDateFrom) {
      setLimits((state) => ({...state, min: rangeLimits.minTo}));
    } else {
      const isValidDate = isValid(watchDateFrom);

      isValidDate && setLimits((state) => ({...state, min: watchDateFrom}));
    }
  }, [watchDateFrom, rangeLimits]);

  useEffect(() => {
    if (!watchDateTo) {
      setLimits((state) => ({...state, max: rangeLimits.maxFrom}));
    } else {
      const isValidDate = isValid(watchDateTo);

      isValidDate && setLimits((state) => ({...state, max: watchDateTo}));
    }
  }, [watchDateTo, rangeLimits]);

  const rules = {
    [dateFromName]: {
      validate: (value) => {
        const isValidDate = isValid(value);
        const resetedValue = isValidDate ? set(value, resetValue) : null;

        return (
          (isValidDate &&
            !isAfter(resetedValue, limits.max) &&
            !isAfter(resetedValue, rangeLimits.maxFrom) &&
            !isBefore(value, rangeLimits.minFrom)) ||
          "Choose valid Date from"
        );
      },
    },
    [dateToName]: {
      validate: (value) => {
        const isValidDate = isValid(value);
        const resetedValue = isValidDate ? set(value, resetValue) : null;

        return !dateToRequired && !value
          ? true
          : (isValidDate &&
              !isBefore(value, limits.min) &&
              !isBefore(value, rangeLimits.minTo) &&
              !isAfter(resetedValue, rangeLimits.maxTo)) ||
              "Choose valid Date to";
      },
    },
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Controller
          name={dateFromName}
          control={control}
          rules={rules[dateFromName]}
          render={({field: {onChange, value, onBlur, ref}, fieldState: {invalid, error}}) => (
            <DatePicker
              views={["year", "month", "day"]}
              label={dateFromLabel}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              inputRef={ref}
              format="dd/MM/yyyy"
              minDate={rangeLimits.minFrom}
              maxDate={limits.max}
              defaultCalendarMonth={rangeLimits.minFrom}
              disabled={disabled}
              slotProps={{
                textField: {
                  variant: "outlined",
                  fullWidth: true,
                  size,
                  error: invalid,
                  helperText: error?.message,
                  FormHelperTextProps: {error: invalid},
                },
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        {showDateTo && (
          <Controller
            name={dateToName}
            control={control}
            rules={rules[dateToName]}
            render={({field: {onChange, value, ref, onBlur}, fieldState: {invalid, error}}) => (
              <DatePicker
                views={["year", "month", "day"]}
                label={dateToLabel}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                inputRef={ref}
                format="dd/MM/yyyy"
                minDate={limits.min}
                maxDate={rangeLimits.maxTo}
                defaultCalendarMonth={rangeLimits.maxTo}
                disabled={disabled}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    fullWidth: true,
                    size,
                    error: invalid,
                    helperText: error?.message,
                    FormHelperTextProps: {error: invalid},
                  },
                }}
              />
            )}
          />
        )}
      </Grid>
    </Grid>
  );
}

FormInputDateRange.propTypes = {
  control: PropTypes.object,
  dateFromName: PropTypes.string,
  dateToName: PropTypes.string,
  dateFromLabel: PropTypes.string,
  dateToLabel: PropTypes.string,
  size: PropTypes.string,
  showDateTo: PropTypes.bool,
  dateToRequired: PropTypes.bool,
  rangeLimits: PropTypes.object,
  watch: PropTypes.func,
  disabled: PropTypes.bool,
};
