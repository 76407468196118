import React, {useContext, useMemo} from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import {useActor} from "@xstate/react";
import {MachineContext} from "lib";

export default function Spinner() {
  const [state] = useActor(useContext(MachineContext));
  const {
    isMachineWorking,
    isAuthMachineWorking,
    profileMachineRef,
    crewDocsMachineRef,
    crewLogsMachineRef,
    employmentMachineRef,
    remitterAuthMachineRef,
    marfinMachineRef,
  } = state.context;

  const profileMachineWorking = useMemo(() => {
    return profileMachineRef?.state.context.isMachineWorking;
  }, [profileMachineRef?.state.context.isMachineWorking]);

  const crewDocsMachineWorking = useMemo(() => {
    return crewDocsMachineRef?.state.context.isMachineWorking;
  }, [crewDocsMachineRef?.state.context.isMachineWorking]);

  const crewLogsMachineWorking = useMemo(() => {
    return crewLogsMachineRef?.state.context.isMachineWorking;
  }, [crewLogsMachineRef?.state.context.isMachineWorking]);

  const employmentMachineWorking = useMemo(() => {
    return employmentMachineRef?.state.context.isMachineWorking;
  }, [employmentMachineRef?.state.context.isMachineWorking]);

  const remitterAuthMachineWorking = useMemo(() => {
    return remitterAuthMachineRef?.state.context.isMachineWorking;
  }, [remitterAuthMachineRef?.state.context.isMachineWorking]);

  const marfinMachineWorking = useMemo(() => {
    return marfinMachineRef?.state.context.isMachineWorking;
  }, [marfinMachineRef?.state.context.isMachineWorking]);

  const isAnyMachineWorking = useMemo(
    () =>
      isMachineWorking ||
      isAuthMachineWorking ||
      profileMachineWorking ||
      crewDocsMachineWorking ||
      crewLogsMachineWorking ||
      employmentMachineWorking ||
      remitterAuthMachineWorking ||
      marfinMachineWorking ||
      false,
    [
      isMachineWorking,
      isAuthMachineWorking,
      crewDocsMachineWorking,
      crewLogsMachineWorking,
      employmentMachineWorking,
      profileMachineWorking,
      remitterAuthMachineWorking,
      marfinMachineWorking,
    ]
  );

  return (
    <Backdrop sx={{color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000}} open={isAnyMachineWorking}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
