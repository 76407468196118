import React, {useCallback} from "react";
import {Cancel, Edit, FileDownload, Save} from "@mui/icons-material";
import {Button, ButtonGroup, Grid, Tooltip} from "@mui/material";
import PropTypes from "prop-types";

export default function EditToolbar(props) {
  const {handleEdit, canSave, canEdit, canDiscard, isEditModeOn} = props;
  const {hasExport, isExportDisabled, handleExport, isExportMenuAvailable, exportBtnTooltip} = props;

  const onExportClick = useCallback(
    (event) => {
      !isExportMenuAvailable ? handleExport() : handleExport(event.currentTarget);
    },
    [isExportMenuAvailable, handleExport]
  );

  const saveBtn = canSave ? (
    <Tooltip title="Save changes" arrow>
      <Button onClick={() => handleEdit("save")} color="success">
        <Save />
      </Button>
    </Tooltip>
  ) : (
    <Button onClick={() => handleEdit("save")} color="success" disabled={true}>
      <Save />
    </Button>
  );
  const cancelBtn = (
    <Tooltip title="Discard changes" arrow>
      <Button onClick={() => handleEdit("cancel")} color="error">
        <Cancel />
      </Button>
    </Tooltip>
  );
  const editBtn = (
    <Tooltip title="Edit table" arrow>
      <Button onClick={() => handleEdit("edit")} color="info">
        <Edit />
      </Button>
    </Tooltip>
  );

  const exportBtn = isExportDisabled ? (
    <Button color="info" disabled={true} onClick={() => onExportClick("export")}>
      <FileDownload />
    </Button>
  ) : (
    <Tooltip title={exportBtnTooltip || "Download *.csv"} arrow>
      <Button color="info" onClick={() => onExportClick("export")}>
        <FileDownload />
      </Button>
    </Tooltip>
  );

  return (
    <Grid container spacing={0} justifyContent="space-between">
      <Grid item>
        <ButtonGroup variant="text" aria-label="edit-toolbar" size="small" sx={{px: 0.5, display: "flex"}}>
          {canEdit && !isEditModeOn && editBtn}
          {canEdit && isEditModeOn && saveBtn}
          {canEdit && isEditModeOn && canDiscard && cancelBtn}
          {hasExport && !isEditModeOn && exportBtn}
        </ButtonGroup>
      </Grid>
      <Grid item>{props.center}</Grid>
      <Grid item xs>
        {props.right}
      </Grid>
    </Grid>
  );
}

EditToolbar.defaultProps = {
  canEdit: false,
  canDiscard: true,
  isEditModeOn: false,
  canSave: false,
  hasExport: false,
  isExportDisabled: true,
  isExportMenuAvailable: false,
};

EditToolbar.propTypes = {
  canEdit: PropTypes.bool,
  canDiscard: PropTypes.bool,
  isEditModeOn: PropTypes.bool,
  handleEdit: PropTypes.func,
  canSave: PropTypes.bool,
  hasExport: PropTypes.bool,
  isExportDisabled: PropTypes.bool,
  handleExport: PropTypes.func,
  right: PropTypes.node,
  center: PropTypes.node,
  isExportMenuAvailable: PropTypes.bool,
  exportBtnTooltip: PropTypes.string,
};
