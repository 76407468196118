import {assign, sendParent} from "xstate";

const actions = {
  updateEmployerUser: assign({
    employerUser: (_, {data: {employerUser}}) => employerUser,
  }),
  resetEmployerUser: assign({
    employerUser: null,
  }),
  resetEmployments: assign({
    employmentActions: (_, {data: {employmentActions}}) => employmentActions,
    employments: (_, {data: {employments}}) => employments,
  }),
  addEmployment: assign({
    employments: ({employments}, {data: {newEmployment}}) => [...employments, newEmployment],
    employmentUpdates: ({employmentUpdates}, {data: {newEmployment}}) =>
      new Set(employmentUpdates).add(newEmployment.id),
  }),
  updateEmployment: assign({
    employments: ({employments}, {data: {updatedEmployment}}) =>
      !updatedEmployment
        ? employments
        : employments.map((item) => (item.id !== updatedEmployment.id ? item : updatedEmployment)),
    employmentUpdates: ({employmentUpdates}, {data: {updatedEmployment}}) => {
      if (!updatedEmployment || !employmentUpdates.has(updatedEmployment.id)) {
        return employmentUpdates;
      }
      const newUpdates = new Set(employmentUpdates);
      newUpdates.delete(updatedEmployment.id);
      return newUpdates;
    },
  }),
  patchEmployments: assign({
    employments: ({employments}, {data: updatedEmployments}) =>
      employments.map((item) => {
        const updatedEmployment = updatedEmployments.find((_employment) => _employment.id === item.id);
        return updatedEmployment ? {...item, ...updatedEmployment} : item;
      }),
  }),
  addEmploymentUpdate: assign({
    employmentUpdates: ({employmentUpdates}, {data: employmentId}) => new Set(employmentUpdates).add(employmentId),
  }),
  addUnprocessedEvent: assign({
    unprocessedEvents: ({unprocessedEvents}, event) => [...unprocessedEvents, event],
  }),
  resetEmploymentUpdates: assign({
    employmentUpdates: (_, {data: {employmentUpdates}}) => employmentUpdates,
    unprocessedEvents: (_, {data: {unprocessedEvents}}) => unprocessedEvents,
    employments: ({employments}, {data: {newEmployments}}) =>
      !newEmployments || newEmployments.length === 0 ? employments : [...employments, ...newEmployments],
  }),
  sendAlert: sendParent((_, {data: {alert}}) => ({type: "alert", data: alert})),
  sendError: sendParent((_, {data}) => ({type: "updateError", data: data})),
  machineStartsWorking: assign({isMachineWorking: () => true}),
  machineStopsWorking: assign({isMachineWorking: () => false}),
  logger: (context, event, {state}) => console.log("EMPLOYMENT MACHINE EVENT", state.value, event, context),
};

export default actions;
