import {getErrorMessage} from "lib/helpers";
import {actions, assign, forwardTo, spawn} from "xstate";

import {getDiverRunningTotals, getTotalSeaDays} from "./serviceMachines/logsMachineSet/helpers";
import {
  crewDocsMachine,
  crewLogsMachine,
  employmentMachine,
  marfinMachine,
  profileMachine,
  remitterAuthMachine,
} from "./serviceMachines";

const {stop} = actions;

const machineActions = {
  updateSubscriptions: assign({subscriptions: (_, {data}) => data}),
  updateUser: assign({
    user: (_, {data}) => data,
    authTimeExp: (_, {data: eventData}) => {
      const {accessToken} = eventData.signInUserSession;
      localStorage.setItem("lastActiveTime", Date.now());

      return accessToken.payload.exp;
    },
  }),
  updateAccountBlockInfo: assign({
    accountBlockInfo: (_, {data: {accountBlockInfo}}) => accountBlockInfo,
  }),
  updateCrewData: assign({
    alert: (_, {data: {alert}}) => alert,
    isTourOpen: (_, {data: {crewProfile}}) => !crewProfile,
  }),
  spawnProfileMachine: assign({
    profileMachineRef: ({user}, {data}) => {
      const {crewProfile, crewRanks} = data;
      const crewSubscription = crewProfile.subscriptions.items[0];

      return spawn(profileMachine({owner: user.attributes.sub, crewProfile, crewRanks, crewSubscription}), {
        sync: true,
      });
    },
  }),
  spawnMachines: assign({
    profileMachineRef: ({user}, {data}) => {
      const {crewProfile, crewRanks, crewSubscription, profileImgKey} = data;
      return spawn(
        profileMachine({
          owner: user.attributes.sub,
          crewProfile,
          profileImgKey,
          crewRanks,
          crewSubscription,
        }),
        {sync: true}
      );
    },
    crewDocsMachineRef: (_, {data: {crewDocs, crewRankDocs}}) =>
      spawn(crewDocsMachine({crewDocs, crewRankDocs}), {sync: true}),
    crewLogsMachineRef: (_, {data: {crewSeaLogs, crewDiveLogs, crewDiveSupLogs}}) => {
      const seaLogs = !crewSeaLogs
        ? null
        : {
            items: crewSeaLogs?.items.reverse(),
            totalSeaDays: getTotalSeaDays(crewSeaLogs?.items),
          };

      const diveLogs = !crewDiveLogs ? null : getDiverRunningTotals(crewDiveLogs?.items);
      const diveSupLogs = !crewDiveSupLogs ? null : {items: crewDiveSupLogs?.items.reverse()};
      return spawn(crewLogsMachine({seaLogs, diveLogs, diveSupLogs}), {sync: true});
    },
    employmentMachineRef: ({user}, {data: {employmentUpdates}}) =>
      spawn(employmentMachine({owner: user.attributes.sub, employmentUpdates}), {sync: true}),
    remitterAuthMachineRef: () => spawn(remitterAuthMachine, {sync: true}),
    marfinMachineRef: () => spawn(marfinMachine, {sync: true}),
  }),
  stopProfileMachine: stop(({profileMachineRef}) => profileMachineRef),
  stopDocMachine: stop(({crewDocsMachineRef}) => crewDocsMachineRef),
  stopLogMachine: stop(({crewLogsMachineRef}) => crewLogsMachineRef),
  stopEmploymentMachine: stop(({employmentMachineRef}) => employmentMachineRef),
  stopRemitterAuthMachine: stop(({remitterAuthMachineRef}) => remitterAuthMachineRef),
  stopMarfinMachine: stop(({marfinMachineRef}) => marfinMachineRef),
  updateNotifications: assign({
    notifications: (_, {data: {notifications}}) => notifications,
  }),
  alertCVCreated: assign({
    alert: (_, {data: {type}}) => {
      const message =
        type === "generateCVzip"
          ? `Your CV-package created successfully! Check Downloads Folder for "cv-package.zip"!`
          : `Your CV created successfully! Check Downloads Folder!`;
      return {type: "success", message: message};
    },
  }),
  updateSignedZipURL: assign({
    signedZipURL: (_, {data: {signedZipURL}}) => signedZipURL,
  }),
  forwardToProfileMachine: forwardTo(({profileMachineRef}) => profileMachineRef),
  forwardToDocsMachine: forwardTo(({crewDocsMachineRef}) => crewDocsMachineRef),
  forwardToEmploymentMachine: forwardTo(({employmentMachineRef}) => employmentMachineRef),
  forwardToLogMachine: forwardTo(({crewLogsMachineRef}) => crewLogsMachineRef),
  forwardToRemitterAuthMachine: forwardTo(({remitterAuthMachineRef}) => remitterAuthMachineRef),
  forwardToMarfinMachine: forwardTo(({marfinMachineRef}) => marfinMachineRef),
  confirmModalDecision: assign({
    confirmModalSetup: ({confirmModalSetup}, {data: decision}) => ({...confirmModalSetup, decision}),
  }),
  updateConsentConfirmModal: assign({
    confirmModalSetup: ({confirmModalSetup}, {data: consent}) =>
      !confirmModalSetup ? null : {...confirmModalSetup, consent},
  }),
  updateAuthWarning: assign({
    alert: () => {
      const message = `Your Sign In Session will end in 5 min due to Security reasons!
      You will be signed out. Please, save your progrees now. You may continue after Sign In again.`;
      return {type: "error", message};
    },
  }),
  resetLastActiveTime: () => localStorage.setItem("lastActiveTime", Date.now()),
  updateAlert: assign({
    alert: (_, {data: {type, message}}) => ({type, message}),
  }),
  updateError: assign({
    alert: (_, {data, type}) => (data ? {type: "error", message: getErrorMessage(data, type)} : null),
  }),
  clearStorageEmail: () => localStorage.removeItem("loginEmail"),
  clearLastActiveTime: () => localStorage.removeItem("lastActiveTime"),
  clearAllCrewData: assign({
    user: null,
    authTimeExp: null,
    alert: null,
    confirmModalSetup: null,
    signedZipURL: null,
    profileMachineRef: null,
    crewDocsMachineRef: null,
    crewLogsMachineRef: null,
    crewDocs: null,
    crewSeaLogs: null,
    crewRankDocs: null,
    crewDiveLogs: null,
    crewSupLogs: null,
  }),
  machineStartsWorking: assign({isMachineWorking: true}),
  machineStopsWorking: assign({isMachineWorking: false}),
  authMachineIsWorking: assign({isAuthMachineWorking: (_, {data}) => data}),
  logger: (context, event, {state}) => console.log("MAIN MACHINE STATE CONTEXT EVENT", state?.value, context, event),
};

export default machineActions;
