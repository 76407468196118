import React, {useCallback, useContext, useEffect, useMemo} from "react";
import {GppMaybe} from "@mui/icons-material";
import {Alert, Box, Grid, Paper, Typography} from "@mui/material";
import {useActor, useSelector} from "@xstate/react";
import {MachineContext} from "lib";

import SetupForm from "./SetupForm";
import TOTPCodeForm from "./TOTPCodeForm";

const MAIN_BOX_STYLES = {
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  p: 1,
  overflow: "auto",
  height: {xs: "auto", md: 1},
};

export function withAuthProtection(Component) {
  const Protected = (props) => {
    const mainMachine = useContext(MachineContext);
    const [state, send] = useActor(mainMachine);
    const alert = useMemo(
      () => state.context.remitterAuthMachineRef?.state.context.alert,
      [state.context.remitterAuthMachineRef?.state.context.alert]
    );
    const totpSetup = useMemo(
      () => state.context.remitterAuthMachineRef?.state.context.totpSetup,
      [state.context.remitterAuthMachineRef?.state.context.totpSetup]
    );
    const mainMachineStateValue = useSelector(mainMachine, (state) => state?.toStrings().pop());
    const remitterAuthMachineStateValue = useSelector(state.context.remitterAuthMachineRef, (state) =>
      state?.toStrings().pop()
    );
    const onBackClicked = useCallback(() => send("BACK_TO_TOTP_SETUP"), [send]);
    const resetAlert = useCallback(() => send("RESET_REMITTER_AUTH_ALERT"), [send]);

    const authComponents = useMemo(() => {
      return {
        setingUpMfa: <SetupForm totpSetup={totpSetup} send={send} />,
        remitterTotpTokenInput: (
          <TOTPCodeForm
            type={"VERIFY"}
            send={send}
            onBackClicked={onBackClicked}
            alert={alert}
            resetAlert={resetAlert}
          />
        ),
        verifyingRemitterToken: (
          <TOTPCodeForm
            type={"VERIFY"}
            send={send}
            onBackClicked={onBackClicked}
            alert={alert}
            resetAlert={resetAlert}
          />
        ),
        totpTokenInput: <TOTPCodeForm type={"CONFIRM"} send={send} alert={alert} resetAlert={resetAlert} />,
        confirmingTotpToken: <TOTPCodeForm type={"CONFIRM"} send={send} alert={alert} resetAlert={resetAlert} />,
      };
    }, [alert, onBackClicked, resetAlert, send, totpSetup]);
    const currentComponent = useMemo(
      () => authComponents[remitterAuthMachineStateValue],
      [authComponents, remitterAuthMachineStateValue]
    );

    useEffect(() => {
      if (mainMachineStateValue === "signedIn.idle" && remitterAuthMachineStateValue === "idle") {
        send("LOGIN_REMITTER");
      }
    }, [send, mainMachineStateValue, remitterAuthMachineStateValue]);

    useEffect(() => {
      return () => send("CANCEL_LOGIN_REMITTER");
    }, [send]);

    return remitterAuthMachineStateValue === "authorised" ? (
      <Component {...props} />
    ) : (
      <Box
        sx={{
          ...MAIN_BOX_STYLES,
          backgroundImage: `url(${"/images/ship-wheel.svg"})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "200px",
          backgroundPosition: "15% 50%",
          backgroundColor: "rgba(255,255,255, 0.75)",
          backgroundBlendMode: "screen",
        }}>
        <Alert icon={<GppMaybe fontSize="large" />} severity="info" sx={{mb: 1}}>
          <Typography variant="subtitle1">
            Time-based One-Time Password Authentication is required to access this page.
          </Typography>
        </Alert>
        <Grid
          container
          spacing={1}
          sx={{
            flexGrow: 1,
            alignItems: "center",
            justifyContent: {xs: "center", sm: "flex-end"},
          }}>
          <Grid item xs={12} md={8} lg={6} xl={5} sx={{display: "flex", justifyContent: "center"}}>
            <Box sx={{flexGrow: 1, maxWidth: {xs: "100%", sm: "32rem"}}}>
              {!currentComponent ? (
                <></>
              ) : (
                <Paper elevation={16} sx={{p: 2}}>
                  {currentComponent}
                </Paper>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  };

  Protected.displayName = `WithProtection(${Component})`;

  return Protected;
}
