import React, {useMemo} from "react";
import {Box, CircularProgress, Grid, TextField, Typography} from "@mui/material";
import {format} from "date-fns";
import {BIRTH_DATE_FIELD_NAME, SWIFT_CODE_FIELD_NAME} from "lib/data";
import PropTypes from "prop-types";

export default function ConfirmBankAccountDetailsForm({accountDetails, beneficiary}) {
  const accountDetailsFields = useMemo(() => {
    return Object.keys(accountDetails?.accountDetails ?? {}).map((key) => ({
      name: key,
      value: accountDetails.accountDetails[key],
      label: accountDetails.accountTemplate.find((item) => item.name === key)?.title ?? "",
    }));
  }, [accountDetails]);
  const bankSwiftCodeField = useMemo(
    () =>
      SWIFT_CODE_FIELD_NAME in accountDetails.accountDetails
        ? undefined
        : {name: "bic", value: accountDetails?.bankDetails?.bank.bic, label: "SWITFT code"},
    [accountDetails]
  );
  const bankNameFields = useMemo(() => {
    const bankName = accountDetails?.bankName ?? accountDetails?.bankDetails?.name;
    return {name: "name", value: bankName, label: "Bank name"};
  }, [accountDetails]);

  const addressDetailsFields = useMemo(() => {
    if (!beneficiary?.beneficiaryDetails) return [];

    const fields = Object.keys(beneficiary.beneficiaryDetails).map((key) => ({
      name: key,
      value:
        key === BIRTH_DATE_FIELD_NAME
          ? beneficiary.beneficiaryDetails[key]
            ? format(beneficiary.beneficiaryDetails[key], "dd MMM yyyy")
            : ""
          : beneficiary.beneficiaryDetails[key],
      label: beneficiary.beneficiaryTemplate.find((item) => item.name === key)?.title ?? "",
    }));

    fields.push({name: "country", value: beneficiary?.beneficiaryCountry.name, label: "Country"});
    return fields;
  }, [beneficiary]);

  return (
    <Box mx={1}>
      <Typography variant="subtitle2" color={"text.disabled"} mb={2}>
        Bank Account Details:
      </Typography>
      <Grid container spacing={1} mb={1}>
        {accountDetailsFields.map(({name, value, label}) => (
          <Grid item key={name} xs={12} sm={6}>
            <TextField
              label={label}
              value={value}
              InputLabelProps={{shrink: true}}
              variant="standard"
              size="small"
              disabled
              multiline
              fullWidth
            />
          </Grid>
        ))}
        {bankSwiftCodeField && (
          <Grid item key={bankSwiftCodeField.name} xs={12} sm={6}>
            <TextField
              label={bankSwiftCodeField.label}
              value={bankSwiftCodeField.value}
              InputLabelProps={{shrink: true}}
              variant="standard"
              size="small"
              disabled
              multiline
              fullWidth
            />
          </Grid>
        )}
        <Grid item key={bankNameFields.name} xs={12} sm={6}>
          <TextField
            label={bankNameFields.label}
            value={bankNameFields.value}
            InputLabelProps={{shrink: true}}
            variant="standard"
            size="small"
            disabled
            multiline
            fullWidth
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle2" color={"text.disabled"} sx={{mt: 3, mb: 2}}>
        Address Details:
      </Typography>
      <Grid container spacing={1}>
        {!addressDetailsFields.length && (
          <Grid item xs={12}>
            <CircularProgress color="inherit" size={18} />
          </Grid>
        )}
        {addressDetailsFields.map(({name, value, label}) => (
          <Grid item key={name} xs={12} sm={6}>
            <TextField
              label={label}
              InputLabelProps={{shrink: true}}
              value={value}
              variant="standard"
              size="small"
              multiline
              disabled
              fullWidth
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

ConfirmBankAccountDetailsForm.propTypes = {
  accountDetails: PropTypes.object,
  beneficiary: PropTypes.object,
};
