import React, {useEffect, useMemo, useState} from "react";
import {Button, Grid, Link, Typography} from "@mui/material";
import {AlertToast} from "lib/components";
import PropTypes from "prop-types";

export default function CVUrlAlert({signedZipURL, send}) {
  const [btnTitle, setBtnTitle] = useState("Copy Link");
  const [show, setShow] = useState(false);

  const content = useMemo(() => {
    const onClick = () =>
      navigator.clipboard.writeText(signedZipURL).then(
        () => setBtnTitle("Copied!"),
        () => setBtnTitle("Link Not Copied!")
      );

    return !signedZipURL ? null : (
      <>
        <Typography sx={{fontWeight: 500}}>Link to share your CV-Package:</Typography>
        <Link href={signedZipURL} sx={{fontWeight: 500}}>
          {"CV-package.zip"}
        </Link>
        <Grid container spacing={3} justifyContent="space-between" mt={1}>
          <Grid item xs={6}>
            <Typography variant="subtitle2" color="error">
              The link will expire in 15 minutes.
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Button onClick={onClick} variant="contained" fullWidth sx={{height: 1}}>
              {btnTitle}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }, [signedZipURL, btnTitle]);

  const onCloseClick = () => {
    setShow(false);
    send("resetSignedZipURL");
    setBtnTitle("Copy Link");
  };

  useEffect(() => {
    setShow(!!signedZipURL);
  }, [signedZipURL]);

  return <AlertToast content={content} show={show} onCloseClick={onCloseClick} />;
}

CVUrlAlert.propTypes = {
  signedZipURL: PropTypes.string,
  send: PropTypes.func,
};
