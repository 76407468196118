import React from "react";
import {Button, Grid, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";
import PropTypes from "prop-types";

export default function FormFooter(props) {
  const {
    backActionLabel = "",
    backBtnTitle = "",
    onBackClicked,
    sumbitBtnTitle,
    submitDisabled = false,
    backButton,
  } = props;

  return (
    <Grid container spacing={1} justifyContent="space-between" mt={3} direction={{xs: "column-reverse", sm: "row"}}>
      <Grid item xs={12} sm="auto" sx={{display: "flex", justifyContent: {xs: "center", sm: "start"}}}>
        <Typography variant="body2" color="text.disabled">
          {backActionLabel}
          {backButton ? (
            backButton
          ) : (
            <Button
              type="button"
              size="small"
              variant="text"
              onClick={onBackClicked}
              sx={{fontSize: "inherit", color: blue.A400, "&:hover": {textDecoration: "underline"}}}>
              {backBtnTitle}
            </Button>
          )}
        </Typography>
      </Grid>

      <Grid item xs={12} sm="auto">
        <Button type="submit" variant="contained" disabled={submitDisabled} sx={{width: 1, px: 3}}>
          {sumbitBtnTitle}
        </Button>
      </Grid>
    </Grid>
  );
}

FormFooter.propTypes = {
  backActionLabel: PropTypes.string,
  backBtnTitle: PropTypes.string,
  onBackClicked: PropTypes.func,
  sumbitBtnTitle: PropTypes.string,
  submitDisabled: PropTypes.bool,
  backButton: PropTypes.node,
};
