import React, {useCallback, useReducer, useRef, useState} from "react";
import {Box, Button, Link, Paper, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";
import {formReducer, validateEmail} from "lib/helpers";
import PropTypes from "prop-types";

import {EmailInput, ErrorNote, FormFooter, PasswordInput} from "../common";

import TryFreeInfo from "./TryFreeInfo";

export default function SignInForm({alert, onSubmit, onResetPassword, onSignUp}) {
  const formRef = useRef(null);
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const initialState = {email: localStorage.getItem("loginEmail") || "", password: ""};
  const [formState, dispatch] = useReducer(formReducer, initialState);
  const onChange = ({currentTarget: {name, value}}) => dispatch({type: name, data: value});
  const handleResetPassword = () => onResetPassword();
  const handleSignUp = () => {
    setOpen(false);
    onSignUp();
  };
  const handleOpen = () => setOpen(true);
  const handleClose = useCallback(() => setOpen(false), []);

  const freeTryButton = (
    <Button
      component={Link}
      onClick={handleOpen}
      size="small"
      sx={{fontSize: "inherit", color: blue.A400, "&:hover": {textDecoration: "underline"}}}>
      Try CrewBook free*
    </Button>
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    formRef.current.classList.add("was-validated");
    if (formRef.current.checkValidity()) {
      localStorage.setItem("loginEmail", formState.email);
      const {isValid, message} = validateEmail(formState.email);

      isValid ? onSubmit(formState) : setError(message);
      formRef.current.classList.remove("was-validated");
      dispatch({type: "reset", data: initialState});
    }
  };

  return (
    <Paper elevation={16} sx={{p: 2}}>
      <Typography variant="h6" align="center" mb={3}>
        Sign in to your Crew Account
      </Typography>
      <Box component="form" ref={formRef} noValidate onSubmit={handleSubmit}>
        <EmailInput form={formRef.current} name={"email"} value={formState.email} onChange={onChange} />
        <PasswordInput form={formRef.current} name={"password"} value={formState.password} onChange={onChange} />

        <Typography color="text.disabled" variant="body2">
          Forgot your password?
          <Button
            component={Link}
            onClick={handleResetPassword}
            size="small"
            sx={{
              fontSize: "inherit",
              color: blue.A400,
              ml: 1,
              "&:hover": {textDecoration: "underline"},
            }}>
            Reset password
          </Button>
        </Typography>

        <ErrorNote {...{alert, error}} />
        <FormFooter backActionLabel={"No account?"} backButton={freeTryButton} sumbitBtnTitle={"Sign in"} />
      </Box>
      <TryFreeInfo open={open} handleClose={handleClose} handleSignUp={handleSignUp} />
    </Paper>
  );
}

SignInForm.propTypes = {
  alert: PropTypes.object,
  onSubmit: PropTypes.func,
  onResetPassword: PropTypes.func,
  onSignUp: PropTypes.func,
};
