import {createMachine} from "xstate";

import actions from "./actions";
import {addLogEntry, deleteLogEntry, editLogEntry, loadVesselTypes} from "./services";

const crewLogsMachine = ({seaLogs, diveLogs, diveSupLogs}) =>
  createMachine(
    {
      id: "crewLogsMachine",
      predictableActionArguments: true,
      initial: "displaying",
      context: {
        crewSeaLogs: seaLogs,
        crewDiveLogs: diveLogs,
        crewDiveSupLogs: diveSupLogs,
        vesselTypes: null,
        isMachineWorking: false,
      },
      states: {
        displaying: {
          id: "displaying",
          on: {
            addLogEntry: "adding",
            deleteLogEntry: "deleting",
            editLogEntry: "editing",
            loadVesselTypesList: {
              target: "loadingVesselTypes",
              cond: "vesselTypesNotLoaded",
            },
          },
        },
        loadingVesselTypes: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: () => loadVesselTypes(),
            onDone: {target: "displaying", actions: "updateVesselTypes"},
            onError: {target: "displaying", actions: "sendError"},
          },
        },
        adding: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: (context, {data}) => addLogEntry({context, ...data}),
            onDone: [
              {
                target: "displaying",
                actions: ["addLogEntry", "sendAlert"],
                cond: "isLogAdded",
              },
              {target: "displaying", actions: "sendError"},
            ],
            onError: {target: "displaying", actions: "sendError"},
          },
        },
        deleting: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: (_, {data}) => deleteLogEntry(data),
            onDone: {
              target: "displaying",
              actions: ["deleteLogEntry", "sendAlert"],
            },
            onError: {target: "displaying", actions: "sendError"},
          },
        },
        editing: {
          entry: ["machineStartsWorking"],
          exit: ["machineStopsWorking"],
          invoke: {
            src: (_, {data}) => editLogEntry(data),
            onDone: {
              target: "displaying",
              actions: ["updateLogEntry", "sendAlert"],
            },
            onError: {target: "displaying", actions: "sendError"},
          },
        },
      },
    },
    {
      actions,
      guards: {
        isLogAdded: (_, {data}) => data?.type !== "error",
        vesselTypesNotLoaded: ({vesselTypes}) => !vesselTypes,
      },
    }
  );

export default crewLogsMachine;
