import React from "react";
import {Link} from "react-router-dom";
import {arrowRightIcon} from "lib/icons";
import PropTypes from "prop-types";

export default function NoEmployerUserFound({handleResetSearch, email}) {
  return (
    <div className="text-danger">
      <p>
        Crew Manager with email <strong>{email}</strong>
        {` is not registered with `}
        <strong>ShipBook</strong>.
      </p>
      <p className="mb-1">You may share your CV and documents via:</p>
      <p className="mb-1">
        <Link to="/profile">
          <button type="button" className="btn btn-link ps-0" data-bs-dismiss="modal">
            Profile Business Card
          </button>
        </Link>
        {arrowRightIcon} Generate CV
        {arrowRightIcon} Get the link to share CV-package.zip
      </p>
      <div className="d-flex">
        <button className="btn btn-primary ms-auto px-4" type="button" id="resetSearch" onClick={handleResetSearch}>
          Reset Search
        </button>
      </div>
    </div>
  );
}

NoEmployerUserFound.propTypes = {
  email: PropTypes.string,
  handleResetSearch: PropTypes.func,
};
