import {gql} from "urql";

export const createAccount = gql`
  mutation CreateAccount(
    $accountNumber: String!
    $bank: String!
    $beneficiary: String!
    $iban: String!
    $routingCode: String!
    $swiftCode: String!
  ) {
    createAccount(
      accountNumber: $accountNumber
      bank: $bank
      beneficiary: $beneficiary
      iban: $iban
      routingCode: $routingCode
      swiftCode: $swiftCode
    ) {
      ... on Error {
        message
      }
      ... on MutationCreateAccountSuccess {
        data {
          accountNumber
          bank
          beneficiary
          iban
          preference
          routingCode
          swiftCode
          label
          bankAccountCertificate {
            ... on MissingBankAccountCertificate {
              status
            }
            ... on ProvidedBankAccountCertificate {
              s3Uri
              status
            }
            ... on VerifiedBankAccountCertificate {
              s3Uri
              status
              verified {
                on
                by {
                  email
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const deleteAccount = gql`
  mutation DeleteAccount($accountNumber: String!, $swiftCode: String!) {
    deleteAccount(accountNumber: $accountNumber, swiftCode: $swiftCode) {
      ... on Error {
        message
      }
      ... on MutationDeleteAccountSuccess {
        data {
          swiftCode
          accountNumber
        }
      }
    }
  }
`;

export const updateBeneficiary = gql`
  mutation UpdateBeneficiary($accountNumber: String!, $beneficiary: String!, $swiftCode: String!) {
    updateBeneficiary(accountNumber: $accountNumber, beneficiary: $beneficiary, swiftCode: $swiftCode) {
      ... on MutationUpdateBeneficiarySuccess {
        data {
          accountNumber
          swiftCode
          beneficiary
          label
        }
      }
    }
  }
`;

export const setPreferredAccount = gql`
  mutation SetPreferred($accountNumber: String!, $swiftCode: String!) {
    setPreferred(accountNumber: $accountNumber, swiftCode: $swiftCode) {
      ... on MutationSetPreferredSuccess {
        data {
          accountNumber
          swiftCode
          preference
        }
      }
    }
  }
`;

export const deleteBankCertificate = gql`
  mutation DeleteCertificate($accountNumber: String!, $swiftCode: String!) {
    deleteCertificate(accountNumber: $accountNumber, swiftCode: $swiftCode) {
      ... on Error {
        message
      }
      ... on MutationDeleteCertificateSuccess {
        data {
          swiftCode
          accountNumber
          bankAccountCertificate {
            ... on MissingBankAccountCertificate {
              status
            }
            ... on ProvidedBankAccountCertificate {
              s3Uri
              status
            }
            ... on VerifiedBankAccountCertificate {
              s3Uri
              status
              verified {
                on
                by {
                  email
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const createWallet = gql`
  mutation CreateWallet($providerId: String!, $walletId: String!) {
    createWallet(providerId: $providerId, walletId: $walletId) {
      ... on Error {
        message
      }
      ... on MutationCreateWalletSuccess {
        data {
          poolId
          preference
          profileId
          walletId
          provider {
            id
            name
            walletIdDescription
          }
        }
      }
    }
  }
`;

export const deleteWallet = gql`
  mutation DeleteWallet($providerId: String!, $walletId: String!) {
    deleteWallet(providerId: $providerId, walletId: $walletId) {
      ... on MutationDeleteWalletSuccess {
        data {
          walletId
          provider {
            id
          }
        }
      }
      ... on Error {
        message
      }
    }
  }
`;

export const setPreferredWallet = gql`
  mutation SetPreferredWallet($providerId: String!, $walletId: String!) {
    setPreferredWallet(providerId: $providerId, walletId: $walletId) {
      ... on Error {
        message
      }
      ... on MutationSetPreferredWalletSuccess {
        data {
          preference
          walletId
          provider {
            id
            name
            walletIdDescription
          }
        }
      }
    }
  }
`;
