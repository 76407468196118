import React from "react";
import {Typography} from "@mui/material";
import PropTypes from "prop-types";

export default function ErrorNote({alert, error = ""}) {
  const color = alert?.type === "error" ? "error" : alert?.type === "success" ? "success" : "primary";

  return (
    <>
      <Typography variant="h6" color={color}>
        {alert?.type ? alert?.message : ""}
      </Typography>
      <Typography variant="h6" color={color}>
        {error}
      </Typography>
    </>
  );
}

ErrorNote.propTypes = {
  alert: PropTypes.object,
  error: PropTypes.string,
};
