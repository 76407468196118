import {blue, grey, indigo, orange, yellow} from "@mui/material/colors";
import {createTheme} from "@mui/material/styles";

const mainCssTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: blue.A700,
      light: blue.A200,
      dark: indigo.A700,
      contrastText: "#fff",
    },
    secondary: {
      main: grey[700],
      light: grey[400],
      dark: grey[900],
      contrastText: "#fff",
    },
    warning: {
      main: yellow[500],
      light: yellow[100],
      dark: yellow[900],
      contrastText: "#fff",
    },
    danger: {
      main: orange[500],
      light: orange[100],
      dark: orange[900],
      contrastText: "#fff",
    },
    text: {
      primary: indigo[900],
      disabled: grey[700],
    },
    background: {
      stepper: grey[50],
    },
  },
  typography: {
    button: {
      textTransform: "none",
      fontSize: "1rem",
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "1.1rem",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          // color: '#fff'
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        dot: {
          width: "0.7rem",
          height: "0.7rem",
          borderRadius: "50%",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          position: "relative",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "0.5rem",
          fontSize: "1rem",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiDataGrid-columnHeaderTitle": {
            lineHeight: "normal !important",
            whiteSpace: "normal !important",
          },
          "& .MuiDataGrid-cell": {
            lineHeight: "normal !important",
            whiteSpace: "normal !important",
            display: "flex !important",
            alignItems: "center",
            outline: "none !important",
          },
          "& .MuiDataGrid-columnsContainer": {
            backgroundColor: blue[50],
            justifyContent: "center",
          },
          "& .MuiDataGrid-menuIcon": {
            height: "100%",
            margin: "auto 0 auto 0",
          },
          "& .MuiDataGrid-columnHeader": {
            outline: "none !important",
          },
        },
      },
    },
  },
});

export default mainCssTheme;
