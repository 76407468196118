import React from "react";
import {confirmDialog, getPhotoSize} from "lib/helpers";

export default async function checkPassportPhoto({photoFile, send, service}) {
  const photoSize = await getPhotoSize(photoFile);
  let isValid = photoSize.width === photoSize.height && photoSize.width <= 600;

  if (!isValid) {
    const content = (
      <div>
        <p>
          The Size of Passport Photo is not valid. The example of starndard photo size is 600x600. Uploading photo size
          is {photoSize.width}x{photoSize.height}.
        </p>
        <p>Would you like to continue upload?</p>
      </div>
    );
    const result = await confirmDialog({send, service, setup: {content, consent: true}});
    isValid = result === 1;
  }

  return isValid;
}
