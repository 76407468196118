import React, {useContext, useState} from "react";
import {Send} from "@mui/icons-material";
import {Button, TextField} from "@mui/material";
import {useActor} from "@xstate/react";
import {MachineContext} from "lib";
import PropTypes from "prop-types";

export default function MessageToolbar({employmentId, employmentStatus}) {
  const [state, send] = useActor(useContext(MachineContext));
  const {profileMachineRef} = state.context;
  const {crewProfile} = profileMachineRef?.state.context || {};
  const {firstName, lastName} = crewProfile || {};
  const [memo, setMemo] = useState("");
  const isStatusWithdrawn = employmentStatus.status === "WITHDRAWN";
  const messagePlaceholder = isStatusWithdrawn
    ? "Unable to send a message. Application withdrawn."
    : "your message here";
  const handleChange = ({target}) => setMemo(target.value);
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const userData = {firstName, lastName};
    const data = {type: "INQUIRY", memo, employmentId, userData};
    send({type: "sendEmploymentEvent", data});
    setMemo("");
  };

  return (
    <form onSubmit={handleSubmit} style={{display: "flex"}}>
      <TextField
        size="small"
        id="message"
        label={messagePlaceholder}
        autoComplete="off"
        sx={{flexGrow: 1, mr: 1}}
        value={memo}
        onChange={handleChange}
        disabled={isStatusWithdrawn}
      />
      <Button
        variant="contained"
        type="submit"
        endIcon={<Send />}
        sx={{mb: {xs: 1, md: 0}}}
        disabled={isStatusWithdrawn || memo === ""}>
        Send
      </Button>
    </form>
  );
}

MessageToolbar.propTypes = {
  employmentId: PropTypes.string,
  employmentStatus: PropTypes.object,
  onSend: PropTypes.func,
};
