import {createMachine} from "xstate";

import actions from "./actions";
import services from "./services";

const guards = {
  isAuthorisationInProgress: (_, __, {state}) => state.value !== "idle" && state.value !== "authorised",
};

const remitterAuthMachine = createMachine(
  {
    id: "remitterAuthMachine",
    predictableActionArguments: true,
    initial: "idle",
    context: {
      user: null,
      alert: null,
      totpSetup: null,
      isMachineWorking: false,
    },
    on: {
      CANCEL_LOGIN_REMITTER: [{target: "idle", cond: guards.isAuthorisationInProgress}],
      RESET_REMITTER_AUTH_ALERT: {actions: "resetError"},
    },
    states: {
      idle: {
        on: {
          LOGIN_REMITTER: "checkingAuth",
        },
      },
      checkingAuth: {
        entry: ["resetTotpSetup", "machineStartsWorking"],
        exit: ["machineStopsWorking"],
        invoke: services.checkingAuth,
        on: {
          SETUP_MFA: {target: "setingUpMfa"},
          TOTP_INPUT: {target: "totpTokenInput", actions: "updateUser"},
        },
      },
      setingUpMfa: {
        entry: ["machineStartsWorking"],
        invoke: services.setupTOTP,
        on: {
          UPDATE_TOTP_SETUP: {actions: ["updateTotpSetup", "machineStopsWorking"]},
          CONTINUE_TOTP_SETUP: {target: "remitterTotpTokenInput"},
        },
      },
      remitterTotpTokenInput: {
        entry: ["logger"],
        exit: ["resetError"],
        on: {
          VERIFY_TOTP: {target: "verifyingRemitterToken", actions: "updateTotpSetup"},
          BACK_TO_TOTP_SETUP: {target: "setingUpMfa"},
        },
      },
      verifyingRemitterToken: {
        entry: ["machineStartsWorking", "resetError"],
        exit: ["machineStopsWorking"],
        invoke: services.verifyRemitterToken,
        on: {
          SUCCESS: {target: "authorised", actions: "updateUser"},
          ERROR: {target: "remitterTotpTokenInput", actions: "updateError"},
        },
      },
      totpTokenInput: {
        entry: ["logger"],
        exit: ["resetError"],
        on: {
          CONFIRM_TOTP: {target: "confirmingTotpToken", actions: "updateTotpSetup"},
        },
      },
      confirmingTotpToken: {
        entry: ["machineStartsWorking", "resetError"],
        exit: ["machineStopsWorking"],
        invoke: services.verifyTotpToken,
        on: {
          SUCCESS: {target: "authorised"},
          ERROR: {target: "totpTokenInput", actions: "updateError"},
        },
      },
      authorised: {
        entry: ["sendUserToParent"],
        on: {
          LOGOUT: {target: "idle", actions: ["resetTotpSetup"]},
        },
      },
    },
  },
  {actions}
);

export default remitterAuthMachine;
