import React from "react";
import {DECO_SCHEDULES, GAS_MIXTURE} from "lib/data";
import PropTypes from "prop-types";

export default function WorkDescription({formState, onChange}) {
  return (
    <>
      <div className="col-12 col-sm-6 col-lg-4 col-xl-2 mb-3">
        <label className="form-label" htmlFor="helmetType">
          Type of Helmet/Mask used
        </label>
        <input
          type="text"
          className="form-control"
          id="helmetType"
          value={formState.helmetType}
          name="helmetType"
          maxLength="64"
          onChange={onChange}
          required
        />
        <div className="invalid-feedback">Please provide a valid Type of Helmet of Mask.</div>
      </div>

      <div className="col-12 col-sm-6 col-lg-3 col-xl-2 mb-3">
        <label className="form-label" htmlFor="gasMixture">
          Breathing Mixture used
        </label>
        <select
          className="form-select"
          id="gasMixture"
          name="gasMixture"
          value={formState.gasMixture}
          onChange={onChange}
          required>
          <option value="" disabled>
            Choose...
          </option>
          {GAS_MIXTURE.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
        <div className="invalid-feedback">Please choose a valid Breathing Mixture.</div>
      </div>

      <div className="col-12 col-sm-6 col-lg-5 col-xl-5 mb-3">
        <label className="form-label" htmlFor="workDescription">
          Work Description, equipment and tools used
        </label>
        <input
          type="text"
          className="form-control"
          id="workDescription"
          value={formState.workDescription}
          name="workDescription"
          maxLength="64"
          onChange={onChange}
          required
        />
        <div className="invalid-feedback">Please provide a valid Work Description.</div>
      </div>

      <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3">
        <label className="form-label" htmlFor="decoSchedules">
          Type of Decompression Schedules used
        </label>
        <select
          className="form-select"
          id="decoSchedules"
          name="decoSchedules"
          value={formState.decoSchedules}
          onChange={onChange}
          required>
          <option value="" disabled>
            Choose...
          </option>
          {DECO_SCHEDULES.map((name) => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

WorkDescription.propTypes = {
  formState: PropTypes.object,
  onChange: PropTypes.func,
};
