import React from "react";
import {Avatar, Button, IconButton, Tooltip} from "@mui/material";
import {AuthLink} from "lib/components";
import PropTypes from "prop-types";

import MenuItemTitle from "./MenuItemTitle";

const toolbarActiveStyle = {color: "#fff", textDecoration: "underline", opacity: 1};
const toolbarNonActiveStyle = {color: "#fff", textDecoration: "none", opacity: 0.7};

const UserMenuItem = ({menuItem, btnStyles, isSmallScreen}) => {
  const {to, title, className, updates, icon} = menuItem;

  return (
    <AuthLink to={to} isNavLink={true} style={({isActive}) => (isActive ? toolbarActiveStyle : toolbarNonActiveStyle)}>
      {isSmallScreen ? (
        <Tooltip title={title}>
          <IconButton sx={btnStyles}>
            <MenuItemTitle
              updates={updates}
              title={
                <Avatar sx={{bgcolor: "white", width: "2rem", height: "2rem", color: "primary.main"}}>{icon}</Avatar>
              }
            />
          </IconButton>
        </Tooltip>
      ) : (
        <Button variant="outlined" sx={btnStyles} className={className}>
          <MenuItemTitle updates={updates} title={title} />
        </Button>
      )}
    </AuthLink>
  );
};

UserMenuItem.propTypes = {
  menuItem: PropTypes.object,
  btnStyles: PropTypes.object,
  isSmallScreen: PropTypes.bool,
};

export default UserMenuItem;
