import { authExchange } from "@urql/exchange-auth";
import { Auth } from "aws-amplify";

async function initializeAuthState() {
  try {
    const session = await Auth.currentSession();
    return session;
  } catch (e) {
    await Auth.signOut();
    return null;
  }
}

const cognitoAuthExchange = authExchange(async utils => {
  let session = await initializeAuthState();
  return {
    async refreshAuth() {
      try {
        session = await Auth.currentSession();
      } catch (e) {
        await Auth.signOut();
        session = null;
      }
    },
    didAuthError(error) {
      return error.graphQLErrors.some(e => e.message === 'Unauthorized')
    },
    willAuthError() {
      if (!session) return true;
      try {
        const exp = session.getAccessToken().getExpiration();
        return exp * 1000 < Date.now()
      } catch(e) {
        return true
      }
    },
    addAuthToOperation(operation) {
      if (!session) return operation;
      return utils.appendHeaders(operation, {
        Authorization: `Bearer ${session?.getAccessToken().getJwtToken()}`,
      });
    },
  }
})

export default cognitoAuthExchange;


