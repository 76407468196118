import {API, graphqlOperation, Storage} from "aws-amplify";
import awsExports from "aws-exports";
import {isAfter, parseISO} from "date-fns";
import {createShipCrewEmployment, createShipCrewEmploymentEvent, deleteEmploymentUpdate} from "graphql/mutations";
import {getEmploymentUpdate} from "graphql/queries";

import {signDocument} from "./signDocument";

const BUCKET = awsExports.aws_user_files_s3_bucket;
const REGION = awsExports.aws_user_files_s3_bucket_region;

const addEmploymentEvent = async (input) => {
  const response = await API.graphql(graphqlOperation(createShipCrewEmploymentEvent, {input}));
  const {createShipCrewEmploymentEvent: newEvent} = response.data;
  return newEvent;
};

const addEmployment = async ({tenantId}) => {
  const input = {tenantId};
  const response = await API.graphql(graphqlOperation(createShipCrewEmployment, {input}));
  const {createShipCrewEmployment: newEmployment} = response.data;
  return newEmployment;
};

const clearEmploymentUpdates = async (employmentId) => {
  const response = await API.graphql(graphqlOperation(getEmploymentUpdate, {employmentId}));
  const {getEmploymentUpdate: update} = response.data;
  !!update && (await API.graphql(graphqlOperation(deleteEmploymentUpdate, {input: {employmentId}})));
};

const saveSignedDocument = async ({employments, employmentId, type, user}) => {
  const employment = employments.find((item) => item.id === employmentId);
  const eventTypeFilter = type === "ACCEPT_OFFER" ? "RELEASE_OFFER" : "RELEASE_CONTRACT";
  const allOfferEvents = employment.employmentEvents.items.filter((item) => item.type === eventTypeFilter);

  const {file} =
    allOfferEvents.length === 1
      ? allOfferEvents[0]
      : allOfferEvents.reduce((result, item) =>
          isAfter(parseISO(item.createdAt), parseISO(result.createdAt)) ? item : result
        );
  const signedPdfBlob = await signDocument({file, user});

  if (!signedPdfBlob) {
    const message = "Unable to sign the Document. Try again or contact us for technical support.";
    return {alert: {type: "error", message}};
  }
  const millis = Date.now();
  const fileName = type === "ACCEPT_OFFER" ? `signed_offer_${millis}` : `signed_contract_${millis}`;
  const fileKey = `employmentId_${employmentId}/${fileName}.pdf`;
  const storageOptions = {level: "protected", contentType: signedPdfBlob.type};
  const {key} = await Storage.put(fileKey, signedPdfBlob, storageOptions);

  return {bucket: BUCKET, key, region: REGION};
};

export {addEmployment, addEmploymentEvent, clearEmploymentUpdates, saveSignedDocument};
