import {API, graphqlOperation, Storage} from "aws-amplify";
import awsExports from "aws-exports";
import {format} from "date-fns";
import {saveAs} from "file-saver";
import {
  createCrewDoc,
  createPersonalDoc,
  deleteCrewDoc,
  deletePersonalDoc,
  updateCrewDoc,
  updatePersonalDoc,
} from "graphql/customMutations";
import {descriptionByDoc} from "graphql/queries";
import {appSettings} from "lib";
import {getFileExtension, readImg, removeNullishProps} from "lib/helpers";

const BUCKET = awsExports.aws_user_files_s3_bucket;
const REGION = awsExports.aws_user_files_s3_bucket_region;
const getTotalDocsVolume = (allDocs = []) => allDocs.reduce((result, doc) => doc.docSize + result, 0);

async function _resetImage(imageFile) {
  const imgData = await readImg(imageFile);

  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function () {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const scale = Math.max(img.width / 600, img.height / 600, 1);

      canvas.width = img.width / scale;
      canvas.height = img.height / scale;
      ctx.scale(1 / scale, 1 / scale);
      ctx.drawImage(img, 0, 0);
      canvas.toBlob((blob) => resolve(blob));
    };
    img.onerror = (error) => reject(error);
    img.src = imgData;
  });
}

const copyToArchive = async (existingDoc) => {
  const {
    issueDate,
    expiryDate,
    docSize,
    docNum,
    doc,
    file: {key: oldFileKey},
  } = existingDoc;
  const {issuePlace, includedInCV, title} = existingDoc;
  const docTitle = title || doc.title;
  const fileExt = getFileExtension(oldFileKey);
  const date = format(new Date(), "ddMMMyyyy-HHmmss");
  const {key} = await Storage.copy(
    {key: oldFileKey, level: "protected"},
    {key: `Personal/${docTitle}-${date}.${fileExt}`, level: "protected"}
  );
  const fileObj = {bucket: BUCKET, key, region: REGION};
  const newTitle = `${docTitle}-${date}`;
  const input = {
    issueDate,
    expiryDate,
    docSize,
    docNum,
    title: newTitle,
    file: fileObj,
    issuePlace,
    includedInCV,
  };
  const response = await API.graphql(graphqlOperation(createPersonalDoc, {input}));
  const {
    data: {createPersonalDoc: newArchiveDoc},
  } = response;
  return newArchiveDoc;
};

const _uploadToBucket = async (oldFileKey, file, filePath) => {
  !!oldFileKey && (await Storage.remove(oldFileKey, {level: "protected"}));

  return new Promise((resolve, reject) => {
    Storage.put(filePath, file, {
      level: "protected",
      contentType: file.type,
      resumable: true,
      completeCallback: (event) => resolve(event.key),
      errorCallback: (err) => reject(`Unexpected error while uploading: ${err}`),
    });
  });
};

const uploadDoc = async ({docData}) => {
  const gqlDocOperations = {
    Personal: {
      create: {operation: createPersonalDoc, name: "createPersonalDoc"},
      update: {operation: updatePersonalDoc, name: "updatePersonalDoc"},
    },
    Required: {
      create: {operation: createCrewDoc, name: "createCrewDoc"},
      update: {operation: updateCrewDoc, name: "updateCrewDoc"},
    },
  };
  const {passportPhotoDocId} = appSettings;
  const {shouldArchive, existingDoc, docFile, ...crewDocData} = docData;
  const {fileExt, file} = docFile;
  const docType = !crewDocData.docId ? "Personal" : "Required";
  const newArchiveDoc = shouldArchive === 0 ? await copyToArchive(existingDoc) : null;
  const isPassportPhoto = crewDocData?.docId === passportPhotoDocId;
  const fileName = isPassportPhoto
    ? `${crewDocData.docDescriptionId}.png`
    : docType === "Required"
    ? `${crewDocData.docDescriptionId}${Date.now()}.${fileExt}`
    : `${crewDocData.title}.${fileExt}`;
  const filePath = `${docType}/${fileName}`;
  const fileToUpload = !isPassportPhoto ? file : await _resetImage(file);

  await _uploadToBucket(existingDoc?.file.key, fileToUpload, filePath);

  const fileObj = {bucket: BUCKET, key: filePath, region: REGION};
  const docSize = Math.ceil(file.size / 1024);
  const resetedData = removeNullishProps({...crewDocData, docSize, id: existingDoc?.id});
  const currentOperaion = !existingDoc ? gqlDocOperations[docType].create : gqlDocOperations[docType].update;
  const {data} = await API.graphql(
    graphqlOperation(currentOperaion.operation, {input: {...resetedData, file: fileObj}})
  );
  const upsertedDoc = data[currentOperaion.name];
  const profileImgKey = isPassportPhoto ? upsertedDoc.file.key : null;

  return {
    newDoc: upsertedDoc,
    profileImgKey,
    isNew: !existingDoc,
    newArchiveDoc,
  };
};

const deleteDoc = async ({docData: {fileKey, id}}) => {
  await Storage.remove(fileKey, {level: "protected"});

  const docType = fileKey.includes("Personal/") ? "Personal" : "Required";
  const gqlDocOperations = {
    Personal: {operation: deletePersonalDoc, name: "deletePersonalDoc"},
    Required: {operation: deleteCrewDoc, name: "deleteCrewDoc"},
  };
  const currentOperation = gqlDocOperations[docType];
  const {data} = await API.graphql(graphqlOperation(currentOperation.operation, {input: {id}}));

  return {removedDoc: data[currentOperation.name]};
};

async function downloadDoc({fileName, fileKey}) {
  const saveAsFileName = `${fileName}.${getFileExtension(fileKey)}`;
  const {Body} = await Storage.get(fileKey, {download: true, level: "protected"});
  saveAs(Body, saveAsFileName);

  return;
}

async function updateDoc({newDocData, isRequired}) {
  const docType = !isRequired ? "Personal" : "Required";
  const gqlDocOperations = {
    Personal: {operation: updatePersonalDoc, name: "updatePersonalDoc"},
    Required: {operation: updateCrewDoc, name: "updateCrewDoc"},
  };
  const currentOperaion = gqlDocOperations[docType];
  const {data} = await API.graphql(graphqlOperation(currentOperaion.operation, {input: {...newDocData}}));

  return {updatedDoc: data[currentOperaion.name], isRequired};
}

async function loadMoreDescriptions({docId, nextToken}) {
  const response = await API.graphql(graphqlOperation(descriptionByDoc, {docId, nextToken}));
  const {items, nextToken: newNextToken} = response.data.descriptionByDoc;

  return {docId, descriptions: items, nextToken: newNextToken};
}

export {deleteDoc, downloadDoc, getTotalDocsVolume, loadMoreDescriptions, updateDoc, uploadDoc};
