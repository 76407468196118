import React, {useEffect, useMemo, useRef, useState} from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import PropTypes from "prop-types";

dayjs.extend(customParseFormat);
const dateRegex = /^[0-9]{4}[-]{1}[0-9]{2}[-]{1}[0-9]{2}$/g;

export default function DateForm(props) {
  const {dateState, dateName, dateTitle, cssClass = "", min, max, onChange, disabled} = props;
  const dateRef = useRef(null);
  const initFeedback = useMemo(() => "Please provide a valid Date.", []);
  const [feedback, setFeedback] = useState(initFeedback);
  const [dateLimits, setDateLimits] = useState({min: "", max: ""});

  const handleChange = (event) => {
    const {
      target: {value, type},
    } = event;

    if (type === "text") {
      const newdDate = dateRegex.test(value) ? dayjs(value, "YYYY-MM-DD", true) : null;
      const customValidity =
        !newdDate || !newdDate.isValid()
          ? "Please provide a valid Date (YYYY-MM-DD)."
          : newdDate.isAfter(dayjs(dateLimits.max))
          ? `Please provide a valid Date earlier than ${dateLimits.max} (YYYY-MM-DD).`
          : newdDate.isBefore(dayjs(dateLimits.min))
          ? `Please provide a valid Date later than ${dateLimits.min} (YYYY-MM-DD).`
          : "";

      dateRef.current.setCustomValidity(customValidity);
      setFeedback(customValidity);
    }
    onChange(event);
  };

  useEffect(() => {
    dateState === "" && setFeedback(initFeedback);
  }, [dateState, initFeedback]);

  useEffect(() => {
    setDateLimits({min: min?.format("YYYY-MM-DD"), max: max?.format("YYYY-MM-DD")});
  }, [min, max]);

  return (
    <>
      <div className={`mb-3 ${cssClass}`}>
        <label className="form-label" htmlFor={dateName}>
          {dateTitle}
        </label>
        <input
          className="form-control"
          type="date"
          id={dateName}
          ref={dateRef}
          placeholder="YYYY-MM-DD"
          value={dateState}
          name={dateName}
          min={dateLimits.min}
          max={dateLimits.max}
          onChange={handleChange}
          disabled={disabled}
          required
        />
        <div className="invalid-feedback">{feedback}</div>
      </div>
    </>
  );
}

DateForm.propTypes = {
  dateState: PropTypes.string,
  dateName: PropTypes.string,
  dateTitle: PropTypes.string,
  cssClass: PropTypes.string,
  min: PropTypes.object,
  max: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
