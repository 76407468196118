import React, {useEffect, useState} from "react";
import {ArrowBackIos} from "@mui/icons-material";
import {Box, Button, FormHelperText, Grid, Typography} from "@mui/material";
import {OTPInput} from "lib/components";
import PropTypes from "prop-types";

const ACTION = {
  CONFIRM: "CONFIRM_TOTP",
  VERIFY: "VERIFY_TOTP",
};
const MIN_BTN_WIDTH = "10rem";

export default function TOTPCodeForm({send, onBackClicked, type, alert, resetAlert}) {
  const [otp, setOtp] = useState("");

  useEffect(() => otp !== "" && !!alert && resetAlert(), [alert, otp, resetAlert]);

  useEffect(() => {
    if (otp.length === 6) {
      send({type: ACTION[type], data: {code: otp}});
      setOtp("");
    }
  }, [otp, send, type]);

  return (
    <Box>
      <Typography variant="h6" mb={2}>
        Confirm One Time Password (OTP) Code
      </Typography>
      <Grid container my={3} width={1} minHeight={"8rem"} justifyContent={"center"}>
        <Grid item>
          <Typography align="center">Enter the OTP code from your Authentication application</Typography>
        </Grid>
        <Grid item>
          <OTPInput value={otp} onChange={setOtp} length={6} />
          <FormHelperText error>{alert?.message}</FormHelperText>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        direction={{xs: "column-reverse", sm: "row"}}
        width={1}>
        <Grid item xs={12} sm="auto" sx={{display: "flex", justifyContent: {xs: "center", sm: "start"}}}>
          {!!onBackClicked && (
            <Button
              variant="outlined"
              onClick={onBackClicked}
              color="error"
              fullWidth
              sx={{minWidth: MIN_BTN_WIDTH}}
              startIcon={<ArrowBackIos />}>
              Back to Setup
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

TOTPCodeForm.propTypes = {
  onBackClicked: PropTypes.func,
  resetAlert: PropTypes.func,
  type: PropTypes.oneOf(["CONFIRM", "VERIFY"]),
  send: PropTypes.func,
  alert: PropTypes.object,
};
