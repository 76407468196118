import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {AuthRoute, NotFound} from "lib/components";

import {
  About,
  AdditionalForm,
  BankAccount,
  BiometricalForm,
  ContactUs,
  CookiesPolicy,
  CrewDocs,
  CrewLogs,
  CrewProfile,
  DataPrivacy,
  Employment,
  Finance,
  Home,
  MyAccount,
  Notifications,
  PersonalForm,
  PrivacyPolicy,
  Subscription,
  TermsConditions,
} from "components";
import Claims from "components/finance/Claims";
import PaymentDetails from "components/finance/PaymentDetails";
import PaySlips from "components/finance/PaySlips";

export default function RouteSwitch() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<ContactUs />} />
      <Route path="/terms" element={<TermsConditions />} />
      <Route path="/data-privacy" element={<DataPrivacy />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/cookies" element={<CookiesPolicy />} />
      <Route
        path="/profile"
        element={
          <AuthRoute>
            <CrewProfile />
          </AuthRoute>
        }>
        <Route path="personal-details" element={<PersonalForm />} />
        <Route path="biometrical-details" element={<BiometricalForm />} />
        <Route path="additional-details" element={<AdditionalForm />} />
        <Route path="" element={<Navigate to="personal-details" replace />}></Route>
      </Route>
      <Route
        path="/crewdocs"
        element={
          <AuthRoute>
            <CrewDocs />
          </AuthRoute>
        }
      />
      <Route
        path="/crewlogs"
        element={
          <AuthRoute>
            <CrewLogs />
          </AuthRoute>
        }
      />

      <Route
        path="/finance"
        element={
          <AuthRoute>
            <Finance />
          </AuthRoute>
        }>
        <Route path="payment-details" element={<PaymentDetails />} exact />
        <Route path="payment-details/bank-accounts/:accountId" element={<BankAccount />} exact />
        <Route
          path="payment-details/bank-accounts"
          element={<Navigate to="/finance/payment-details#bank-accounts" replace />}
          exact
        />
        <Route path="pay-slips" element={<PaySlips />} exact />
        <Route path="claims" element={<Claims />} exact />
        <Route path="" element={<Navigate to="payment-details" replace />} exact></Route>
      </Route>

      <Route
        path="/employment"
        element={
          <AuthRoute>
            <Employment />
          </AuthRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <AuthRoute>
            <Notifications />
          </AuthRoute>
        }
      />
      <Route
        path="/account"
        element={
          <AuthRoute>
            <MyAccount />
          </AuthRoute>
        }
      />
      <Route path="/subscription" element={<Subscription />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
