import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import {Alert, Box, CircularProgress, Grid, TextField, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import {validateBic, validateIban} from "graphqlMarfin/queries";
import {validateBIC, validateIBAN} from "ibantools"; // getCountrySpecifications
import {FormInputSelect, FormInputText} from "lib/components";
import {
  ACCOUNT_DETAILS_VALIDATION_ERROR,
  ACCOUNT_NUMBER_FIELD_NAME,
  IBAN_FIELD_NAME,
  IBAN_STATUS,
  ROUTING_CODE_PATTERN,
  SWIFT_CODE_FIELD_NAME,
  VALIDATION_ERROR,
} from "lib/data";
import UrqlClient, {QueryType} from "lib/helpers/urqlClient/urqlClient";
import MachineContext from "lib/MachineContext";
import PropTypes from "prop-types";

export default function BeneficiaryAccountDetailsForm({
  countryTemplates,
  countriesOptions,
  setAccountDetails,
  setIsAccountDetailsValid,
  savedAccountDetails,
}) {
  const [, send] = useActor(useContext(MachineContext));

  const initBankCountryCode = useRef(savedAccountDetails?.bankCountryCode ?? "");
  const initAccountDetails = useRef(savedAccountDetails?.accountDetails ?? null);
  const lastValidatedBank = useRef(savedAccountDetails?.bankDetails ?? null);

  const [accountTemplateEditableFields, setAccountTemplateEditableFields] = useState([]);

  const bankCountryFormMethods = useForm({
    defaultValues: {bankCountryCode: initBankCountryCode.current},
  });
  const {control: bankCountryCotrol} = bankCountryFormMethods;
  const bankCountryCode = useWatch({control: bankCountryCotrol, name: "bankCountryCode"});

  const methods = useForm({mode: "onChange"});
  const {control, reset, formState, setValue, trigger, getValues} = methods;
  const {isValid, isValidating} = formState;
  const [iban, accountNumber, swiftCode] = useWatch({
    control: control,
    name: [IBAN_FIELD_NAME, ACCOUNT_NUMBER_FIELD_NAME, SWIFT_CODE_FIELD_NAME],
  });

  const isAccountdDetailsValid = useMemo(() => !!bankCountryCode && isValid, [bankCountryCode, isValid]);

  const [validatedBankDetails, setValidatedBankDetails] = useState(lastValidatedBank.current);

  const currentCountryTemplate = useMemo(
    () => (!bankCountryCode ? null : countryTemplates.find((template) => template.countryCode === bankCountryCode)),
    [bankCountryCode, countryTemplates]
  );
  const currentIbanStatus = useMemo(() => currentCountryTemplate?.ibanStatus ?? null, [currentCountryTemplate]);

  const accountTemplate = useMemo(() => {
    if (!currentCountryTemplate?.accountTemplate) return [];

    const {properties} = JSON.parse(currentCountryTemplate?.accountTemplate);

    return Object.keys(properties).map((key) => ({...properties[key], name: key}));
  }, [currentCountryTemplate]);

  // TODO: check how to use currentCountrySpecification and bban_regexp, for Russia it gives wrong regexp
  // const currentCountrySpecification = useMemo(
  //   () => (!bankCountryCode ? null : getCountrySpecifications()[bankCountryCode.toUpperCase()]),
  //   [bankCountryCode]
  // );

  const validatedBankName = useMemo(() => {
    const bank = validatedBankDetails?.bank;
    if (!bank) {
      return "";
    }

    const department = validatedBankDetails.bank.department ? `, ${validatedBankDetails.bank.department}` : "";
    return `${validatedBankDetails.bank.name ?? ""}${department}`;
  }, [validatedBankDetails]);

  const isControlRequired = useCallback(
    (controlName) => accountTemplate.some((item) => item.name === controlName && item.type !== "hidden"),
    [accountTemplate]
  );

  const getError = useCallback((errorType, errorCode) => {
    const errorsByType = ACCOUNT_DETAILS_VALIDATION_ERROR[errorType];
    return !errorsByType ? "" : errorsByType[errorCode]?.error ?? "";
  }, []);

  const setBankDetails = useCallback(
    (bankDetails) => {
      const parsedBankDetails = {
        ...bankDetails,
        bank: JSON.parse(bankDetails.bank),
        validation: !bankDetails?.validation ? null : JSON.parse(bankDetails.validation),
      };
      const {country_code, country_name} = parsedBankDetails.bank.structured_address;
      const isValidCountry = currentCountryTemplate.countryCode.toUpperCase() === country_code.toUpperCase();
      const error = !isValidCountry
        ? VALIDATION_ERROR.bankCountryMismatchShouldBeProvided(
            currentCountryTemplate.countryName.toUpperCase(),
            country_name.toUpperCase()
          )
        : null;

      lastValidatedBank.current = {bankDetails: parsedBankDetails, validation: error};
      if (isControlRequired(SWIFT_CODE_FIELD_NAME)) {
        setValue(SWIFT_CODE_FIELD_NAME, parsedBankDetails.bank.bic, {shouldValidate: true});
      }
      setValidatedBankDetails(parsedBankDetails);
      return error;
    },
    [currentCountryTemplate, isControlRequired, setValue]
  );

  const rules = useMemo(
    () => ({
      [SWIFT_CODE_FIELD_NAME]: {
        validate: async (value) => {
          let isRequired = isControlRequired(SWIFT_CODE_FIELD_NAME);

          // IBAN is experimental or recommended
          if (currentIbanStatus === IBAN_STATUS.RECOMMENDED || currentIbanStatus === IBAN_STATUS.EXPERIMANTAL) {
            const ibanValue = getValues(IBAN_FIELD_NAME);

            isRequired = ibanValue === "";
          }

          if (!isRequired && !value) return true;

          const {errorCodes, valid} = validateBIC(value);

          if (!valid) {
            setValidatedBankDetails(null);
            return getError("BIC", errorCodes[0]);
          }

          const _lastValidatedBankSwiftCode = lastValidatedBank.current?.bankDetails
            ? lastValidatedBank.current?.bankDetails[SWIFT_CODE_FIELD_NAME]?.toUpperCase()
            : "";
          let validation = lastValidatedBank.current?.validation ?? null;

          if (_lastValidatedBankSwiftCode === value) {
            setValidatedBankDetails(lastValidatedBank.current?.bankDetails ?? null);
          } else {
            try {
              const {error, data: bankDetails} = await UrqlClient.execute(QueryType.QUERY, validateBic, "validateBic", {
                swiftCode: value,
              });

              if (error || !bankDetails) {
                validation = error.message ?? VALIDATION_ERROR.swiftCodeIsNotValid;
                setValidatedBankDetails(null);
              } else {
                validation = setBankDetails(bankDetails);
              }
            } catch (error) {
              send({type: "alert", data: {message: error.message}});
            }
          }
          return validation;
        },
      },
      routingCode: {
        validate: (value) => {
          const isRequired = isControlRequired("routingCode");

          if (!isRequired && !value) return true;
          if (isRequired && !value) return VALIDATION_ERROR.required;
          return ROUTING_CODE_PATTERN.test(value) || VALIDATION_ERROR.invalidRoutingCodeFormat;
        },
      },
      [ACCOUNT_NUMBER_FIELD_NAME]: {
        validate: (value) => {
          let isRequired = isControlRequired(ACCOUNT_NUMBER_FIELD_NAME);

          // IBAN is experimental or recommended
          if (currentIbanStatus === IBAN_STATUS.RECOMMENDED || currentIbanStatus === IBAN_STATUS.EXPERIMANTAL) {
            const ibanValue = getValues(IBAN_FIELD_NAME);

            isRequired = ibanValue === "";
          }

          if (!isRequired && !value) return true;
          if (isRequired && !value) return VALIDATION_ERROR.required;

          // const accountRegExp = !isRequired
          //   ? null
          //   : !currentCountrySpecification?.bban_regexp
          //   ? new RegExp(/^[A-Z0-9]{1,50}$/)
          //   : new RegExp(currentCountrySpecification.bban_regexp);
          // TODO: check how to use bban_regexp, for Russia it gives wrong regexp
          const accountRegExp = !isRequired ? null : new RegExp(/^[A-Z0-9]{1,50}$/);

          return !accountRegExp ? true : accountRegExp.test(value) || VALIDATION_ERROR.invalidAccountNumberFormat;
        },
      },
      [IBAN_FIELD_NAME]: {
        validate: async (value) => {
          let isRequired = isControlRequired(IBAN_FIELD_NAME);

          // IBAN is experimental or recommended
          if (currentIbanStatus === IBAN_STATUS.RECOMMENDED || currentIbanStatus === IBAN_STATUS.EXPERIMANTAL) {
            const swiftCode = getValues(SWIFT_CODE_FIELD_NAME);
            const accountNumber = getValues(ACCOUNT_NUMBER_FIELD_NAME);

            isRequired = swiftCode === "" && accountNumber === "";
          }

          if (!isRequired && !value) return true;

          const {errorCodes, valid} = validateIBAN(value);

          if (!valid) {
            setValidatedBankDetails(null);
            return getError("IBAN", errorCodes[0]);
          }

          const _lastValidatedBankIban = lastValidatedBank.current?.bankDetails
            ? lastValidatedBank.current.bankDetails[IBAN_FIELD_NAME]?.toUpperCase()
            : "";
          let validation = lastValidatedBank.current?.validation ?? null;

          if (_lastValidatedBankIban === value) {
            setValidatedBankDetails(lastValidatedBank.current?.bankDetails ?? null);
          } else {
            try {
              const {error, data: bankDetails} = await UrqlClient.execute(
                QueryType.QUERY,
                validateIban,
                "validateIban",
                {
                  iban: value,
                }
              );

              if (error || !bankDetails) {
                validation = error.message ?? VALIDATION_ERROR.ibanIsNotValid;
                setValidatedBankDetails(null);
              } else {
                validation = setBankDetails(bankDetails);
              }
            } catch (error) {
              send({type: "alert", data: {message: error.message}});
            }
          }

          return !validation ? true : validation;
        },
      },
    }),
    [currentIbanStatus, getError, getValues, isControlRequired, send, setBankDetails]
  );

  const disabled = useMemo(
    () => ({
      [IBAN_FIELD_NAME]: !!accountNumber,
      [ACCOUNT_NUMBER_FIELD_NAME]: !!iban,
      [SWIFT_CODE_FIELD_NAME]: !!iban,
    }),
    [iban, accountNumber]
  );

  useEffect(() => {
    trigger([ACCOUNT_NUMBER_FIELD_NAME, SWIFT_CODE_FIELD_NAME]);
  }, [iban, trigger]);

  useEffect(() => {
    trigger([IBAN_FIELD_NAME]);
  }, [accountNumber, swiftCode, trigger]);

  useEffect(() => {
    setAccountTemplateEditableFields([]);
  }, [bankCountryCode, trigger]);

  useEffect(() => {
    const formFields = accountTemplate.filter((item) => item.type !== "hidden");

    if (formFields.length) {
      const initFormState = initAccountDetails.current
        ? initAccountDetails.current
        : formFields.reduce((res, item) => ({...res, [item.name]: ""}), {});

      setValidatedBankDetails(null);
      reset(initFormState);
      initAccountDetails.current = null;
    }

    setAccountTemplateEditableFields(formFields);
  }, [accountTemplate, reset]);

  useEffect(() => {
    if (accountTemplateEditableFields.length) {
      trigger();
    }
  }, [accountTemplateEditableFields, trigger]);

  useEffect(() => {
    setIsAccountDetailsValid(isAccountdDetailsValid);
  }, [isAccountdDetailsValid, setIsAccountDetailsValid]);

  useEffect(() => {
    return () => {
      setAccountDetails({
        accountDetails: getValues(),
        bankDetails: validatedBankDetails,
        bankName: validatedBankName,
        bankCountryCode: bankCountryCode,
        accountTemplate: accountTemplate,
      });
    };
  }, [accountTemplate, getValues, bankCountryCode, setAccountDetails, validatedBankDetails, validatedBankName]);

  return (
    <Box component="form" noValidate autoComplete="off">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FormInputSelect
            name={"bankCountryCode"}
            control={bankCountryCotrol}
            label={"Beneficiary Bank Country"}
            options={countriesOptions}
            fullWidth
          />
        </Grid>
        {accountTemplateEditableFields.map((item) => (
          <Grid item xs={12} sm={6} key={item.name}>
            <FormInputText
              name={item.name}
              control={control}
              label={item.title}
              rules={rules[item.name]}
              endAdornment={isValidating ? <CircularProgress color="inherit" size={18} /> : null}
              disabled={disabled[item.name] ?? false}
              required
              autocapitalize
              fullWidth
            />
          </Grid>
        ))}
        {(currentIbanStatus === IBAN_STATUS.RECOMMENDED || currentIbanStatus === IBAN_STATUS.EXPERIMANTAL) && (
          <Grid item xs={12}>
            <Alert severity="info">
              IBAN is {currentIbanStatus === IBAN_STATUS.RECOMMENDED ? "Recommended" : "Experimental"} for{" "}
              {currentCountryTemplate.countryName}. Please enter [IBAN] or [Account Number and SWIFT code].
            </Alert>
          </Grid>
        )}
      </Grid>
      <Typography variant="subtitle2" color={"text.disabled"} sx={{mt: 3, mb: 2}}>
        Validated Bank Account Details:
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField
            label="Bank name"
            variant="standard"
            value={validatedBankName}
            InputProps={{
              endAdornment: isValidating ? <CircularProgress color="inherit" size={18} /> : null,
            }}
            InputLabelProps={{shrink: true}}
            size="small"
            disabled
            fullWidth
          />
        </Grid>
        {!isControlRequired(SWIFT_CODE_FIELD_NAME) && (
          <Grid item xs={"auto"}>
            <TextField
              label="SWIFT code"
              variant="standard"
              InputLabelProps={{shrink: true}}
              InputProps={{
                endAdornment: isValidating ? <CircularProgress color="inherit" size={18} /> : null,
              }}
              value={validatedBankDetails?.bank?.bic ?? ""}
              size="small"
              disabled
              fullWidth
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

BeneficiaryAccountDetailsForm.propTypes = {
  countryTemplates: PropTypes.array,
  countriesOptions: PropTypes.array,
  setAccountDetails: PropTypes.func,
  setIsAccountDetailsValid: PropTypes.func,
  savedAccountDetails: PropTypes.object,
};
