import fontkit from "@pdf-lib/fontkit";
import {Storage} from "aws-amplify";
import {
  getFileExtension,
  getPdfLibSignature,
  robotoFontArrayBufer,
  signatureFontArrayBufer,
  signatureSize,
} from "lib/helpers";
import {PDFDocument, rgb} from "pdf-lib";

const getBlobBuffer = (blob) => new Promise((resolve) => blob.arrayBuffer().then((buffer) => resolve(buffer)));

const loadPdf = (fileBuffer) => PDFDocument.load(fileBuffer);

const createPdf = async ({fileBuffer, isPng, isJpg}) => {
  const pdfDoc = await PDFDocument.create();
  const page = pdfDoc.addPage();
  const image = isJpg ? await pdfDoc.embedJpg(fileBuffer) : isPng ? await pdfDoc.embedPng(fileBuffer) : null;
  !!image && page.drawImage(image, {x: 0, y: 0, width: page.getWidth(), height: page.getHeight()});
  return !image ? null : pdfDoc;
};

const signDocument = async ({file, user}) => {
  const storageOptions = {download: true, level: "protected"};
  const {Body} = await Storage.get(file.key, storageOptions);
  const fileName = file.key.split("/").pop();
  const fileExtension = getFileExtension(fileName);
  const isPdf = Body.type === "application/pdf" || fileExtension === "pdf";
  const isPng = Body.type === "image/png" || fileExtension === "png";
  const isJpg = Body.type === "image/jpeg" || ["jpeg", "jpg"].includes(fileExtension);

  if (!isPdf && !isPng && !isJpg) return null;

  const fileBuffer = await getBlobBuffer(Body);
  const pdfDoc = !isPdf ? await createPdf({fileBuffer, isPng, isJpg}) : await loadPdf(fileBuffer);
  const pages = pdfDoc.getPages();
  const {width, height} = signatureSize;
  const {textLines, path} = await getPdfLibSignature(user);

  pdfDoc.registerFontkit(fontkit);

  const robotoFont = await pdfDoc.embedFont(robotoFontArrayBufer);
  const signatureFont = await pdfDoc.embedFont(signatureFontArrayBufer);
  const customFonts = {standard: robotoFont, signature: signatureFont};
  const margin = 20;

  // TODO: check it
  pages.forEach((page) => {
    const startX = page.getWidth() - width - margin;

    page.drawSvgPath(path.stroke, {x: startX, y: height + margin, borderColor: rgb(...path.color), borderWidth: 1});

    textLines.forEach(({text, options}) => {
      const {x, y, size, color, fontType} = options;
      page.drawText(text, {
        x: startX + x,
        y: y + margin,
        size,
        font: customFonts[fontType],
        color: color ? rgb(...color) : rgb(0, 0, 0),
      });
    });
  });

  const pdfBytes = await pdfDoc.save();
  return new Blob([pdfBytes], {type: "application/pdf"});
};

export {signDocument};
