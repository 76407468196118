const emerDetailsTable = (profile) => {
  const {emerContactName, emerContactPhone, emerContactRelation, emerContactAddress, emerContactEmail = ""} = profile;

  return {
    headerRows: 1,
    widths: ["*", "auto", "auto", "auto", "auto"],
    body: [
      [
        {text: "Name", style: "th2"},
        {text: "Relationship", style: "th2"},
        {text: "Address", style: "th2"},
        {text: "Phone", style: "th2"},
        {text: "Email", style: "th2"},
      ],
      [
        {text: emerContactName, style: "tdLight"},
        {text: emerContactRelation, style: "tdLight"},
        {text: emerContactAddress, style: "tdLight"},
        {text: emerContactPhone, style: "tdLight"},
        {text: emerContactEmail, style: "tdLight"},
      ],
    ],
  };
};

export default emerDetailsTable;
