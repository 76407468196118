async function getPhotoSize(imgFile) {
  return new Promise((resole, reject) => {
    const img = new Image();

    img.onload = () => {
      resole({width: img.naturalWidth, height: img.naturalHeight});
    };
    img.onerror = () => reject(new Error(false));
    img.src = URL.createObjectURL(imgFile);
  });
}

export default getPhotoSize;
