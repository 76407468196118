const SHOW_SIZE = [
  "35 / 4",
  "37 / 5",
  "38 / 6",
  "39 / 7",
  "40 / 7.5",
  "41 / 8",
  "42 / 9",
  "43 / 10",
  "44 / 11",
  "45 / 11.5",
  "46 / 12",
  "47 / 13",
  "48 / 14",
  "49 / 15",
  "50 / 15.5",
  "51 / 16",
];

export default SHOW_SIZE;
