import React from "react";
import {Badge} from "@mui/material";
import {red} from "@mui/material/colors";
import PropTypes from "prop-types";

const MenuItemTitle = ({title, updates}) => (
  <Badge badgeContent={updates} sx={{".MuiBadge-badge": {color: "white", backgroundColor: red["A200"]}}}>
    {title}
  </Badge>
);

MenuItemTitle.propTypes = {
  title: PropTypes.node,
  updates: PropTypes.number,
};

export default MenuItemTitle;
