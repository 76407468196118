import React, {useCallback, useContext, useMemo, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {ContactPage, Fingerprint, Person} from "@mui/icons-material";
import {Box, Grid, Tab, Tabs, Typography} from "@mui/material";
import {useActor} from "@xstate/react";
import MachineContext from "lib/MachineContext";

import {CVUrlAlert, UserCard} from "./index";

import "scss/crewProfile.scss";

export default function CrewProfile() {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, send] = useActor(useContext(MachineContext));
  const signedZipURL = useMemo(() => state.context.signedZipURL, [state.context.signedZipURL]);
  const crewProfile = useMemo(
    () => state.context.profileMachineRef?.state.context?.crewProfile,
    [state.context.profileMachineRef?.state.context?.crewProfile]
  );

  const tabsInfo = useMemo(
    () => [
      {
        label: !crewProfile ? "Start with your Personal Data" : "Personal Details",
        disabled: false,
        icon: <Person />,
        index: 0,
        to: "/profile/personal-details",
      },
      {
        label: "Biometrical Details",
        disabled: !crewProfile,
        icon: <Fingerprint />,
        index: 1,
        to: "/profile/biometrical-details",
      },
      {
        label: "Additional Details",
        disabled: !crewProfile,
        icon: <ContactPage />,
        index: 2,
        to: "/profile/additional-details",
      },
    ],
    [crewProfile]
  );

  const [activeTab, setActiveTab] = useState(tabsInfo.find((tab) => tab.to === location.pathname)?.index ?? 0);

  const handleTabClick = useCallback(
    (_, newValue) => {
      setActiveTab(newValue);
      navigate(tabsInfo[newValue].to);
    },
    [navigate, tabsInfo]
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        p: 1,
        overflow: "auto",
        height: {xs: "auto", md: 1},
      }}>
      <Grid container spacing={1} sx={{mt: 0}}>
        <Grid item xs={12} md={3} sx={{height: 1, p: 0.5, display: "flex"}}>
          <UserCard />
        </Grid>
        <Grid item xs={12} md={9} sx={{height: 1, p: 0.5, display: "flex", flexDirection: "column"}}>
          <Box sx={{flexGrow: 1}}>
            <Box sx={{borderBottom: 1, borderColor: "divider", mb: 2}}>
              <Tabs value={activeTab} onChange={handleTabClick} aria-label="crew details" variant="fullWidth">
                {tabsInfo.map(({label, icon, index, disabled}) => (
                  <Tab
                    key={index}
                    icon={icon}
                    disabled={disabled}
                    label={
                      <Typography color="text.secondary" variant="span" sx={{fontSize: "0.7rem"}}>
                        {label}
                      </Typography>
                    }
                  />
                ))}
              </Tabs>
            </Box>
            <Outlet />
          </Box>
        </Grid>
      </Grid>
      <CVUrlAlert signedZipURL={signedZipURL} send={send} />
    </Box>
  );
}
