import React, {useMemo} from "react";
import {Grid} from "@mui/material";
import {subYears} from "date-fns";
import {FormInputDateRange} from "lib/components";
import PropTypes from "prop-types";

export default function DocValidityForm({control, watch}) {
  const rangeLimits = useMemo(
    () => ({
      minFrom: subYears(new Date(), 80),
      maxFrom: new Date(),
      minTo: null,
      maxTo: null,
    }),
    []
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormInputDateRange
          control={control}
          watch={watch}
          dateFromLabel={"Issue Date"}
          dateToLabel={"Expiry Date"}
          dateFromName="issueDate"
          dateToName="expiryDate"
          dateToRequired={false}
          rangeLimits={rangeLimits}
        />
      </Grid>
    </Grid>
  );
}

DocValidityForm.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
};
