export default async function readImg(imgBlob) {
  return new Promise((resolve, reject) => {
    if (!imgBlob) resolve();

    const reader = new FileReader();

    reader.onload = ({target}) => resolve(target.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(imgBlob);
  });
}
