import {Auth} from "aws-amplify";

const customAttrs = () => {
  const date = new Date(new Date().toUTCString());
  return {"custom:createdAt": date.toISOString()};
};

const checkUserAttributes = async (user) => {
  const {identityId} = await Auth.currentUserCredentials();
  if (!user?.attributes) return user;
  if (user.attributes["custom:identityId"] !== identityId) {
    await Auth.updateUserAttributes(user, {"custom:identityId": identityId});
  }
  return user;
};

const services = {
  signIn: {
    src:
      (_, {data: {email, password}}) =>
      (sendBack) => {
        Auth.signIn(email, password)
          .then(checkUserAttributes)
          .then((data) => sendBack({type: "done", data}))
          .catch((data) => sendBack({type: "error", data}));
      },
  },
  forgotPassword: {
    src:
      ({userEmail}) =>
      (sendBack) => {
        Auth.forgotPassword(userEmail)
          .then(() => sendBack("forgotPasswordSent"))
          .catch((data) => sendBack({type: "forgotPasswordError", data}));
      },
  },
  forgotPasswordSubmit: {
    src:
      ({userEmail}, {data: {verificationCode, newPassword}}) =>
      (sendBack) => {
        Auth.forgotPasswordSubmit(userEmail, verificationCode, newPassword)
          .then(() => sendBack({type: "passwordResetDone", data: "Password has been successfully updated!"}))
          .catch((data) => sendBack({type: "passwordResetError", data}));
      },
  },
  completeNewPassword: {
    src:
      ({user}, {data: {newPassword}}) =>
      (sendBack) => {
        Auth.completeNewPassword(user, newPassword, customAttrs())
          .then(() => sendBack({type: "passwordResetDone", data: "Password has been successfully updated!"}))
          .catch((data) => sendBack({type: "completeNewPasswordError", data}));
      },
  },
  signUp: {
    src:
      (_, {data: {email, password, phoneNumber}}) =>
      (sendBack) => {
        Auth.signUp({
          username: email,
          password,
          attributes: {email, phone_number: phoneNumber, ...customAttrs()},
        })
          .then(() => sendBack("confirmationSent"))
          .catch((data) => sendBack({type: "signUpError", data}));
      },
  },
  confirmSignUp: {
    src:
      ({userEmail}, {data}) =>
      (sendBack) => {
        Auth.confirmSignUp(userEmail, data)
          .then(() => sendBack("signIn"))
          .catch((data) => sendBack({type: "confirmSignUpError", data}));
      },
  },
  resendSignUp: {
    src:
      ({userEmail}) =>
      (sendBack) => {
        Auth.resendSignUp(userEmail)
          .then(() => sendBack("confirmationSent"))
          .catch((data) => sendBack({type: "signUpError", data}));
      },
  },
};

export default services;
