import {matchAccount} from "machine/serviceMachines/marfinMachineSet/bankAccount";
import {assign, sendParent} from "xstate";

import {matchWallet} from "./eWallet";

const actions = {
  updateBankAccounts: assign({
    bankAccounts: ({bankAccounts}, {data}) => data?.bankAccounts ?? bankAccounts,
  }),
  updateWallets: assign({
    eWallets: ({eWallets}, {data}) => data?.eWallets ?? eWallets,
  }),
  updateWalletProviders: assign({
    eWalletProviders: ({eWalletProviders}, {data}) => data?.eWalletProviders ?? eWalletProviders,
  }),
  addBankAccount: assign({
    bankAccounts: ({bankAccounts}, {data}) => (data ? [...bankAccounts, data] : bankAccounts),
  }),
  deleteBankAccount: assign({
    bankAccounts: ({bankAccounts}, {data}) =>
      data ? bankAccounts.filter((item) => !matchAccount(item, data)) : bankAccounts,
  }),
  updateCountryTemplates: assign({
    countryTemplates: ({countryTemplates}, {data}) => data ?? countryTemplates,
  }),
  updateSelectedAccount: assign({
    bankAccount: ({bankAccount}, {data}) => ({...bankAccount, ...data?.bankAccount}),
    countryTemplates: ({countryTemplates}, {data}) => data?.countryTemplates ?? countryTemplates,
    bankAccounts: ({bankAccounts}, {data}) =>
      data.bankAccount
        ? bankAccounts.map((item) => (matchAccount(item, data.bankAccount) ? {...item, ...data.bankAccount} : item))
        : bankAccounts,
  }),
  updatePreferedAccount: assign({
    bankAccounts: ({bankAccounts}, {data}) =>
      bankAccounts.map((item) => {
        const match = data.find((_item) => matchAccount(item, _item));
        return match ? {...item, ...match} : item;
      }),
  }),
  addWallet: assign({
    eWallets: ({eWallets}, {data}) => (data ? [...eWallets, data] : eWallets),
  }),
  deleteWallet: assign({
    eWallets: ({eWallets}, {data}) => (data ? eWallets.filter((item) => !matchWallet(item, data)) : eWallets),
  }),
  updatePreferedWallet: assign({
    eWallets: ({eWallets}, {data}) =>
      eWallets.map((item) => {
        const match = data.find((_item) => matchWallet(item, _item));
        return match ? {...item, ...match} : item;
      }),
  }),
  sendAlert: sendParent((_, {data: {alert}}) => ({type: "alert", data: alert})),
  sendError: sendParent((_, {data}) => ({type: "updateError", data: data})),
  machineStartsWorking: assign({isMachineWorking: true}),
  machineStopsWorking: assign({isMachineWorking: false}),
  resetSelectedAccount: assign({bankAccount: null}),
  resetAllData: assign({
    bankAccounts: [],
    eWallets: [],
    countryTemplates: null,
    bankAccount: null,
  }),
  logger: (context, event, p, pp) => console.log("MARFIN Machine STATE EVENT CTX", event, context, p, pp),
};

export default actions;
