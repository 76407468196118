import {API, Auth, graphqlOperation} from "aws-amplify";
import {verifyRemitterToken} from "graphql/queries";

const IMAGE_URL = "https://www.crewbook.app/favicon_io/apple-touch-icon.png";

const services = {
  checkingAuth: {
    src: () => (sendBack) =>
      Auth.currentAuthenticatedUser({bypassCache: true}).then((currentUser) => {
        currentUser?.signInUserSession.idToken?.payload["cognito:groups"].indexOf("remitter") === -1
          ? sendBack({type: "SETUP_MFA", data: currentUser})
          : sendBack({type: "TOTP_INPUT", data: currentUser});
      }),
  },
  setupTOTP: {
    src: () => async (sendBack) => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser({bypassCache: true});
        const code = await Auth.setupTOTP(currentUser);
        const {email} = currentUser.attributes;
        const qrCodeStr = `otpauth://totp/CrewBook:${email}?secret=${code}&issuer=CrewBook&image=${IMAGE_URL}`;

        sendBack({type: "UPDATE_TOTP_SETUP", data: {code, qrCodeStr}});
      } catch (error) {
        sendBack({type: "setupTotpError", data: error});
      }
    },
  },
  verifyRemitterToken: {
    src:
      (_, {data: challengeAnswer}) =>
      (sendBack) => {
        API.graphql(graphqlOperation(verifyRemitterToken, {code: challengeAnswer.code}))
          .then(() => Auth.currentAuthenticatedUser({bypassCache: true}))
          .then((user) => sendBack({type: "SUCCESS", data: user}))
          .catch((data) => sendBack({type: "ERROR", data}));
      },
  },
  verifyTotpToken: {
    src:
      ({user}, {data: challengeAnswer}) =>
      (sendBack) => {
        Auth.verifyTotpToken(user, challengeAnswer.code)
          .then(() => sendBack({type: "SUCCESS"}))
          .catch((data) => sendBack({type: "ERROR", data}));
      },
  },
};

export default services;
