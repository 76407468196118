import React, {useEffect, useMemo} from "react";
import {useForm} from "react-hook-form";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@mui/material";
import {formatISO, parseISO} from "date-fns";
import {FormInputDate, FormInputText} from "lib/components";
import {dateToString} from "lib/helpers";
import PropTypes from "prop-types";

export default function DateToConfirmModal({isVisible, logEntry, handleSave, handleDiscard}) {
  const rangeLimits = useMemo(() => {
    const min = !logEntry ? null : parseISO(logEntry?.dateFrom);
    return {min, max: new Date()};
  }, [logEntry]);

  const initialFormState = useMemo(() => {
    const {rankTitle, vesselName, dateFrom} = logEntry || {};
    return {
      rankTitle: rankTitle || "",
      vesselName: vesselName || "",
      dateFrom: !dateFrom ? "" : dateToString(dateFrom),
      newDateTo: null,
    };
  }, [logEntry]);

  const methods = useForm({defaultValues: initialFormState, mode: "all"});
  const {handleSubmit, control, reset, formState} = methods;
  const {isValid} = formState;

  const handleClose = () => handleDiscard();

  const onSubmit = (data) => {
    if (isValid) {
      const {newDateTo} = data;
      handleSave(logEntry.id, formatISO(newDateTo, {representation: "date"}));
    }
  };

  useEffect(() => {
    reset(initialFormState);
  }, [reset, initialFormState]);

  return (
    <Dialog open={isVisible} onClose={handleClose}>
      <DialogTitle>Update Log Entry</DialogTitle>
      <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={{xs: 2, sm: 3}} mb={3}>
            <Grid item xs={12} sm={6}>
              <FormInputText name={"rankTitle"} control={control} label={"Rank"} fullWidth readOnly />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputText name={"vesselName"} control={control} label={"Vessel"} fullWidth readOnly />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputText name={"dateFrom"} control={control} label={"Date From"} fullWidth readOnly />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormInputDate control={control} label={"Date To"} name={"newDateTo"} rangeLimits={rangeLimits} />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{display: "flex", justifyContent: "space-between", px: 3, pb: 2}}>
          <Button
            onClick={handleClose}
            data-confirm={-1}
            variant="outlined"
            id="cancel-btn"
            color="error"
            sx={{color: "error"}}>
            Discard
          </Button>
          <Button
            type="submit"
            data-confirm={1}
            variant="outlined"
            id="confirm-btn"
            color="primary"
            sx={{color: "primary.dark"}}
            disabled={!isValid}>
            Save
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

DateToConfirmModal.propTypes = {
  isVisible: PropTypes.bool,
  logEntry: PropTypes.object,
  handleSave: PropTypes.func,
  handleDiscard: PropTypes.func,
};
