import dayjs from "dayjs";

const seaLogsColumns = [
  {
    field: "dateFrom",
    type: "date",
    title: "Date From",
  },
  {
    field: "dateTo",
    type: "date",
    title: "Date To",
    isEditable: ({row: {dateTo}}) => !dateTo,
  },
  {
    field: "days",
    type: "number",
    title: "Days",
    valueGetter: ({row: {dateTo, dateFrom}}) => (!dateTo ? "" : dayjs(dateTo).diff(dayjs(dateFrom), "day") + 1),
  },
  {
    field: "rankTitle",
    type: "string",
    title: "Rank",
  },
  {
    field: "vesselName",
    type: "string",
    title: "Vessel Name",
  },
  {
    field: "vesselType",
    type: "string",
    title: "Vessel Type",
  },
  {
    field: "tonnage",
    type: "number",
    title: "GRT",
  },
  {
    field: "company",
    type: "string",
    title: "Company",
  },
  {
    field: "engagementPlace",
    type: "string",
    title: "Place of Engagement",
  },
  {
    field: "dischargePlace",
    type: "string",
    title: "Place of Discharge",
  },
  {
    field: "operationArea",
    type: "string",
    title: "Area of Operation",
  },
];

export default seaLogsColumns;
