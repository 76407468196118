import React, {useCallback, useContext, useMemo, useState} from "react";
import {ArrowDropDown} from "@mui/icons-material";
import {Button, Card, CardActions, CardContent, Menu, MenuItem, Typography} from "@mui/material";
import {blue} from "@mui/material/colors";
import {useActor} from "@xstate/react";
import MachineContext from "lib/MachineContext";
import PropTypes from "prop-types";

export default function EmploymentActionCard({employment, onActionClick}) {
  const {id, employmentStatus, designatedRankTitle, vesselName} = employment;
  const [state] = useActor(useContext(MachineContext));
  const {employmentMachineRef} = state.context;
  const {employmentActions} = employmentMachineRef.state.context;
  const employmentStatusTitle = employmentStatus?.description || "";

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = useCallback(
    (action) => () => {
      setAnchorEl(null);
      action && onActionClick({action, employmentId: id});
    },
    [id, onActionClick]
  );
  const menuItems = useMemo(
    () =>
      !employmentStatus
        ? []
        : employmentStatus.crewActions.sort().map((action) => (
            <MenuItem key={action} onClick={handleClose(action)} sx={{color: "text.secondary"}}>
              {employmentActions[action].actionTitle}
            </MenuItem>
          )),
    [employmentStatus, employmentActions, handleClose]
  );

  return (
    <Card sx={{height: 1, backgroundColor: blue[50]}}>
      <CardContent sx={{textAlign: "center"}}>
        <Typography gutterBottom variant="body2" color="text.secondary">
          Employment status:
        </Typography>
        <Typography gutterBottom variant="h6">
          {employmentStatusTitle}
        </Typography>

        {!!designatedRankTitle && (
          <>
            <Typography gutterBottom variant="body2" color="text.secondary">
              Position:
            </Typography>
            <Typography gutterBottom variant="h6">
              {designatedRankTitle}
            </Typography>
            <Typography gutterBottom variant="body2" color="text.secondary">
              Vessel name:
            </Typography>
            <Typography gutterBottom variant="h6">
              {vesselName}
            </Typography>
          </>
        )}
      </CardContent>

      <CardActions sx={{justifyContent: "center"}}>
        {menuItems.length > 0 && (
          <>
            <Button
              id="menu-button"
              variant="outlined"
              aria-controls={open ? "action-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              endIcon={<ArrowDropDown />}>
              {"Actions"}
            </Button>
            <Menu
              id="action-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose()}
              MenuListProps={{"aria-labelledby": "menu-button"}}>
              {menuItems}
            </Menu>
          </>
        )}
      </CardActions>
    </Card>
  );
}

EmploymentActionCard.propTypes = {
  employment: PropTypes.object,
  onActionClick: PropTypes.func,
};
