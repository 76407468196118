/* eslint-disable max-len */
import React from "react";
import {Box, Typography} from "@mui/material";
import {PoliciesStyledBox} from "lib/components";

export default function About() {
  return (
    <PoliciesStyledBox>
      <Box sx={{fontSize: "1.2rem", width: {xs: "100%", sm: "90%"}}}>
        <p>
          The <strong>CrewBook</strong> is fully automated secure platform to manage your personal crew data, verify it
          with leading certificates providers, authorities and companies around maritime industry, preventing theft of
          documents, compromizing and forgery.
        </p>
        <p>
          The <strong>CrewBook</strong> is compliant with{" "}
          <a href="https://aws.amazon.com/compliance/gdpr-center/?sc_ichannel=ha&sc_icampaign=acq_awsblogsb&sc_icontent=security-resources">
            GDPR
          </a>{" "}
          and{" "}
          <a href="https://aws.amazon.com/compliance/iso-certified/">
            ISO/IEC 27001:2013, 27017:2015, 27018:2019, ISO/IEC 9001:2015, CSA STAR CCM v3.0.1.
          </a>
        </p>
        <p>
          Powered by AWS, the platform has 99.999999999% of fault tolerance, so you can be sure your data is available
          wherever you are.
        </p>
        <p>
          The <strong>CrewBook</strong> will help you efficiently manage your crew life with minimal expenses related to
          your Rank.
        </p>
        <p>
          Advanced platform algorithm will help you to be notified about your documents expiration, suggestions with
          further Rank opportunities based on your served sea hours and the further certification trainings to achieve
          your career expectation and prospects.
        </p>
        <p>
          All document requirements are compliant with (but not limited to)&nbsp;
          <a href="https://www.imo.org/en">
            <img className="icon" src="/images/IMOlogo.png" alt="IMO logo" width="110rem" />
          </a>
          &nbsp;and&nbsp;
          <a href="https://www.imca-int.com">
            <img className="icon" src="/images/IMCAlogo.svg" alt="IMCA logo" width="70rem" />
          </a>
          &nbsp; basic regulations.
        </p>

        <Typography variant="h4" sx={{textAlign: "end"}}>
          Welcome Aboard!
        </Typography>
      </Box>
    </PoliciesStyledBox>
  );
}
