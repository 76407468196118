import React, {useCallback, useContext, useMemo, useState} from "react";
import {NavLink} from "react-router-dom";
import {AccountBox, Article, AttachMoneyOutlined, Badge as BadgeIcon, Waves} from "@mui/icons-material";
import {Avatar, Box, Button, Divider, Link, Menu, MenuItem, Tooltip, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useActor} from "@xstate/react";
import {MachineContext} from "lib";
import PropTypes from "prop-types";

import UserMenuItem from "./UserMenuItem";

const menuActiveStyle = {textDecoration: "underline", opacity: 1};
const menuNonActiveStyle = {textDecoration: "none", opacity: 0.7};
const btnStyles = {color: "inherit", textDecoration: "inherit", "&:hover": {backgroundColor: "primary.light"}};

export default function UserMenu({handleTourGuide}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, send] = useActor(useContext(MachineContext));
  const {user, employmentMachineRef, profileMachineRef} = state.context;
  const {crewProfile} = profileMachineRef?.state.context || {};
  const initials = !crewProfile?.firstName
    ? user?.attributes.email.slice(0, 2).toUpperCase()
    : `${crewProfile.firstName.slice(0, 1)}${crewProfile.lastName.slice(0, 1)}`.toUpperCase();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isAccountBlocked = useMemo(() => !!state.context.accountBlockInfo, [state.context.accountBlockInfo]);
  const employmentUpdates = useMemo(
    () => employmentMachineRef?.state.context.employmentUpdates || {},
    [employmentMachineRef?.state.context.employmentUpdates]
  );
  const updatesNumber = useMemo(() => employmentUpdates?.size || 0, [employmentUpdates]);
  const mainUserMenu = useMemo(
    () => [
      {
        to: "/profile",
        title: "Profile",
        className: "tour-step-profile",
        icon: <AccountBox />,
        isVisible: !isAccountBlocked,
      },
      {
        to: "/crewdocs",
        title: "Docs",
        className: "tour-step-crewdocs",
        icon: <Article />,
        isVisible: !isAccountBlocked,
      },
      {to: "/crewlogs", title: "Logs", className: "tour-step-crewlogs", icon: <Waves />, isVisible: !isAccountBlocked},
      {to: "/finance", title: "Finance", icon: <AttachMoneyOutlined />, isVisible: !isAccountBlocked},
      {
        to: "/employment",
        title: "Employment",
        className: "tour-step-employment",
        updates: updatesNumber,
        icon: <BadgeIcon />,
        isVisible: !isAccountBlocked,
      },
    ],
    [isAccountBlocked, updatesNumber]
  );
  const onSignOut = useCallback(() => send({type: "signOut"}), [send]);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleTourClick = useCallback(() => {
    handleTourGuide();
    handleClose();
  }, [handleClose, handleTourGuide]);
  const accountMenu = useMemo(
    () => [
      {
        to: "/",
        title: "Tour Guide",
        onClick: handleTourClick,
        isVisible: !isSmallScreen && !isAccountBlocked,
        isLink: false,
      },
      {
        to: "/account",
        title: "My Account",
        onClick: handleClose,
        isVisible: !isAccountBlocked,
        isLink: true,
      },
      {
        to: "/notifications",
        title: "Notifications",
        onClick: handleClose,
        isVisible: !isAccountBlocked,
        isLink: true,
      },
      {
        to: "/subscription",
        title: "Subscription",
        onClick: handleClose,
        isVisible: true,
        isLink: true,
      },
      {to: "/", title: "Sign Out", onClick: onSignOut, isVisible: true, isLink: false},
    ],
    [isAccountBlocked, handleClose, handleTourClick, isSmallScreen, onSignOut]
  );

  return (
    <Box sx={{display: "flex"}}>
      {mainUserMenu.reduce(
        (result, item) =>
          !item.isVisible
            ? result
            : [
                ...result,
                <UserMenuItem menuItem={item} key={item.to} btnStyles={btnStyles} isSmallScreen={isSmallScreen} />,
              ],
        []
      )}
      <Button
        variant="outlined"
        aria-controls="user-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{...btnStyles, opacity: 0.7}}>
        <Tooltip title="Account">
          <Avatar sx={{bgcolor: "white", width: "2rem", height: "2rem"}}>
            <Typography color="primary.main" variant="button">
              {initials}
            </Typography>
          </Avatar>
        </Tooltip>
      </Button>
      <Menu
        elevation={2}
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
        transformOrigin={{vertical: "top", horizontal: "right"}}
        MenuListProps={{"aria-labelledby": "user-menu"}}
        onClose={handleClose}>
        <MenuItem disabled>{user?.attributes?.email}</MenuItem>
        {accountMenu.reduce((result, {to, title, onClick, isVisible, isLink}, index) => {
          if (!isVisible) return result;

          result.length > 0 && result.push(<Divider key={index} variant="middle" />);
          result.push(
            <MenuItem key={title} onClick={onClick} sx={{minWidth: "12rem"}}>
              <Link
                end
                component={NavLink}
                color="inherit"
                width={1}
                underline="none"
                to={to}
                style={({isActive}) => (isActive && isLink ? menuActiveStyle : menuNonActiveStyle)}>
                {title}
              </Link>
            </MenuItem>
          );
          return result;
        }, [])}
      </Menu>
    </Box>
  );
}

UserMenu.propTypes = {
  handleTourGuide: PropTypes.func,
};
